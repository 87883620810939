import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { textProps } from "./types";

const Text: FC<textProps> = ({ type, children, ...typoGraphyProps }) => {
  return (
    <Box>
      <Typography sx={textStyles[type]} {...typoGraphyProps}>
        {children}
      </Typography>
    </Box>
  );
};

const textStyles = {
  infoComponentSmall: {
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0em",
    fontWeight: 400,
    fontFamily: "Graphik",
  },
  extraSmall: {
    fontSize: "12px",
    letterSpacing: "0em",
    lineHeight: "18px",
    fontFamily: "Graphik",
  },
  listSmall: {
    fontSize: "13px",
    lineHeight: "14px",
    fontFamily: "Graphik",
  },
  lightMessage: {
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: 0.32,
    fontFamily: "Graphik",
    opacity: 0.6,
  },
  lightMessageCenter: {
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: 0.32,
    fontFamily: "Graphik",
    textAlign: "center",
    opacity: 0.6,
  },
  small: {
    fontSize: "14px",
    letterSpacing: "0em",
    lineHeight: "18px",
    fontFamily: "Graphik",
  },
  extraSmallTransparent: {
    fontSize: "12px",
    letterSpacing: "0em",
    lineHeight: "14px",
    fontFamily: "Graphik",
    opacity: 0.7,
  },
  smallTransparent: {
    fontSize: "14px",
    letterSpacing: "0em",
    lineHeight: "18px",
    fontFamily: "Graphik",
    opacity: 0.7,
  },
  smallTransparentCenter: {
    fontSize: "14px",
    letterSpacing: "0.01em",
    lineHeight: "18px",
    fontFamily: "Graphik",
    textAlign: "center",
    opacity: 0.7,
  },
  medium: {
    fontSize: "16px",
    letterSpacing: "0em",
    lineHeight: "20px",
    fontFamily: "Graphik",
  },
  listMedium: {
    fontSize: "15px",
    letterSpacing: "0em",
    lineHeight: "20px",
    fontFamily: "Graphik",
  },
  dialogText: {
    fontSize: "15px",
    fontWeight: 400,
    letterSpacing: "0em",
    lineHeight: "20px",
    fontFamily: "Graphik",
    textAlign: "center",
  },
  large: {
    fontSize: "18px",
    letterSpacing: "0.16px",
    lineHeight: "22px",
    fontFamily: "Graphik",
  },
  nameLarge: {
    fontSize: "20px",
    letterSpacing: "0em",
    lineHeight: "26px",
    fontFamily: "Graphik",
  },
  extraLarge: {
    fontSize: "22px",
    letterSpacing: "0em",
    lineHeight: "26px",
    fontFamily: "Graphik",
  },
  listSmallBold: {
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "14px",
    fontFamily: "Graphik",
  },
  dataSmallBold: {
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "16px",
    fontFamily: "Graphik",
    letterSpacing: "-0.32px",
  },
  dataMediumBold: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "16x",
    fontFamily: "Graphik",
  },
  dataLightMessage: {
    fontSize: "13px",
    lineHeight: "16px",
    letterSpacing: "-0.32px",
    fontFamily: "Graphik",
    opacity: 0.6,
  },
  smallBold: {
    fontWeight: 600,
    fontSize: "14px",
    letterSpacing: "0em",
    lineHeight: "18px",
    fontFamily: "Graphik",
  },
  smallBoldCenter: {
    fontWeight: 600,
    fontSize: "14px",
    letterSpacing: "0em",
    lineHeight: "18px",
    fontFamily: "Graphik",
    textAlign: "center",
  },
  mediumBold: {
    fontWeight: 600,
    fontSize: "16px",
    letterSpacing: "0em",
    lineHeight: "20px",
    fontFamily: "Graphik",
  },
  listMediumBold: {
    fontWeight: 600,
    fontSize: "15px",
    letterSpacing: "0em",
    lineHeight: "20px",
    fontFamily: "Graphik",
  },
  largeBold: {
    fontWeight: 600,
    fontSize: "18px",
    letterSpacing: "0em",
    lineHeight: "22px",
    fontFamily: "Graphik",
  },
  largeBoldCenter: {
    fontWeight: 600,
    fontSize: "18px",
    letterSpacing: "0em",
    lineHeight: "22px",
    fontFamily: "Graphik",
    textAlign: "center",
  },
  extraLargeBold: {
    fontSize: "22px",
    fontWeight: 600,
    letterSpacing: "0em",
    lineHeight: "32px",
    fontFamily: "Graphik",
  },
  successLargeBold: {
    fontSize: "32px",
    lineHeight: "40px",
    fontWeight: 600,
    letterSpacing: "0em",
    fontFamily: "InvescoEditor",
  },
  subHeading: {
    lineHeight: "31px",
    letterSpacing: "-0.33px",
    fontSize: "24px",
    fontFamily: "Graphik",
    textAlign: "center",
  },
  ekycHeadingLarge: {
    fontSize: "20px",
    lineHeight: "20px",
    fontWeight: 600,
    letterSpacing: "0em",
    fontFamily: "InvescoEditor",
  },
  introTitleHeading: {
    fontSize: "20px",
    lineHeight: "20px",
    fontWeight: 600,
    letterSpacing: "-0.32px",
    fontFamily: "InvescoEditor",
  },
  subHeadingBold: {
    lineHeight: "31px",
    letterSpacing: "-0.33px",
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: "Graphik",
  },
  headingBold: {
    fontSize: "40px",
    fontWeight: 600,
    lineHeight: "52px",
    letterSpacing: "-0.56px",
    fontFamily: "Graphik",
  },
  invalidKycMessage: {
    fontSize: "26px",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: "0em",
    fontFamily: "Graphik",
  },
  error: {
    fontSize: "16px",
    letterSpacing: "0em",
    lineHeight: "20px",
    fontFamily: "Graphik",
    color: "red",
  },
} as const;
export default Text;
