import { Box, Button } from "@mui/material";
import Text from "components/Text";
import { FC, useEffect, useRef, useState } from "react";
import colors from "res/colors";
import strings from "res/strings";
import { otpProps } from "./types";

const OtpTimer: FC<otpProps> = ({ onResend }) => {
  const timer = useRef<any>(null);
  const time = useRef<number>(60);
  const [displayTime, updateDisplayTime] = useState("00:00:60");
  useEffect(() => {
    timer.current = setInterval(updateTimer, 1000);
    return function cleanup() {
      clearInterval(timer.current);
    };
  }, []);

  const updateTimer = () => {
    time.current = time.current - 1;
    const _time = new Date(time.current * 1000).toISOString().substr(11, 8);
    updateDisplayTime(_time);
    if (time.current === 0) {
      clearInterval(timer.current);
    }
  };

  const handleResend = () => {
    onResend();
    time.current = 30;
    const _time = new Date(time.current * 1000).toISOString().substr(11, 8);
    updateDisplayTime(_time);
    timer.current = setInterval(updateTimer, 1000);
  };
  if (time.current === 0) {
    return (
      <Box sx={styles.container}>
        <Text type="lightMessage">{strings.didntGetOtp}</Text>
        <Button
          variant="text"
          sx={styles.resendOtpStyle}
          onClick={handleResend}
        >
          Resend OTP
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      <Text type="lightMessage">{strings.resentOtp}</Text>
      <Box sx={{ mr: 1 }}></Box>
      <Text color={colors.infoTextGreen} type="lightMessage">
        {displayTime}
      </Text>
    </Box>
  );
};

const styles = {
  container: {
    marginVerticle: "10px",
    display: "flex",
    flexDirection: "row",
  },
  resendOtpStyle: {
    color: colors.backGroundBlue,
    paddingTop: "0px",
    paddingBottom: "0px",
    textTransform: "none",
  },
} as const;

export default OtpTimer;
