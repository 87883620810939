import {
  Box,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Text from "components/Text";
import strings from "res/strings";
import colors from "res/colors";
import Input from "components/Input";
import { ChangeEvent, useState, useEffect, useRef } from "react";
import ButtonIcon from "components/ButtonIcon";
import isMobile from "utils/isMobile";
import {
  editTransaction,
  resetNomineeProceedPressed,
  setNomineeDetails,
  setNomineeProceedPressed,
  setTransactionDetails,
  resetEditTransactionError,
} from "store/transaction/slice";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import {
  getEditTransacation,
  ONLY_NUM_REG_EXP,
  TransactionType,
} from "utils/constants";
import ActivityOverlay from "components/ActivityOverlay/ActivityOverlay";
import Select from "components/Select";
import { SelectOption } from "components/FormSelect";
import moment from "moment";
import DateInput from "components/DateInput";
import Info from "components/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RouteConfig from "routes/RouteConfig";

const relationOption = [
  {
    title: "Father",
    value: "Father",
  },
  {
    title: "Mother",
    value: "Mother",
  },
  {
    title: "Court Appointed Guardian",
    value: "Court Appointed Guardian",
  },
];
export const typeOfGuardianOptions = [
  { title: "Natural Guardian", value: "Natural Guardian" },
  { title: "Court Appointed Guardian", value: "Court Appointed Guardian" },
];
const NomineeAdd = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const { ihNo } = useAppSelector((state) => state.appState);
  const { ihNo, transactionType } = useAppSelector((state) => state.appState);
  const {
    transactionSchemeDetails,
    editTransactionInfo,
    transactionDetails,
    isEditingTransaction,
    didEditTransaction,
    editTransactionError,
    nomineeDetails,
    nomineeProceedPressed,
  } = useAppSelector((state) => state.transaction);
  let converter = require("number-to-words");
  const elementRef = useRef<any>(null);
  const nameRegex = /^[a-zA-Z ]+$/;
  const panRegex = /^[a-zA-Z0-9]+$/;
  const [nomineeAddress, setNomineeAddress] = useState("");
  const [nomineeAddressChecked, setNomineeAddressChecked] = useState(false);
  const [nomineeName, setNomineeName] = useState("");
  const [nomineeDob, setNomineeDob] = useState<any>(null);
  const [nomineeIsMinor, setNomineeIsMinor] = useState(false);
  const [nomineePan, setNomineePan] = useState("");
  const [allocation, setAllocation] = useState("");
  const [relationshipWithInvestor, setRelationshipWithInvestor] = useState("");
  const [guardianName, setGuardianName] = useState("");
  const [guardianPan, setGuardianPan] = useState("");
  const [guardianEmail, setGuardianEmail] = useState("");
  const [relationshipWithGuardian, setRelationshipWithGuardian] = useState("");
  const [typeOfGuardian, setTypeOfGuardian] = useState("");
  const [totalAllocation, setTotalAllocation] = useState(0);

  const [nomineeNameError, setNomineeNameError] = useState("");
  const [nomineeAddressError, setNomineeAddressError] = useState("");
  const [nomineePanError, setNomineePanError] = useState("");
  const [nomineeDobError, setNomineeDobError] = useState("");
  const [relationshipWithInvestorError, setRelationshipWithInvestorError] =
    useState("");
  const [allocationError, setAllocationError] = useState("");

  const [guardianNameError, setGuardianNameError] = useState("");
  const [guardianPanError, setGuardianPanError] = useState("");
  const [guardianEmailError, setGuardianEmailError] = useState("");
  const [relationshipWithGuardianError, setRelationshipWithGuardianError] =
    useState("");
  const [typeOfGuardianError, setTypeOfGuardianError] = useState("");
  const [tempNomineeDetailsSetting, setTempNomineeDetailsSetting] =
    useState<any>(null);

  useEffect(() => {
    elementRef?.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    if (didEditTransaction) {
      createNewTransactionDetails(tempNomineeDetailsSetting);
      clearPreviousNomineeDetails();
      transactionType === TransactionType.newLumpsum
        ? navigate(RouteConfig.newLumpsumTransactionDetails)
        : navigate(RouteConfig.newSipTransactionDetails);
      dispatch(resetEditTransactionError());
    }
  }, [didEditTransaction]);

  const handleCloseButtonResetNominee = () => {
    const transactionData = transactionDetails?.fetchFreshCartDataResponseList;
    let nominee1Details = null;
    let nominee2Details = null;
    let nominee3Details = null;
    let nomineeData = [];
    if (transactionData?.nominee1Name) {
      nominee1Details = {
        nomineeAddress: transactionData?.nominee1Address,
        nomineeAllocation: transactionData?.nominee1Allocation,
        nomineeDOB: transactionData?.nominee1DOB,
        nomineeGuardianName: transactionData?.nominee1GuardianName,
        nomineeGuardianPan: transactionData?.nominee1GuardianPan,
        nomineeName: transactionData?.nominee1Name,
        nomineePan: transactionData?.nominee1Pan,
        nomineeProofOfRelationship:
          transactionData?.nominee1ProofOfRelationship,
        nomineeRelation: transactionData?.nominee1Relation,
        nomineeRelationshipWithGuardian:
          transactionData?.nominee1RelationshipWithGuardian,
        nomineeTypeoOfGuardian: transactionData?.nominee1TypeoOfGuardian,
      };
      nomineeData.push(nominee1Details);
    }
    if (transactionData?.nominee2Name) {
      nominee2Details = {
        nomineeAddress: transactionData?.nominee2Address,
        nomineeAllocation: transactionData?.nominee2Allocation,
        nomineeDOB: transactionData?.nominee2DOB,
        nomineeGuardianName: transactionData?.nominee2GuardianName,
        nomineeGuardianPan: transactionData?.nominee2GuardianPan,
        nomineeName: transactionData?.nominee2Name,
        nomineePan: transactionData?.nominee2Pan,
        nomineeProofOfRelationship:
          transactionData?.nominee2ProofOfRelationship,
        nomineeRelation: transactionData?.nominee2Relation,
        nomineeRelationshipWithGuardian:
          transactionData?.nominee2RelationshipWithGuardian,
        nomineeTypeoOfGuardian: transactionData?.nominee2TypeoOfGuardian,
      };
      nomineeData.push(nominee2Details);
    }
    if (transactionData?.nominee3Name) {
      nominee3Details = {
        nomineeAddress: transactionData?.nominee3Address,
        nomineeAllocation: transactionData?.nominee3Allocation,
        nomineeDOB: transactionData?.nominee3DOB,
        nomineeGuardianName: transactionData?.nominee3GuardianName,
        nomineeGuardianPan: transactionData?.nominee3GuardianPan,
        nomineeName: transactionData?.nominee3Name,
        nomineePan: transactionData?.nominee3Pan,
        nomineeProofOfRelationship:
          transactionData?.nominee3ProofOfRelationship,
        nomineeRelation: transactionData?.nominee3Relation,
        nomineeRelationshipWithGuardian:
          transactionData?.nominee3RelationshipWithGuardian,
        nomineeTypeoOfGuardian: transactionData?.nominee3TypeoOfGuardian,
      };
      nomineeData.push(nominee3Details);
    }
    dispatch(setNomineeDetails(nomineeData));
  };

  const handleScreenCancel = () => {
    handleCloseButtonResetNominee();
    if (editTransactionError) {
      dispatch(resetEditTransactionError());
    }
    transactionType === TransactionType.newLumpsum
      ? navigate(RouteConfig.newLumpsumTransactionDetails)
      : navigate(RouteConfig.newSipTransactionDetails);
  };
  const handleNomineeName = (event: ChangeEvent<HTMLInputElement>) => {
    setNomineeNameError("");
    if (nameRegex.test(event?.target?.value) || event?.target?.value === "") {
      if (
        event?.target?.value?.toLowerCase()?.trim() ===
        transactionDetails?.fetchFreshCartDataResponseList?.ap1LastName
          ?.toLowerCase()
          ?.trim()
      ) {
        setNomineeNameError("Nominee name can't be same as applicant name.");
      }
      setNomineeName(event.target.value);
    }
  };
  const handleNomineePan = (event: ChangeEvent<HTMLInputElement>) => {
    setNomineePanError("");
    if (panRegex.test(event.target.value) || event?.target?.value === "") {
      setNomineePan(event.target.value?.toUpperCase());
    }
  };

  const handleNomineeAllocation = (event: ChangeEvent<HTMLInputElement>) => {
    setAllocationError("");
    const data = event?.target?.value;

    if (ONLY_NUM_REG_EXP?.test(data) || data === "") {
      if (parseFloat(data) > 100 || totalAllocation + parseFloat(data) > 100) {
        setAllocation(data);
        setAllocationError("Total allocation can not be more than 100%");
      } else if (data) {
        setAllocation(data);
      } else {
        setAllocation("");
      }
    }
  };
  const handleNomineeRelationship = (event: ChangeEvent<HTMLInputElement>) => {
    setRelationshipWithInvestor(event.target.value);
    setRelationshipWithInvestorError("");
  };
  const handleNomineeAdressChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setNomineeAddressChecked(event.target.checked);
    setNomineeAddressError("");
    if (event.target.checked) {
      setSameAsApplicantAddress();
    } else {
      setNomineeAddress("");
    }
  };
  const handleNomineeAddress = (event: ChangeEvent<HTMLInputElement>) => {
    setNomineeAddress(event.target.value);
    setNomineeAddressError("");
  };
  const handleNomineeDob = (dob: any) => {
    const dobformat = moment(dob).format("DD-MMM-YYYY");

    const minor = checkIsMinor(dobformat);
    setNomineeIsMinor(minor);
    setNomineeDob(dobformat);
    setNomineeDobError("");
    setGuardianName("");
    setGuardianPan("");
    setGuardianEmail("");
    setTypeOfGuardian("");
    setRelationshipWithGuardian("");
  };

  const handleNomineeGaurdianName = (event: ChangeEvent<HTMLInputElement>) => {
    setGuardianNameError("");
    if (nameRegex.test(event?.target?.value) || event?.target?.value === "") {
      setGuardianName(event.target.value);
    }
  };
  const handleNomineeGaurdianPan = (event: ChangeEvent<HTMLInputElement>) => {
    setGuardianPanError("");
    if (panRegex.test(event.target.value) || event?.target?.value === "") {
      setGuardianPan(event.target.value?.toUpperCase());
    }
  };
  const handleNomineeGaurdianEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setGuardianEmail(event.target.value);
    setGuardianEmailError("");
  };
  const handleSelectedGaurdianRelation = (option: SelectOption) => {
    const value = option?.value;
    if (
      value === relationOption?.[0]?.value ||
      value === relationOption?.[1]?.value
    ) {
      setTypeOfGuardian(typeOfGuardianOptions?.[0]?.value);
    }
    if (value === relationOption?.[2]?.value) {
      setTypeOfGuardian(typeOfGuardianOptions?.[1]?.value);
    }
    setRelationshipWithGuardian(value);
    setRelationshipWithGuardianError("");
    setTypeOfGuardianError("");
  };

  const checkIsMinor = (age: any) => {
    return moment().diff(moment(age), "years") >= 18 ? false : true;
  };

  const getTotalAllocation = () => {
    const val = totalAllocation + parseFloat(allocation);
    return val ? val : 0;
  };

  const createNewTransactionDetails = (nomineeValues: any) => {
    const transactionData = {
      ...transactionDetails?.fetchFreshCartDataResponseList,
      ...nomineeValues,
    };
    const finalTransactionData = {
      fetchFreshCartDataResponseList: transactionData,
    };
    dispatch(setTransactionDetails(finalTransactionData));
  };

  const handleEditTransactionCall = () => {
    const editTransactionData = {
      transactionType:
        transactionType === TransactionType.newLumpsum ? "Lumpsum" : "SIP",
      batchNo: transactionDetails?.fetchFreshCartDataResponseList?.batchNo,
      transactionDateTime:
        transactionDetails?.fetchFreshCartDataResponseList?.transactionDateTime,
      activity: "",
      newUser: true,
    };

    const nomineeDetailsTemp = {
      nominee1Address: nomineeDetails?.[0]?.nomineeAddress || "",
      nominee1Allocation: nomineeDetails?.[0]?.nomineeAllocation || "",
      nominee1DOB: nomineeDetails?.[0]?.nomineeDOB || "",
      nominee1GuardianName: nomineeDetails?.[0]?.nomineeGuardianName || "",
      nominee1GuardianPan: nomineeDetails?.[0]?.nomineeGuardianPan || "",
      nominee1Name: nomineeDetails?.[0]?.nomineeName || "",
      nominee1Pan: nomineeDetails?.[0]?.nomineePan || "",
      nominee1Relation: nomineeDetails?.[0]?.nomineeRelation || "",
      nominee1RelationshipWithGuardian:
        nomineeDetails?.[0]?.nomineeRelationshipWithGuardian || "",
      nominee1TypeoOfGuardian:
        nomineeDetails?.[0]?.nomineeTypeoOfGuardian || "",

      nominee2Address: nomineeDetails?.[1]?.nomineeAddress || "",
      nominee2Allocation: nomineeDetails?.[1]?.nomineeAllocation || "",
      nominee2DOB: nomineeDetails?.[1]?.nomineeDOB || "",
      nominee2GuardianName: nomineeDetails?.[1]?.nomineeGuardianName || "",
      nominee2GuardianPan: nomineeDetails?.[1]?.nomineeGuardianPan || "",
      nominee2Name: nomineeDetails?.[1]?.nomineeName || "",
      nominee2Pan: nomineeDetails?.[1]?.nomineePan || "",
      nominee2Relation: nomineeDetails?.[1]?.nomineeRelation || "",
      nominee2RelationshipWithGuardian:
        nomineeDetails?.[1]?.nomineeRelationshipWithGuardian || "",
      nominee2TypeoOfGuardian:
        nomineeDetails?.[1]?.nomineeTypeoOfGuardian || "",

      nominee3Address: nomineeDetails?.[2]?.nomineeAddress || "",
      nominee3Allocation: nomineeDetails?.[2]?.nomineeAllocation || "",
      nominee3DOB: nomineeDetails?.[2]?.nomineeDOB || "",
      nominee3GuardianName: nomineeDetails?.[2]?.nomineeGuardianName || "",
      nominee3GuardianPan: nomineeDetails?.[2]?.nomineeGuardianPan || "",
      nominee3Name: nomineeDetails?.[2]?.nomineeName || "",
      nominee3Pan: nomineeDetails?.[2]?.nomineePan || "",
      nominee3Relation: nomineeDetails?.[2]?.nomineeRelation || "",
      nominee3RelationshipWithGuardian:
        nomineeDetails?.[2]?.nomineeRelationshipWithGuardian || "",
      nominee3TypeoOfGuardian:
        nomineeDetails?.[2]?.nomineeTypeoOfGuardian || "",

      idonotWishToNominee: "Y",
    };

    const finalEditTransactionData = {
      ...transactionDetails?.fetchFreshCartDataResponseList,
      ...nomineeDetailsTemp,
      ...editTransactionData,
    };
    setTempNomineeDetailsSetting(nomineeDetailsTemp);
    dispatch(editTransaction(finalEditTransactionData));
    // createNewTransactionDetails(nomineeDetailsTemp);
    // clearPreviousNomineeDetails();
    // navigate(RouteConfig.newLumpsumTransactionDetails);
  };

  useEffect(() => {
    if (nomineeDetails) {
      let totalInvestmentStored = 0;
      nomineeDetails?.map((ele: any) => {
        totalInvestmentStored += parseFloat(ele?.nomineeAllocation);
      });
      setTotalAllocation(totalInvestmentStored);
    }
  }, [nomineeDetails]);

  useEffect(() => {
    if (nomineeProceedPressed) {
      handleEditTransactionCall();
    }

    return () => {
      dispatch(resetNomineeProceedPressed());
    };
  }, [nomineeProceedPressed]);

  const setSameAsApplicantAddress = () => {
    let applicantAddress = [
      transactionDetails?.fetchFreshCartDataResponseList?.ap1Address1,
      transactionDetails?.fetchFreshCartDataResponseList?.ap1Address2,

      transactionDetails?.fetchFreshCartDataResponseList?.ap1City,

      transactionDetails?.fetchFreshCartDataResponseList?.ap1State,

      transactionDetails?.fetchFreshCartDataResponseList?.ap1CountryOfResidence,
      transactionDetails?.fetchFreshCartDataResponseList?.ap1PinCode,
    ]
      ?.filter((element) => Boolean(element))
      .join(", ");
    setNomineeAddress(applicantAddress);
  };

  const checkForNomineeError = () => {
    let isError = false;
    if (!nomineeName) {
      isError = true;
      setNomineeNameError("Please enter nominee name.");
    }
    if (!nomineeAddress) {
      isError = true;
      setNomineeAddressError("Please enter nominee address.");
    }
    if (!nomineeDob) {
      isError = true;
      setNomineeDobError("Please enter nominee date of birth.");
    }
    if (nomineeDob === "Invalid date") {
      isError = true;
      setNomineeDobError("Please enter valid nominee date of birth.");
    }
    if (nomineePan && nomineePan?.length < 10) {
      isError = true;
      setNomineePanError("Please enter valid nominee PAN.");
    }
    if (!relationshipWithInvestor) {
      isError = true;
      setRelationshipWithInvestorError(
        "Please enter relationship with investor."
      );
    }
    if (!allocation) {
      isError = true;
      setAllocationError("Please enter nominee allocation.");
    }
    return isError;
  };

  const checkForMinorError = () => {
    let isError = false;
    if (!guardianName) {
      isError = true;
      setGuardianNameError("Please enter gaurdian name.");
    }
    if (!guardianEmail) {
      isError = true;
      setGuardianEmailError("Please enter gaurdian email.");
    }
    if (!relationshipWithGuardian) {
      isError = true;
      setRelationshipWithGuardianError(
        "Please enter relationship with gaurdian."
      );
    }
    if (!guardianPan) {
      isError = true;
      setGuardianPanError("Please enter gaurdian PAN.");
    }
    if (!typeOfGuardian) {
      isError = true;
      setTypeOfGuardianError("Please enter type of gaurdian.");
    }
    return isError;
  };

  const clearPreviousNomineeDetails = () => {
    setNomineeAddress("");
    setNomineeAddressChecked(false);
    setNomineeName("");
    setNomineeDob(null);
    setNomineeIsMinor(false);
    setNomineePan("");
    setAllocation("");
    setRelationshipWithInvestor("");
    setGuardianName("");
    setGuardianPan("");
    setGuardianEmail("");
    setRelationshipWithGuardian("");
    setTypeOfGuardian("");
  };

  const addNomineeDetails = () => {
    let tempNomineeDetails = [...nomineeDetails];
    const nominieeData = {
      nomineeAddress: nomineeAddress,
      nomineeAllocation: allocation,
      nomineeDOB: nomineeDob,
      nomineeGuardianName: guardianName,
      nomineeGuardianPan: guardianPan,
      nomineeName: nomineeName,
      nomineePan: nomineePan,
      nomineeProofOfRelationship: "",
      nomineeRelation: relationshipWithInvestor,
      nomineeRelationshipWithGuardian: relationshipWithGuardian,
      nomineeTypeoOfGuardian: typeOfGuardian,
    };
    tempNomineeDetails.push(nominieeData);

    dispatch(setNomineeDetails(tempNomineeDetails));
  };

  const handleAddNomineeClicked = () => {
    if (checkForNomineeError()) {
      return;
    }
    if (nomineeIsMinor && checkForMinorError()) {
      return;
    }
    const total = getTotalAllocation();
    setTotalAllocation(total);
    addNomineeDetails();
    clearPreviousNomineeDetails();
  };

  const handleProceedClicked = () => {
    if (checkForNomineeError()) {
      return;
    }
    if (nomineeIsMinor && checkForMinorError()) {
      return;
    }
    addNomineeDetails();
    dispatch(setNomineeProceedPressed());
  };

  const renderNomineeMinorSection = () => {
    return (
      <>
        <Box sx={{ pt: "10px" }}>
          <Info
            message={strings.minorNomineeMessage}
            iconPosition={"center"}
            icon={<InfoOutlinedIcon sx={{ color: colors.infoTextBlue }} />}
            textStyle={{ fontSize: "15px" }}
          />
        </Box>
        <Grid item>
          <Text type="smallBold">{strings.minorNomineeGaurdian}</Text>
          <Box sx={{ my: "12px" }} />
          <Input
            value={guardianName}
            size={"small"}
            placeholder={"Name"}
            onChange={handleNomineeGaurdianName}
            error={guardianNameError}
          />
          <Box sx={{ my: "18px" }} />
          <Input
            value={guardianPan}
            size={"small"}
            placeholder={"PAN"}
            onChange={handleNomineeGaurdianPan}
            inputProps={{ maxLength: 10 }}
            error={guardianPanError}
          />
          <Box sx={{ my: "18px" }} />
          <Input
            value={guardianEmail}
            size={"small"}
            placeholder={"Email"}
            onChange={handleNomineeGaurdianEmail}
            error={guardianEmailError}
          />
          <Box sx={{ my: "18px" }} />
          <Select
            placeHolder={strings.gaurdianRelationship}
            value={relationshipWithGuardian}
            options={relationOption}
            onOptionSelect={handleSelectedGaurdianRelation}
            errorMessage={relationshipWithGuardianError}
            errorStyle={{
              fontSize: "14px",
              letterSpacing: "0em",
              lineHeight: "20px",
              // fontFamily: "Graphik",
              ml: "10px",
              my: "5px",
              color: "#d63227",
            }}
          />
          <Box sx={{ my: "18px" }} />
          {relationshipWithGuardian ? (
            <>
              <Text type="smallBold">{strings.typeOfGaurdian}</Text>
              <Box sx={{ my: "8px" }} />
              <Input
                value={typeOfGuardian}
                size={"small"}
                onChange={() => {}}
                error={typeOfGuardianError}
                inputProps={{ readOnly: true }}
              />
            </>
          ) : null}
          <Box sx={{ mb: "8px", mt: "24px" }}>
            <Divider />
          </Box>
        </Grid>
      </>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      ref={elementRef}
    >
      <Box
        sx={{
          mt: isMobile() ? "30%" : "10%",
          mx: "5%",
          pb: "20px",
          width: isMobile() ? "90%" : "50%",
          mb: "60px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton onClick={handleScreenCancel}>
            <CancelOutlinedIcon sx={{ color: colors.gray, fontSize: "32px" }} />
          </IconButton>
        </Box>
        <Box sx={{ px: "24px" }}>
          <Text type="subHeadingBold">
            {strings.addNomineeDetails?.replace(
              "#",
              nomineeDetails?.length + 1
            )}
          </Text>
          <Box sx={{ my: "20px" }}>
            <Divider sx={{ backgroundColor: colors.backGroundBlue }} />
          </Box>

          <Grid container direction={"column"} rowSpacing={"24px"}>
            <Grid item>
              <Text type="smallBold">{strings.nomineeName}</Text>
              <Box sx={{ my: "12px" }} />
              <Input
                value={nomineeName}
                size={"small"}
                placeholder={"Name"}
                autoFocus={true}
                onChange={handleNomineeName}
                error={nomineeNameError}
              />
              <Box sx={{ my: "8px" }} />
            </Grid>

            <Grid item>
              <Text type="smallBold">{strings.nomineePan}</Text>
              <Box sx={{ my: "12px" }} />
              <Input
                value={nomineePan}
                size={"small"}
                placeholder={"PAN"}
                onChange={handleNomineePan}
                inputProps={{ maxLength: 10 }}
                error={nomineePanError}
              />
              <Box sx={{ my: "8px" }} />
            </Grid>
            <Grid item>
              <Text type="smallBold">{strings.nomineeDob}</Text>
              <Box sx={{ my: "12px" }} />

              <DateInput
                label="Select DOB"
                onChange={handleNomineeDob}
                error={Boolean(nomineeDobError)}
                helperText={nomineeDobError}
                dateValue={nomineeDob}
                inputFormat={"dd/MM/yyyy"}
              />
              {/* {nomineeDobError && <Text type="error">{nomineeDobError}</Text>} */}
              <Box sx={{ my: "8px" }} />
            </Grid>
            {nomineeIsMinor ? renderNomineeMinorSection() : null}
            <Grid item>
              <Text type="smallBold">{strings.nomineeAllocation}</Text>
              <Box sx={{ my: "12px" }} />
              <Input
                value={allocation}
                size={"small"}
                placeholder={strings.nomineeAllocation}
                onChange={handleNomineeAllocation}
                error={allocationError}
              />
              <Box sx={{ my: "8px" }} />
            </Grid>
            <Grid item>
              <Text type="smallBold">{strings.nomineeRelation}</Text>
              <Box sx={{ my: "12px" }} />
              <Input
                value={relationshipWithInvestor}
                size={"small"}
                placeholder={"Enter Relationship"}
                onChange={handleNomineeRelationship}
                error={relationshipWithInvestorError}
              />
              <Box sx={{ my: "8px" }} />
            </Grid>
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mt: -1,
                }}
              >
                <Checkbox
                  sx={{
                    // mt: -1,
                    // mt: "24px",
                    // mb: "40px",
                    // color: colors.backGroundBlue,
                    "&.Mui-checked": {
                      color: colors.backGroundBlue,
                    },
                  }}
                  checked={nomineeAddressChecked}
                  onChange={handleNomineeAdressChecked}
                />
                <Text type="smallBold">{strings.nomineeSameAddress}</Text>
              </Box>
              <Box sx={{ my: "12px" }} />
              {/* {!nomineeAddressChecked ? ( */}
              <>
                <Text type="smallBold">{strings.nomineeAddress}</Text>

                <Box sx={{ my: "12px" }} />
                <Input
                  value={nomineeAddress}
                  size={"small"}
                  placeholder={strings.nomineeAddress}
                  onChange={handleNomineeAddress}
                  error={nomineeAddressError}
                  inputProps={{ readOnly: nomineeAddressChecked }}
                />
                <Box sx={{ my: "8px" }} />
              </>
              {/* ) : null} */}
            </Grid>
          </Grid>
          <Box sx={{ my: "12px" }} />
          {nomineeDetails.length <= 1 && getTotalAllocation() < 100 ? (
            <>
              <ButtonIcon
                // disabled={amountErrorFlag}
                sx={{
                  backgroundColor: colors.white,
                  borderColor: colors.backGroundBlue,
                  color: colors.backGroundBlue,
                  width: "100%",
                  py: "12px",
                }}
                title={`+ Add ${converter.toWordsOrdinal(
                  nomineeDetails.length + 2
                )} Nominee`}
                icon={false}
                onClick={handleAddNomineeClicked}
              />
              <Box
                sx={{
                  my: "8px",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Text
                  type="small"
                  sx={{
                    textAlign: "center",
                    fontSize: "15px",
                    letterSpacing: "0em",
                    lineHeight: "18px",
                    fontFamily: "Graphik",
                    opacity: 0.6,
                  }}
                >
                  {strings.nomineeMessage}
                </Text>
              </Box>
            </>
          ) : null}
          <Box sx={{ my: "16px" }} />
          {editTransactionError ? (
            <Text
              type="mediumBold"
              color="red"
              sx={{ marginTop: "10px", marginBottom: "15px" }}
            >
              {editTransactionError}
            </Text>
          ) : null}
          <ButtonIcon
            // disabled={amountErrorFlag}
            sx={{
              backgroundColor: colors.backGroundBlue,
              color: colors.backgroundWhite,
              width: "100%",
              py: "12px",
            }}
            title={"Proceed"}
            icon={true}
            onClick={handleProceedClicked}
          />
        </Box>
      </Box>
      {isEditingTransaction && <ActivityOverlay />}
    </Box>
  );
};

export default NomineeAdd;
