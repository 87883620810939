import * as React from "react";

import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { DateInputProps } from "./types";
import { useEffect } from "react";
const DateInput: React.FC<DateInputProps> = ({
  label,
  onChange,
  error = false,
  helperText = "",
  dateValue,
  inputFormat = null,
}) => {
  const [value, setValue] = React.useState<Date | null>(dateValue);

  useEffect(() => {
    setValue(dateValue);
  }, [dateValue]);

  const handleChange = (newValue: Date | null) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={label}
        inputFormat={inputFormat ? inputFormat : "MM/dd/yyyy"}
        value={value}
        onChange={handleChange}
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="filled"
            fullWidth
            error={error}
            helperText={helperText}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateInput;
