import axios from "axios";

class Request {
  //constructor() {}

  static POST = async (
    endpoint: string,
    params: any,
    config?: any
  ): Promise<any> => {
    //
    try {
      const response = await axios.post(endpoint, params, config);
      //
      if (
        response.data &&
        response.data.resultInfo &&
        (response.data.resultInfo.resultStatus === "-1" ||
          response.data.resultInfo.resultStatus === "5000")
        // (response.data.resultInfo.resultCode === 'FAILURE' ||
        //   response.data.resultInfo.resultStatus === '0')
      ) {
        return {
          error: response.data.resultInfo.resultMsg,
          error_type: response.data.resultInfo.resultCodeId,
        };
      }
      //
      return response;
    } catch (error: any) {
      const errorData: any = Request.getErrorMessage(error);
      if (errorData.error.resultInfo) {
        return {
          error: errorData.error.resultInfo.resultMsg,
          error_type: errorData.error.resultInfo.resultCodeId,
        };
      }
      return errorData;
    }
  };
  static GET = async (endpoint: string, params: any) => {
    try {
      const response = await axios.get(endpoint, { params: params });
      return response;
    } catch (error: any) {
      return Request.getErrorMessage(error);
    }
  };
  static PUT = async (
    endpoint: string,
    params: any,
    config?: any
  ): Promise<any> => {
    try {
      const response = await axios.put(endpoint, params, config);
      return response;
    } catch (error: any) {
      return Request.getErrorMessage(error);
    }
  };
  static PATCH = async (
    endpoint: string,
    params: any,
    config?: any
  ): Promise<any> => {
    try {
      const response = await axios.patch(endpoint, params, config);
      return response;
    } catch (error: any) {
      return Request.getErrorMessage(error);
    }
  };

  static DELETE = async (endpoint: string, params: any) => {
    try {
      const response = await axios.delete(endpoint, { data: params });
      return response;
    } catch (error: any) {
      return Request.getErrorMessage(error);
    }
  };

  static getErrorMessage = (error: {
    response?: { data: { message?: string } };
  }) => {
    if (error.response) {
      return {
        error:
          error.response.data.message ||
          error.response.data ||
          "Something went wrong",
        error_type: "ERROR_TYPE_SERVER",
      };
    }

    return { error: "Network Error", error_type: "ERROR_TYPE_NETWORK" };
  };

  static uploadChequeLeaf = async (endpoint = "", data: any) => {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let form = new FormData();

    try {
      if (data.file) {
        form.append(
          "file",
          data.file,
          "file" + new Date().getTime() + data?.file?.name
        );
      } else {
        form.append("file", "");
      }

      if (data.ihNo) {
        form.append("ihNo", data.ihNo);
      }
      const uploadresponse = await axios.post(endpoint, form, config);
      if (
        uploadresponse?.data &&
        uploadresponse?.data?.resultInfo &&
        (uploadresponse?.data?.resultInfo?.resultStatus === "-1" ||
          uploadresponse?.data?.resultInfo?.resultStatus === "5000")
        // (uploadresponse.data.resultInfo.resultCode === 'FAILURE' ||
        //   uploadresponse.data.resultInfo.resultStatus === '0')
      ) {
        return {
          error: uploadresponse?.data?.resultInfo?.resultMsg,
          error_type: uploadresponse?.data?.resultInfo?.resultCodeId,
        };
      }
      return uploadresponse;
    } catch (error: any) {
      return Request.getErrorMessage(error);
    }
  };
}

export default Request;
