import { Box, Button, Card, Divider, Grid } from "@mui/material";
import Text from "components/Text";
import { FC } from "react";
import colors from "res/colors";
import { GridDataProps } from "./types";

const DisplayGridData: FC<GridDataProps> = ({
  data,
  titleExtraMessage,
  title,
  editable,
  infoComponent = null,
  contentType,
  onEdit,
  onDelete,
  deletable,
}) => {
  return (
    <Card sx={{ borderRadius: "10px", mb: "8px", px: "20px", py: "24px" }}>
      {title ? (
        <div>
          <Grid container>
            <Grid item xs={deletable ? 8 : 10}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "end",
                }}
              >
                <Text type="largeBold">{title}</Text>
                {titleExtraMessage ? (
                  <Text type="smallTransparent">{titleExtraMessage}</Text>
                ) : null}
              </Box>
            </Grid>

            {deletable ? (
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button
                  sx={{
                    px: 0,
                    py: 0,
                    textTransform: "none",
                  }}
                  onClick={() => {
                    if (onDelete) {
                      onDelete(data, contentType);
                    }
                  }}
                >
                  <Text type="dataSmallBold" color={colors.backGroundBlue}>
                    Delete
                  </Text>
                </Button>
              </Grid>
            ) : null}

            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {editable ? (
                <Button
                  sx={{
                    px: 0,
                    py: 0,
                    textTransform: "none",
                  }}
                  onClick={() => {
                    if (onEdit) {
                      onEdit(data, contentType);
                    }
                  }}
                >
                  <Text type="dataSmallBold" color={colors.backGroundBlue}>
                    Edit
                  </Text>
                </Button>
              ) : null}
            </Grid>
          </Grid>
          <Box sx={{ mt: "16px", mb: "8px" }}>
            <Divider
              variant="fullWidth"
              sx={{ backgroundColor: colors.backGroundBlue }}
            />
          </Box>
        </div>
      ) : null}

      {Object.entries(data).map(([title, value]) => {
        return (
          <div key={title}>
            <Grid container>
              <Grid item xs={4.5}>
                <Text type="dataLightMessage">{title}</Text>
              </Grid>
              <Grid item xs={7.5}>
                {typeof value === "string" ? (
                  <Text
                    // sx={{ display: "flex", flexWrap: "wrap" }}
                    type="dataSmallBold"
                  >
                    {value}
                  </Text>
                ) : (
                  value
                )}
              </Grid>
            </Grid>
            {title ===
            Object.keys(data)[Object.keys(data).length - 1] ? null : (
              <Box sx={{ mt: "16px", mb: "8px" }}>
                <Divider
                  sx={{ backgroundColor: colors.backgroundWhite }}
                  variant="fullWidth"
                />
              </Box>
            )}
          </div>
        );
      })}

      <Grid item>{infoComponent}</Grid>
    </Card>
  );
};

export default DisplayGridData;
