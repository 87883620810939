export const getSwitchRedemptionType = (type: string) => {
  if (type === "A") {
    return " Partial Amount";
  }
  if (type === "U") {
    return " Partial Unit";
  }
  if (type === "AU") {
    return " Full Unit";
  }
};
