/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { useAppSelector } from "hooks/useAppSelector";
import TransactionDetails from "components/TransactionDetails/TransactionDetails";
import ActivityOverlay from "components/ActivityOverlay/ActivityOverlay";
import { TransactionSteps } from "utils/constants";
import { useAppDispatch } from "hooks/useAppDispatch";
import {
  loadPaymentOptions,
  setTransactionApproval,
  rejectTransaction,
  resetConfirmTransactionStates,
  setEditTransactionInfo,
  resetPaymentOptions,
  resetDidLoadPaymentOptions,
} from "store/transaction/slice";
import RouteConfig from "routes/RouteConfig";
import { useNavigate } from "react-router-dom";
import { resetOtpStates } from "store/otp/slice";
import { INVESCO_WEB_URL } from "utils/constants";
import { getDistributorDetails } from "utils/getDistributorDetails";
import {
  decrementTransactionStage,
  incrementTransactionStage,
} from "store/appstate/slice";

const LumpsumTransactionDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    transactionDetails,
    transactionDistributor,
    isLoadingPaymentOptions,
    didLoadPaymentOptions,
    paymentOptionsError,
    transactionSchemeDetails,
    isLoadingTransactionSchemesDetails,
    isRejectingTransaction,
    didRejectTransaction,
    rejectTransactionError,
    didApproveTransaction,
  } = useAppSelector((state) => state.transaction);

  useEffect(() => {
    if (didLoadPaymentOptions) {
      dispatch(setTransactionApproval(true));
      navigate(RouteConfig.paymentOptions);
      dispatch(incrementTransactionStage());
      dispatch(resetDidLoadPaymentOptions());
    }
  }, [didLoadPaymentOptions]);
  useEffect(() => {
    if (didRejectTransaction) {
      // dispatch(resetOtpStates());
      // dispatch(resetConfirmTransactionStates());
      navigate(RouteConfig.lumpsumTransactionReject);
    }
  }, [didRejectTransaction]);

  useEffect(() => {
    if (didApproveTransaction) {
      dispatch(decrementTransactionStage());
    }
  }, []);
  const transactions = useMemo(() => {
    if (
      !transactionDetails ||
      !transactionDetails.cartInitiateResponse ||
      !transactionDetails.cartInitiateResponse.schemeList ||
      isLoadingTransactionSchemesDetails
    ) {
      return [];
    }
    return transactionDetails.cartInitiateResponse.schemeList.map(
      (item: any, index: number) => {
        return {
          title: "Investment Details",
          canEdit: true,
          index: index,
          contents: {
            "Investment Type": "Lumpsum",
            "Scheme Name": transactionSchemeDetails[index].fundDesc,
            Option: `${transactionSchemeDetails[index]?.optDesc} - ${transactionSchemeDetails[index]?.planDesc}`,
            "Investment amount": parseInt(item.amount).toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
              // minimumFractionDigits: 0,
              // maximumFractionDigits: 0,
            }),
          },
        };
      }
    );
  }, [
    transactionDetails,
    isLoadingTransactionSchemesDetails,
    transactionSchemeDetails,
  ]);

  const handleTransactionApprove = () => {
    dispatch(
      loadPaymentOptions({
        panNumber: transactionDetails.cartInitiateResponse.pan,
        folioNumber: transactionDetails.cartInitiateResponse.folioNumber,
        // arnCode: transactionDetails.cartInitiateResponse.arnCode,
        // panNumber: "ADXPS3064N",
        // folioNumber: "2108514332",
      })
    );
  };
  const handleTransactionReject = () => {
    const distributor = getDistributorDetails(transactionDistributor);
    dispatch(
      rejectTransaction({
        batchNo: transactionDetails.cartInitiateResponse.batchNo,
        pan: transactionDetails.cartInitiateResponse.pan,
        ...distributor,
        rejectReason: "Not Intrested",
      })
    );
  };

  const handleEditTransaction = (data: any, _type: string | undefined) => {
    dispatch(setEditTransactionInfo(data));
    navigate(RouteConfig.lumpsumTransactionEdit);
  };

  return (
    <>
      {!isLoadingTransactionSchemesDetails ? (
        <TransactionDetails
          stepper={{ currentIndex: 1, steps: TransactionSteps }}
          transactions={transactions}
          error={paymentOptionsError || rejectTransactionError}
          onApproveTranaction={handleTransactionApprove}
          onRejectTranaction={handleTransactionReject}
          onEditTransaction={handleEditTransaction}
        />
      ) : null}
      {(isLoadingPaymentOptions ||
        isLoadingTransactionSchemesDetails ||
        isRejectingTransaction) && <ActivityOverlay />}
    </>
  );
};
export default LumpsumTransactionDetails;
