import { all, takeLatest, call, put, takeLeading } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { TResponse } from "types";
import {
  loadPaymentOptions,
  didLoadPaymentOptions,
  didFailToLoadPaymentOptions,
  getOtmBilldeskUrl,
  getBilldeskUrl,
  didGetBilldeskUrl,
  didFailToGetBilldeskUrl,
  confirmTransaction,
  didConfirmTransaction,
  didFailToConfirmConfirmTransaction,
  loadTransactionSchemeDetails,
  didLoadTransactionSchemeDetails,
  rejectTransaction,
  didRejectTransaction,
  didFailToRejectTransaction,
  editTransaction,
  didEditTransaction,
  didFailToEditTransaction,
  didLoadNewTransactionPaymentOptions,
  didFailToLoadNewTransactionPaymentOptions,
  loadNewTransactionPaymentOptions,
  didFailToLoadFoliowiseSchemeData,
  didLoadFoliowiseSchemeData,
  loadFoliowiseSchemeData,
  didLoadFetchExitLoadData,
  didFailedToLoadFetchExitLoad,
  loadFetchExitLoadData,
  confirmPauseTransaction,
  confirmCancelTransaction,
  didFailedLoadOtmBankData,
  didLoadOtmBankData,
  loadOtmBankData,
  didFailToLoadOtmConfirmation,
  didLoadOtmConfirmation,
  loadOtmConfirmation,
  didFailToValidateBankDetails,
  didValidateBankDetails,
  validateBankDetails,
  uploadChequeLeaf,
  didUploadChequeLeaf,
  didFailToUploadChequeLeaf,
} from "./slice";

import Request from "networking/Request";
import axios from "axios";

const PAYMENT_OPTIONS_BY_IFSC_ENDPOINT =
  "v1/nontransaction/bank/FetchBankDetailsByIfsc";
const PAYMENT_OPTIONS_ENDPOINT = "v1/transaction/RegisteredBankDetail";
const BILL_DESK_URL_ENDPOINT = "v1/transaction/transactionPayment";
const OTM_BILL_DESK_URL_ENDPOINT = "v1/transaction/otmPayment";

const CONFIRM_TRANSACTION_ENDPOINT = "v1/transaction/confirm";

const CONFIRM_PAUSE_ENDPOINT = "v1/transaction/pauseSipConfirm";
const CONFIRM_CANCEL_ENDPOINT = "v1/transaction/cancelSipSwpStpConfirm";

const SCHEME_DETAILS_ENDPOINT = "v1/nontransaction/SchemeMaster";
const REJECT_TRANSACTION_ENDPOINT = "v1/transaction/rejectTransaction";
const EDIT_TRANSACTION_ENDPOINT = "v1/transaction/editTransaction";
const INVESTOR_FOLIOS_AND_SCHEMES_ENDPOINT =
  "v1/nontransaction/InvestorFolioWiseScheme";
const FETCH_OAD_ENDPOINT = "v1/nontransaction/FetchExitLoad";
const FETCH_OTM_BANK_DETAILS = "v1/admin/otm/bank/getBankDetailsByIfsc";
const CONFIRM_CART_TRANSACTION_ENDPOINT =
  "v1/transaction/confirmCartTransaction";
const VERIFY_BANK_DETAILS = "v1/transaction/validateBankDetails";
const UPLOAD_CHEQUE_LEAF = "v1/transaction/uploadChequeLeaf";
const getTranascationDetails = async (schemes: { [key: string]: any }[]) => {
  // Return our response in the allData variable as an array
  const data = await Promise.all(
    schemes.map((params) => Request.POST(SCHEME_DETAILS_ENDPOINT, params))
  ).then(
    axios.spread((...allData) => {
      const schemedata = allData.map((item: any, index: number) => {
        if (item && item.data && item.data.data) {
          return {
            fundDesc: item.data.data.schemeDesc,
            schemeCode: item.data.data.schemeCode,
            optionCode: item.data.data.optionCode,
            planCode: item.data.data.planCode,
            optDesc: item.data.data.optDesc,
            schemeDesc: item.data.data.schemeDesc,
            planDesc: item.data.data.planDesc,
            category: item.data.data.category,
            sebiCategory: item.data.data.fmSebiSchemeCategory,
            minAddPurchase: item.data.data.minAddPurchase,
            sipFrequency: item.data.data.sipFrequency,
            stpFrequency: item.data.data.stpFrequency,
            swpFrequency: item.data.data.swpFrequency,
          };
        } else {
          return {
            fundDesc: schemes[index].schemeCode,
            optDesc: schemes[index].optionCode,
            planDesc: schemes[index].planCode,
            schemeDesc: schemes[index].optionCode,
            category: schemes[index].category,
            sebiCategory: schemes[index].fmSebiSchemeCategory,
          };
        }
      });

      return schemedata;
    })
  );

  return data || [];
};

function* handleFetchLoadOtmBankData(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    FETCH_OTM_BANK_DETAILS,
    params
  );

  if (response.error) {
    yield put(didFailedLoadOtmBankData());
  } else {
    yield put(didLoadOtmBankData(response.data.data));
  }
}

function* handleFetchLoadExitData(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    FETCH_OAD_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(didFailedToLoadFetchExitLoad());
  } else {
    yield put(didLoadFetchExitLoadData(response.data.data));
  }
}
function* handleLoadTransactionSchemeDetails(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    getTranascationDetails,
    action.payload
  );

  if (response) {
    yield put(didLoadTransactionSchemeDetails(response));
  }
}
function* handleGetFoliowiseSchemeDetails(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    INVESTOR_FOLIOS_AND_SCHEMES_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(didFailToLoadFoliowiseSchemeData(response.error));
  } else {
    yield put(didLoadFoliowiseSchemeData(response.data.data));
  }
}
function* handleEditTransaction(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    EDIT_TRANSACTION_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(didFailToEditTransaction(response.error));
  } else {
    yield put(didEditTransaction({}));
  }
}

function* handleRejectTransaction(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    REJECT_TRANSACTION_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(didFailToRejectTransaction(response.error));
  } else {
    yield put(didRejectTransaction({}));
  }
}

function* handleConfirmTransaction(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    CONFIRM_TRANSACTION_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(didFailToConfirmConfirmTransaction(response.error));
  } else {
    yield put(didConfirmTransaction({}));
  }
}
function* handleConfirmPauseTransaction(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    CONFIRM_PAUSE_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(didFailToConfirmConfirmTransaction(response.error));
  } else {
    yield put(didConfirmTransaction({}));
  }
}
function* handleConfirmCancelTransaction(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    CONFIRM_CANCEL_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(didFailToConfirmConfirmTransaction(response.error));
  } else {
    yield put(didConfirmTransaction({}));
  }
}
function* handleLoadNewTransactionPaymentOptions(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  delete params?.accNo;
  const response: TResponse = yield call(
    Request.POST,
    PAYMENT_OPTIONS_BY_IFSC_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(didFailToLoadNewTransactionPaymentOptions(response.error));
  } else {
    if (action?.payload?.accNo) {
      yield put(
        didLoadNewTransactionPaymentOptions([
          { ...response.data.data, bankAcno: action.payload?.accNo },
        ])
      );
    } else {
      yield put(didLoadNewTransactionPaymentOptions([response.data.data]));
    }
  }
}
function* handleLoadPaymentOptions(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    PAYMENT_OPTIONS_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(
      didFailToLoadPaymentOptions(
        "Technical issue. Please contact your distributor."
      )
    );
  } else {
    if (
      !(
        response.data.data.registeredBankResponseList &&
        response.data.data.registeredBankResponseList.length > 0
      )
    ) {
      yield put(
        didFailToLoadPaymentOptions(
          "Bank details are not available for online transaction. Please contact your distributor."
        )
      );
    } else yield put(didLoadPaymentOptions(response.data.data));
  }
}

function* handleGetBilldeskUrl(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    BILL_DESK_URL_ENDPOINT,
    params
  );

  if (response.error) {
    let error = response?.error;
    if (
      response?.error_type === "23" ||
      response?.error === "PGCustomerid already exists in the system."
    ) {
      error =
        "Link is expired. Please ask Mutual Fund Distributor to initiate a fresh transaction.";
    }
    yield put(didFailToGetBilldeskUrl(error));
  } else {
    yield put(didGetBilldeskUrl(response.data.data.url));
  }
}
function* handleGetOtmBilldeskUrl(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    OTM_BILL_DESK_URL_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(didFailToGetBilldeskUrl(response.error));
  } else {
    yield put(didGetBilldeskUrl(response.data.data.url));
  }
}

function* handleOtmTransaction(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    CONFIRM_CART_TRANSACTION_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(didFailToLoadOtmConfirmation(response.error));
  } else {
    yield put(
      didLoadOtmConfirmation(
        response?.data?.data.confirmCartTransactionResponse
      )
    );
  }
}
function* handleValidateBankDetails(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    VERIFY_BANK_DETAILS,
    params
  );

  if (response.error) {
    yield put(didFailToValidateBankDetails(response.error));
  } else {
    yield put(didValidateBankDetails(response?.data?.data));
  }
}
function* handleUploadChequeLeaf(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.uploadChequeLeaf,
    UPLOAD_CHEQUE_LEAF,
    params
  );

  if (response.error) {
    yield put(
      didFailToUploadChequeLeaf(
        "Upload cheque leaf failed, Please try again or connect with the Distributor."
      )
    );
  } else {
    yield put(didUploadChequeLeaf(response?.data?.data));
  }
}

function* watcherLoadOtmConformation() {
  yield takeLatest(loadOtmConfirmation.type, handleOtmTransaction);
}
function* watcherLoadNewPaymentOption() {
  yield takeLatest(
    loadNewTransactionPaymentOptions.type,
    handleLoadNewTransactionPaymentOptions
  );
}
function* watcherLoadPaymentOptions() {
  yield takeLatest(loadPaymentOptions.type, handleLoadPaymentOptions);
}
function* watcherGetBilldeskUrl() {
  yield takeLatest(getBilldeskUrl.type, handleGetBilldeskUrl);
}
function* watcherGetOtmBilldeskUrl() {
  yield takeLatest(getOtmBilldeskUrl.type, handleGetOtmBilldeskUrl);
}
function* watcherRejectTransaction() {
  yield takeLatest(rejectTransaction.type, handleRejectTransaction);
}

function* watcherConfirmTransaction() {
  yield takeLatest(confirmTransaction.type, handleConfirmTransaction);
}
function* watcherConfirmPauseTransaction() {
  yield takeLatest(confirmPauseTransaction.type, handleConfirmPauseTransaction);
}
function* watcherConfirmCancelTransaction() {
  yield takeLatest(
    confirmCancelTransaction.type,
    handleConfirmCancelTransaction
  );
}
function* watcherEditTransaction() {
  yield takeLatest(editTransaction.type, handleEditTransaction);
}

function* watcherLoadTransactionSchemeDetails() {
  yield takeLatest(
    loadTransactionSchemeDetails.type,
    handleLoadTransactionSchemeDetails
  );
}
function* watcherLoadSchemeData() {
  yield takeLeading(
    loadFoliowiseSchemeData.type,
    handleGetFoliowiseSchemeDetails
  );
}
function* watcherLoadExitData() {
  yield takeLatest(loadFetchExitLoadData.type, handleFetchLoadExitData);
}

function* watcherLoadOtmBankData() {
  yield takeLatest(loadOtmBankData.type, handleFetchLoadOtmBankData);
}
function* watcherValidateBankDetails() {
  yield takeLatest(validateBankDetails.type, handleValidateBankDetails);
}
function* watcherUploadChequeLeaf() {
  yield takeLatest(uploadChequeLeaf.type, handleUploadChequeLeaf);
}
export function* transactionSaga() {
  yield all([
    watcherEditTransaction(),
    watcherRejectTransaction(),
    watcherLoadTransactionSchemeDetails(),
    watcherConfirmTransaction(),
    watcherConfirmPauseTransaction(),
    watcherConfirmCancelTransaction(),
    watcherGetBilldeskUrl(),
    watcherGetOtmBilldeskUrl(),
    watcherLoadPaymentOptions(),
    watcherLoadNewPaymentOption(),
    watcherLoadSchemeData(),
    watcherLoadExitData(),
    watcherLoadOtmBankData(),
    watcherLoadOtmConformation(),
    watcherValidateBankDetails(),
    watcherUploadChequeLeaf(),
  ]);
}
