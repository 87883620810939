import React, { useEffect } from "react";
import { Box } from "@mui/system";
import DisplayGridData from "components/DisplayGridData";
import colors from "res/colors";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Link,
  TextField,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import Text from "components/Text";
import isMobile from "utils/isMobile";
import ButtonIcon from "components/ButtonIcon";
import { useAppSelector } from "hooks/useAppSelector";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/useAppDispatch";
import RejectConfirmationDialog from "./RejectConfirmationDialog";
import ApproveConfirmationDialog from "./ApproveConfirmationDialog";
import {
  generateOtp,
  resetOtpStates,
  resetVerifyOtp,
  verifyApplicantOtp,
} from "store/otp/slice";
import { TransactionType } from "utils/constants";
import ActivityOverlay from "components/ActivityOverlay";
import OtpInput from "react-otp-input";
import OtpTimer from "components/OtpTimer";
import TwoFactorAuthentication from "components/TwoFactorAuthentication/TwoFactorAuthentication";

export type StepperOption = {
  id: number;
  title: string;
};

export interface TransactionDetailsProps {
  stepper: {
    currentIndex: number;
    steps: StepperOption[];
  };
  transactions: Array<{ [key: string]: any }>;
  error?: string;
  onApproveTranaction?: () => void;
  onRejectTranaction?: () => void;
  onEditTransaction?: (data: any, contentType: string | undefined) => void;
  showReasonError?: boolean;
}

const TransactionDetails: React.FC<TransactionDetailsProps> = ({
  transactions,
  error,
  onApproveTranaction,
  onRejectTranaction,
  onEditTransaction,
  showReasonError = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { transactionInvestor, transactionDistributor, transactionInfo } =
    useAppSelector((state) => state.transaction);
  const { transactionType } = useAppSelector((state) => state.appState);

  const [checked, setChecked] = useState(false);
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const {
    didVerifyOtp,
    isVerifyingApplicantOtp,
    applicantOtpError,
    didVerifyApplicantOtp,
    userMakedInfo,
  } = useAppSelector((state) => state.otp);
  const { ihNo } = useAppSelector((state) => state.appState);
  const [otpDialogOpen, setOtpDialogOpen] = React.useState(false);
  const [otp, setOtp] = useState("");

  const handleApprove = () => {
    if (
      transactionType === TransactionType.sip ||
      transactionType === TransactionType.lumpsum ||
      transactionType === TransactionType.newSip ||
      transactionType === TransactionType.newLumpsum
    ) {
      dispatch(generateOtp({ ihNo: ihNo }));
      setOtpDialogOpen(true);
    } else if (transactionType === TransactionType.otm) {
      if (onApproveTranaction) {
        onApproveTranaction();
      }
    } else {
      if (showReasonError) {
        setAlertDialogOpen(true);
        return;
      }
      handleShowApproveDialog();
    }
  };
  const onConfirmApprove = () => {
    setShowApproveDialog(false);
    if (onApproveTranaction) {
      onApproveTranaction();
    }
  };
  const handleShowApproveDialog = () => {
    setShowApproveDialog(true);
  };
  const closeApproveDialog = () => {
    setShowApproveDialog(false);
  };

  const handleReject = () => {
    setShowRejectDialog(false);
    if (onRejectTranaction) {
      onRejectTranaction();
    } else {
      dispatch(resetOtpStates());
      navigate("/");
    }
  };
  const handleShowRejectDialog = () => {
    setShowRejectDialog(true);
  };
  const closeRejectDialog = () => {
    setShowRejectDialog(false);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (showReasonError) {
      setAlertDialogOpen(true);
      return;
    }
    setChecked(event.target.checked);
  };

  const handleOtpDialogClose = () => {
    setOtp("");
    setOtpDialogOpen(false);
    dispatch(resetVerifyOtp());
  };

  const handleOTPChange = (event: string) => {
    setOtp(event);
  };

  const handleResendPressed = () => {
    setOtp("");

    dispatch(resetVerifyOtp());
    dispatch(generateOtp({ ihNo: ihNo }));
  };

  useEffect(() => {
    if (otp.length === 5) {
      //  here goes the verify 2FA OTP logic
      const params = {
        userId: transactionDistributor?.ARN,
        userType: "EMAILMOBILE",
        otp: otp,
        referenceId: ihNo,
        email: transactionInvestor?.Email,
        mobileNo: transactionInvestor?.Mobile,
        otpMobile: otp,
      };
      dispatch(verifyApplicantOtp(params));
    }
    return () => {
      dispatch(resetVerifyOtp());
    };
  }, [otp]);
  useEffect(() => {
    //  here goes the transaction approve logic
    if (didVerifyApplicantOtp) {
      if (
        transactionType === TransactionType.sip ||
        transactionType === TransactionType.lumpsum ||
        transactionType === TransactionType.newSip ||
        transactionType === TransactionType.newLumpsum
      ) {
        if (onApproveTranaction) {
          onApproveTranaction();
        }
      }
    }
  }, [didVerifyApplicantOtp]);

  if (!didVerifyOtp) {
    return <Navigate to="/" replace />;
  }

  const transactionData =
    transactionType !== TransactionType.otm
      ? transactionInfo
      : {
          Name: "Invesco Mutual Fund",
          "Utility No.": "NACH000000003747",
        };

  const showError = Boolean(error);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          mt: isMobile() ? "30%" : "10%",
          mx: "5%",
          width: isMobile() ? "90%" : "50%",
        }}
      >
        {/* {
          <ProgressStepper
            current={stepper.currentIndex}
            options={stepper.steps}
          />
        } */}
        <Box sx={{ mb: "20px" }} />
        <DisplayGridData
          title="Investor Details"
          data={{
            ...transactionInvestor,
            Mobile: transactionInvestor.Mobile
              ? transactionInvestor.Mobile
              : "Mobile number is not available in the folio",
            Email: transactionInvestor.Email
              ? transactionInvestor.Email
              : "Email id is not available in the folio",
          }}
          editable={false}
        />

        {transactions.map((item: any) => (
          <DisplayGridData
            key={JSON.stringify(item.title)}
            title={item.title}
            titleExtraMessage={item.titleExtraMessage || ""}
            data={item.contents}
            editable={
              transactionInfo["Transaction Status"] === "Pending"
                ? item.canEdit
                : false
            }
            onEdit={() =>
              onEditTransaction ? onEditTransaction(item, item.type) : null
            }
            contentType={item.type}
          />
        ))}
        {transactionType !== TransactionType.otm ? (
          <DisplayGridData
            title="Distributor Details"
            data={transactionDistributor}
            editable={false}
          />
        ) : null}
        {transactionType !== TransactionType.otm ? (
          <DisplayGridData
            title="Transaction Details"
            data={transactionData}
            editable={false}
          />
        ) : null}
        {transactionInfo["Transaction Status"] === "Pending" ||
        !transactionInfo["Transaction Status"] ? (
          <Box sx={{ pb: "20px" }}>
            <FormControlLabel
              sx={{
                mt: "20px",
                mb: "20px",
                alignItems: "flex-start",
              }}
              control={
                <Checkbox
                  sx={{
                    mt: -1,
                    "&.Mui-checked": {
                      color: colors.backGroundBlue,
                    },
                  }}
                  checked={checked}
                  onChange={handleChange}
                />
              }
              label={Disclaimer}
              labelPlacement="end"
            />
            {showError ? (
              <Text
                type="mediumBold"
                color="red"
                sx={{ marginTop: "10px", marginBottom: "15px" }}
              >
                {error}
              </Text>
            ) : null}

            <Grid container>
              <Grid item xs={5.5}>
                <ButtonIcon
                  sx={{
                    backgroundColor: colors.infoBackgroundRed,
                    color: colors.infoTextRed,
                    width: isMobile() ? "100%" : "275px",
                    py: "10px",
                  }}
                  title={"Reject"}
                  icon={false}
                  onClick={handleShowRejectDialog}
                />
              </Grid>
              <Grid item xs={1}></Grid>

              <Grid item xs={5.5}>
                <ButtonIcon
                  disabled={!checked}
                  title={"Approve"}
                  icon={true}
                  onClick={handleApprove}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box sx={{ width: "100%", height: "30px" }} />
        )}
      </Box>
      {showRejectDialog ? (
        <RejectConfirmationDialog
          onClose={closeRejectDialog}
          onReject={handleReject}
        />
      ) : null}
      {showApproveDialog ? (
        <ApproveConfirmationDialog
          onClose={closeApproveDialog}
          onApprove={onConfirmApprove}
        />
      ) : null}

      <TwoFactorAuthentication
        otpDialogOpen={otpDialogOpen}
        handleOtpDialogClose={handleOtpDialogClose}
        isVerifyingApplicantOtp={isVerifyingApplicantOtp}
        otp={otp}
        handleOTPChange={handleOTPChange}
        applicantOtpError={applicantOtpError}
        maskedEmail={userMakedInfo?.email}
        maskedMobile={userMakedInfo?.mobile}
        handleResendPressed={handleResendPressed}
      />

      <Dialog
        open={alertDialogOpen}
        onClose={() => {
          setAlertDialogOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select reason for cancellation.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAlertDialogOpen(false);
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default TransactionDetails;

const handleTermsAndConditions = () => {
  // rel="noopener noreferrer" target="_blank" href="google.com"

  window.open("www.google.com", "_blank");
};
const Disclaimer = (
  <Text type="small">
    Yes I have read, understood and shall abide by the contents of the Scheme
    information Document(s) of the respective schemes & Statement of Additional
    Information of Invesco Mutual Fund and the{" "}
    <Link
      sx={{ fontWeight: 800 }}
      onClick={() => {
        window.open(
          "https://invest.invescomutualfund.com/investonline/General/TermsConditions.html",
          "_blank"
        );
      }}
    >
      terms & conditions
    </Link>{" "}
    of the online portal usage. I confirm that the payment made for subscription
    is from the bank account in which first named unit holder is the first
    holder or one of the joint holders (applicable in case of joint bank
    account) and payment is not a third party payment.
  </Text>
);
