/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Box, Card } from "@mui/material";
import Text from "components/Text";
import strings from "res/strings";
import Info from "components/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import colors from "res/colors";
import ButtonIcon from "components/ButtonIcon";
import isMobile from "utils/isMobile";
import ActivityOverlay from "components/ActivityOverlay";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import RouteConfig from "routes/RouteConfig";
import { generateOtp } from "store/otp/slice";
import { ReactComponent as IntroLogo } from "../../res/images/InvestorApproval.svg";
export interface IntroProps {
  title: string;
  message: string;
  onGenerateOtp?: () => void;
  onSuccessfullyGeneratingOtp?: () => void;
  errorMessage?: string;
  showAcitivityOverlay?: boolean;
  investmentType?: string;
}
const Intro: React.FC<IntroProps> = ({
  message,
  onGenerateOtp,
  onSuccessfullyGeneratingOtp,
  title,
  errorMessage = "",
  showAcitivityOverlay = false,
  investmentType,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isGeneratingOtp, generateOtpError, didGenerateOtp } = useAppSelector(
    (state) => state.otp
  );
  const { ihNo } = useAppSelector((state) => state.appState);

  useEffect(() => {
    if (didGenerateOtp) {
      if (onSuccessfullyGeneratingOtp) {
        onSuccessfullyGeneratingOtp();
      } else {
        navigate(RouteConfig.otp);
      }
    }
  }, [didGenerateOtp]);

  const handleGenerateOtp = () => {
    if (onGenerateOtp) {
      onGenerateOtp();
      return;
    }
    dispatch(generateOtp({ ihNo: ihNo }));
  };
  const getGeneratorErrorMessage = () => {
    if (generateOtpError) {
      return "OTP could not be generated at this time. Try again later.";
    }
  };
  const showError = Boolean(errorMessage || generateOtpError);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          mt: isMobile() ? "30%" : "10%",
          mx: "5%",
          width: isMobile() ? "90%" : "50%",
        }}
      >
        <Box sx={{ ml: "12px", mb: "16px", mt: "20px" }}>
          <Text type="introTitleHeading">{title}</Text>
        </Box>
        <Card
          sx={{
            pt: "24px",
            pb: "16px",
            mb: "8px",
            px: "16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "10px",
          }}
        >
          {/* <HourglassBottomIcon sx={{ height: "48px", width: "48px" }} /> */}
          <IntroLogo height={48} width={48} />
          <Box sx={{ py: "12px" }}>
            <Text type="largeBoldCenter">{message}</Text>
          </Box>
          <Box>
            <Text type="lightMessageCenter">{strings.approvalMessage}</Text>
          </Box>

          {showError ? (
            <Text
              type="mediumBold"
              color="red"
              sx={{ marginTop: "15px", marginBottom: "15px" }}
            >
              {errorMessage || getGeneratorErrorMessage()}
            </Text>
          ) : null}
          <Box sx={{ width: "50px", height: "50px" }} />
          <ButtonIcon
            title={strings.generateOtp}
            icon={true}
            onClick={handleGenerateOtp}
          />
          <Box sx={{ pt: "20px", pb: "16px" }}>
            <Info
              message={strings.otpMessage}
              iconPosition={"start"}
              icon={<InfoOutlinedIcon sx={{ color: colors.infoTextBlue }} />}
            />
          </Box>
        </Card>
      </Box>
      {(showAcitivityOverlay || isGeneratingOtp) && <ActivityOverlay />}
    </Box>
  );
};
export default Intro;
