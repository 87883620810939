import RouteConfig from "./RouteConfig";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Landing from "pages/Landing";
import NavBar from "components/NavBar";

import PaymentOptions from "pages/payment-options/PaymentOptions";

import STP from "pages/stp/STP";
import STPOtp from "pages/stp/otp/STPOtp";
import STPTransactionDetails from "pages/stp/transaction-details/STPTransactionDetails";
import STPtargetSchemeEdit from "pages/stp/edit/STPtargetSchemeEdit";
import STPEditTransaction from "pages/stp/stpEdit/STPEditTransaction";

import SWP from "pages/swp/SWP";
import SWPOtp from "pages/swp/otp/SWPOtp";
import SWPTransactionDetails from "pages/swp/transaction-details/SWPTransactionDetails";

import Switch from "pages/switch/Switch";
import SwitchOtp from "pages/switch/otp/SwitchOtp";
import SwitchTransactionDetails from "pages/switch/transaction-details/SwitchTransactionDetails";
import SwitchEdit from "pages/switch/edit/SwitchEdit";

import Lumpsum from "pages/lumpsum/Lumpsum";
import LumpsumOtp from "pages/lumpsum/otp/LumpsumOtp";
import LumpsumTransactionDetails from "pages/lumpsum/transaction-details/LumpsumTransactionDetails";
import LumpsumEdit from "pages/lumpsum/edit/LumpSumEdit";

import NewLumpsum from "pages/new-lumpsum/NewLumpsum";
import NewLumpsumOtp from "pages/new-lumpsum/otp/NewLumpsumOtp";
import NewLumpsumEdit from "pages/new-lumpsum/edit/NewLumpsumEdit";
import NewLumpsumTransactionDetails from "pages/new-lumpsum/transaction-details/NewLumpsumTransactionDetails";

import Redemption from "pages/redemption/Redemption";
import RedemptionOtp from "pages/redemption/otp/RedemptionOtp";
import RedemptionTransactionDetails from "pages/redemption/transaction-details/RedemptionTransactionDetails";
import RedemptionEdit from "pages/redemption/edit/RedemptionEdit";
import Sip from "pages/sip/Sip";
import SipOtp from "pages/sip/otp/SipOtp";
import SipTransactionDetails from "pages/sip/transaction-details/SipTransactionDetails";
import SipTransactionEdit from "pages/sip/edit/SipEdit";

import CancelSip from "pages/cancel-sip/CancelSIP";
import CancelSipOtp from "pages/cancel-sip/otp/CancelSIPOtp";
import CancelSipTransactionDetails from "pages/cancel-sip/transaction-details/CancelSIPTransactionDetails";
import CancelSIPEdit from "pages/cancel-sip/edit/CancelSIPEdit";

import PauseSip from "pages/pause-sip/PauseSIP";
import PauseSipOtp from "pages/pause-sip/otp/PauseSIPOtp";
import PauseSipTransactionDetails from "pages/pause-sip/transaction-details/PauseSIPTransactionDetails";
import PauseSIPEdit from "pages/pause-sip/edit/PauseSIPEdit";

import NewSip from "pages/new-sip/NewSip";
import NewSipOtp from "pages/new-sip/otp/NewSipOtp";
import NewSipEdit from "pages/new-sip/edit/NewSipEdit";
import NewSipTransactionDetails from "pages/new-sip/transaction-details/NewSipTransactionDetails";

import OTM from "pages/otm/OTM";
import OTMOtp from "pages/otm/otp/OTMOtp";
import OTMTransactionDetails from "pages/otm/transaction-details/OTMTransactionDetails";
import OTMTransactionEdit from "pages/otm/edit/OTMEdit";

import Ekyc from "pages/ekyc/Ekyc";
import EkycOtp from "pages/ekyc/otp/EkycOtp";
import EkycSuccess from "pages/ekyc/success/EkycSuccess";

import TransactionSuccess from "pages/transaction-success";

import UnlockSuccess from "pages/mfd-unlock-success/MFDUnlockSuccess";

import TransactionFail from "pages/tranasaction-fail/TransactionFail";
import RejectSuccess from "pages/reject-success/RejectSuccess";
import SwpEdit from "pages/swp/edit/SwpEdit";
import NewTransactionEdit from "pages/new-transaction-edit/NewTransactionEdit";
import NomineeEdit from "pages/NomineeEdit/NomineeEdit";
import OtmTransactionSuccess from "pages/otm-transaction-success";
import NomineeAdd from "pages/NomineeAdd/NomineeAdd";
import PrivacyPolicy from "pages/privacy-policy/PrivacyPolicy";

// import Otp from "pages/Otp";
// import KYC from "pages/KYC/KYCIntro";
// import KycSuccess from "pages/KYC/Success";

// import TransactionApprovalIntro from "pages/TransactionApproval/Intro";
// import TransactionApprovalReview from "pages/TransactionApproval/Review";
// import EditInvestmentDetails from "pages/TransactionApproval/Review/EditInvestmentDetails";
// import TransactionApprovalPayment from "pages/TransactionApproval/Payment";
// import ReviewSuccess from "pages/TransactionApproval/ReviewSuccess";

// import SipApprovalIntro from "pages/SipApproval/Intro";
// import SipApprovalReview from "pages/SipApproval/Review";
// import SipApprovalPayment from "pages/SipApproval/Payment";
// import SipApprovalSuccess from "pages/SipApproval/SipSuccess";

// import InvestorApprovalIntro from "pages/InvestorApproval/Intro";
// import InvestorApprovalReview from "pages/InvestorApproval/Review";
// import InvestorApprovalSuccess from "pages/InvestorApproval/InvestorSuccess";
// import InvestorApprovalReject from "pages/InvestorApproval/ReviewReject";
// import InvestorApprovalInvestmentDetails from "pages/InvestorApproval/InvestmentDetails";
// import IvestorApprovalApplicant from "pages/InvestorApproval/Applicant";
// import IvestorApprovalNominee from "pages/InvestorApproval/Nominee";
// import IvestorApprovalPayment from "pages/InvestorApproval/Payment";

// import OtmInvestorApprovalInto from "pages/OTMInvestorApproval/Intro";
// import OtmInvestorApprovalReview from "pages/OTMInvestorApproval/Review";
// import OtmInvestorApprovalSuccess from "pages/OTMInvestorApproval/ReviewSuccess";
// import OtmInvestorApprovalReject from "pages/OTMInvestorApproval/ReviewReject";
// import OtmInvestorApprovalEditBankDetails from "pages/OTMInvestorApproval/Review/EditBankDetails";
// import OtmInvestorApprovalEditMandateDetails from "pages/OTMInvestorApproval/Review/EditMandateDetails";

// import StpApprovalIntro from "pages/StpApproval/Intro";
// import StpApprovalReview from "pages/StpApproval/Review";
// import StpApprovalSuccess from "pages/StpApproval/ReviewSuccess";
// import StpApprovalReject from "pages/StpApproval/ReviewReject";

// import SwpApprovalIntro from "pages/SwpApproval/Intro";
// import SwpApprovalReview from "pages/SwpApproval/Review";
// import SwpApprovalSuccess from "pages/SwpApproval/ReviewSuccess";
// import SwpApprovalReject from "pages/SwpApproval/ReviewReject";

// import SwitchApprovalIntro from "pages/SwitchApproval/Intro";
// import SwitchApprovalReview from "pages/SwitchApproval/Review";
// import SwitchApprovalSuccess from "pages/SwitchApproval/ReviewSuccess";
// import SwitchApprovalReject from "pages/SwitchApproval/ReviewReject";

// import SipCancellationIntro from "pages/SipCancellation/Intro";
// import SipCancellationReview from "pages/SipCancellation/Review";
// import SipCancellationSuccess from "pages/SipCancellation/ReviewSuccess";
// import SipCancellationReject from "pages/SipCancellation/ReviewReject";

// import TransactionPauseIntro from "pages/TransactionPauseApproval/Intro";
// import TransactionPauseReview from "pages/TransactionPauseApproval/Review";
// import TransactionPauseSuccess from "pages/TransactionPauseApproval/ReviewSuccess";
// import TransactionPauseReject from "pages/TransactionPauseApproval/ReviewReject";

// import TransactionTopUpIntro from "pages/TransactionTopUpApproval/Intro";
// import TransactionTopUpReview from "pages/TransactionTopUpApproval/Review";
// import TransactionTopUpSuccess from "pages/TransactionTopUpApproval/ReviewSuccess";
// import TransactionTopUpReject from "pages/TransactionTopUpApproval/ReviewReject";

// import TransactionRedemptionIntro from "pages/TransactionRedemptionApproval/Intro";
// import TransactionRedemptionReview from "pages/TransactionRedemptionApproval/Review";
// import TransactionRedemptionSuccess from "pages/TransactionRedemptionApproval/ReviewSuccess";
// import TransactionRedemptionReject from "pages/TransactionRedemptionApproval/ReviewReject";

const AppRoute = () => {
  return (
    <div style={styles.container}>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path={RouteConfig.landing} element={<Landing />} />
          <Route path={RouteConfig.privacyPolicy} element={<PrivacyPolicy />} />
          <Route
            path={RouteConfig.transactionSuccess}
            element={
              <TransactionSuccess
                transactionSuccessMessage={"Your transaction was successful."}
              />
            }
          />
          <Route
            path={RouteConfig.transactionFail}
            element={<TransactionFail />}
          />

          {/* ################################# */}
          <Route path={RouteConfig.lumpsum} element={<Lumpsum />} />
          <Route path={RouteConfig.lumpsumOtp} element={<LumpsumOtp />} />
          <Route
            path={RouteConfig.lumpsumTransactionDetails}
            element={<LumpsumTransactionDetails />}
          />
          <Route
            path={RouteConfig.lumpsumTransactionEdit}
            element={<LumpsumEdit />}
          />
          <Route
            path={
              RouteConfig.lumpsumTransactionDetails +
              "/" +
              RouteConfig.paymentOptions
            }
            element={<PaymentOptions />}
          />
          <Route
            path={RouteConfig.otmPaymentSuccess}
            element={
              <OtmTransactionSuccess
                transactionSuccessMessage={
                  "Your Mandate has been created successfully."
                }
              />
            }
          />
          <Route
            path={RouteConfig.otmTransactionSuccess}
            element={
              <OtmTransactionSuccess
                transactionSuccessMessage={
                  "Your transaction is submitted successfully."
                }
              />
            }
          />
          <Route
            path={RouteConfig.otmPaymentFail}
            element={<TransactionFail />}
          />
          <Route
            path={RouteConfig.lumpsumTransactionReject}
            element={<RejectSuccess />}
          />

          {/* ################################# */}

          <Route path={RouteConfig.newLumpsum} element={<NewLumpsum />} />
          <Route path={RouteConfig.newLumpsumOtp} element={<NewLumpsumOtp />} />
          <Route
            path={RouteConfig.newLumpsumTransactionDetails}
            element={<NewLumpsumTransactionDetails />}
          />
          <Route
            path={RouteConfig.newLumpsumTransactionEdit}
            element={<NewLumpsumEdit />}
          />
          <Route
            path={RouteConfig.newLumpsumTransactionReject}
            element={<RejectSuccess />}
          />
          <Route
            path={
              RouteConfig.newLumpsumTransactionDetails +
              "/" +
              RouteConfig.paymentOptions
            }
            element={<PaymentOptions />}
          />
          <Route
            path={RouteConfig.newTransactionEdit}
            element={<NewTransactionEdit />}
          />
          <Route
            path={RouteConfig.newTransactionNomineeEdit}
            element={<NomineeEdit />}
          />
          <Route
            path={RouteConfig.newTransactionNomineeAdd}
            element={<NomineeAdd />}
          />
          {/* ################################# */}

          <Route path={RouteConfig.sip} element={<Sip />} />
          <Route path={RouteConfig.sipOtp} element={<SipOtp />} />
          <Route
            path={RouteConfig.sipTransactionDetails}
            element={<SipTransactionDetails />}
          />
          <Route
            path={RouteConfig.sipTransactionDetails + "/" + RouteConfig.edit}
            element={<SipTransactionEdit />}
          />
          <Route
            path={RouteConfig.sipTransactionReject}
            element={<RejectSuccess />}
          />
          <Route
            path={
              RouteConfig.sipTransactionDetails +
              "/" +
              RouteConfig.paymentOptions
            }
            element={<PaymentOptions />}
          />

          {/* ################################# */}

          <Route path={RouteConfig.newSip} element={<NewSip />} />
          <Route path={RouteConfig.newSipOtp} element={<NewSipOtp />} />
          <Route
            path={RouteConfig.newSipTransactionDetails}
            element={<NewSipTransactionDetails />}
          />
          <Route path={RouteConfig.newSipEdit} element={<NewSipEdit />} />
          <Route path={RouteConfig.newSipReject} element={<RejectSuccess />} />
          <Route
            path={
              RouteConfig.newSipTransactionDetails +
              "/" +
              RouteConfig.paymentOptions
            }
            element={<PaymentOptions />}
          />
          {/* ################################# */}

          <Route path={RouteConfig.otm} element={<OTM />} />

          <Route path={RouteConfig.otmOtp} element={<OTMOtp />} />
          <Route
            path={RouteConfig.otmTransactionDetails}
            element={<OTMTransactionDetails />}
          />
          <Route path={RouteConfig.otmEdit} element={<OTMTransactionEdit />} />
          <Route
            path={RouteConfig.otmTransactionReject}
            element={<RejectSuccess />}
          />
          <Route
            path={
              RouteConfig.otmTransactionDetails +
              "/" +
              RouteConfig.paymentOptions
            }
            element={<PaymentOptions />}
          />
          {/* ################################# */}

          <Route path={RouteConfig.cancelSipStpSwp} element={<CancelSip />} />
          <Route
            path={RouteConfig.cancelSipStpSwpOtp}
            element={<CancelSipOtp />}
          />
          <Route
            path={RouteConfig.cancelSipStpSwpTransactionDetails}
            element={<CancelSipTransactionDetails />}
          />
          <Route
            path={RouteConfig.cancelSipStpSwpTransactionEdit}
            element={<CancelSIPEdit />}
          />
          <Route
            path={RouteConfig.cancelSipStpSwpSuccess}
            element={
              <TransactionSuccess transactionSuccessMessage="Your SIP cancellation request has been submitted." />
            }
          />
          <Route
            path={RouteConfig.cancelSipStpSwpTransactionReject}
            element={<RejectSuccess />}
          />
          {/* ################################# */}

          <Route path={RouteConfig.pauseSipStpSwp} element={<PauseSip />} />
          <Route
            path={RouteConfig.pauseSipStpSwpOtp}
            element={<PauseSipOtp />}
          />
          <Route
            path={RouteConfig.pauseSipStpSwpTransactionDetails}
            element={<PauseSipTransactionDetails />}
          />
          <Route
            path={RouteConfig.pauseSipStpSwpTransactionEdit}
            element={<PauseSIPEdit />}
          />
          <Route
            path={RouteConfig.pauseSipStpSwpSuccess}
            element={
              <TransactionSuccess transactionSuccessMessage="Your SIP Pause request was successful" />
            }
          />
          <Route
            path={RouteConfig.pauseSipTransactionReject}
            element={<RejectSuccess />}
          />

          {/* ################################# */}

          <Route path={RouteConfig.stp} element={<STP />} />
          <Route path={RouteConfig.stpOtp} element={<STPOtp />} />
          <Route
            path={RouteConfig.stpTransactionDetails}
            element={<STPTransactionDetails />}
          />
          <Route
            path={RouteConfig.stpTargetSchemeEdit}
            element={<STPtargetSchemeEdit />}
          />
          <Route
            path={RouteConfig.stpSuccess}
            element={
              <TransactionSuccess
                transactionSuccessMessage={
                  "Your STP request is received for registration."
                }
              />
            }
          />
          <Route
            path={RouteConfig.cancelStpSuccess}
            element={
              <TransactionSuccess transactionSuccessMessage="Your STP cancellation request has been submitted." />
            }
          />
          <Route
            path={RouteConfig.stpTransactionReject}
            element={<RejectSuccess />}
          />

          {/* ################################# */}

          <Route path={RouteConfig.swp} element={<SWP />} />
          <Route path={RouteConfig.swpOtp} element={<SWPOtp />} />
          <Route
            path={RouteConfig.swpTransactionDetails}
            element={<SWPTransactionDetails />}
          />
          <Route
            path={RouteConfig.swpSuccess}
            element={
              <TransactionSuccess
                transactionSuccessMessage={
                  "Your SWP request is received for registration."
                }
              />
            }
          />
          <Route
            path={RouteConfig.swpTransactionReject}
            element={<RejectSuccess />}
          />
          <Route
            path={RouteConfig.cancelSwpSuccess}
            element={
              <TransactionSuccess transactionSuccessMessage="Your SWP cancellation request has been submitted." />
            }
          />
          <Route path={RouteConfig.swpedit} element={<SwpEdit />} />
          {/* ################################# */}

          <Route path={RouteConfig.switch} element={<Switch />} />
          <Route path={RouteConfig.switchOtp} element={<SwitchOtp />} />
          <Route
            path={RouteConfig.switchTransactionDetails}
            element={<SwitchTransactionDetails />}
          />
          <Route path={RouteConfig.switchEdit} element={<SwitchEdit />} />
          <Route
            path={RouteConfig.switchSuccess}
            element={
              <TransactionSuccess
                transactionSuccessMessage={
                  "Your switch request has been successfully registered "
                }
              />
            }
          />
          <Route
            path={RouteConfig.switchTransactionReject}
            element={<RejectSuccess />}
          />
          {/* ################################# */}
          <Route path={RouteConfig.redemption} element={<Redemption />} />
          <Route path={RouteConfig.redemptionOtp} element={<RedemptionOtp />} />
          <Route
            path={RouteConfig.redemptionTransactionDetails}
            element={<RedemptionTransactionDetails />}
          />
          <Route
            path={RouteConfig.redemptionEdit}
            element={<RedemptionEdit />}
          />
          <Route
            path={RouteConfig.redemptionSuccess}
            element={
              <TransactionSuccess
                transactionSuccessMessage={
                  "Your Redemption request was successful"
                }
              />
            }
          />
          <Route
            path={RouteConfig.redemptionTransactionReject}
            element={<RejectSuccess />}
          />
          {/* ################################# */}
          <Route path={RouteConfig.ekyc} element={<Ekyc />} />
          <Route path={RouteConfig.ekycOtp} element={<EkycOtp />} />
          <Route path={RouteConfig.ekycSuccess} element={<EkycSuccess />} />
          <Route path={RouteConfig.ekycSuccess1} element={<EkycSuccess />} />
          <Route path={RouteConfig.unlockAccount} element={<UnlockSuccess />} />

          {/* ################################# */}

          {/* <Route path={RouteConfig.kyc} element={<KYC />} />
          <Route
            path={RouteConfig.kyc + "/" + RouteConfig.otp}
            element={<Otp />}
          />
          <Route
            path={RouteConfig.kyc + "/" + RouteConfig.success}
            element={<KycSuccess />}
          />
  
          <Route
            path={RouteConfig.transactionApproval + "/" + RouteConfig.intro}
            element={<TransactionApprovalIntro />}
          />
          <Route
            path={RouteConfig.transactionApproval + "/" + RouteConfig.review}
            element={<TransactionApprovalReview />}
          />
          <Route
            path={
              RouteConfig.transactionApproval +
              "/" +
              RouteConfig.review +
              "/" +
              RouteConfig.editInvestmentDetails
            }
            element={<EditInvestmentDetails />}
          />
          <Route
            path={
              RouteConfig.transactionApproval +
              "/" +
              RouteConfig.review +
              "/" +
              RouteConfig.payment
            }
            element={<TransactionApprovalPayment />}
          />
          <Route
            path={
              RouteConfig.transactionApproval +
              "/" +
              RouteConfig.review +
              "/" +
              RouteConfig.reviewSuccess
            }
            element={<ReviewSuccess />}
          />
          <Route
            path={RouteConfig.sipApproval + "/" + RouteConfig.intro}
            element={<SipApprovalIntro />}
          />
          <Route
            path={RouteConfig.sipApproval + "/" + RouteConfig.review}
            element={<SipApprovalReview />}
          />
          <Route
            path={
              RouteConfig.sipApproval +
              "/" +
              RouteConfig.review +
              "/" +
              RouteConfig.payment
            }
            element={<SipApprovalPayment />}
          />
          <Route
            path={
              RouteConfig.sipApproval +
              "/" +
              RouteConfig.review +
              "/" +
              RouteConfig.success
            }
            element={<SipApprovalSuccess />}
          />
          <Route
            path={RouteConfig.investorApproval + "/" + RouteConfig.intro}
            element={<InvestorApprovalIntro />}
          />
          <Route
            path={RouteConfig.investorApproval + "/" + RouteConfig.review}
            element={<InvestorApprovalReview />}
          />
          <Route
            path={
              RouteConfig.investorApproval +
              "/" +
              RouteConfig.review +
              "/" +
              RouteConfig.success
            }
            element={<InvestorApprovalSuccess />}
          />
          <Route
            path={
              RouteConfig.investorApproval +
              "/" +
              RouteConfig.review +
              "/" +
              RouteConfig.reviewReject
            }
            element={<InvestorApprovalReject />}
          />
          <Route
            path={
              RouteConfig.investorApproval + "/" + RouteConfig.investmentDetails
            }
            element={<InvestorApprovalInvestmentDetails />}
          />
          <Route
            path={RouteConfig.investorApproval + "/" + RouteConfig.applicant}
            element={<IvestorApprovalApplicant />}
          />
          <Route
            path={RouteConfig.investorApproval + "/" + RouteConfig.nominee}
            element={<IvestorApprovalNominee />}
          />
          <Route
            path={RouteConfig.investorApproval + "/" + RouteConfig.payment}
            element={<IvestorApprovalPayment />}
          />
          <Route
            path={RouteConfig.investorOtm + "/" + RouteConfig.intro}
            element={<OtmInvestorApprovalInto />}
          />
          <Route
            path={RouteConfig.investorOtm + "/" + RouteConfig.review}
            element={<OtmInvestorApprovalReview />}
          />
          <Route
            path={RouteConfig.investorOtm + "/" + RouteConfig.reviewSuccess}
            element={<OtmInvestorApprovalSuccess />}
          />
          <Route
            path={RouteConfig.investorOtm + "/" + RouteConfig.reviewReject}
            element={<OtmInvestorApprovalReject />}
          />
          <Route
            path={
              RouteConfig.investorOtm +
              "/" +
              RouteConfig.review +
              "/" +
              RouteConfig.editInvestmentDetails
            }
            element={<OtmInvestorApprovalEditBankDetails />}
          />
          <Route
            path={
              RouteConfig.investorOtm +
              "/" +
              RouteConfig.review +
              "/" +
              RouteConfig.editMandateDetails
            }
            element={<OtmInvestorApprovalEditMandateDetails />}
          />
          <Route
            path={RouteConfig.stpApproval + "/" + RouteConfig.intro}
            element={<StpApprovalIntro />}
          />
          <Route
            path={RouteConfig.stpApproval + "/" + RouteConfig.review}
            element={<StpApprovalReview />}
          />
          <Route
            path={RouteConfig.stpApproval + "/" + RouteConfig.reviewSuccess}
            element={<StpApprovalSuccess />}
          />
          <Route
            path={RouteConfig.stpApproval + "/" + RouteConfig.reviewReject}
            element={<StpApprovalReject />}
          />
          <Route
            path={RouteConfig.swpApproval + "/" + RouteConfig.intro}
            element={<SwpApprovalIntro />}
          />
          <Route
            path={RouteConfig.swpApproval + "/" + RouteConfig.review}
            element={<SwpApprovalReview />}
          />
          <Route
            path={RouteConfig.swpApproval + "/" + RouteConfig.reviewSuccess}
            element={<SwpApprovalSuccess />}
          />
          <Route
            path={RouteConfig.swpApproval + "/" + RouteConfig.reviewReject}
            element={<SwpApprovalReject />}
          />
          <Route
            path={RouteConfig.switchApproval + "/" + RouteConfig.intro}
            element={<SwitchApprovalIntro />}
          />
          <Route
            path={RouteConfig.switchApproval + "/" + RouteConfig.review}
            element={<SwitchApprovalReview />}
          />
          <Route
            path={RouteConfig.switchApproval + "/" + RouteConfig.reviewSuccess}
            element={<SwitchApprovalSuccess />}
          />
          <Route
            path={RouteConfig.switchApproval + "/" + RouteConfig.reviewReject}
            element={<SwitchApprovalReject />}
          />
          <Route
            path={RouteConfig.sipCancellation + "/" + RouteConfig.intro}
            element={<SipCancellationIntro />}
          />
          <Route
            path={RouteConfig.sipCancellation + "/" + RouteConfig.review}
            element={<SipCancellationReview />}
          />
          <Route
            path={RouteConfig.sipCancellation + "/" + RouteConfig.reviewSuccess}
            element={<SipCancellationSuccess />}
          />
          <Route
            path={RouteConfig.sipCancellation + "/" + RouteConfig.reviewReject}
            element={<SipCancellationReject />}
          />
          <Route
            path={
              RouteConfig.transactionPauseApproval + "/" + RouteConfig.intro
            }
            element={<TransactionPauseIntro />}
          />
          <Route
            path={
              RouteConfig.transactionPauseApproval + "/" + RouteConfig.review
            }
            element={<TransactionPauseReview />}
          />
          <Route
            path={
              RouteConfig.transactionPauseApproval +
              "/" +
              RouteConfig.reviewSuccess
            }
            element={<TransactionPauseSuccess />}
          />
          <Route
            path={
              RouteConfig.transactionPauseApproval +
              "/" +
              RouteConfig.reviewReject
            }
            element={<TransactionPauseReject />}
          />
          <Route
            path={
              RouteConfig.transactionTopUpApproval + "/" + RouteConfig.intro
            }
            element={<TransactionTopUpIntro />}
          />
          <Route
            path={
              RouteConfig.transactionTopUpApproval + "/" + RouteConfig.review
            }
            element={<TransactionTopUpReview />}
          />
          <Route
            path={
              RouteConfig.transactionTopUpApproval +
              "/" +
              RouteConfig.reviewSuccess
            }
            element={<TransactionTopUpSuccess />}
          />
          <Route
            path={
              RouteConfig.transactionTopUpApproval +
              "/" +
              RouteConfig.reviewReject
            }
            element={<TransactionTopUpReject />}
          />
          <Route
            path={
              RouteConfig.transactionRedemptionApproval +
              "/" +
              RouteConfig.intro
            }
            element={<TransactionRedemptionIntro />}
          />
          <Route
            path={
              RouteConfig.transactionRedemptionApproval +
              "/" +
              RouteConfig.review
            }
            element={<TransactionRedemptionReview />}
          />
          <Route
            path={
              RouteConfig.transactionRedemptionApproval +
              "/" +
              RouteConfig.reviewSuccess
            }
            element={<TransactionRedemptionSuccess />}
          />
          <Route
            path={
              RouteConfig.transactionRedemptionApproval +
              "/" +
              RouteConfig.reviewReject
            }
            element={<TransactionRedemptionReject />}
          />
           */}
          <Route path={"*"} element={<Landing />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
const styles = Object.freeze({
  container: {
    // display: "flex",
    minHeight: "100vh",
    minWidth: "100vw",
    // backgroundColor: "red",
  },
});
export default AppRoute;
