import React, { useEffect, useMemo, useRef } from "react";
import { Box } from "@mui/system";
import DisplayGridData from "components/DisplayGridData";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import colors from "res/colors";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputAdornment,
  Link,
  TextField,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import Text from "components/Text";
import isMobile from "utils/isMobile";
import ButtonIcon from "components/ButtonIcon";
import { useAppSelector } from "hooks/useAppSelector";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/useAppDispatch";
import RejectConfirmationDialog from "./RejectConfirmationDialog";
import {
  generateOtp,
  resetOtpStates,
  resetVerifyOtp,
  verifyApplicantOtp,
} from "store/otp/slice";
import { TWO_BYTES, TransactionType } from "utils/constants";
import moment from "moment";
import { convertSIPFrequency } from "utils/convertSIPFrequency";
import ActivityOverlay from "components/ActivityOverlay/ActivityOverlay";
import {
  resetUploadChequeLeaf,
  resetUploadChequeLeafMessage,
  resetValidateBankDetails,
  resetValidationError,
  setCountVerified,
  setOtpVerifiedEmail,
  setOtpVerifiedEmailList,
  setOtpVerifiedMobile,
  setOtpVerifiedMobileList,
  setShowUploadCheckLeaf,
  setValidateBankDetailsMsgShown,
  uploadChequeLeaf,
  validateBankDetails,
} from "store/transaction/slice";
import { convertToCurrencyFormat } from "utils/convertToCurrencyFormat";
import ImageDropzone from "components/ImageDropZone";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";
import TwoFactorAuthentication from "components/TwoFactorAuthentication/TwoFactorAuthentication";
import RouteConfig from "routes/RouteConfig";
import { TrendingUpTwoTone } from "@mui/icons-material";
import Info from "components/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import strings from "res/strings";
import { capitalize } from "lodash";
export type StepperOption = {
  id: number;
  title: string;
};

export interface NewSipLumpsumTransactionDetailsProps {
  stepper: {
    currentIndex: number;
    steps: StepperOption[];
  };
  transactions: Array<{ [key: string]: any }>;
  error?: string;
  onApproveTranaction?: () => void;
  onRejectTranaction?: () => void;
  onEditTransaction?: (data: any, contentType: string | undefined) => void;
  onDeleteTransaction?: (data: any, contentType: string | undefined) => void;
}

const NewSipLumpsumTransactionDetails: React.FC<
  NewSipLumpsumTransactionDetailsProps
> = ({
  error,
  onApproveTranaction,
  onRejectTranaction,
  onEditTransaction,
  onDeleteTransaction,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    transactionDetails,
    transactionSchemeDetails,
    transactionDistributor,
    transactionInfo,
    otpVerifiedMobile,
    otpVerifiedEmail,
    otpVerifiedEmailList,
    otpVerifiedMobileList,
    countVerified,
    isLoadingValidateBank,
    didValidateBankDetails,
    validateBankDetailsError,
    isLoadingUploadChequeLeaf,
    didUploadChequeLeaf,
    uploadChequeLeafError,
    showUploadCheckLeaf,
    validateBankDetailsMsgShown,
    nomineeDetails,
    nomineeStatus,
    didEditTransaction,
    isEditingTransaction,
  } = useAppSelector((state) => state.transaction);

  const { transactionType, ihNo } = useAppSelector((state) => state.appState);
  const {
    didVerifyOtp,
    isVerifyingApplicantOtp,
    applicantOtpError,
    didVerifyApplicantOtp,
    userMakedInfo,
  } = useAppSelector((state) => state.otp);

  const [checked, setChecked] = useState(false);
  const [nomineeDeclarationChecked, setNomineeDeclarationChecked] = useState(
    nomineeDetails?.length < 1
  );
  const [showNomineeAlert, setShowNomineeAlert] = useState(false);
  const [showRejectDialog, setShowRejectDialog] = useState(false);

  const [mobileOTPDialogOpen, setMobileOtpDialogOpen] = React.useState(false);
  const [emailOTPDialogOpen, setEmailOtpDialogOpen] = React.useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = React.useState(false);
  const [verificationMobileOTP, setVerificationMobileOTP] = React.useState("");
  const [verificationEmailOTP, setVerificationEmailOTP] = React.useState("");
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [otpError, setOtpError] = React.useState("");
  const [validationError, setValidationError] = React.useState("");
  const [count, setCount] = React.useState(0);
  // const [countVerified, setCountVerified] = React.useState(0);
  const [totalNominiAllocation, setTotalNominiAllocation] = React.useState(0);
  const [nomineePresent, setNomineePresent] = React.useState(false);
  const [verifyPending, setVerifyPending] = React.useState(false);
  const [bankDetailsVerifyPending, setBankDetailsVerifyPending] =
    React.useState(false);
  const [validateBankAlert, setValidateBankAlert] = React.useState(false);
  const [uploadCheckLeaf, setUploadCheckLeaf] = React.useState(false);
  const [validateBankChecked, setValidateBankChecked] = React.useState(false);
  const [resendOtp, setResendOtp] = React.useState("sent");
  const [validateBankMessage, setValidateBankMessage] = React.useState("");
  const [uploadChequeImage, setUploadChequeImage] = React.useState<any>(null);
  const [showUploadChequeError, setShowUploadChequeError] = React.useState("");
  const [showChequeFileError, setShowChequeFileError] = React.useState("");
  const imageDropzoneThumbnailImageRef = useRef<any>(null);
  const [otpDialogOpen, setOtpDialogOpen] = React.useState(false);
  const [otp, setOtp] = useState("");
  const [applicantDataMobile, setApplicantDataMobile] =
    React.useState<any>(null);
  const [applicantDataEmail, setApplicantDataEmail] = React.useState<any>(null);
  const [showNomineeDeleteAlert, setShowNomineeDeleteAlert] = useState(false);
  const [nomineeDeleteData, setNomineeDeleteData] = useState("");
  const [nomineeDeleteType, setNomineeDeleteType] = useState<
    string | undefined
  >("");

  useEffect(() => {
    if (didValidateBankDetails && !validateBankDetailsMsgShown) {
      setValidateBankMessage("Pennydrop authentication of bank is successful.");
    } else if (validateBankDetailsError) {
      setValidateBankMessage(
        "Pennydrop authentication of bank has failed. Please upload cheque leaf."
      );
    } else {
      setValidateBankMessage("");
    }
  }, [didValidateBankDetails, validateBankDetailsError]);
  useEffect(() => {
    if (checked) {
      if (verifyPending || bankDetailsVerifyPending) {
        setAlertDialogOpen(true);
      }
    }
  }, [checked]);
  useEffect(() => {
    if (didUploadChequeLeaf) {
      setUploadCheckLeaf(false);
      imageDropzoneThumbnailImageRef?.current?.clearImage();
      dispatch(resetValidationError());
      dispatch(resetUploadChequeLeafMessage());
    }
  }, [didUploadChequeLeaf]);

  useEffect(() => {
    if (showUploadCheckLeaf) {
      dispatch(resetValidationError());
    }
  }, [showUploadCheckLeaf]);
  useEffect(() => {
    setNomineeDeclarationChecked(nomineeDetails?.length < 1);
  }, [nomineeDetails]);

  const handleResendOtpMobile = () => {
    setOtpError("");
    dispatch(resetVerifyOtp());
    dispatch(generateOtp({ mobileNo: mobileNumber }));
    setResendOtp("resent");
  };
  const handleResendOtpEmail = () => {
    setOtpError("");
    dispatch(resetVerifyOtp());
    dispatch(generateOtp({ email: email }));
    setResendOtp("resent");
  };

  const handleMobileOtpClickOpen = (mobile: any) => {
    setOtpError("");
    setMobileOtpDialogOpen(true);
    setMobileNumber(mobile);
    dispatch(generateOtp({ mobileNo: mobile }));
    setResendOtp("sent");
  };

  const handleMobileOtpClose = () => {
    setMobileOtpDialogOpen(false);
    dispatch(resetVerifyOtp());
    setApplicantDataMobile(null);
    setVerificationMobileOTP("");
  };
  const handleEmailOtpClose = () => {
    setEmailOtpDialogOpen(false);
    dispatch(resetVerifyOtp());
    setApplicantDataEmail(null);
    setVerificationEmailOTP("");
  };
  const handleEmailOtpClickOpen = (email: any) => {
    setOtpError("");
    setEmailOtpDialogOpen(true);
    setEmail(email);
    dispatch(generateOtp({ email: email }));
    setResendOtp("sent");
  };

  const handleCheckLeafUpload = () => {
    setShowUploadChequeError("");
    if (showChequeFileError) {
      return;
    }
    if (!uploadChequeImage) {
      setShowUploadChequeError("Please upload cheque leaf image.");
      return;
    }
    dispatch(
      uploadChequeLeaf({
        file: uploadChequeImage,
        ihNo: transactionDetails?.fetchFreshCartDataResponseList?.batchNo,
      })
    );
  };
  const handleOnValidateBankDetails = () => {
    setValidateBankChecked(false);
    setValidateBankAlert(false);
    dispatch(
      validateBankDetails({
        ifsc: transactionDetails?.fetchFreshCartDataResponseList
          ?.payoutIFSCCode,
        accountNo:
          transactionDetails?.fetchFreshCartDataResponseList?.payoutBankAcNo,
        mobileNo:
          transactionDetails?.fetchFreshCartDataResponseList?.ap1MobileNumber,
        pan: transactionDetails?.fetchFreshCartDataResponseList?.ap1Pan,
        investorName:
          transactionDetails?.fetchFreshCartDataResponseList?.ap1LastName,
        requestType: "PennyDrop",
        fund: transactionDetails?.fetchFreshCartDataResponseList?.fund,
        consent: "Y",
        category: transactionDetails?.fetchFreshCartDataResponseList?.category,
        ihNo: transactionDetails?.fetchFreshCartDataResponseList?.batchNo,
      })
    );
  };

  useEffect(() => {
    if (didVerifyApplicantOtp) {
      if (otpDialogOpen) {
        if (onApproveTranaction) {
          onApproveTranaction();
          setOtpDialogOpen(false);
        }
      } else {
        if (applicantDataMobile) {
          dispatch(
            setOtpVerifiedMobileList({
              key: applicantDataMobile,
              value: mobileNumber,
            })
          );
          dispatch(setOtpVerifiedMobile(applicantDataMobile));
        }
        if (applicantDataEmail) {
          dispatch(
            setOtpVerifiedEmailList({
              key: applicantDataEmail,
              value: email,
            })
          );
          dispatch(setOtpVerifiedEmail(applicantDataEmail));
        }
        handleMobileOtpClose();
        handleEmailOtpClose();
      }
    }
  }, [didVerifyApplicantOtp]);
  const handleMobileOtpVerify = () => {
    setOtpError("");
    if (!verificationMobileOTP || verificationMobileOTP?.length < 5) {
      setOtpError("Invalid OTP. Please enter valid OTP.");
      return;
    }
    const params = {
      userId: transactionDistributor?.ARN,
      userType: "MOBILE",
      otp: verificationMobileOTP,
      referenceId: ihNo,
      email: "",
      mobileNo: mobileNumber,
      otpMobile: "",
    };

    dispatch(verifyApplicantOtp(params));
  };

  const handleEmailOtpVerify = () => {
    setOtpError("");
    if (!verificationEmailOTP || verificationEmailOTP?.length < 5) {
      setOtpError("Invalid OTP. Please enter valid OTP.");
      return;
    }
    const params = {
      userId: transactionDistributor?.ARN,
      userType: "EMAIL",
      otp: verificationEmailOTP,
      referenceId: ihNo,
      email: email,
      mobileNo: "",
      otpMobile: "",
    };

    dispatch(verifyApplicantOtp(params));
  };

  useEffect(() => {
    if (verificationMobileOTP?.length >= 5) {
      handleMobileOtpVerify();
    }
    if (verificationEmailOTP?.length >= 5) {
      handleEmailOtpVerify();
    }
  }, [verificationMobileOTP, verificationEmailOTP]);
  const [mobileOtp, setMobileOtp] = React.useState("");
  const [emailOtp, setEmailOtp] = React.useState("");

  const handleMobileOtpInput = (newValue: string) => {
    setMobileOtp(newValue);
  };
  const handleEmailOtpInput = (newValue: string) => {
    setEmailOtp(newValue);
  };

  const handleApprove = () => {
    setValidationError("");
    if (
      !nomineeDeclarationChecked &&
      nomineePresent &&
      totalNominiAllocation !== 100
    ) {
      setValidationError("Nominee Allocation should be 100%");
      return;
    }
    dispatch(generateOtp({ ihNo: ihNo }));
    setOtpDialogOpen(true);
  };

  const handleReject = () => {
    setShowRejectDialog(false);
    if (onRejectTranaction) {
      onRejectTranaction();
    } else {
      dispatch(resetOtpStates());
      navigate("/");
    }
  };
  const handleShowRejectDialog = () => {
    setShowRejectDialog(true);
  };
  const closeRejectDialog = () => {
    setShowRejectDialog(false);
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const handleNomineeDeclarationChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.checked) {
      setShowNomineeAlert(true);
      return;
    }
    setNomineeDeclarationChecked(event.target.checked);
  };
  const handleValidateBankCheckChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setValidateBankChecked(event.target.checked);
  };

  const handleThumbnailImageDrop = (imageData: {
    url: string;
    name: string;
    size?: number;
  }) => {
    setShowChequeFileError("");
    if (imageData?.size && imageData?.size > TWO_BYTES) {
      setShowChequeFileError("File size should be under 2mb.");
      return;
    }
    setUploadChequeImage(imageData);
    setShowUploadChequeError("");
  };
  // *********************************************************************************************************

  const handleOtpDialogClose = () => {
    setOtp("");
    setOtpDialogOpen(false);
    dispatch(resetVerifyOtp());
  };

  const handleOTPChange = (event: string) => {
    setOtp(event);
  };

  const handleResendPressed = () => {
    setOtp("");
    dispatch(resetVerifyOtp());
    dispatch(generateOtp({ ihNo: ihNo }));
  };

  useEffect(() => {
    if (otp.length === 5) {
      //  here goes the verify 2FA OTP logic
      const params = {
        userId: transactionDistributor?.ARN,
        userType: "EMAILMOBILE",
        otp: otp,
        referenceId: ihNo,
        email: transactionDetails.fetchFreshCartDataResponseList.ap1Email,
        mobileNo:
          transactionDetails.fetchFreshCartDataResponseList.ap1MobileNumber,
        otpMobile: otp,
      };
      dispatch(verifyApplicantOtp(params));
    }
    return () => {
      dispatch(resetVerifyOtp());
    };
  }, [otp]);

  const showError = Boolean(error);

  const getSipData = (item: any) => {
    if (transactionType === TransactionType.newLumpsum) {
      return null;
    } else {
      return {
        "SIP Frequency": convertSIPFrequency(item.sipFrequency),
        "SIP Start Date": moment(item.sipStartDate).format("DD-MMM-YYYY"),
        "SIP End Date": moment(item.sipEndDate).format("DD-MMM-YYYY"),
        "No. of Installment": item.noOfInstallments,
      };
    }
  };
  const getSIPTopupData = (item: any) => {
    if (!item.topUpAmount || item.topUpAmount === "0") {
      return null;
    } else {
      return {
        "Top-Up Amount": item.topUpAmount,
        "Top-Up Frequency":
          item.topUpFrequency === "H" ? "Half Yearly" : "Yearly",
        "Top-Up Start Date": item.topUpStartDate
          ? moment(item.topUpStartDate).format("DD-MMM-YYYY")
          : "",
        "Top-Up End Date": item.topUpEndDate
          ? moment(item.topUpEndDate).format("DD-MMM-YYYY")
          : "",
      };
    }
  };

  const handleDeleteTransaction = (data: any, type: string | undefined) => {
    setNomineeDeleteData(data);
    setNomineeDeleteType(type);
    setShowNomineeDeleteAlert(true);
  };

  const transactionContents = useMemo(() => {
    if (!didVerifyOtp) {
      return [];
    }
    const data: { [key: string]: any }[] = [];

    transactionSchemeDetails.map((item: any, index: number) => {
      data.push({
        title: `Investment ${index + 1}`,
        canEdit: true,
        contentType: "Investment",
        index: index,
        contents: {
          "Investment Type":
            transactionType === TransactionType.newLumpsum
              ? "Lumpsum"
              : "Systematic Investment Plan",
          "Scheme Name": item.fundDesc,
          Option: `${item.optDesc} - ${item?.planDesc}`,
          "Investment amount": transactionDetails.fetchFreshCartDataResponseList
            .schemeList[index].amount
            ? `₹${convertToCurrencyFormat(
                transactionDetails.fetchFreshCartDataResponseList.schemeList[
                  index
                ].amount
              )}`
            : "",
          ...getSipData(
            transactionDetails.fetchFreshCartDataResponseList.schemeList[index]
          ),
          ...getSIPTopupData(
            transactionDetails.fetchFreshCartDataResponseList.schemeList[index]
          ),
        },
      });
    });

    let verifyPending = false;
    if (
      otpVerifiedMobile &&
      otpVerifiedMobile["Applicant_1"] &&
      otpVerifiedMobileList["Applicant_1"] ===
        transactionDetails.fetchFreshCartDataResponseList.ap1MobileNumber
    ) {
    } else {
      verifyPending = true;
    }

    if (
      otpVerifiedEmail &&
      otpVerifiedEmail["Applicant_1"] &&
      otpVerifiedEmailList["Applicant_1"] ===
        transactionDetails.fetchFreshCartDataResponseList.ap1Email
    ) {
    } else {
      verifyPending = true;
    }

    data.push({
      title: "Primary Applicant",
      canEdit: true,
      contentType: "Applicant_1",
      mobileNumber:
        transactionDetails.fetchFreshCartDataResponseList.ap1MobileNumber,
      emailId: transactionDetails.fetchFreshCartDataResponseList.ap1Email,
      contents: {
        "Investor Name":
          transactionDetails.fetchFreshCartDataResponseList.ap1LastName,
        PAN: transactionDetails.fetchFreshCartDataResponseList.ap1Pan,
        Mobile: (
          <Button
            sx={{
              px: 0,
              py: 0,
              textTransform: "none",
            }}
            onClick={() => {
              setApplicantDataMobile("Applicant_1");
              handleMobileOtpClickOpen(
                transactionDetails.fetchFreshCartDataResponseList
                  .ap1MobileNumber
              );
            }}
            disabled={
              otpVerifiedMobile &&
              otpVerifiedMobile["Applicant_1"] &&
              otpVerifiedMobileList["Applicant_1"] ===
                transactionDetails.fetchFreshCartDataResponseList
                  .ap1MobileNumber
            }
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Text type="dataSmallBold" color={colors.black}>
                {
                  transactionDetails.fetchFreshCartDataResponseList
                    .ap1MobileNumber
                }
              </Text>
              <Box sx={{ ml: "10px" }} />
              {otpVerifiedMobile &&
              otpVerifiedMobile["Applicant_1"] &&
              otpVerifiedMobileList["Applicant_1"] ===
                transactionDetails.fetchFreshCartDataResponseList
                  .ap1MobileNumber ? (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Text type="dataSmallBold" color={colors.green}>
                    {"Verified"}
                  </Text>
                  <CheckCircleIcon
                    style={{
                      color: colors.green,
                      marginLeft: 3,
                      fontSize: 15,
                    }}
                  />
                </Box>
              ) : (
                <Text type="dataSmallBold" color={colors.backGroundBlue}>
                  {"Verify"}
                </Text>
              )}
            </Box>
          </Button>
        ),
        "Mobile belongs to":
          transactionDetails.fetchFreshCartDataResponseList.ap1MobileBelongsTo,
        // transactionDetails.fetchFreshCartDataResponseList.ap1MobileNumber,
        Email: (
          <Button
            sx={{
              px: 0,
              py: 0,
              textTransform: "none",
            }}
            onClick={() => {
              setApplicantDataEmail("Applicant_1");
              handleEmailOtpClickOpen(
                transactionDetails.fetchFreshCartDataResponseList.ap1Email
              );
            }}
            disabled={
              otpVerifiedEmail &&
              otpVerifiedEmail["Applicant_1"] &&
              otpVerifiedEmailList["Applicant_1"] ===
                transactionDetails.fetchFreshCartDataResponseList.ap1Email
            }
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Text type="dataSmallBold" color={colors.black}>
                {transactionDetails.fetchFreshCartDataResponseList.ap1Email}
              </Text>
              <Box sx={{ ml: "10px" }} />

              {otpVerifiedEmail &&
              otpVerifiedEmail["Applicant_1"] &&
              otpVerifiedEmailList["Applicant_1"] ===
                transactionDetails.fetchFreshCartDataResponseList.ap1Email ? (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Text type="dataSmallBold" color={colors.green}>
                    {"Verified"}
                  </Text>
                  <CheckCircleIcon
                    style={{
                      color: colors.green,
                      marginLeft: 3,
                      fontSize: 15,
                    }}
                  />
                </Box>
              ) : (
                <Text type="dataSmallBold" color={colors.backGroundBlue}>
                  {"Verify"}
                </Text>
              )}
            </Box>
          </Button>
        ),
        "Email belongs to":
          transactionDetails.fetchFreshCartDataResponseList.ap1EmailBelongsTo,
      },
    });

    if (transactionDetails.fetchFreshCartDataResponseList.ap2Pan) {
      if (
        !(
          transactionDetails.fetchFreshCartDataResponseList.ap1MobileNumber ===
          transactionDetails.fetchFreshCartDataResponseList.ap2MobileNumber
        )
      ) {
        if (
          otpVerifiedMobile &&
          otpVerifiedMobile["Applicant_2"] &&
          otpVerifiedMobileList["Applicant_2"] ===
            transactionDetails.fetchFreshCartDataResponseList.ap2MobileNumber
        ) {
        } else {
          verifyPending = true;
        }
      }

      if (
        !(
          transactionDetails.fetchFreshCartDataResponseList.ap1Email ===
          transactionDetails.fetchFreshCartDataResponseList.ap2Email
        )
      ) {
        if (
          otpVerifiedEmail &&
          otpVerifiedEmail["Applicant_2"] &&
          otpVerifiedEmailList["Applicant_2"] ===
            transactionDetails.fetchFreshCartDataResponseList.ap2Email
        ) {
        } else {
          verifyPending = true;
        }
      }

      data.push({
        title: "Co-Applicant 1",
        canEdit: true,
        contentType: "Applicant_2",
        mobileNumber:
          transactionDetails.fetchFreshCartDataResponseList.ap2MobileNumber,
        emailId: transactionDetails.fetchFreshCartDataResponseList.ap2Email,
        contents: {
          "Investor Name":
            transactionDetails.fetchFreshCartDataResponseList.ap2LastName,
          PAN: transactionDetails.fetchFreshCartDataResponseList.ap2Pan,
          Mobile:
            transactionDetails.fetchFreshCartDataResponseList
              .ap1MobileNumber ===
            transactionDetails.fetchFreshCartDataResponseList
              .ap2MobileNumber ? (
              transactionDetails.fetchFreshCartDataResponseList.ap2MobileNumber
            ) : (
              <Button
                sx={{
                  px: 0,
                  py: 0,
                  textTransform: "none",
                }}
                onClick={() => {
                  setApplicantDataMobile("Applicant_2");
                  handleMobileOtpClickOpen(
                    transactionDetails.fetchFreshCartDataResponseList
                      .ap2MobileNumber
                  );
                }}
                disabled={
                  otpVerifiedMobile &&
                  otpVerifiedMobile["Applicant_2"] &&
                  otpVerifiedMobileList["Applicant_2"] ===
                    transactionDetails.fetchFreshCartDataResponseList
                      .ap2MobileNumber
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Text type="dataSmallBold" color={colors.black}>
                    {
                      transactionDetails.fetchFreshCartDataResponseList
                        .ap2MobileNumber
                    }
                  </Text>
                  <Box sx={{ ml: "10px" }} />
                  {otpVerifiedMobile &&
                  otpVerifiedMobile["Applicant_2"] &&
                  otpVerifiedMobileList["Applicant_2"] ===
                    transactionDetails.fetchFreshCartDataResponseList
                      .ap2MobileNumber ? (
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Text type="dataSmallBold" color={colors.green}>
                        {"Verified"}
                      </Text>
                      <CheckCircleIcon
                        style={{
                          color: colors.green,
                          marginLeft: 3,
                          fontSize: 15,
                        }}
                      />
                    </Box>
                  ) : (
                    <Text type="dataSmallBold" color={colors.backGroundBlue}>
                      {"Verify"}
                    </Text>
                  )}
                </Box>
              </Button>
            ),
          "Mobile belongs to":
            transactionDetails.fetchFreshCartDataResponseList
              .ap2MobileBelongsTo,
          // transactionDetails.fetchFreshCartDataResponseList.ap1MobileNumber,
          Email:
            transactionDetails.fetchFreshCartDataResponseList.ap1Email ===
            transactionDetails.fetchFreshCartDataResponseList.ap2Email ? (
              transactionDetails.fetchFreshCartDataResponseList.ap2Email
            ) : (
              <Button
                sx={{
                  px: 0,
                  py: 0,
                  textTransform: "none",
                }}
                onClick={() => {
                  setApplicantDataEmail("Applicant_2");
                  handleEmailOtpClickOpen(
                    transactionDetails.fetchFreshCartDataResponseList.ap2Email
                  );
                }}
                disabled={
                  otpVerifiedEmail &&
                  otpVerifiedEmail["Applicant_2"] &&
                  otpVerifiedEmailList["Applicant_2"] ===
                    transactionDetails.fetchFreshCartDataResponseList.ap2Email
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Text type="dataSmallBold" color={colors.black}>
                    {transactionDetails.fetchFreshCartDataResponseList.ap2Email}
                  </Text>
                  <Box sx={{ ml: "10px" }} />
                  {otpVerifiedEmail &&
                  otpVerifiedEmail["Applicant_2"] &&
                  otpVerifiedEmailList["Applicant_2"] ===
                    transactionDetails.fetchFreshCartDataResponseList
                      .ap2Email ? (
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Text type="dataSmallBold" color={colors.green}>
                        {"Verified"}
                      </Text>
                      <CheckCircleIcon
                        style={{
                          color: colors.green,
                          marginLeft: 3,
                          fontSize: 15,
                        }}
                      />
                    </Box>
                  ) : (
                    <Text type="dataSmallBold" color={colors.backGroundBlue}>
                      {"Verify"}
                    </Text>
                  )}
                </Box>
              </Button>
            ),
          "Email belongs to":
            transactionDetails.fetchFreshCartDataResponseList.ap2EmailBelongsTo,
        },
      });
    }

    if (transactionDetails.fetchFreshCartDataResponseList.ap3Pan) {
      if (
        !(
          transactionDetails.fetchFreshCartDataResponseList.ap1MobileNumber ===
          transactionDetails.fetchFreshCartDataResponseList.ap3MobileNumber
        ) ||
        !(
          transactionDetails.fetchFreshCartDataResponseList.ap2MobileNumber ===
          transactionDetails.fetchFreshCartDataResponseList.ap3MobileNumber
        )
      ) {
        if (
          otpVerifiedMobile &&
          otpVerifiedMobile["Applicant_3"] &&
          otpVerifiedMobileList["Applicant_3"] ===
            transactionDetails.fetchFreshCartDataResponseList.ap3MobileNumber
        ) {
        } else {
          verifyPending = true;
        }
      }

      if (
        !(
          transactionDetails.fetchFreshCartDataResponseList.ap1Email ===
          transactionDetails.fetchFreshCartDataResponseList.ap3Email
        ) ||
        !(
          transactionDetails.fetchFreshCartDataResponseList.ap2Email ===
          transactionDetails.fetchFreshCartDataResponseList.ap3Email
        )
      ) {
        if (
          otpVerifiedEmail &&
          otpVerifiedEmail["Applicant_3"] &&
          otpVerifiedEmailList["Applicant_3"] ===
            transactionDetails.fetchFreshCartDataResponseList.ap3Email
        ) {
        } else {
          verifyPending = true;
        }
      }
      data.push({
        title: "Co-Applicant 2",
        canEdit: true,
        contentType: "Applicant_3",
        mobileNumber:
          transactionDetails.fetchFreshCartDataResponseList.ap3MobileNumber,
        emailId: transactionDetails.fetchFreshCartDataResponseList.ap3Email,
        contents: {
          "Investor Name":
            transactionDetails.fetchFreshCartDataResponseList.ap3LastName,
          PAN: transactionDetails.fetchFreshCartDataResponseList.ap3Pan,
          Mobile:
            transactionDetails.fetchFreshCartDataResponseList
              .ap1MobileNumber ===
              transactionDetails.fetchFreshCartDataResponseList
                .ap3MobileNumber ||
            transactionDetails.fetchFreshCartDataResponseList
              .ap2MobileNumber ===
              transactionDetails.fetchFreshCartDataResponseList
                .ap3MobileNumber ? (
              transactionDetails.fetchFreshCartDataResponseList.ap3MobileNumber
            ) : (
              <Button
                sx={{
                  px: 0,
                  py: 0,
                  textTransform: "none",
                }}
                onClick={() => {
                  setApplicantDataMobile("Applicant_3");
                  handleMobileOtpClickOpen(
                    transactionDetails.fetchFreshCartDataResponseList
                      .ap3MobileNumber
                  );
                }}
                disabled={
                  otpVerifiedMobile &&
                  otpVerifiedMobile["Applicant_3"] &&
                  otpVerifiedMobileList["Applicant_3"] ===
                    transactionDetails.fetchFreshCartDataResponseList
                      .ap3MobileNumber
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Text type="dataSmallBold" color={colors.black}>
                    {
                      transactionDetails.fetchFreshCartDataResponseList
                        .ap3MobileNumber
                    }
                  </Text>
                  <Box sx={{ ml: "10px" }} />
                  {otpVerifiedMobile &&
                  otpVerifiedMobile["Applicant_3"] &&
                  otpVerifiedMobileList["Applicant_3"] ===
                    transactionDetails.fetchFreshCartDataResponseList
                      .ap3MobileNumber ? (
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Text type="dataSmallBold" color={colors.green}>
                        {"Verified"}
                      </Text>
                      <CheckCircleIcon
                        style={{
                          color: colors.green,
                          marginLeft: 3,
                          fontSize: 15,
                        }}
                      />
                    </Box>
                  ) : (
                    <Text type="dataSmallBold" color={colors.backGroundBlue}>
                      {"Verify"}
                    </Text>
                  )}
                </Box>
              </Button>
            ),
          "Mobile belongs to":
            transactionDetails.fetchFreshCartDataResponseList
              .ap3MobileBelongsTo,
          // transactionDetails.fetchFreshCartDataResponseList.ap1MobileNumber,
          Email:
            transactionDetails.fetchFreshCartDataResponseList.ap1Email ===
              transactionDetails.fetchFreshCartDataResponseList.ap3Email ||
            transactionDetails.fetchFreshCartDataResponseList.ap2Email ===
              transactionDetails.fetchFreshCartDataResponseList.ap3Email ? (
              transactionDetails.fetchFreshCartDataResponseList.ap3Email
            ) : (
              <Button
                sx={{
                  px: 0,
                  py: 0,
                  textTransform: "none",
                }}
                onClick={() => {
                  setApplicantDataEmail("Applicant_3");
                  handleEmailOtpClickOpen(
                    transactionDetails.fetchFreshCartDataResponseList.ap3Email
                  );
                }}
                disabled={
                  otpVerifiedEmail &&
                  otpVerifiedEmail["Applicant_3"] &&
                  otpVerifiedEmailList["Applicant_3"] ===
                    transactionDetails.fetchFreshCartDataResponseList.ap3Email
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Text type="dataSmallBold" color={colors.black}>
                    {transactionDetails.fetchFreshCartDataResponseList.ap3Email}
                  </Text>
                  <Box sx={{ ml: "10px" }} />
                  {otpVerifiedEmail &&
                  otpVerifiedEmail["Applicant_3"] &&
                  otpVerifiedEmailList["Applicant_3"] ===
                    transactionDetails.fetchFreshCartDataResponseList
                      .ap3Email ? (
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Text type="dataSmallBold" color={colors.green}>
                        {"Verified"}
                      </Text>
                      <CheckCircleIcon
                        style={{
                          color: colors.green,
                          marginLeft: 3,
                          fontSize: 15,
                        }}
                      />
                    </Box>
                  ) : (
                    <Text type="dataSmallBold" color={colors.backGroundBlue}>
                      {"Verify"}
                    </Text>
                  )}
                </Box>
              </Button>
            ),
          "Email belongs to":
            transactionDetails.fetchFreshCartDataResponseList.ap3EmailBelongsTo,
        },
      });
    }
    let totalNominiAllocationCount = 0;
    let nominePresent = false;
    if (transactionDetails.fetchFreshCartDataResponseList.nominee1Name) {
      nominePresent = true;
      totalNominiAllocationCount += Number(
        transactionDetails.fetchFreshCartDataResponseList.nominee1Allocation
      );

      data.push({
        title: "Nominee 1",
        canEdit: true,
        canDelete: true,
        contentType: "Nominee1",
        age: transactionDetails?.fetchFreshCartDataResponseList?.nominee1DOB,
        // age: "2017-03-14T00:00:00.000Z",
        additionalInfo: {
          "Nominee Address":
            transactionDetails?.fetchFreshCartDataResponseList?.nominee1Address,
          "Gaurdian Name":
            transactionDetails?.fetchFreshCartDataResponseList
              ?.nominee1GuardianName,
          "Gaurdian Pan":
            transactionDetails?.fetchFreshCartDataResponseList
              ?.nominee1GuardianPan,
          "Nominee Pan":
            transactionDetails?.fetchFreshCartDataResponseList?.nominee1Pan,
          "Gaurdian Relation":
            transactionDetails?.fetchFreshCartDataResponseList
              ?.nominee1RelationshipWithGuardian,
          "Type of Gaurdian":
            transactionDetails?.fetchFreshCartDataResponseList
              ?.nominee1TypeoOfGuardian,
        },
        contents: {
          "Nominee Name":
            transactionDetails?.fetchFreshCartDataResponseList?.nominee1Name,
          "Nominee Relationship":
            transactionDetails?.fetchFreshCartDataResponseList
              ?.nominee1Relation,
          "Nominee Allocation":
            transactionDetails?.fetchFreshCartDataResponseList
              ?.nominee1Allocation + "%",
        },
      });
    }
    if (transactionDetails.fetchFreshCartDataResponseList.nominee2Name) {
      nominePresent = true;
      totalNominiAllocationCount += Number(
        transactionDetails.fetchFreshCartDataResponseList.nominee2Allocation
      );
      data.push({
        title: "Nominee 2",
        canEdit: true,
        canDelete: true,
        contentType: "Nominee2",
        age: transactionDetails.fetchFreshCartDataResponseList.nominee2DOB,
        additionalInfo: {
          "Nominee Address":
            transactionDetails?.fetchFreshCartDataResponseList?.nominee2Address,
          "Gaurdian Name":
            transactionDetails?.fetchFreshCartDataResponseList
              ?.nominee2GuardianName,
          "Gaurdian Pan":
            transactionDetails?.fetchFreshCartDataResponseList
              ?.nominee2GuardianPan,
          "Nominee Pan":
            transactionDetails?.fetchFreshCartDataResponseList?.nominee2Pan,
          "Gaurdian Relation":
            transactionDetails?.fetchFreshCartDataResponseList
              ?.nominee2RelationshipWithGuardian,
          "Type of Gaurdian":
            transactionDetails?.fetchFreshCartDataResponseList
              ?.nominee2TypeoOfGuardian,
        },
        contents: {
          "Nominee Name":
            transactionDetails?.fetchFreshCartDataResponseList?.nominee2Name,
          "Nominee Relationship":
            transactionDetails?.fetchFreshCartDataResponseList
              ?.nominee2Relation,
          "Nominee Allocation":
            transactionDetails?.fetchFreshCartDataResponseList
              ?.nominee2Allocation + "%",
        },
      });
    }
    if (transactionDetails.fetchFreshCartDataResponseList.nominee3Name) {
      nominePresent = true;
      totalNominiAllocationCount += Number(
        transactionDetails.fetchFreshCartDataResponseList.nominee3Allocation
      );
      data.push({
        title: "Nominee 3",
        canEdit: true,
        canDelete: true,
        contentType: "Nominee3",
        age: transactionDetails.fetchFreshCartDataResponseList.nominee3DOB,
        additionalInfo: {
          "Nominee Address":
            transactionDetails?.fetchFreshCartDataResponseList?.nominee3Address,
          "Gaurdian Name":
            transactionDetails?.fetchFreshCartDataResponseList
              ?.nominee3GuardianName,
          "Gaurdian Pan":
            transactionDetails?.fetchFreshCartDataResponseList
              ?.nominee3GuardianPan,
          "Nominee Pan":
            transactionDetails?.fetchFreshCartDataResponseList?.nominee3Pan,
          "Gaurdian Relation":
            transactionDetails?.fetchFreshCartDataResponseList
              ?.nominee3RelationshipWithGuardian,
          "Type of Gaurdian":
            transactionDetails?.fetchFreshCartDataResponseList
              ?.nominee3TypeoOfGuardian,
        },
        contents: {
          "Nominee Name":
            transactionDetails?.fetchFreshCartDataResponseList?.nominee3Name,
          "Nominee Relationship":
            transactionDetails?.fetchFreshCartDataResponseList
              ?.nominee3Relation,
          "Nominee Allocation":
            transactionDetails?.fetchFreshCartDataResponseList
              ?.nominee3Allocation + "%",
        },
      });
    }

    let bankDetailsVerifyPending = false;
    if (didValidateBankDetails) {
      bankDetailsVerifyPending = false;
    } else if (didUploadChequeLeaf) {
      bankDetailsVerifyPending = false;
    } else {
      bankDetailsVerifyPending = true;
    }
    data.push({
      title: "Payment",
      canEdit: false,
      contentType: "Payment",
      /** Check if the transaction is Minor
       * if Minor ==> show the bank details belongs to Gaurdian/Minor
       * else ==> dont show Bank details belongs to whome
       */
      contents:
        transactionDetails?.fetchFreshCartDataResponseList?.ap1Status === "M"
          ? {
              "Bank details belongs to": capitalize(
                transactionDetails?.fetchFreshCartDataResponseList
                  ?.bankAccountBelongsTo
              ),
              "Payment Mode":
                transactionDetails.fetchFreshCartDataResponseList.payMode,
              "Bank Name":
                transactionDetails.fetchFreshCartDataResponseList
                  .payoutBankName,
              "A/C Number": (
                <Button
                  sx={{
                    px: 0,
                    py: 0,
                    textTransform: "none",
                  }}
                  onClick={() => {
                    !showUploadCheckLeaf
                      ? setValidateBankAlert(true)
                      : setUploadCheckLeaf(true);
                  }}
                  disabled={didValidateBankDetails || didUploadChequeLeaf}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <Text type="dataSmallBold" color={colors.black}>
                      {
                        transactionDetails?.fetchFreshCartDataResponseList
                          ?.payoutBankAcNo
                      }
                    </Text>
                    <Box sx={{ ml: "10px" }} />
                    {didValidateBankDetails || didUploadChequeLeaf ? (
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Text type="dataSmallBold" color={colors.green}>
                          {"Verified"}
                        </Text>
                        <CheckCircleIcon
                          style={{
                            color: colors.green,
                            marginLeft: 3,
                            fontSize: 15,
                          }}
                        />
                      </Box>
                    ) : showUploadCheckLeaf ? (
                      <Text type="dataSmallBold" color={colors.backGroundBlue}>
                        {"Upload cheque leaf"}
                      </Text>
                    ) : (
                      <Text type="dataSmallBold" color={colors.backGroundBlue}>
                        {"Verify"}
                      </Text>
                    )}
                  </Box>
                </Button>
              ),
            }
          : {
              "Payment Mode":
                transactionDetails.fetchFreshCartDataResponseList.payMode,
              "Bank Name":
                transactionDetails.fetchFreshCartDataResponseList
                  .payoutBankName,
              "A/C Number": (
                <Button
                  sx={{
                    px: 0,
                    py: 0,
                    textTransform: "none",
                  }}
                  onClick={() => {
                    !showUploadCheckLeaf
                      ? setValidateBankAlert(true)
                      : setUploadCheckLeaf(true);
                  }}
                  disabled={didValidateBankDetails || didUploadChequeLeaf}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <Text type="dataSmallBold" color={colors.black}>
                      {
                        transactionDetails?.fetchFreshCartDataResponseList
                          ?.payoutBankAcNo
                      }
                    </Text>
                    <Box sx={{ ml: "10px" }} />
                    {didValidateBankDetails || didUploadChequeLeaf ? (
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Text type="dataSmallBold" color={colors.green}>
                          {"Verified"}
                        </Text>
                        <CheckCircleIcon
                          style={{
                            color: colors.green,
                            marginLeft: 3,
                            fontSize: 15,
                          }}
                        />
                      </Box>
                    ) : showUploadCheckLeaf ? (
                      <Text type="dataSmallBold" color={colors.backGroundBlue}>
                        {"Upload cheque leaf"}
                      </Text>
                    ) : (
                      <Text type="dataSmallBold" color={colors.backGroundBlue}>
                        {"Verify"}
                      </Text>
                    )}
                  </Box>
                </Button>
              ),
            },
    });
    // setCount(counter);

    setVerifyPending(verifyPending);
    setBankDetailsVerifyPending(bankDetailsVerifyPending);

    if (nominePresent) {
      setNomineePresent(nominePresent);
      setTotalNominiAllocation(totalNominiAllocationCount);
    }

    return data;
  }, [
    mobileOTPDialogOpen,
    emailOTPDialogOpen,
    didValidateBankDetails,
    validateBankDetailsError,
    didUploadChequeLeaf,
    transactionDetails,
    showUploadCheckLeaf,
  ]);

  if (!didVerifyOtp) {
    return <Navigate to="/" replace />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isLoadingValidateBank || isEditingTransaction ? (
        <ActivityOverlay />
      ) : null}
      <Box
        sx={{
          mt: isMobile() ? "30%" : "10%",
          mx: "5%",
          width: isMobile() ? "90%" : "50%",
        }}
      >
        {/* {
          <ProgressStepper
            current={stepper.currentIndex}
            options={stepper.steps}
          />
        } */}
        <Box sx={{ mt: "20px" }} />
        {transactionContents.map((item: any) => (
          <DisplayGridData
            key={JSON.stringify(item.title)}
            title={item.title}
            titleExtraMessage={item.titleExtraMessage || ""}
            data={item.contents}
            editable={
              transactionInfo["Transaction Status"] === "Pending"
                ? item.canEdit
                : false
            }
            deletable={
              transactionInfo["Transaction Status"] === "Pending"
                ? item.canDelete
                : false
            }
            onEdit={() =>
              onEditTransaction
                ? onEditTransaction(item, item.contentType)
                : null
            }
            contentType={item.type}
            onDelete={() =>
              onDeleteTransaction
                ? handleDeleteTransaction(item, item.contentType)
                : null
            }
          />
        ))}
        {transactionDetails?.fetchFreshCartDataResponseList?.ap1Status ===
          "M" &&
          transactionDetails?.fetchFreshCartDataResponseList
            ?.bankAccountBelongsTo === "MINOR" && (
            <>
              <Info
                message={strings?.minor_bank_details}
                iconPosition={"center"}
                icon={<InfoOutlinedIcon sx={{ color: colors.infoTextBlue }} />}
              />
              <Box sx={{ mb: "8px" }}></Box>
            </>
          )}
        <DisplayGridData
          title="Distributor Details"
          data={transactionDistributor}
          editable={false}
        />
        <DisplayGridData
          title="Transaction Details"
          data={transactionInfo}
          editable={false}
        />
        {transactionInfo["Transaction Status"] === "Pending" ||
        !transactionInfo["Transaction Status"] ? (
          <Box sx={{ pb: "20px" }}>
            {/* {nomineeDetails?.length < 1 ? ( */}
            {nomineeStatus !== '' && nomineeDetails?.length < 1 ? (
              <>
                <FormControlLabel
                  sx={{ mt: "20px", mb: "20px", alignItems: "flex-start" }}
                  control={
                    <Checkbox
                      sx={{
                        mt: -1,
                        // mt: "24px",
                        // mb: "40px",
                        // color: colors.backGroundBlue,
                        "&.Mui-checked": {
                          color: colors.backGroundBlue,
                        },
                      }}
                      checked={nomineeDeclarationChecked}
                      onChange={handleNomineeDeclarationChange}
                    />
                  }
                  label={NomineeDeclaration}
                  labelPlacement="end"
                />
              </>
            ) : null}
            <FormControlLabel
              sx={{ mt: "20px", mb: "20px", alignItems: "flex-start" }}
              control={
                <Checkbox
                  sx={{
                    mt: -1,
                    // mt: "24px",
                    // mb: "40px",
                    // color: colors.backGroundBlue,
                    "&.Mui-checked": {
                      color: colors.backGroundBlue,
                    },
                  }}
                  checked={checked}
                  onChange={handleChange}
                />
              }
              label={Disclaimer}
              labelPlacement="end"
            />
            {showError ? (
              <Text
                type="mediumBold"
                color="red"
                sx={{ marginTop: "10px", marginBottom: "15px" }}
              >
                {error}
              </Text>
            ) : null}
            {validationError ? (
              <Text
                type="mediumBold"
                color="red"
                sx={{ marginTop: "10px", marginBottom: "15px" }}
              >
                {validationError}
              </Text>
            ) : null}

            <Grid container>
              <Grid item xs={5.5}>
                <ButtonIcon
                  sx={{
                    backgroundColor: colors.infoBackgroundRed,
                    color: colors.infoTextRed,
                    width: isMobile() ? "100%" : "275px",
                    py: "10px",
                  }}
                  title={"Reject"}
                  icon={false}
                  onClick={handleShowRejectDialog}
                />
              </Grid>

              <Grid item xs={1}></Grid>

              <Grid item xs={5.5}>
                <ButtonIcon
                  disabled={
                    !(checked && !verifyPending && !bankDetailsVerifyPending)
                  }
                  title={"Approve"}
                  icon={true}
                  onClick={handleApprove}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box sx={{ width: "100%", height: "30px" }} />
        )}
      </Box>
      <TwoFactorAuthentication
        otpDialogOpen={otpDialogOpen}
        handleOtpDialogClose={handleOtpDialogClose}
        isVerifyingApplicantOtp={isVerifyingApplicantOtp}
        otp={otp}
        handleOTPChange={handleOTPChange}
        applicantOtpError={applicantOtpError}
        maskedEmail={userMakedInfo?.email}
        maskedMobile={userMakedInfo?.mobile}
        handleResendPressed={handleResendPressed}
      />
      {showRejectDialog ? (
        <RejectConfirmationDialog
          onClose={closeRejectDialog}
          onReject={handleReject}
        />
      ) : null}
      <Dialog open={mobileOTPDialogOpen} onClose={handleMobileOtpClose}>
        <DialogTitle>Mobile Number Verification</DialogTitle>
        <DialogContent>
          {isVerifyingApplicantOtp ? (
            <Box
              sx={{
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
              }}
            >
              <ActivityOverlay />
            </Box>
          ) : null}
          <Text
            type="dataSmallBold"
            color={colors.backGroundBlue}
            sx={{
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "20px",
              fontFamily: "Graphik",
              letterSpacing: "-0.32px",
            }}
          >
            {`OTP is ${resendOtp} to your Mobile Number XXXXXX${mobileNumber?.slice(
              -4
            )}`}
          </Text>
          <Box sx={{ marginBottom: "20px" }} />
          {/* <DialogContentText>Enter OTP</DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="otp"
            label="Enter Mobile OTP"
            type="text"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setVerificationMobileOTP(event?.target?.value);
            }}
            inputProps={{ maxLength: 5 }}
          />
          {/* <MuiOtpInput
            value={mobileOtp}
            onChange={handleMobileOtpInput}
            textTransform={undefined}
          /> */}
          {otpError || applicantOtpError ? (
            <Text type="dataSmallBold" color={colors.radicalRed}>
              {otpError || applicantOtpError}
            </Text>
          ) : null}
          <Box sx={{ marginBottom: "50px" }} />
          <Text
            type="dataSmallBold"
            color={"#00000080"}
            sx={{
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "20px",
              fontFamily: "Graphik",
              letterSpacing: "-0.32px",
            }}
          >
            {`OTP is valid for 10 minutes. In case you have not received the OTP, Please click "Resend OTP".`}
          </Text>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMobileOtpClose}>Cancel</Button>
          <Button
            onClick={handleResendOtpMobile}
            disabled={isVerifyingApplicantOtp}
          >
            Resend OTP
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={emailOTPDialogOpen} onClose={handleEmailOtpClose}>
        <DialogTitle>Email ID Verification</DialogTitle>
        <DialogContent>
          {isVerifyingApplicantOtp ? (
            <Box
              sx={{
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
              }}
            >
              <ActivityOverlay />
            </Box>
          ) : null}
          <Text
            type="dataSmallBold"
            color={colors.backGroundBlue}
            sx={{
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "20px",
              fontFamily: "Graphik",
              letterSpacing: "-0.32px",
            }}
          >
            {`OTP is ${resendOtp} to your E-Mail ID XXXXXX${email
              ?.split("@")[0]
              .slice(-4)}@${email?.split("@")[1]}.`}
          </Text>
          <Box sx={{ marginBottom: "20px" }} />
          {/* <DialogContentText>Enter OTP</DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="otp"
            label="Enter Email OTP"
            type=""
            fullWidth
            variant="standard"
            onChange={(event) => {
              setVerificationEmailOTP(event?.target?.value);
            }}
            inputProps={{ maxLength: 5 }}
          />
          {/* <MuiOtpInput
            value={mobileOtp}
            onChange={handleMobileOtpInput}
            textTransform={undefined}
          /> */}
          {otpError || applicantOtpError ? (
            <Text type="dataSmallBold" color={colors.radicalRed}>
              {otpError || applicantOtpError}
            </Text>
          ) : null}
          <Box sx={{ marginBottom: "50px" }} />
          <Text
            type="dataSmallBold"
            color={"#00000080"}
            sx={{
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "20px",
              fontFamily: "Graphik",
              letterSpacing: "-0.32px",
            }}
          >
            {`OTP is valid for 10 minutes. In case you have not received the OTP, Please click "Resend OTP".`}
          </Text>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEmailOtpClose}>Cancel</Button>
          <Button
            onClick={handleResendOtpEmail}
            disabled={isVerifyingApplicantOtp}
          >
            Resend OTP
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={alertDialogOpen}
        onClose={() => {
          setChecked(false);
          setAlertDialogOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Authentication</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Mobile, Email Id verification and Pennydrop authentication of bank
            is must to proceed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setChecked(false);
              setAlertDialogOpen(false);
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(validateBankMessage)}
        onClose={() => {
          setValidateBankMessage("");
          if (validateBankDetailsError) {
            dispatch(setShowUploadCheckLeaf());
          } else {
            dispatch(setValidateBankDetailsMsgShown());
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">Authentication</DialogTitle> */}
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              color: validateBankDetailsError
                ? colors.radicalRed
                : colors.black,
            }}
          >
            {validateBankMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setValidateBankMessage("");
              if (validateBankDetailsError) {
                dispatch(setShowUploadCheckLeaf());
              } else {
                dispatch(setValidateBankDetailsMsgShown());
              }
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={validateBankAlert}
        onClose={() => {
          setValidateBankChecked(false);
          setValidateBankAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">Authentication</DialogTitle> */}
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            alignContent: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Checkbox
            sx={{
              mt: -0.8,

              "&.Mui-checked": {
                color: colors.backGroundBlue,
              },
            }}
            checked={validateBankChecked}
            onChange={handleValidateBankCheckChange}
          />
          <DialogContentText id="alert-dialog-description">
            I/We hereby authorize Invesco Mutual Fund to credit my bank account
            with ₹ 1/-
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setValidateBankChecked(false);
              setValidateBankAlert(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleOnValidateBankDetails}
            disabled={!validateBankChecked}
          >
            Verify
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={uploadCheckLeaf}
        onClose={() => {
          setUploadCheckLeaf(false);
          dispatch(resetUploadChequeLeaf());
          setShowUploadChequeError("");
          setShowChequeFileError("");
          setUploadChequeImage("");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">Upload Cheque Leaf</DialogTitle> */}
        <DialogContent sx={{ alignItems: "center" }}>
          <ImageDropzone
            onDropped={handleThumbnailImageDrop}
            ref={imageDropzoneThumbnailImageRef}
            heading="Upload Cheque Leaf Image"
            icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
            type="Image"
            error={showUploadChequeError || showChequeFileError}
            required={true}
            showText={false}
          />
          {isLoadingUploadChequeLeaf ? <ActivityOverlay /> : null}
          <Box
            sx={{
              mt: "20px",
              alignItems: "center",
            }}
          >
            <Text
              type="medium"
              sx={{
                fontSize: "16px",
                letterSpacing: "0em",
                lineHeight: "25px",
                fontFamily: "Graphik",
                mt: "5px",
              }}
            >
              <b> Note:</b> Please upload .tiff /.tif /.jpeg /.jpg /.png. Image
              size should be less than 2MB.
            </Text>
          </Box>
          {uploadChequeLeafError ? (
            <Text
              type="dataSmallBold"
              color={colors.radicalRed}
              sx={{
                mt: "20px",
                fontWeight: 600,
                fontSize: "13px",
                lineHeight: "16px",
                fontFamily: "Graphik",
                letterSpacing: "-0.32px",
              }}
            >
              {uploadChequeLeafError}
            </Text>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setUploadCheckLeaf(false);
              dispatch(resetUploadChequeLeaf());
              setShowUploadChequeError("");
              setShowChequeFileError("");
              setUploadChequeImage("");
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleCheckLeafUpload}>Upload</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showNomineeAlert}
        onClose={() => {
          setShowNomineeAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">Authentication</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            To ADD nominee, please click on Add Nominee.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowNomineeAlert(false);
            }}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              navigate(RouteConfig.newTransactionNomineeAdd);
            }}
            autoFocus
          >
            Add Nominee
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showNomineeDeleteAlert}
        onClose={() => {
          setShowNomineeDeleteAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Nominee</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this nominee details?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowNomineeDeleteAlert(false);
            }}
            autoFocus
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setShowNomineeDeleteAlert(false);
              onDeleteTransaction &&
                onDeleteTransaction(nomineeDeleteData, nomineeDeleteType);
            }}
            autoFocus
          >
            Delete Nominee
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default NewSipLumpsumTransactionDetails;

const handleTermsAndConditions = () => {
  // rel="noopener noreferrer" target="_blank" href="google.com"

  window.open("www.google.com", "_blank");
};
const Disclaimer = (
  <Text type="small">
    Yes I have read, understood and shall abide by the contents of the Scheme
    information Document(s) of the respective schemes & Statement of Additional
    Information of Invesco Mutual Fund and the{" "}
    <Link
      sx={{ fontWeight: 800 }}
      onClick={() => {
        window.open(
          "https://invest.invescomutualfund.com/investonline/General/TermsConditions.html",
          "_blank"
        );
      }}
    >
      terms & conditions
    </Link>{" "}
    of the online portal usage. I confirm that the payment made for subscription
    is from the bank account in which first named unit holder is the first
    holder or one of the joint holders (applicable in case of joint bank
    account) and payment is not a third party payment.
  </Text>
);
const NomineeDeclaration = (
  <>
    <Text type="dataMediumBold">I Do Not Intend To Nominate </Text>
    <Box style={{ marginTop: "2px" }} />
    <Text type="small">
      I / We hereby confirm that I / We do not wish to appoint any nominee(s)
      for my mutual fund units held in my / our mutual fund folio and understand
      the issues involved in non-appointment of nominee(s) and further are aware
      that in case of death of all the account holder(s), my / our legal heirs
      would need to submit all the requisite documents issued by Court or other
      such competent authority, based on the value of assets held in the mutual
      fund folio.
    </Text>
  </>
);
