import Otp from "components/Otp/Otp";
import { useNavigate } from "react-router-dom";
import RouteConfig from "routes/RouteConfig";

const STPOtp = () => {
  const navigate = useNavigate();
  const handleOnVerifyOtp = () => {
    navigate(RouteConfig.stpTransactionDetails);
  };
  return <Otp onVerifyOtp={handleOnVerifyOtp} />;
};
export default STPOtp;
