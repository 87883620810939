import { Box, Typography } from "@mui/material";

const Landing = () => {
  return (
    <Box mt={"64px"}>
      <Typography>Last Publish: 16th May 2022</Typography>
    </Box>
  );
};
export default Landing;
