import { useEffect, useMemo, useState } from "react";
import TransactionDetails from "components/TransactionDetails/TransactionDetails";
import {
  confirmTransaction,
  loadFetchExitLoadData,
  loadFoliowiseSchemeData,
  rejectTransaction,
  resetConfirmTransactionStates,
  setEditTransactionInfo,
} from "store/transaction/slice";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import ActivityOverlay from "components/ActivityOverlay";
import RouteConfig from "routes/RouteConfig";

import { TransactionSteps } from "utils/constants";
import moment from "moment";
import { getDistributorDetails } from "utils/getDistributorDetails";
import { setSuccessPageScheme } from "store/appstate/slice";
import _ from "lodash";

const RedemptionTransactionDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    transactionDetails,
    transactionDistributor,
    isConfirmingTransaction,
    didConfirmTransaction,
    confirmTransactionError,
    transactionSchemeDetails,
    isLoadingTransactionSchemesDetails,
    isRejectingTransaction,
    didRejectTransaction,
    rejectTransactionError,
    didLoadFoliowiseScheme,
    isLoadingFoliowiseSchemeData,
    didLoadFetchExitLoadData,
    isLoadingFetchExitLoadData,
    fetchExitLoadData,
  } = useAppSelector((state) => state.transaction);

  useEffect(() => {
    if (didConfirmTransaction) {
      dispatch(resetConfirmTransactionStates());
      dispatch(setSuccessPageScheme(transactionSchemeDetails[0].fundDesc));
      navigate(RouteConfig.redemptionSuccess);
    }
  }, [didConfirmTransaction]);

  useEffect(() => {
    if (didRejectTransaction) {
      // dispatch(resetOtpStates());
      // dispatch(resetConfirmTransactionStates());
      navigate(RouteConfig.redemptionTransactionReject);
    }
  }, [didRejectTransaction]);

  useEffect(() => {
    if (didLoadFoliowiseScheme) {
      navigate(RouteConfig.redemptionEdit);
    }
  }, [didLoadFoliowiseScheme]);
  useEffect(() => {
    if (!_.isEmpty(transactionDetails)) {
      dispatch(
        loadFetchExitLoadData({
          scheme: transactionDetails.fetchTransactionDetails.schemeCode,
          plan: transactionDetails.fetchTransactionDetails.planCode,
          units: transactionDetails.fetchTransactionDetails.units,
          amount: transactionDetails.fetchTransactionDetails.amount,
          pan: transactionDetails.fetchTransactionDetails.investorPan,
          folio: transactionDetails.fetchTransactionDetails.folioNumber,
          userId: transactionDetails.fetchTransactionDetails.brokerCode,
          fund: "120",
          ttrType: "FUL",
          trdt: moment(
            transactionDetails.fetchTransactionDetails.transactionDate
          ).format("YYYY-MM-DD"),
        })
      );
    }
  }, [transactionDetails]);

  const transactions = useMemo(() => {
    if (
      !transactionDetails ||
      !transactionDetails.fetchTransactionDetails ||
      isLoadingTransactionSchemesDetails
    ) {
      return [];
    }
    const data = [];

    const unitOrAmount =
      transactionDetails.fetchTransactionDetails.aorUFlag !== "A"
        ? {
            "Redemption Unit": transactionDetails.fetchTransactionDetails.units,
          }
        : {
            "Redemption Amount": parseInt(
              transactionDetails.fetchTransactionDetails.amount
            ).toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
              // minimumFractionDigits: 0,
              // maximumFractionDigits: 0,
            }),
          };

    data.push({
      title: "Redemption Details",
      canEdit: true,
      contents: {
        Scheme: transactionSchemeDetails.length
          ? transactionSchemeDetails[0].fundDesc
          : "",
        Option: transactionSchemeDetails.length
          ? `${transactionSchemeDetails[0]?.optDesc} - ${transactionSchemeDetails[0]?.planDesc}`
          : "",
        ...unitOrAmount,
        "Applicable Exit Load":
          "₹" +
          (fetchExitLoadData?.approxLoadAmount
            ? fetchExitLoadData?.approxLoadAmount
            : "0"),
        // getFetchExitLoadData(),
        "Transaction No. ": transactionDetails.fetchTransactionDetails.batchNo,
        "Transaction Date": moment(
          transactionDetails.fetchTransactionDetails.transactionDate
        ).format("DD-MMM-YYYY"),
        "Bank Name": transactionDetails.fetchTransactionDetails.bankName,
        "A/c Number": transactionDetails.fetchTransactionDetails.bankAccNo,
      },
    });

    return data;
  }, [
    transactionDetails,
    transactionSchemeDetails,
    isLoadingTransactionSchemesDetails,
    didLoadFetchExitLoadData,
  ]);
  const handleTransactionApprove = () => {
    dispatch(
      confirmTransaction({
        investorPan: transactionDetails.fetchTransactionDetails.investorPan,
        folioNo: transactionDetails.fetchTransactionDetails.folioNumber,
        batchNo: transactionDetails.fetchTransactionDetails.batchNo,
        ihNo: transactionDetails.fetchTransactionDetails.ihno,
        txnType: "REDEEM",
      })
    );
  };

  const handleTransactionReject = () => {
    const distributor = getDistributorDetails(transactionDistributor);
    dispatch(
      rejectTransaction({
        batchNo: transactionDetails.fetchTransactionDetails.batchNo,
        pan: transactionDetails.fetchTransactionDetails.investorPan,
        ...distributor,
        rejectReason: "Not Intrested",
      })
    );
  };

  const handleEditTransaction = (data: any, _type: string | undefined) => {
    dispatch(setEditTransactionInfo(data));
    dispatch(
      loadFoliowiseSchemeData({
        arnCode: transactionDetails.fetchTransactionDetails.brokerCode,
        userId: transactionDetails.fetchTransactionDetails.brokerCode,
        pan: transactionDetails.fetchTransactionDetails.investorPan,
        investorRequestFlag: true,
      })
    );
  };

  return (
    <>
      {!isLoadingTransactionSchemesDetails ? (
        <TransactionDetails
          stepper={{ currentIndex: 0, steps: TransactionSteps }}
          error={confirmTransactionError || rejectTransactionError}
          transactions={transactions}
          onApproveTranaction={handleTransactionApprove}
          onEditTransaction={handleEditTransaction}
          onRejectTranaction={handleTransactionReject}
        />
      ) : null}

      {(isConfirmingTransaction ||
        isLoadingTransactionSchemesDetails ||
        isRejectingTransaction) && <ActivityOverlay />}
      {isLoadingFoliowiseSchemeData && <ActivityOverlay />}
      {isLoadingFetchExitLoadData && <ActivityOverlay />}
    </>
  );
};
export default RedemptionTransactionDetails;
