import { Box } from "@mui/material";
import Text from "components/Text";
import { FC, useEffect } from "react";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import colors from "res/colors";
import isMobile from "utils/isMobile";
import { useAppDispatch } from "hooks";
import { setTransactionStage } from "store/appstate/slice";
import ButtonIcon from "components/ButtonIcon";
import { INVESCO_WEB_URL } from "utils/constants";

const TransactionFail: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTransactionStage());
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          mt: isMobile() ? "30%" : "10%",
          // textAlign: "left",
          maxWidth: "640px",
          px: "10%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            height: "108px",
            width: "108px",
            borderRadius: "50%",
            backgroundColor: colors.infoTextRed,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReportProblemOutlinedIcon
            sx={{ height: "60px", width: "60px", color: colors.white }}
          />
        </Box>
        <Box sx={{ mt: "24px", mb: "12px" }}>
          <Text type="successLargeBold">{"Transaction Failed"}</Text>
        </Box>
        <Box>
          <Text type="smallTransparentCenter">
            {"Failed to complete your transaction. Please try again."}
          </Text>
        </Box>

        <Box
          sx={{ mt: "53px", mx: "5%", backgroundColor: "red", width: "200px" }}
        >
          <ButtonIcon
            sx={{ width: "100%" }}
            title={"Close"}
            icon={false}
            onClick={() => window.location.replace(INVESCO_WEB_URL)}
          ></ButtonIcon>
        </Box>
      </Box>
    </Box>
  );
};
export default TransactionFail;
