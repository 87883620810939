export const convertToCurrencyFormat = (amount: string | number) => {
  const number = parseFloat(amount?.toString());

  if (isNaN(number)) {
    return "";
  }
  const currency = new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 0,
  }).format(number);

  return currency;
};
