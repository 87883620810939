import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import colors from "res/colors";

const ActivityOverlay: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CircularProgress className={classes.progress} />
    </div>
  );
};
const useStyles = makeStyles({
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: colors.activityOverlayBackground,
  },
  progress: {
    position: "fixed",
    top: "50%",
    left: "50%",
    color: colors.primary,
  },
});
export default ActivityOverlay;
