import { ThemeProvider } from "@mui/material";
import theme from "theme";
import AppRoute from "routes/AppRoute";
import { Provider } from "react-redux";
import { store } from "store";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setBaseURL } from "networking/NetworkManager";
setBaseURL();

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppRoute />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          closeOnClick
          transition={Slide}
        />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
