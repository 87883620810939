import { Box, Divider, Grid, IconButton, InputAdornment } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Text from "components/Text";
import strings from "res/strings";
import colors from "res/colors";
import Input from "components/Input";
import { ChangeEvent, useState, useEffect } from "react";
import ButtonIcon from "components/ButtonIcon";
import ToggleButtonOptions from "components/ToggleButton/ToggleButtonOptions";
import isMobile from "utils/isMobile";
import {
  editTransaction,
  setTransactionDetails,
} from "store/transaction/slice";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { getEditTransacation } from "utils/constants";
import ActivityOverlay from "components/ActivityOverlay/ActivityOverlay";
import { ToWords } from "to-words";
import { verifyIsNumber } from "utils/verifyIsNumber";

const amountData = [{ label: "1000" }, { label: "2000" }, { label: "3000" }];
const LumpSumEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ihNo } = useAppSelector((state) => state.appState);
  const {
    transactionSchemeDetails,
    editTransactionInfo,
    transactionDetails,
    isEditingTransaction,
    didEditTransaction,
    editTransactionError,
  } = useAppSelector((state) => state.transaction);
  const { schemesCategeory, allSchemes, schemeOptions } = useAppSelector(
    (state) => state.globalData
  );
  const [investmentOption, setInvestmentOption] = useState("");
  const [investmentAmount, setInvestmentAmount] = useState("");
  const [selectedSchemeOptions, setSelectedSchemeOption] = useState([]);
  const [amountErrorFlag, setAmountErrorFlag] = useState(false);

  const minLumpsumAmount =
    transactionSchemeDetails[editTransactionInfo.index].minAddPurchase;
  useEffect(() => {
    if (Object.keys(editTransactionInfo).length) {
      setInvestmentAmount(
        transactionDetails.cartInitiateResponse.schemeList[
          editTransactionInfo.index
        ].amount
      );
      setInvestmentOption(editTransactionInfo.contents.Option);
    }
  }, []);

  useEffect(() => {
    if (didEditTransaction) {
    }
  }, [didEditTransaction]);

  useEffect(() => {
    const transactionCategory =
      transactionSchemeDetails[0]?.category === "LIQUID"
        ? "FIXED INCOME"
        : "FIXED INCOME";

    setSelectedSchemeOption(
      schemeOptions[transactionCategory][
        transactionSchemeDetails[editTransactionInfo.index].schemeDesc
      ]
    );
  }, []);
  if (!Object.keys(editTransactionInfo).length) {
    navigate("/");
    return <div />;
  }
  function getAmountErrorMessage() {
    if (amountErrorFlag) {
      return `Minimum investment amount is ${minLumpsumAmount}`;
    }
  }
  const toWords = new ToWords({
    localeCode: "en-IN",
  });

  const handleInvestmentAmount = (amount: string) => {
    parseInt(minLumpsumAmount) > parseInt(amount)
      ? setAmountErrorFlag(true)
      : setAmountErrorFlag(false);

    setInvestmentAmount(amount);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && !verifyIsNumber(event.target.value)) {
      return;
    }

    parseInt(minLumpsumAmount) > parseInt(event.target.value) ||
    !event.target.value
      ? setAmountErrorFlag(true)
      : setAmountErrorFlag(false);
    setInvestmentAmount(event.target.value);
  };
  const handleInvestmentOption = (InvestmentOptionSelected: string) => {
    setInvestmentOption(InvestmentOptionSelected);
  };

  const createNewTransactionDetails = () => {
    const data = { ...transactionDetails };
    data.cartInitiateResponse = { ...transactionDetails.cartInitiateResponse };

    const schemes = transactionDetails.cartInitiateResponse.schemeList.map(
      (item: any, index: number) => {
        return {
          ...item,
        };
      }
    );
    schemes[editTransactionInfo.index].amount = investmentAmount;
    // schemes[editTransactionInfo.index].opt = selectedOptionCode;

    data.cartInitiateResponse.schemeList = schemes;
    dispatch(setTransactionDetails(data));

    // const tempSchemeDetails = [...transactionSchemeDetails];
    // tempSchemeDetails[editTransactionInfo.index].optDesc = selectedOptDesc;
    // dispatch(setTransactionSchemeDetails(tempSchemeDetails));
  };

  const handleAccept = () => {
    if (amountErrorFlag) {
      return;
    }

    // const data = { ...transactionDetails.cartInitiateResponse };

    const data = getEditTransacation(
      ihNo,
      "lumpsum",
      transactionDetails,
      transactionDetails.cartInitiateResponse.batchNo
    );

    const editTransactionData = {
      transactionType: "Lumpsum",
      batchNo: transactionDetails.cartInitiateResponse.batchNo,
      transactionDateTime:
        transactionDetails.cartInitiateResponse.transactionDateTime,
      editSchemeList: [
        {
          ihNo: transactionDetails.cartInitiateResponse.schemeList[
            editTransactionInfo.index
          ].ihNo,
          amount: investmentAmount,
        },
      ],
      activity: "",
      isFreshPurhase: "N",
    };

    const schemes = transactionDetails.cartInitiateResponse.schemeList.map(
      (item: { [key: string]: any }) => {
        // return { ...item };
        return {
          ihNo: item.ihNo,
          optionCode: item.opt,
          amount: item.amount,
          sipStartDate: item.sipStartDate,
          sipEndDate: item.sipEndDate,
          sipFrequency: item.sipFrequency,
          noOfInstallments: item.noOfInstallments,
          topUpAmount: item.topUpAmount,
          topUpFrequency: item.topUpFrequency,
          topUpMaxYears: item.topUpMaxYears,
          topUpMaxAmount: item.topUpMaxAmount,
        };
      }
    );
    schemes[editTransactionInfo.index].amount = investmentAmount;
    data.editSchemeList = schemes;
    data.amount = investmentAmount;
    data.optionCode =
      transactionDetails.cartInitiateResponse.schemeList[
        editTransactionInfo.index
      ].opt;
    dispatch(editTransaction(editTransactionData));
    createNewTransactionDetails();
    navigate(-1);
  };

  const handleScreenCancel = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          mt: isMobile() ? "30%" : "10%",
          mx: "5%",
          pb: "20px",
          width: isMobile() ? "90%" : "50%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton onClick={handleScreenCancel}>
            <CancelOutlinedIcon sx={{ color: colors.gray }} />
          </IconButton>
        </Box>
        <Box sx={{ px: "24px" }}>
          <Text type="subHeadingBold">{strings.editInvestmentDetails}</Text>
          <Box sx={{ my: "20px" }}>
            <Divider sx={{ backgroundColor: colors.backGroundBlue }} />
          </Box>

          <Grid container direction={"column"} rowSpacing={"24px"}>
            <Grid item>
              <Text type="dataLightMessage">{strings.FolioNo}</Text>
              <Text type="dataMediumBold">
                {transactionDetails.cartInitiateResponse.folioNumber}
              </Text>
            </Grid>
            <Grid item>
              <Text type="dataLightMessage">{strings.Scheme}</Text>
              <Text type="dataMediumBold">
                {editTransactionInfo.contents["Scheme Name"]}
              </Text>
            </Grid>
          </Grid>

          <Box sx={{ my: "20px" }}>
            <Divider />
          </Box>
          {/* <ToggleButtonOptions
            options={investmentType}
            selectMultiple={false}
            setBorder={false}
            title={"Investment Option"}
            value={investmentOption}
            onClick={handleInvestmentOption}
          /> */}
          {/* <Select
            placeHolder=""
            label="Investment Option"
            options={selectedSchemeOptions}
            onOptionSelect={(selected) => {
              const selectedObject = JSON.parse(selected.value);
              const optionDesc = selectedObject.optDesc;
              const optionCode = selectedObject.optionCode;
              setInvestmentOption(selected.value);
            
              setSelectedOptionCode(optionCode);
              setSelectedOptDesc(optionDesc);
            }}
            value={investmentOption}
          /> */}
          {/* <ToggleOption
            options={investmentType}
            onClick={handleInvestmentOption}
          /> */}
          {/* <Box sx={{ mt: "24px" }}>
            <Text type="lightMessage">{strings.IDCW}</Text>
          </Box>
          <Box sx={{ mb: "24px", mt: "12px" }}>
            <Divider />
          </Box> */}
          <Text type="smallBold">{strings.investmentAmount}</Text>
          <Box sx={{ py: "12px" }}>
            <Input
              value={investmentAmount}
              onChange={handleChange}
              error={getAmountErrorMessage()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {`Min. amt ₹${minLumpsumAmount}`}
                  </InputAdornment>
                ),
              }}
            />
            <Text type="medium">
              {parseInt(investmentAmount) > 0
                ? toWords.convert(parseFloat(investmentAmount))
                : null}
            </Text>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ mb: "20px" }}>
              <ToggleButtonOptions
                title="Investment Option"
                options={amountData}
                selectMultiple={false}
                setBorder={false}
                value={investmentAmount}
                onClick={handleInvestmentAmount}
              />
            </Box>
          </Box>

          {editTransactionError ? (
            <Text
              type="mediumBold"
              color="red"
              sx={{ marginTop: "10px", marginBottom: "15px" }}
            >
              {editTransactionError}
            </Text>
          ) : null}
          <ButtonIcon
            disabled={amountErrorFlag}
            title={"Accept"}
            icon={true}
            onClick={handleAccept}
          />
        </Box>
      </Box>
      {isEditingTransaction && <ActivityOverlay />}
    </Box>
  );
};

export default LumpSumEdit;
