export default Object.freeze({
  lumpsum: "lumpsum/:ihNo",
  lumpsumOtp: "/lumpsum/otp",
  lumpsumTransactionDetails: "/lumpsum/transaction-details",
  lumpsumTransactionEdit: "/lumpsum/transaction-details/edit",
  lumpsumTransactionReject: "/lumpsum/transaction-details/reject",

  otmPaymentSuccess: "/otm/success",
  otmTransactionSuccess: "/otm/transactionSuccess",
  otmPaymentFail: "/otm/fail",

  newLumpsum: "new-lumpsum/:ihNo",
  newLumpsumOtp: "/new-lumpsum/otp",
  newLumpsumTransactionDetails: "/new-lumpsum/transaction-details",
  newLumpsumTransactionEdit: "/new-lumpsum/transaction-details/edit",
  newLumpsumTransactionReject: "/new-lumpsum/transaction-details/reject",

  newTransactionEdit: "/new-transaction/edit",
  newTransactionNomineeEdit: "/new-transaction-nominee/edit",
  newTransactionNomineeAdd: "/new-transaction-nominee/AddNominee",
  sip: "sip/:ihNo",
  sipOtp: "/sip/otp",
  sipTransactionDetails: "/sip/transaction-details",
  sipTransactionEdit: "/sip/transaction-details/edit",
  sipTransactionReject: "/sip/transaction-details/reject",

  newSip: "new-sip/:ihNo",
  newSipOtp: "/new-sip/otp",
  newSipTransactionDetails: "/new-sip/transaction-details",
  newSipEdit: "/new-sip/transaction-details/edit",
  newSipReject: "/new-sip/transaction-details/reject",

  cancelSipStpSwp: "cancel-sipstpswp/:ihNo",
  cancelSipStpSwpOtp: "/cancel-sipstpswp/otp",
  cancelSipStpSwpTransactionDetails: "/cancel-sipstpswp/transaction-details",
  cancelSipStpSwpTransactionEdit: "/cancel-sipstpswp/transaction-details/edit",
  cancelSipStpSwpSuccess: "/cancel-sipstpswp/sucess",
  cancelStpSuccess: "/cancel-stp/sucess",
  cancelSwpSuccess: "/cancel-swp/sucess",
  cancelSipStpSwpTransactionReject:
    "/cancel-sipstpswp/transaction-details/reject",

  pauseSipStpSwp: "pause-sip/:ihNo",
  pauseSipStpSwpOtp: "/pause-sip/otp",
  pauseSipStpSwpTransactionDetails: "/pause-sip/transaction-details",
  pauseSipStpSwpTransactionEdit: "/pause-sip/transaction-details/edit",
  pauseSipStpSwpSuccess: "/pause-sip/sucess",
  pauseSipTransactionReject: "/pause-sip/transaction-details/reject",

  otm: "otm/:ihNo",
  otmOtp: "/otm/otp",
  otmTransactionDetails: "/otm/transaction-details",
  otmEdit: "/otm/transaction-details/edit",
  otmTransactionReject: "/otm/transaction-details/reject",

  stp: "stp/:ihNo",
  stpOtp: "/stp/otp",
  stpTransactionDetails: "/stp/transaction-details",
  stpSuccess: "/stp/sucess",
  stpTransactionReject: "/stp/transaction-details/reject",
  stpTargetSchemeEdit: "/stp/edit/target-scheme",

  swp: "swp/:ihNo",
  swpOtp: "/swp/otp",
  swpTransactionDetails: "/swp/transaction-details",
  swpSuccess: "/swp/sucess",
  swpTransactionReject: "/swp/transaction-details/reject",
  swpedit: "/swp/edit",

  switch: "switch/:ihNo",
  switchOtp: "/switch/otp",
  switchTransactionDetails: "/switch/transaction-details",
  switchSuccess: "/switch/sucess",
  switchTransactionReject: "/switch/transaction-details/reject",
  switchEdit: "/switch/edit",

  redemption: "redemption/:ihNo",
  redemptionOtp: "/redemption/otp",
  redemptionTransactionDetails: "/redemption/transaction-details",
  redemptionSuccess: "/redemption/sucess",
  redemptionTransactionReject: "/redemption/transaction-details/reject",
  redemptionEdit: "/redemption/edit",

  otp: "otp",
  edit: "edit",
  paymentOptions: "payment-options",

  ekyc: "ekyc/:ihNo",
  ekycOtp: "/ekyc/otp",
  ekycSuccess: "/ekyc/success",
  ekycSuccess1: "/ekyc/success/:id",
  landing: "/",

  unlockAccount: "unlock-account/*",
  transactionSuccess: "transaction/success",
  transactionFail: "transaction/fail",
  transactionReject: "transaction/reject",
  intro: "intro",
  review: "review",

  editInvestmentDetails: "edit-investment-details",
  editMandateDetails: "edit-mandate-details",
  payment: "payment",
  reviewReject: "review-reject",
  reviewSuccess: "review-success",
  sipApproval: "sip-approval",
  transactionApproval: "transaction-approval",
  transactionPauseApproval: "transaction-pause-approval",
  transactionTopUpApproval: "transaction-topup-approval",
  transactionRedemptionApproval: "transaction-redemption-approval",
  sipCancellation: "sip-cancellation",
  stpApproval: "stp-approval",
  swpApproval: "swp-approval",
  switchApproval: "switch-approval",
  investorApproval: "investor-approval",
  investmentDetails: "investment-details",
  applicant: "applicant",
  nominee: "nominee",
  investorOtm: "investor-otm",
  privacyPolicy: "privacy-policy",
});
