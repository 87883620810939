import { Box, Divider, Grid, IconButton, InputAdornment } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks";
import { useNavigate } from "react-router-dom";
import colors from "res/colors";
import strings from "res/strings";
import isMobile from "utils/isMobile";
import Text from "components/Text";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { ChangeEvent, useEffect, useState } from "react";
import Input from "components/Input";
import {
  editTransaction,
  setTransactionDetails,
} from "store/transaction/slice";
import ButtonIcon from "components/ButtonIcon";
import ActivityOverlay from "components/ActivityOverlay";
import { ToWords } from "to-words";

const SwpEdit = () => {
  const toWords = new ToWords({
    localeCode: "en-IN",
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ihNo } = useAppSelector((state) => state.appState);
  const {
    transactionSchemeDetails,
    transactionDetails,
    editTransactionInfo,
    editTransactionError,
    isEditingTransaction,
  } = useAppSelector((state) => state.transaction);

  const [investmentAmount, setInvestmentAmount] = useState(
    transactionDetails.fetchTransactionDetails.amount
  );
  const [minAmount, setMinAmount] = useState("");
  const [amountErrorFlag, setAmountErrorFlag] = useState(false);
  useEffect(() => {
    let tempMinAmount: string = "";
    transactionSchemeDetails[editTransactionInfo.index].swpFrequency.map(
      (item: any, index: number) => {
        if (item.frequency === editTransactionInfo.contents["SWP Frequency"]) {
          if (tempMinAmount) {
            if (parseInt(tempMinAmount) > parseInt(item.minAmount)) {
              tempMinAmount = item.minAmount;
            }
          } else tempMinAmount = item.minAmount;
        }
      }
    );

    setMinAmount(tempMinAmount);
  });
  function getAmountErrorMessage() {
    if (amountErrorFlag) {
      return `Minimum investment amount is ${minAmount}`;
    }
  }

  const handleScreenCancel = () => {
    navigate(-1);
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    parseInt(minAmount) > parseInt(event.target.value)
      ? setAmountErrorFlag(true)
      : setAmountErrorFlag(false);
    setInvestmentAmount(event.target.value);
  };
  const createNewTransactionDetails = () => {
    const data = { ...transactionDetails };
    data.fetchTransactionDetails = {
      ...transactionDetails.fetchTransactionDetails,
    };
    data.fetchTransactionDetails.amount = investmentAmount;
    dispatch(setTransactionDetails(data));

    // const tempSchemeDetails = [...transactionSchemeDetails];
    // tempSchemeDetails[editTransactionInfo.index].optDesc = selectedOptDesc;
    // dispatch(setTransactionSchemeDetails(tempSchemeDetails));
  };
  const handleAccept = () => {
    // const data = getEditTransacation(
    //   ihNo,
    //   "lumpsum",
    //   transactionDetails,
    //   transactionDetails.cartInitiateResponse.batchNo
    // );

    // const editTransactionData = {
    //   transactionType: "switch",
    //   ihNo: transactionDetails.fetchTransactionDetails.batchNo,
    //   batchNo: transactionDetails.fetchTransactionDetails.batchNo,
    //   transactionDateTime:
    //     transactionDetails.fetchTransactionDetails.transactionDateTime,
    //   editSchemeList: [
    //     {
    //       ihNo: transactionDetails.fetchTransactionDetails.ihNo,
    //       optionCode: transactionDetails.fetchTransactionDetails.option,
    //       amount: investmentAmount,
    //     },
    //   ],
    //   optionCode: transactionDetails.fetchTransactionDetails.option,
    //   amount: investmentAmount,
    //   activity: "",
    // };
    const editTransactionData = {
      transactionType: "swp",
      batchNo: transactionDetails.fetchTransactionDetails.batchNo,
      transactionDateTime:
        transactionDetails.fetchTransactionDetails.transactionDateTime,
      editSchemeList: [
        {
          ihNo: transactionDetails.fetchTransactionDetails.ihNo,
          amount: investmentAmount,
        },
      ],
      amount: investmentAmount,
      activity: "",
      isFreshPurhase: "N",
    };

    dispatch(editTransaction(editTransactionData));
    createNewTransactionDetails();
    navigate(-1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          mt: isMobile() ? "30%" : "10%",
          mx: "5%",
          pb: "20px",
          width: isMobile() ? "90%" : "50%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton onClick={handleScreenCancel}>
            <CancelOutlinedIcon
              sx={{ color: colors.gray }}
              // onClick={handleScreenCancel}
            />
          </IconButton>
        </Box>
        <Box sx={{ px: "24px" }}>
          <Text type="subHeadingBold">{strings.editSwp}</Text>
          <Box sx={{ my: "20px" }}>
            <Divider sx={{ backgroundColor: colors.backGroundBlue }} />
          </Box>

          <Grid container direction={"column"} rowSpacing={"24px"}>
            <Grid item>
              <Text type="dataLightMessage">{strings.FolioNo}</Text>
              <Text type="dataMediumBold">
                {transactionDetails.fetchTransactionDetails.folioNumber}
              </Text>
            </Grid>
            {/* <Grid item>
              <Text type="dataLightMessage">{strings.Scheme}</Text>
              <Text type="dataMediumBold">
                {editTransactionInfo.contents["Scheme Name"]}
              </Text>
            </Grid> */}
          </Grid>
          <Box sx={{ my: "20px" }}>
            <Divider />
          </Box>
          <Text type="smallBold">{"SWP Amount"}</Text>
          <Box sx={{ py: "12px" }}>
            <Input
              value={investmentAmount}
              onChange={handleChange}
              error={getAmountErrorMessage()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {`Min. amt ₹${minAmount}`}
                  </InputAdornment>
                ),
              }}
            />
            <Text type="medium">
              {parseInt(investmentAmount) > 0
                ? toWords.convert(parseFloat(investmentAmount))
                : null}
            </Text>
          </Box>
          {editTransactionError ? (
            <Text
              type="mediumBold"
              color="red"
              sx={{ marginTop: "10px", marginBottom: "15px" }}
            >
              {editTransactionError}
            </Text>
          ) : null}
          <ButtonIcon
            disabled={amountErrorFlag}
            title={"Accept"}
            icon={true}
            onClick={handleAccept}
          />
        </Box>
      </Box>

      {isEditingTransaction && <ActivityOverlay />}
    </Box>
  );
};

export default SwpEdit;
