import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { TResponse } from "types";
import {
  unlockAccount,
  didUnlockAccount,
  didFailToUnlockAccount,
} from "./slice";

import Request from "networking/Request";

const UNBLOCK_ACCOUNT_ENDPOINT = "v1/usermanagement/unblockAccount";

function* handleUnlockAccount(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    UNBLOCK_ACCOUNT_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(didFailToUnlockAccount(response.error));
  } else {
    yield put(didUnlockAccount());
  }
}

function* watcherUnlockAccount() {
  yield takeLatest(unlockAccount.type, handleUnlockAccount);
}

export function* accountUnlockSaga() {
  yield all([watcherUnlockAccount()]);
}
