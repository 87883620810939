import { Grid, Link } from "@mui/material";
import { Box } from "@mui/system";
import SuccesImage from "res/images/success.png";

import Text from "components/Text";
import isMobile from "utils/isMobile";
import strings from "res/strings";
import colors from "res/colors";

const EkycSuccess = () => {
  return (
    <Box sx={{ mt: isMobile() ? "30%" : "10%" }}>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        container
        direction="column"
        columnSpacing={{ xs: 1, sm: 3, md: 4, lg: 5 }}
      >
        <Grid>
          <img
            src={SuccesImage}
            alt="Success"
            height={"140px"}
            width={"190px"}
          />
        </Grid>
        <Grid>
          <Text
            type="successLargeBold"
            sx={{
              fontSize: "32px",
              lineHeight: "40px",
              fontWeight: 500,
              mt: "20px",
            }}
          >
            Successful!
          </Text>
        </Grid>
        <Grid>
          <Text type="large" sx={{ mt: "20px" }}>
            {strings.kycSuccessMessage}
          </Text>
        </Grid>
        {/* <Grid>
          <Text type="large" sx={{ mt: "20px" }}>
            <Link color={colors.backGroundBlue}> Click here</Link> to go to
            login page.
          </Text>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default EkycSuccess;
