import { useEffect } from "react";
import { Grid, Link } from "@mui/material";
import { Box } from "@mui/system";
import SuccesImage from "res/images/success.png";

import Text from "components/Text";
import isMobile from "utils/isMobile";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { unlockAccount } from "./store/slice";
import ActivityOverlay from "components/ActivityOverlay";

const MFDUnlockSuccess = () => {
  const dispatch = useAppDispatch();
  const { isUnlockingAccount, didUnlockAccount, unlockError } = useAppSelector(
    (state) => state.accountUnlock
  );

  useEffect(() => {
    const path = window.location.pathname.replace("/unlock-account/", "");
    const paths = path.split("/");
    const type = paths[0];
    paths.splice(0, 1);
    const userId = paths.join("/");
    dispatch(unlockAccount({ userType: type, userId: userId }));
  }, []);

  if (isUnlockingAccount) {
    return (
      <Box sx={{ mt: isMobile() ? "20%" : "5%" }}>
        <Grid>
          <Text
            type="successLargeBold"
            sx={{
              fontSize: "32px",
              lineHeight: "40px",
              fontWeight: 500,
              mt: "20px",
            }}
          >
            Please wait...
          </Text>
        </Grid>
        <ActivityOverlay />
      </Box>
    );
  }

  if (unlockError) {
    return (
      <Box sx={{ mt: isMobile() ? "20%" : "5%" }}>
        <Grid>
          <Text
            type="successLargeBold"
            sx={{
              fontSize: "32px",
              lineHeight: "40px",
              fontWeight: 500,
              mt: "20px",
            }}
          >
            {unlockError}
          </Text>
        </Grid>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: isMobile() ? "30%" : "10%" }}>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        container
        direction="column"
        columnSpacing={{ xs: 1, sm: 3, md: 4, lg: 5 }}
      >
        <Grid>
          <img
            src={SuccesImage}
            alt="Success"
            height={"140px"}
            width={"190px"}
          />
        </Grid>
        <Grid>
          <Text
            type="successLargeBold"
            sx={{
              fontSize: "32px",
              lineHeight: "40px",
              fontWeight: 500,
              mt: "20px",
            }}
          >
            Successful!
          </Text>
        </Grid>

        {/* <Grid>
          <Text type="large" sx={{ mt: "20px" }}>
            <Link color={colors.backGroundBlue}> Click here</Link> to go to
            login page.
          </Text>
        </Grid> */}
      </Grid>
      <Box
        mt="20px"
        pl="20px"
        pr="20px"
        sx={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text type="dialogText">
          You have successfully unlocked your account. Please login using mobile
          app.
        </Text>
      </Box>
    </Box>
  );
};

export default MFDUnlockSuccess;
