import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      light: "#000AD2",
      main: "#000",
    },
    secondary: {
      main: "#f33e57",
    },
  },
  typography: {
    fontSize: 15,

    fontWeightLight: "lighter",
    fontFamily: "Roboto",
  },
});

export default theme;
