import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import Input from "components/Input";
import Text from "components/Text";
import React from "react";
import colors from "res/colors";
import { UploadOrDragProps } from ".";

const Choose = () => {
  return (
    <Button
      variant="text"
      sx={{
        fontFamily: "Graphik",
        fontSize: "18px",
        lineHeight: "20px",
        fontWeight: 500,
        pr: 1,
        pl: 0,
        color: colors.backGroundBlue,
      }}
    >
      Choose
    </Button>
  );
};

const UploadOrDrag: React.FC<UploadOrDragProps> = ({
  icon,
  type,
  resolution = "",
  showText = true,
}) => {
  const renderComponent = () => {
    if (type === "Image") {
      return (
        <div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              ml: "24px",
              mr: "24px",
            }}
          >
            <Box>
              <Text type="largeBold">
                <Choose />
                or drag it here
              </Text>
            </Box>

            {showText ? (
              <Text type="mediumBold">
                {`Max. Image size 1 mb | Resolution ${
                  resolution ? resolution : "1270 X 750"
                }`}
              </Text>
            ) : null}
          </Box>
        </div>
      );
    } else if (type === "PDF") {
      return (
        <div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ml: "24px",
            }}
          >
            <Text type="largeBold">
              <Choose />
              or Drag it here
            </Text>
            <Text type="mediumBold">Max. PDF size 10 mb</Text>
          </Box>
        </div>
      );
    } else if (type === "Url") {
      return (
        <div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: "450px",
              pl: "36px",
            }}
          >
            <Input variant="outlined" label="Enter Video URL" />
          </Box>
        </div>
      );
    } else if (type === "Excel") {
      return (
        <div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              ml: "24px",
            }}
          >
            <Text type="largeBold">
              <Choose />
              or Drag it here
            </Text>
            <Text type="mediumBold">Max. Image size 1 mb | Only Excel</Text>
          </Box>
        </div>
      );
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        py: "24px",
        pl: "32px",
        // borderStyle: "dashed",
        // borderRadius: "10px",
        // // borderColor: colors.uploadBoxBorderColor,
        // backgroundColor: colors.uploadBoxColor,
        //opacity: 0.4,
        width: "100%",
      }}
    >
      {icon}
      {renderComponent()}
    </Box>
  );
};

export default UploadOrDrag;
