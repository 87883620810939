import Otp from "components/Otp/Otp";
import { useNavigate } from "react-router-dom";
import RouteConfig from "routes/RouteConfig";

const SwitchOtp = () => {
  const navigate = useNavigate();
  const handleOnVerifyOtp = () => {
    navigate(RouteConfig.switchTransactionDetails);
  };
  return <Otp onVerifyOtp={handleOnVerifyOtp} />;
};
export default SwitchOtp;
