import { Button } from "@mui/material";
import { FC } from "react";
import { ButtonProps } from "./types";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import isMobile from "utils/isMobile";
import colors from "res/colors";

const ButtonIcon: FC<ButtonProps> = ({
  title,
  icon,
  variant = "contained",
  ...buttonProps
}) => {
  return (
    <Button
      variant={variant}
      sx={
        variant === "contained"
          ? styles.containedButtonStyle
          : styles.outlinedButtonStyle
      }
      {...buttonProps}
      endIcon={
        icon ? (
          <ArrowForwardOutlinedIcon
            sx={{
              fontSize: "100px",
              position: "absolute",
              mt: "-10px",
              right: "16px",
            }}
          />
        ) : null
      }
    >
      {title}
    </Button>
  );
};

const styles = {
  containedButtonStyle: {
    backgroundColor: colors.backGroundBlue,
    color: colors.white,
    width: isMobile() ? "100%" : "275px",
    py: "10px",
    textTransform: "none",
  },
  outlinedButtonStyle: {
    color: colors.blue,
    borderColor: colors.blue,
    width: isMobile() ? "100%" : "275px",
    py: "10px",
    textTransform: "none",
  },
} as const;
export default ButtonIcon;
