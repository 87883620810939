import { Box, Divider, Grid, IconButton, InputAdornment } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Text from "components/Text";
import strings from "res/strings";
import colors from "res/colors";
import Input from "components/Input";
import { ChangeEvent, useState, useEffect } from "react";
import ButtonIcon from "components/ButtonIcon";
import ToggleButtonOptions from "components/ToggleButton/ToggleButtonOptions";
import isMobile from "utils/isMobile";
import {
  editTransaction,
  setTransactionDetails,
} from "store/transaction/slice";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { getEditTransacation, TransactionType } from "utils/constants";
import ActivityOverlay from "components/ActivityOverlay/ActivityOverlay";
import { ToWords } from "to-words";
import Select from "components/Select";
import { SelectOption } from "components/FormSelect";
import { verifyEmail } from "utils/verifyEmail";
import { verifyIsNumber } from "utils/verifyIsNumber";

const relationOption = [
  {
    title: "Self",
    value: "Self",
  },
  {
    title: "Spouse",
    value: "Spouse",
  },
  {
    title: "Dependant Children",
    value: "Dependant Children",
  },
  {
    title: "Dependant Siblings",
    value: "Dependant Siblings",
  },
  {
    title: "Dependant Spouse",
    value: "Dependant Spouse",
  },
  {
    title: "Guardian",
    value: "Guardian",
  },
];
const amountData = [{ label: "1000" }, { label: "2000" }, { label: "3000" }];
const NewTransactionEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ihNo, transactionType } = useAppSelector((state) => state.appState);

  const {
    transactionSchemeDetails,
    editTransactionInfo,
    transactionDetails,
    isEditingTransaction,
    didEditTransaction,
    editTransactionError,
  } = useAppSelector((state) => state.transaction);

  const [investmentAmount, setInvestmentAmount] = useState("");
  const [amountErrorFlag, setAmountErrorFlag] = useState(false);

  const [mobileNumber, editMobileNumber] = useState("");
  const [mobileNumberError, editMobileNumberError] = useState<string>("");
  const [emailId, editEmailId] = useState("");
  const [emailIdError, editEmailIdError] = useState("");

  const [mobileNumberBelongsTo, editMobileNumberBelongsTo] = useState("");
  const [emailIdBelongsTo, editEmailIdBelongsTo] = useState("");

  useEffect(() => {
    if (Object.keys(editTransactionInfo).length) {
      editMobileNumber(editTransactionInfo.mobileNumber);
      editEmailId(editTransactionInfo.emailId);
      editEmailIdBelongsTo(editTransactionInfo.contents["Email belongs to"]);
      editMobileNumberBelongsTo(
        editTransactionInfo.contents["Mobile belongs to"]
      );
    }
  }, []);
  useEffect(() => {
    if (didEditTransaction) {
    }
  }, [didEditTransaction]);

  if (!Object.keys(editTransactionInfo).length) {
    navigate("/");
    return <div />;
  }

  const handleMobileNumberEdit = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && !verifyIsNumber(event.target.value)) {
      return;
    }
    editMobileNumberError("");

    if (event?.target?.value?.length < 10) {
      editMobileNumberError("Please enter valid mobile number.");
    }
    editMobileNumber(event.target.value);
  };

  const handleEmailIdEdit = (event: ChangeEvent<HTMLInputElement>) => {
    editEmailIdError("");
    if (!verifyEmail(event.target.value)) {
      editEmailIdError("Please enter valid email id.");
    }
    editEmailId(event.target.value);
  };

  const handleMobileBelongsTo = (option: SelectOption) => {
    editMobileNumberBelongsTo(option.value);
  };
  const handleEmailBelongsTo = (option: SelectOption) => {
    editEmailIdBelongsTo(option.value);
  };
  const createNewTransactionDetails = () => {
    const data = { ...transactionDetails };
    data.fetchFreshCartDataResponseList = {
      ...transactionDetails.fetchFreshCartDataResponseList,
    };

    // const schemes =
    //   transactionDetails.fetchFreshCartDataResponseList.schemeList.map(
    //     (item: any, index: number) => {
    //       return {
    //         ...item,
    //       };
    //     }
    //   );
    // schemes[editTransactionInfo.index].amount = investmentAmount;
    // data.fetchFreshCartDataResponseList.schemeList = schemes;
    if (editTransactionInfo.contentType === "Applicant_1") {
      data.fetchFreshCartDataResponseList.ap1MobileNumber = mobileNumber;
      data.fetchFreshCartDataResponseList.ap1MobileBelongsTo =
        mobileNumberBelongsTo;
      data.fetchFreshCartDataResponseList.ap1Email = emailId;
      data.fetchFreshCartDataResponseList.ap1EmailBelongsTo = emailIdBelongsTo;
    } else if (editTransactionInfo.contentType === "Applicant_2") {
      data.fetchFreshCartDataResponseList.ap2MobileNumber = mobileNumber;
      data.fetchFreshCartDataResponseList.ap2MobileBelongsTo =
        mobileNumberBelongsTo;
      data.fetchFreshCartDataResponseList.ap2Email = emailId;
      data.fetchFreshCartDataResponseList.ap2EmailBelongsTo = emailIdBelongsTo;
    } else if (editTransactionInfo.contentType === "Applicant_3") {
      data.fetchFreshCartDataResponseList.ap3MobileNumber = mobileNumber;
      data.fetchFreshCartDataResponseList.ap3MobileBelongsTo =
        mobileNumberBelongsTo;
      data.fetchFreshCartDataResponseList.ap3Email = emailId;
      data.fetchFreshCartDataResponseList.ap3EmailBelongsTo = emailIdBelongsTo;
    }
    dispatch(setTransactionDetails(data));
  };

  const handleAccept = () => {
    if (mobileNumberError || emailIdError) {
      return;
    }

    // const data = getEditTransacation(
    //   ihNo,
    //   "sip",
    //   transactionDetails,
    //   transactionDetails.fetchFreshCartDataResponseList.batchNo
    // );

    // const schemes =
    //   transactionDetails.fetchFreshCartDataResponseList.schemeList.map(
    //     (item: { [key: string]: any }) => {
    //       return {
    //         ihNo: item.ihNo,
    //         optionCode: item.opt,
    //         amount: item.amount,
    //         sipStartDate: item.sipStartDate,
    //         sipEndDate: item.sipEndDate,
    //         sipFrequency: item.sipFrequency,
    //         noOfInstallments: item.noOfInstallments,
    //         topUpAmount: item.topUpAmount,
    //         topUpFrequency: item.topUpFrequency,
    //         topUpMaxYears: item.topUpMaxYears,
    //         topUpMaxAmount: item.topUpMaxAmount,
    //       };
    //     }
    //   );

    // schemes[editTransactionInfo.index].amount = investmentAmount;
    // data.editSchemeList = schemes;

    // use editTransactionInfo.contentType === "Applicant_1" conditions

    const editTransactionData = {
      transactionType:
        transactionType === TransactionType.newLumpsum ? "Lumpsum" : "SIP",
      batchNo: transactionDetails.fetchFreshCartDataResponseList.batchNo,
      transactionDateTime:
        transactionDetails.fetchFreshCartDataResponseList.transactionDateTime ||
        "",
      // editSchemeList: [
      //   {
      //     // ihNo: transactionDetails.fetchFreshCartDataResponseList.schemeList[
      //     //   editTransactionInfo.index
      //     // ].ihNo,
      //     // amount: investmentAmount,
      //   },
      // ],
      activity: "",
      // isFreshPurhase: "Y",
      newUser: true,
    };
    let applicantDetails: any = {};
    if (editTransactionInfo.contentType === "Applicant_1") {
      applicantDetails["ap1MobileNumber"] = mobileNumber;
      applicantDetails["ap1MobileBelongsTo"] = mobileNumberBelongsTo;
      applicantDetails["ap1Email"] = emailId;
      applicantDetails["ap1EmailBelongsTo"] = emailIdBelongsTo;
    } else if (editTransactionInfo.contentType === "Applicant_2") {
      applicantDetails["ap2MobileNumber"] = mobileNumber;
      applicantDetails["ap2MobileBelongsTo"] = mobileNumberBelongsTo;
      applicantDetails["ap2Email"] = emailId;
      applicantDetails["ap2EmailBelongsTo"] = emailIdBelongsTo;
    } else if (editTransactionInfo.contentType === "Applicant_3") {
      applicantDetails["ap3MobileNumber"] = mobileNumber;
      applicantDetails["ap3MobileBelongsTo"] = mobileNumberBelongsTo;
      applicantDetails["ap3Email"] = emailId;
      applicantDetails["ap3EmailBelongsTo"] = emailIdBelongsTo;
    }
    const finalEditTransactionData = {
      ...transactionDetails?.fetchFreshCartDataResponseList,
      ...editTransactionData,
      ...applicantDetails,
    };

    dispatch(editTransaction(finalEditTransactionData));
    createNewTransactionDetails();
    navigate(-1);
  };

  const handleScreenCancel = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          mt: isMobile() ? "30%" : "10%",
          mx: "5%",
          pb: "20px",
          width: isMobile() ? "90%" : "50%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton onClick={handleScreenCancel}>
            <CancelOutlinedIcon sx={{ color: colors.gray }} />
          </IconButton>
        </Box>
        <Box sx={{ px: "24px" }}>
          <Text type="subHeadingBold">{strings.editApplicantDetails}</Text>
          <Box sx={{ my: "20px" }}>
            <Divider sx={{ backgroundColor: colors.backGroundBlue }} />
          </Box>

          <Grid container direction={"column"} rowSpacing={"24px"}>
            <Grid item>
              <Text type="smallBold">{strings.mobileNumber}</Text>
              <Box sx={{ my: "12px" }} />
              <Input
                value={mobileNumber}
                onChange={handleMobileNumberEdit}
                inputProps={{ maxLength: 10 }}
                error={mobileNumberError}
              />
              <Box sx={{ my: "14px" }} />
              <Text type="smallBold">{strings.mobileBelongs}</Text>
              <Box sx={{ my: "8px" }} />
              <Select
                placeHolder="Choose"
                value={mobileNumberBelongsTo}
                onOptionSelect={handleMobileBelongsTo}
                options={relationOption}
              />
            </Grid>
          </Grid>
          <Box sx={{ mb: "24px", mt: "24px" }}>
            <Divider />
          </Box>
          <Grid>
            <Text type="smallBold">{strings.emailId}</Text>
            <Box sx={{ my: "12px" }} />
            {/* <Text type="dataMediumBold">
              {editTransactionInfo.contents["Email"]}
            </Text> */}
            <Input
              type="email"
              value={emailId}
              onChange={handleEmailIdEdit}
              error={emailIdError}
            />
            <Box sx={{ my: "14px" }} />
            <Text type="smallBold">{strings.emailBelongs}</Text>
            <Box sx={{ my: "8px" }} />
            <Select
              value={emailIdBelongsTo}
              onOptionSelect={handleEmailBelongsTo}
              placeHolder="Choose"
              options={relationOption}
            />
          </Grid>
          <Box sx={{ pb: "12px" }} />
          {editTransactionError ? (
            <Text
              type="mediumBold"
              color="red"
              sx={{ marginTop: "10px", marginBottom: "15px" }}
            >
              {editTransactionError}
            </Text>
          ) : null}
          <ButtonIcon
            disabled={amountErrorFlag}
            title={"Accept"}
            icon={true}
            onClick={handleAccept}
          />
        </Box>
      </Box>
      {isEditingTransaction && <ActivityOverlay />}
    </Box>
  );
};

export default NewTransactionEdit;
