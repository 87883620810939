import { Box } from "@mui/material";
import { FC, useEffect } from "react";

import ButtonIcon from "components/ButtonIcon";
import {
  INVESCO_NPS_URL,
  INVESCO_WEB_URL,
  TransactionType,
} from "utils/constants";
import Success from "components/Success";
import { TransactionsSuccessProps } from "./types";
import isMobile from "utils/isMobile";
import { setTransactionStage } from "store/appstate/slice";
import { useAppDispatch, useAppSelector } from "hooks";
const TransactionSuccess: FC<TransactionsSuccessProps> = ({
  transactionSuccessMessage,
}) => {
  const dispatch = useAppDispatch();
  const { transactionType } = useAppSelector((state) => state.appState);
  useEffect(() => {
    dispatch(setTransactionStage());
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          mt: isMobile() ? "30%" : "10%",
          textAlign: "left",
          maxWidth: "640px",
          px: "10%",
        }}
      >
        {/* <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box>
          <IconButton>
            <DownloadForOfflineIcon
              sx={{ color: colors.red, fontSize: "32px" }}
            />
          </IconButton>
        </Box>

        <IconButton>
          <CancelRoundedIcon
            sx={{
              color: colors.gray,

              fontSize: "32px",
            }}
          />
        </IconButton>
      </Box>
      <Box mt="50px">
        <img src={SuccesImage} alt="Success" height={"140px"} width={"190px"} />
      </Box>
      <Box
        sx={{
          mt: "24px",
          mb: "12px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text type="successLargeBold" align="center">
          You have successfully completed the transaction.
        </Text>
      </Box> */}
        <Success
          successMessage={transactionSuccessMessage}
          cancel={false}
          download={false}
        />
        <Box sx={{ mt: "53px", mx: "5%" }}>
          <ButtonIcon
            title={"Close"}
            icon={false}
            onClick={() => {
              // transactionType === TransactionType.otm
              // window.location.replace(INVESCO_NPS_URL);
              window.location.replace(INVESCO_WEB_URL);
            }}
          ></ButtonIcon>
        </Box>

        {/* <Box>
        <Text type="smallTransparentCenter">{successMessage}</Text>
      </Box> */}
      </Box>
    </Box>
  );
};
export default TransactionSuccess;
