import { Box, Divider, Grid, IconButton, InputAdornment } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Text from "components/Text";
import strings from "res/strings";
import colors from "res/colors";
import Input from "components/Input";
import { ChangeEvent, useState, useEffect } from "react";
import ButtonIcon from "components/ButtonIcon";
import ToggleButtonOptions from "components/ToggleButton/ToggleButtonOptions";
import isMobile from "utils/isMobile";

import {
  editTransaction,
  setTransactionDetails,
} from "store/transaction/slice";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { getEditTransacation } from "utils/constants";
import ActivityOverlay from "components/ActivityOverlay/ActivityOverlay";
import moment from "moment";
import { ToWords } from "to-words";
import { verifyIsNumber } from "utils/verifyIsNumber";

const amountData = [{ label: "1000" }, { label: "2000" }, { label: "3000" }];

const SipEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ihNo } = useAppSelector((state) => state.appState);
  const {
    transactionSchemeDetails,
    isLoadingTransactionSchemesDetails,
    editTransactionInfo,
    transactionDetails,
    isEditingTransaction,
    didEditTransaction,
    editTransactionError,
  } = useAppSelector((state) => state.transaction);
  const { schemesCategeory, allSchemes, schemeOptions } = useAppSelector(
    (state) => state.globalData
  );

  const [investmentOption, setInvestmentOption] = useState("");
  const [investmentAmount, setInvestmentAmount] = useState("");
  const [selectedSchemeOptions, setSelectedSchemeOption] = useState([]);
  const [minAmount, setMinAmount] = useState("");
  const [amountErrorFlag, setAmountErrorFlag] = useState(false);
  const [maxAmountError, setMaxAmountError] = useState("");
  const [sipType, setSipType] = useState("Installments");
  const [sipStartDate, setSipStartDate] = useState(
    moment(
      editTransactionInfo.contents["SIP Start Date"],
      "DD-MMM-YYYY"
    ).format("DD MMM YYYY")
  );
  const [sipEndDate, setSipEndDate] = useState(
    moment(editTransactionInfo.contents["SIP End Date"], "DD-MMM-YYYY").format(
      "DD MMM YYYY"
    )
  );
  const [initiateSipEndDate, setInitiateSipEndDate] = useState("");
  const [sipDate, setSipDate] = useState(
    moment(editTransactionInfo.contents["SIP Start Date"], "DD-MMM-YYYY")
      .date()
      .toString()
  );
  const [calculatedSipStartDate, setCalculatedSipStartDate] = useState("");
  const [numberOfInstallment, setNumberOfInstallment] = useState("");
  const [numberOfYears, setNumberOfYears] = useState("");
  const [initialAvailableSipDate, setInitialAvailableSipDate] = useState("");

  const [sipFrequency, setSipFrequency] = useState(
    editTransactionInfo.contents["SIP Frequency"]
  );
  useEffect(() => {
    if (Object.keys(editTransactionInfo).length) {
      setInvestmentAmount(
        transactionDetails.cartInitiateResponse.schemeList[
          editTransactionInfo.index
        ].amount
      );
      setInvestmentOption(editTransactionInfo.contents.Option);
    }
  }, []);
  useEffect(() => {
    let tempMinAmount: string = "";
    transactionSchemeDetails[editTransactionInfo.index].sipFrequency.map(
      (item: any, index: number) => {
        if (item.frequency === editTransactionInfo.contents["SIP Frequency"]) {
          if (tempMinAmount) {
            if (parseInt(tempMinAmount) > parseInt(item.minAmount)) {
              tempMinAmount = item.minAmount;
            }
          } else tempMinAmount = item.minAmount;
        }
      }
    );
    setMinAmount(tempMinAmount);
  });
  const getTransactionCategory = (responseCategory: string) => {
    switch (responseCategory) {
      case "Debt Schemes":
        return "FIXED INCOME";
      case "Hybrid Schemes":
        return "HYBRID";
      default:
        return "";
    }
  };
  // useEffect(() => {
  //   const transactionCategory = getTransactionCategory(
  //     transactionSchemeDetails[editTransactionInfo.index]?.sebiCategory
  //   );
  //

  //   setSelectedSchemeOption(
  //     schemeOptions[transactionCategory][
  //       transactionSchemeDetails[editTransactionInfo.index].schemeDesc
  //     ]
  //   );
  // }, []);
  useEffect(() => {
    if (didEditTransaction) {
    }
  }, [didEditTransaction]);

  if (!Object.keys(editTransactionInfo).length) {
    navigate("/");
    return <div />;
  }

  const toWords = new ToWords({
    localeCode: "en-IN",
  });

  const handleInvestmentAmount = (amount: string) => {
    parseInt(minAmount) > parseInt(amount)
      ? setAmountErrorFlag(true)
      : setAmountErrorFlag(false);
    parseInt(amount) > 10000000
      ? setMaxAmountError("Maximum amount is ₹1,00,00,000.")
      : setMaxAmountError("");
    setInvestmentAmount(amount);
  };
  function getAmountErrorMessage() {
    if (amountErrorFlag) {
      return `Minimum investment amount is ${minAmount}`;
    }
  }
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value && !verifyIsNumber(event.target.value)) {
      return;
    }
    parseInt(minAmount) > parseInt(event.target.value) || !event.target.value
      ? setAmountErrorFlag(true)
      : setAmountErrorFlag(false);
    parseInt(event.target.value) > 10000000
      ? setMaxAmountError("Maximum amount is ₹1,00,00,000.")
      : setMaxAmountError("");
    setInvestmentAmount(event.target.value);
  };
  const handleInvestmentOption = (InvestmentOptionSelected: string) => {
    setInvestmentOption(InvestmentOptionSelected);
  };

  const createNewTransactionDetails = () => {
    const data = { ...transactionDetails };
    data.cartInitiateResponse = { ...transactionDetails.cartInitiateResponse };

    const schemes = transactionDetails.cartInitiateResponse.schemeList.map(
      (item: any, index: number) => {
        return {
          ...item,
        };
      }
    );
    schemes[editTransactionInfo.index].amount = investmentAmount;
    data.cartInitiateResponse.schemeList = schemes;
    dispatch(setTransactionDetails(data));
  };

  const handleAccept = () => {
    const data = getEditTransacation(
      ihNo,
      "SIP",
      transactionDetails,
      transactionDetails.cartInitiateResponse.batchNo
    );

    const schemes = transactionDetails.cartInitiateResponse.schemeList.map(
      (item: { [key: string]: any }) => {
        return {
          ihNo: item.ihNo,
          optionCode: item.opt,
          amount: item.amount,
          sipStartDate: item.sipStartDate,
          sipEndDate: item.sipEndDate,
          sipFrequency: item.sipFrequency,
          noOfInstallments: item.noOfInstallments,
          topUpAmount: item.topUpAmount,
          topUpFrequency: item.topUpFrequency,
          topUpMaxYears: item.topUpMaxYears,
          topUpMaxAmount: item.topUpMaxAmount,

          // urno: "IV099785-A91587"
        };
      }
    );
    schemes[editTransactionInfo.index].amount = investmentAmount;
    data.editSchemeList = schemes;

    const editTransactionData = {
      transactionType: "SIP",
      batchNo: transactionDetails.cartInitiateResponse.batchNo,
      transactionDateTime:
        transactionDetails.cartInitiateResponse.transactionDateTime,
      editSchemeList: [
        {
          ihNo: transactionDetails.cartInitiateResponse.schemeList[
            editTransactionInfo.index
          ].ihNo,
          amount: investmentAmount,
          // urno: transactionDetails.cartInitiateResponse.schemeList[
          //   editTransactionInfo.index
          // ].urno,
        },
      ],
      activity: "",
      isFreshPurhase: "N",
    };
    dispatch(editTransaction(editTransactionData));
    createNewTransactionDetails();
    navigate(-1);
  };

  const handleScreenCancel = () => {
    navigate(-1);
  };

  const calculateInitialAvailableSipDate = (selected: String) => {
    if (selected === "Monthly") {
      setCalculatedSipStartDate("");
      setSipDate("");
      setSipEndDate("");
      setInitiateSipEndDate("");
      setSipType("");
      setNumberOfInstallment("0");
      setNumberOfYears("0");
      setInitialAvailableSipDate(
        moment().add(25, "days").format("DD MMM YYYY")
      );
      setSipStartDate("");
    }
    if (selected === "Quarterly") {
      setCalculatedSipStartDate("");
      setSipDate("");
      setSipEndDate("");
      setInitiateSipEndDate("");
      setSipType("");
      setNumberOfInstallment("0");
      setNumberOfYears("0");
      setSipStartDate("");

      // const currentDate = moment().format('DD/MMM/YYYY');
      let nextAvailableOffsetDate = moment()
        .add(25, "days")
        .format("DD MMM YYYY");
      const month = moment(nextAvailableOffsetDate, "DD MMM YYYY").month();

      if (month === 0 || month === 3 || month === 6 || month === 9) {
        setInitialAvailableSipDate(nextAvailableOffsetDate);
      } else {
        for (let index = 0; index < 2; index++) {
          const tempMonth = month + index + 1;

          if (tempMonth > 11) {
            setInitialAvailableSipDate(
              moment("0101", "DDMM").add(1, "year").format("DD MMM YYYY")
            );

            break;
          }
          if (
            tempMonth === 0 ||
            tempMonth === 3 ||
            tempMonth === 6 ||
            tempMonth === 9
          ) {
            let datePlusMonth = `01${tempMonth}`;

            setInitialAvailableSipDate(
              moment(datePlusMonth, "DDMM")
                .add(1, "month")
                .format("DD MMM YYYY")
            );

            break;
          }
        }
      }
    }
  };
  const calculateSipEndDate = (_sipType: string, sipInterval: string) => {
    if (_sipType === "Perpetual") {
      setSipEndDate(
        moment(`${sipDate}122099`, "DDMMYYYY").format("DD MMM YYYY")
      );
      setInitiateSipEndDate(
        moment(`${sipDate}122099`, "DDMMYYYY").format("YYYY-MM-DD")
      );
      setNumberOfInstallment(
        moment(`${sipDate}122099`, "DDMMYYYY")
          .diff(moment(sipStartDate, "DD MMM YYYY"), "months")
          .toString()
      );
    } else if (_sipType === "Years") {
      if (sipInterval != "0") {
        const years = parseInt(sipInterval);
        const months = years * 12;
        let endDate = moment(calculatedSipStartDate, "DD MMM YYYY")
          .add(years, "y")
          .format("DD MMM YYYY");
        endDate = moment(endDate, "DD MMM YYYY")
          .subtract(1, "month")
          .format("DD MMM YYYY");

        setSipEndDate(endDate);
        setInitiateSipEndDate(
          moment(endDate, "DD MMM YYYY").format("YYYY-MM-DD")
        );
        setNumberOfInstallment(months.toString());
      }
    } else {
      if (sipInterval != "0") {
        const months = parseInt(sipInterval);
        let endDate = moment(calculatedSipStartDate, "DD MMM YYYY")
          .add(months, "M")
          .format("DD MMM YYYY");
        endDate = moment(endDate, "DD MMM YYYY")
          .subtract(1, "month")
          .format("DD MMM YYYY");

        setSipEndDate(endDate);
        setInitiateSipEndDate(
          moment(endDate, "DD MMM YYYY").format("YYYY-MM-DD")
        );
      }
    }
  };
  const calculateQuarterlySipEndDate = (
    _sipType: string,
    sipInterval: string
  ) => {
    if (_sipType === "Perpetual") {
      setSipEndDate(
        moment(sipDate + "102099", "DDMMYYYY").format("DD MMM YYYY")
      );
      setInitiateSipEndDate(
        moment(sipDate + "102099", "DDMMYYYY").format("YYYY-MM-DD")
      );
      setNumberOfInstallment(
        moment(sipDate + "102099", "DDMMYYYY")
          .diff(moment(sipStartDate, "DD MMM YYYY"), "quarters")
          .toString()
      );
    } else if (_sipType === "Years") {
      if (sipInterval != "0") {
        const years = parseInt(sipInterval);
        const _numberOfInstallments = years * 4;
        //
        setNumberOfInstallment(_numberOfInstallments.toString());
        let endDate = moment(sipStartDate, "DD MMM YYYY")
          .add(years, "y")
          .format("DD MMM YYYY");
        endDate = moment(endDate, "DD MMM YYYY")
          .subtract(3, "month")
          .format("DD MMM YYYY");
        //
        setSipEndDate(endDate);
        setInitiateSipEndDate(
          moment(endDate, "DD MMM YYYY").format("YYYY-MM-DD")
        );
      }
    } else {
      if (sipInterval != "0") {
        const _numberOfInstallments = parseInt(sipInterval);
        const months = _numberOfInstallments * 3;
        let endDate = moment(sipStartDate, "DD MMM YYYY")
          .add(months, "month")
          .format("DD MMM YYYY");
        endDate = moment(endDate, "DD MMM YYYY")
          .subtract(3, "month")
          .format("DD MMM YYYY");

        setSipEndDate(endDate);
        setInitiateSipEndDate(
          moment(endDate, "DD MMM YYYY").format("YYYY-MM-DD")
        );
      }
    }
  };
  const calculateSipEndDateFromStartDate = (_date: Date) => {
    if (sipType === "Installments") {
      if (numberOfInstallment) {
        setSipEndDate(
          moment(_date, "DD MMM YYYY")
            .add(parseInt(numberOfInstallment), "M")
            .format("DD MMM YYYY")
        );
      }
    } else if (sipType === "Years") {
      calculateSipEndDate(sipType, numberOfYears);
    }
  };
  const getSipDate = (value: string) => {
    const offsetDay = moment().add(25, "days").format("DD");

    if (sipFrequency === "Monthly") {
      const dateDifference = moment(parseInt(value)).diff(parseInt(offsetDay)); // -12

      if (Math.sign(dateDifference) === -1) {
        const offsetDate = moment().add(25, "days").format("DD MMM YYYY"); //22june
        let calculatedDate = moment(
          moment(value, "DD").format("DD MMM YYYY"),
          "DD MMM YYYY"
        )
          .add(1, "months")
          .format("DD MMM YYYY");

        const secondaryDifference = moment(
          moment(calculatedDate, "DD MMM YYYY")
        ).diff(moment(offsetDate, "DD MMM YYYY"));

        if (
          Math.sign(secondaryDifference) === -1 ||
          !moment(calculatedDate, "DD MMM YYYY").isValid()
        ) {
          calculatedDate = moment(calculatedDate, "DD MMM YYYY")
            .add(1, "month")
            .format("DD MMM YYYY"); //18 july

          setCalculatedSipStartDate(calculatedDate);
          // setSipStartDate(moment(calculatedDate, "DD MMM YYYY").toDate());
          setSipStartDate(calculatedDate);
          calculateSipEndDateFromStartDate(
            moment(calculatedDate, "DD MMM YYYY").toDate()
          );
        } else {
          setCalculatedSipStartDate(calculatedDate);
          setSipStartDate(calculatedDate);
          // setSipStartDate(moment(calculatedDate, "DD MMM YYYY").toDate());
          calculateSipEndDateFromStartDate(
            moment(calculatedDate, "DD MMM YYYY").toDate()
          );
        }
      } else {
        const offsetDate = moment().add(25, "days").format("DD MMM YYYY");
        let calculatedDate = moment(value, "DD").format("DD MMM YYYY");
        const secondaryDifference = moment(calculatedDate, "DD MMM YYYY").diff(
          moment(offsetDate, "DD MMM YYYY")
        );

        if (
          Math.sign(secondaryDifference) === -1 ||
          !moment(calculatedDate, "DD MMM YYYY").isValid()
        ) {
          calculatedDate = moment(calculatedDate, "DD MMM YYYY")
            .add(1, "month")
            .format("DD MMM YYYY");

          setCalculatedSipStartDate(calculatedDate);

          // setSipStartDate(moment(calculatedDate, "DD MMM YYYY").toDate());
          setSipStartDate(calculatedDate);
          calculateSipEndDateFromStartDate(
            moment(calculatedDate, "DD MMM YYYY").toDate()
          );
        } else {
          setCalculatedSipStartDate(calculatedDate);
          setSipStartDate(calculatedDate);
          // setSipStartDate(moment(calculatedDate, "DD MMM YYYY").toDate());
          calculateSipEndDateFromStartDate(
            moment(calculatedDate, "DD MMM YYYY").toDate()
          );
        }
      }
    } else if (sipFrequency === "Quarterly") {
      let currentCalculatedDate = moment(value, "DD").format("DD MMM YYYY");

      let initialMonthDifference = moment(
        moment(currentCalculatedDate, "DD MMM YYYY").month()
      ).diff(moment(initialAvailableSipDate, "DD MMM YYYY").month());
      let initialDateDifference = moment(
        moment(currentCalculatedDate, "DD MMM YYYY").date()
      ).diff(moment(initialAvailableSipDate, "DD MMM YYYY").date());

      if (Math.sign(initialMonthDifference) === -1) {
        currentCalculatedDate = moment(currentCalculatedDate, "DD MMM YYYY")
          .add(Math.abs(initialMonthDifference), "month")
          .format("DD MMM YYYY");

        let secondaryDateDifference = moment(
          currentCalculatedDate,
          "DD MMM YYYY"
        ).diff(moment(initialAvailableSipDate, "DD MMM YYYY"));

        if (Math.sign(secondaryDateDifference) === -1) {
          currentCalculatedDate = moment(currentCalculatedDate, "DD MMM YYYY")
            .add(3, "month")
            .format("DD MMM YYYY");

          setCalculatedSipStartDate(currentCalculatedDate);
          setSipStartDate(currentCalculatedDate);

          // setSipStartDate(
          //   moment(currentCalculatedDate, "DD MMM YYYY").toDate()
          // );
          calculateSipEndDateFromStartDate(
            moment(currentCalculatedDate, "DD MMM YYYY").toDate()
          );
        } else {
          setCalculatedSipStartDate(currentCalculatedDate);
          setSipStartDate(currentCalculatedDate);

          // setSipStartDate(
          //   moment(currentCalculatedDate, "DD MMM YYYY").toDate()
          // );
          calculateSipEndDateFromStartDate(
            moment(currentCalculatedDate, "DD MMM YYYY").toDate()
          );
        }
      } else if (Math.sign(initialMonthDifference) === 0) {
        if (Math.sign(initialDateDifference) === -1) {
          currentCalculatedDate = moment(currentCalculatedDate, "DD MMM YYYY")
            .add(3, "month")
            .format("DD MMM YYYY");
          setCalculatedSipStartDate(currentCalculatedDate);
          setSipStartDate(currentCalculatedDate);

          // setSipStartDate(
          //   moment(currentCalculatedDate, "DD MMM YYYY").toDate()
          // );
          calculateSipEndDateFromStartDate(
            moment(currentCalculatedDate, "DD MMM YYYY").toDate()
          );
        } else {
          setCalculatedSipStartDate(currentCalculatedDate);
          setSipStartDate(currentCalculatedDate);

          // setSipStartDate(
          //   moment(currentCalculatedDate, "DD MMM YYYY").toDate()
          // );
          calculateSipEndDateFromStartDate(
            moment(currentCalculatedDate, "DD MMM YYYY").toDate()
          );
        }
      }
    } else return;
  };
  const renderSipTypeView = () => {
    if (sipType === "Perpetual") {
      return (
        <Text type="mediumBold">
          {"Installments will continue until you cancel this SIP."}
        </Text>
      );
    } else if (sipType === "Installments") {
      return (
        <Box>
          <Input
            keyboardType="number-pad"
            placeholder={"No. of Installments"}
            onChange={(data: ChangeEvent<HTMLInputElement>) => {
              let temp: string = data.target.value;
              temp = temp.replace(/\s/g, "");
              temp = temp.replace(/^0+/, "");
              temp = temp.replace(/[- #*;,.<>\{\}\[\]\\\/]/g, "");

              sipFrequency === "Monthly"
                ? calculateSipEndDate(sipType, temp)
                : calculateQuarterlySipEndDate(sipType, temp);
              isNaN(parseInt(temp))
                ? setNumberOfInstallment("")
                : setNumberOfInstallment(temp);
            }}
            // error={
            //   formMethods.formState.errors.numberOfInstallment?.message ||
            //   getEndDateInputErrorMessage('installment')
            // }
          />
          {/* <Text type="mediumBold">
            {`Minimum number of installments : ${minimumNumberOfInstallments}`}
          </Text> */}
        </Box>
      );
    } else if (sipType === "Years") {
      return (
        <Box>
          <Input
            keyboardType="number-pad"
            placeholder={"No. of Years"}
            onChange={(data: ChangeEvent<HTMLInputElement>) => {
              let temp: string = data.target.value;
              temp = temp.replace(/\s/g, "");
              temp = temp.replace(/^0+/, "");
              temp = temp.replace(/[- #*;,.<>\{\}\[\]\\\/]/g, "");

              sipFrequency === "Monthly"
                ? calculateSipEndDate(sipType, temp)
                : calculateQuarterlySipEndDate(sipType, temp);
              isNaN(parseInt(temp))
                ? setNumberOfYears("")
                : setNumberOfYears(temp);
            }}
            // error={
            //   formMethods.formState.errors.numberOfYears?.message
            //   // ||
            //   // getEndDateInputErrorMessage('year')
            // }
          />
          {/* <Text type="dataGridTitle">{translate('minimum_years_message')}</Text> */}
        </Box>
      );
    } else return;
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          mt: isMobile() ? "30%" : "10%",
          mx: "5%",
          pb: "20px",
          width: isMobile() ? "90%" : "50%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton onClick={handleScreenCancel}>
            <CancelOutlinedIcon sx={{ color: colors.gray }} />
          </IconButton>
        </Box>
        <Box sx={{ px: "24px" }}>
          <Text type="subHeadingBold">{strings.editInvestmentDetails}</Text>
          <Box sx={{ my: "20px" }}>
            <Divider sx={{ backgroundColor: colors.backGroundBlue }} />
          </Box>

          <Grid container direction={"column"} rowSpacing={"24px"}>
            <Grid item>
              <Text type="dataLightMessage">{strings.FolioNo}</Text>
              <Text type="dataMediumBold">
                {transactionDetails.cartInitiateResponse.folioNumber}
              </Text>
            </Grid>
            {/* <Grid item>
              <Text type="dataLightMessage">{strings.Scheme}</Text>
              <Text type="dataMediumBold">
                {editTransactionInfo.contents["Scheme Name"]}
              </Text>
            </Grid> */}
          </Grid>

          <Box sx={{ my: "20px" }}>
            <Divider />
          </Box>
          {/* <ToggleButtonOptions
            options={investmentType}
            selectMultiple={false}
            setBorder={false}
            title={"Investment Option"}
            value={investmentOption}
            onClick={handleInvestmentOption}
          /> */}
          {/* <Select
            placeHolder="Select option"
            label="Investment Option"
            options={selectedSchemeOptions}
            onOptionSelect={(selected) => {
              const selectedObject = JSON.parse(selected.value);
              const optionDesc = selectedObject.optDesc;
              const optionCode = selectedObject.optionCode;
              setInvestmentOption(selected.value);
              
              
              
              setSelectedOptionCode(optionCode);
            }}
            value={investmentOption}
          /> */}
          {/* <ToggleOption
            options={investmentType}
            onClick={handleInvestmentOption}
          /> */}
          {/* <Box sx={{ mt: "24px" }}>
            <Text type="lightMessage">{strings.IDCW}</Text>
          </Box>
          <Box sx={{ mb: "24px", mt: "12px" }}>
            <Divider />
          </Box> */}
          <Text type="smallBold">{strings.investmentAmount}</Text>
          <Box sx={{ py: "12px" }}>
            <Input
              value={investmentAmount}
              onChange={handleChange}
              error={getAmountErrorMessage() || maxAmountError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {`Min. amt ₹${minAmount}`}
                  </InputAdornment>
                ),
              }}
            />
            <Text type="medium">
              {parseInt(investmentAmount) > 0
                ? toWords.convert(parseFloat(investmentAmount))
                : null}
            </Text>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ mb: "20px" }}>
              <ToggleButtonOptions
                // title="Investment Option"
                options={amountData}
                selectMultiple={false}
                setBorder={false}
                value={investmentAmount}
                onClick={handleInvestmentAmount}
              />
            </Box>
          </Box>
          {/* <Box sx={{ my: "20px" }}>
            <Divider />
          </Box>
          <ToggleButtonOptions
            title="SIP Frequency"
            options={sipFrequencyOptions}
            selectMultiple={false}
            setBorder={false}
            value={sipFrequency}
            onClick={(selected: string) => {
              setSipFrequency(selected);
              calculateInitialAvailableSipDate(selected);
            }}
          />
          <Box sx={{ my: "20px" }}>
            <Divider />
          </Box> */}
          {/* <Select
            // ref={dateOptionRef}
            disabled={!sipFrequency ? true : false}
            name="sipDate"
            options={sipFrequency ? dateOptions : []}
            placeholder={"Choose"}
            onOptionSelect={(selected) => {
              getSipDate(selected.value);
              setSipDate(selected.value);
            }}
            value={sipDate}
          />
          <Box sx={{ my: "20px" }}>
            <Divider />
          </Box>
          <Text type="mediumBold">{`Initial available date: ${initialAvailableSipDate}`}</Text>
          <Box sx={{ my: "20px" }}>
            <Divider />
          </Box> */}
          {/* <Select
            disabled={!sipDate ? true : false}
            name="sipType"
            options={sipFrequency ? sipTypeOptions : []}
            onOptionSelect={(selected) => {
              setSipType(selected.value);
              sipFrequency === "Monthly"
                ? calculateSipEndDate(selected.value, "0")
                : calculateQuarterlySipEndDate(selected.value, "0");
            }}
            value={sipType}
          /> */}

          {/* <Box sx={{ my: "20px" }}>
            <Divider />
          </Box>
          <Box style={{ marginTop: 12 }}>{renderSipTypeView()}</Box>
          <Text type="mediumBold">{`SIP start date: ${sipStartDate}`}</Text>

          <Text type="mediumBold">{`SIP end date: ${sipEndDate}`}</Text> */}

          {editTransactionError ? (
            <Text
              type="mediumBold"
              color="red"
              sx={{ marginTop: "10px", marginBottom: "15px" }}
            >
              {editTransactionError}
            </Text>
          ) : null}
          <ButtonIcon
            disabled={amountErrorFlag || Boolean(maxAmountError)}
            title={"Accept"}
            icon={true}
            onClick={handleAccept}
          />
        </Box>
      </Box>
      {isEditingTransaction && <ActivityOverlay />}
    </Box>
  );
};

export default SipEdit;
