//

import { all, takeLatest, call, put } from "redux-saga/effects";

import { TResponse } from "types";

import {
  loadSchemes,
  didLoadSchemes,
  loadEquitySchemes,
  didLoadSchemesForCategeory,
  loadHybridSchemes,
  loadFixedIncomeSchemes,
  loadFunOfFundSchemes,
  loadExchageTradeFundSchemes,
  loadNFOSchemes,
  didLoadInvestor,
  loadAllAndDormantInvestor,
  loadSipInvestor,
  loadLumpsumInvestor,
} from "./slice";
import Request from "networking/Request";
import _ from "lodash";
import { PayloadAction } from "@reduxjs/toolkit";

const SCHEMES_ENDPOINT = "v1/nontransaction/CategoryMaster";
const MYINVESTORS_ENDPOINT = "v1/nontransaction/InvestorList";

const uniqueItems = (data: any[]) => {
  const mapping: { [key: string]: any } = {};
  data.forEach((item: any) => (mapping[item.schemeDesc] = item));

  const schemes = [];
  for (let key in mapping) {
    schemes.push(mapping[key]);
  }
  return schemes;
};
const getUniqueOptions = (data: any[]) => {
  const mapping: { [key: string]: any } = {};
  data.forEach((item: any) => (mapping[item.schemeDesc] = item));

  const options: { [key: string]: any } = [];
  for (let key in mapping) {
    let tempArray: any[] = [];

    data.map((item: any) => {
      if (key === item.schemeDesc) {
        let optionObj = {
          schemeCode: item.schemeCode,
          schemeDesc: item.schemeDesc,
          fundDesc: item.planDesc + "-" + item.optDesc,
          planCode: item.planCode,
          planDesc: item.planDesc,
          optionCode: item.optionCode,
          optDesc: item.optDesc,
        };
        let tempObj = {
          title: item.optDesc + "-" + item.planDesc,
          value: JSON.stringify(optionObj),
        };
        tempArray.push(tempObj);
      }
    });
    const uniques = [
      ...Array.from(new Set(tempArray.map((s) => JSON.stringify(s)))),
    ].map((s) => JSON.parse(s));

    options[key] = uniques;
  }

  return options;
};

function* handleLoadAllAndDormantInvestors(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.POST,
    MYINVESTORS_ENDPOINT,
    action.payload
  );
  if (response.data) {
    yield put(
      didLoadInvestor({
        categeory: "all",
        data: response.data.data,
      })
    );
    yield put(
      didLoadInvestor({
        categeory: "dormantInvestor",
        data: response.data.data.filter(
          (item: any) => item.status === "InActive"
        ),
      })
    );
  } else {
  }
}
function* handleLoadSipInvestors(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.POST,
    MYINVESTORS_ENDPOINT,
    action.payload
  );
  if (response.data) {
    yield put(
      didLoadInvestor({
        categeory: "sip",
        data: response.data.data,
      })
    );
  } else {
  }
}
function* handleLoadLumpsumInvestors(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.POST,
    MYINVESTORS_ENDPOINT,
    action.payload
  );
  if (response.data) {
    yield put(
      didLoadInvestor({
        categeory: "lumpsum",
        data: response.data.data,
      })
    );
  } else {
  }
}
function* handleLoadEquitySchemes() {
  const params = {
    category: "Equity Schemes",
  };
  //

  const response: TResponse = yield call(
    Request.POST,
    SCHEMES_ENDPOINT,
    params
  );
  if (response.data) {
    //
    yield put(
      didLoadSchemesForCategeory({
        data: uniqueItems(response.data.data),
        schemeOptions: getUniqueOptions(response.data.data),
        categeory: "EQUITY",
      })
    );
  } else {
  }
}
function* handleLoadFixedIncomeSchemes() {
  const params = {
    category: "Debt Schemes",
  };
  //

  const response: TResponse = yield call(
    Request.POST,
    SCHEMES_ENDPOINT,
    params
  );
  if (response.data) {
    //
    yield put(
      didLoadSchemesForCategeory({
        data: uniqueItems(response.data.data),
        schemeOptions: getUniqueOptions(response.data.data),

        categeory: "FIXED INCOME",
      })
    );
  } else {
  }
}

function* handleLoadFunOfFundSchemes() {
  const params = {
    category: "Other Schemes",
  };

  const response: TResponse = yield call(
    Request.POST,
    SCHEMES_ENDPOINT,
    params
  );
  if (response.data) {
    //
    yield put(
      didLoadSchemesForCategeory({
        data: uniqueItems(response.data.data),
        schemeOptions: getUniqueOptions(response.data.data),

        categeory: "FUNDS OF FUND",
      })
    );
  } else {
  }
}

function* handleLoadExchageTradeFundSchemes() {
  const params = {
    category: "Other Schemes",
  };
  //

  const response: TResponse = yield call(
    Request.POST,
    SCHEMES_ENDPOINT,
    params
  );
  if (response.data) {
    //
    yield put(
      didLoadSchemesForCategeory({
        data: uniqueItems(response.data.data),
        schemeOptions: getUniqueOptions(response.data.data),

        categeory: "EXCHANGE TRADE FUND",
      })
    );
  } else {
  }
}
function* handleLoadNFOSchemes() {
  const params = {
    category: "NFO",
  };
  //

  const response: TResponse = yield call(
    Request.POST,
    SCHEMES_ENDPOINT,
    params
  );
  if (response.data) {
    yield put(
      didLoadSchemesForCategeory({
        data: uniqueItems(response.data.data),
        schemeOptions: getUniqueOptions(response.data.data),

        categeory: "NFO",
      })
    );
  }
}

function* handleLoadHybridSchemes() {
  const params = {
    category: "Hybrid Schemes",
  };
  //

  const response: TResponse = yield call(
    Request.POST,
    SCHEMES_ENDPOINT,
    params
  );
  if (response.data) {
    //
    yield put(
      didLoadSchemesForCategeory({
        data: uniqueItems(response.data.data),
        schemeOptions: getUniqueOptions(response.data.data),

        categeory: "HYBRID",
      })
    );
  }
}

function* handleLoadSchemes() {
  const params = {};

  const response: TResponse = yield call(
    Request.POST,
    SCHEMES_ENDPOINT,
    params
  );
  if (response.data) {
    const uniqueSchemes: any = {};

    for (let i = 0; i < response.data.data.length; i++) {
      const schemename = response.data.data[i].fundDesc.split("-")[0];
      if (!uniqueSchemes[schemename]) {
        uniqueSchemes[schemename] = {
          ...response.data.data[i],
          label: schemename,
          value: schemename,
        };
      }
    }

    const uniqueSchemesArray: any = [];
    for (var key in uniqueSchemes) {
      if (Object.prototype.hasOwnProperty.call(uniqueSchemes, key)) {
        uniqueSchemesArray.push(uniqueSchemes[key]);
      }
    }

    //

    yield put(
      didLoadSchemes({
        schemes: [],
        schemeDropdownList: uniqueSchemesArray,
      })
    );
  } else {
    const data = {
      schemes: [],
      schemeDropdownList: [{ label: "Scheme1", value: "Scheme1" }],
    };
    yield put(didLoadSchemes(data));
  }
}

function* watcherLoadInvestorAllAndDormant() {
  yield takeLatest(
    loadAllAndDormantInvestor.type,
    handleLoadAllAndDormantInvestors
  );
}
function* watcherLoadSipInvestor() {
  yield takeLatest(loadSipInvestor.type, handleLoadSipInvestors);
}
function* watcherLoadLumpSumInvestor() {
  yield takeLatest(loadLumpsumInvestor.type, handleLoadLumpsumInvestors);
}
function* watcherLoadSchemes() {
  yield takeLatest(loadSchemes.type, handleLoadSchemes);
}

function* watcherLoadEquitySchemes() {
  yield takeLatest(loadEquitySchemes.type, handleLoadEquitySchemes);
}

function* watcherLoadHybridSchemes() {
  yield takeLatest(loadHybridSchemes.type, handleLoadHybridSchemes);
}

function* watcherLoadFixedIncomeSchemes() {
  yield takeLatest(loadFixedIncomeSchemes.type, handleLoadFixedIncomeSchemes);
}
function* watcherLoadFunOfFundSchemes() {
  yield takeLatest(loadFunOfFundSchemes.type, handleLoadFunOfFundSchemes);
}

function* watcherLoadExchageTradeFundSchemes() {
  yield takeLatest(
    loadExchageTradeFundSchemes.type,
    handleLoadExchageTradeFundSchemes
  );
}
function* watcherLoadNFOSchemes() {
  yield takeLatest(loadNFOSchemes.type, handleLoadNFOSchemes);
}

export function* globalDataSaga() {
  yield all([
    watcherLoadHybridSchemes(),
    watcherLoadExchageTradeFundSchemes(),
    watcherLoadNFOSchemes(),
    watcherLoadFunOfFundSchemes(),
    watcherLoadEquitySchemes(),
    watcherLoadFixedIncomeSchemes(),
    watcherLoadSchemes(),
    watcherLoadInvestorAllAndDormant(),
    watcherLoadSipInvestor(),
    watcherLoadLumpSumInvestor(),
  ]);
}
