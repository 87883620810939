/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import strings from "res/strings";
import Intro from "components/Intro";
import { useAppDispatch } from "hooks/useAppDispatch";
import {
  setInvestorFlow,
  setTransactionType,
  setIHNo,
} from "store/appstate/slice";
import { TransactionType, InvestorFlow } from "utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import RouteConfig from "routes/RouteConfig";

const PauseSIP = () => {
  const navigate = useNavigate();
  const { ihNo } = useParams();
  const dispatch = useAppDispatch();
  const handleOtpSuccess = () => {
    navigate(RouteConfig.pauseSipStpSwpOtp);
  };

  useEffect(() => {
    dispatch(setInvestorFlow(InvestorFlow.transaction));
    dispatch(setTransactionType(TransactionType.pauseSip));
    dispatch(setIHNo(ihNo || ""));
  }, []);

  return (
    <Intro
      title="Transaction approval"
      message={strings.pauseSipPending}
      onSuccessfullyGeneratingOtp={handleOtpSuccess}
    />
  );
};
export default PauseSIP;
