export const convertSIPFrequency = (frequency: string) => {
  switch (frequency) {
    case "M":
      return "Monthly";
    case "W":
      return "Weekly";
    case "Y":
      return "Yearly";
    case "Q":
      return "Quarterly";
    case "D":
      return "Daily";
    case "P":
      return "Partial";
    case "F":
      return "Fixed";
    case "C":
      return "Capital Appreciation";
    case "X":
      return "Flex STP";
  }
  return frequency;
};
