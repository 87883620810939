import { Box, IconButton } from "@mui/material";
import Text from "components/Text";
import { FC, useEffect } from "react";
import SuccesImage from "res/images/success.png";

import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import colors from "res/colors";
import ButtonIcon from "components/ButtonIcon";
import { INVESCO_WEB_URL } from "utils/constants";

import { useAppDispatch } from "hooks/useAppDispatch";
import { resetConfirmTransactionStates } from "store/transaction/slice";

import { resetOtpStates } from "store/otp/slice";
import Reject from "components/Reject";
import isMobile from "utils/isMobile";
import { setTransactionStage } from "store/appstate/slice";

const RejectSuccess: FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setTransactionStage());
    dispatch(resetOtpStates());
    dispatch(resetConfirmTransactionStates());
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          mt: isMobile() ? "30%" : "10%",
          textAlign: "left",
          maxWidth: "640px",
          px: "10%",
        }}
      >
        {/* <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box>
          <IconButton>
            <DownloadForOfflineIcon
              sx={{ color: colors.red, fontSize: "32px" }}
            />
          </IconButton>
        </Box>

        <IconButton>
          <CancelRoundedIcon
            sx={{
              color: colors.gray,

              fontSize: "32px",
            }}
          />
        </IconButton>
      </Box>
      <Box mt="50px">
        <img src={SuccesImage} alt="Success" height={"140px"} width={"190px"} />
      </Box> */}
        <Reject
          rejectMessage={"You have successfully rejected the transaction."}
          cancel={false}
          download={false}
        />
        {/* <Box
        sx={{
          mt: "24px",
          mb: "12px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text type="successLargeBold" align="center">
          You have successfully rejected the transaction.
        </Text>
      </Box> */}
        <Box sx={{ mt: "53px", mx: "5%" }}>
          <ButtonIcon
            title={"Close"}
            icon={false}
            onClick={() => window.location.replace(INVESCO_WEB_URL)}
          ></ButtonIcon>
        </Box>

        {/* <Box>
        <Text type="smallTransparentCenter">{successMessage}</Text>
      </Box> */}
      </Box>
    </Box>
  );
};
export default RejectSuccess;
