import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Text from "components/Text";
import { FC, useState } from "react";
import colors from "res/colors";
import { ToggleButtonTypeProps } from ".";

const ToggleButtonOptions: FC<ToggleButtonTypeProps> = ({
  value,
  options,
  selectMultiple = false,
  setBorder = false,
  title,
  onClick,
}) => {
  const [selectedItem, setSelectedItem] = useState(value);
  const handleMouseClick = (
    event: React.MouseEvent<HTMLElement>,
    newInvestmentAmount: string
  ) => {
    setSelectedItem(newInvestmentAmount);

    onClick(newInvestmentAmount);
  };

  return (
    <Box>
      {title ? (
        <Box sx={{ pb: "12px" }}>
          <Text type="smallBold">{title}</Text>
        </Box>
      ) : null}
      <ToggleButtonGroup
        exclusive={!selectMultiple}
        onChange={handleMouseClick}
        value={value}
        sx={setBorder ? styles.styledBorderContainer : styles.borderContainer}
      >
        {options.map((item) => {
          return (
            <ToggleButton key={item.label} value={item.label}>
              {item.label}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Box>
  );
};

const styles = {
  borderContainer: {
    display: "flex",
    flexWrap: "wrap",
    "& .MuiToggleButtonGroup-grouped": {
      mr: "12px",
      mb: "8px",
      px: "16px",
      py: "8px",
      borderRadius: "24px",
      fontSize: "11px",
      lineHeight: "13px",
      fontFamily: "Graphik",
      borderWidth: 0,
      textTransform: "none",

      "&.Mui-selected": {
        backgroundColor: colors.backGroundBlue,
        color: colors.white,
      },
      "&:not(.Mui-selected)": {
        backgroundColor: colors.infoBackgroundBlue,
        color: colors.backGroundBlue,
      },
      "&:not(:first-of-type)": {
        borderRadius: "24px",
      },
      "&:first-of-type": {
        borderRadius: "24px",
      },
    },
  },
  styledBorderContainer: {
    display: "flex",
    flexWrap: "wrap",
    "& .MuiToggleButtonGroup-grouped": {
      mr: "12px",
      mb: "8px",
      px: "16px",
      py: "8px",
      borderRadius: "24px",
      fontSize: "11px",
      lineHeight: "13px",
      fontFamily: "Graphik",
      borderWidth: 0,
      textTransform: "none",
      "&.Mui-selected": {
        backgroundColor: colors.backGroundBlue,
        color: colors.white,
      },
      "&:not(.Mui-selected)": {
        backgroundColor: colors.white,
        color: colors.backGroundBlue,
      },
      "&:not(:first-of-type)": {
        borderRadius: "24px",
        borderLeftWidth: 1,
        borderWidth: 1,
        borderColor: colors.backGroundBlue,
      },
      "&:first-of-type": {
        borderRadius: "24px",
        borderWidth: 1,
        borderColor: colors.backGroundBlue,
      },
      "&.Mui-focusVisible": {
        backgroundColor: colors.backGroundBlue,
        color: colors.white,
      },
      "&.MuiButtonBase-root:hover": {
        backgroundColor: colors.backGroundBlue,
        color: colors.white,
      },
      "&:hover": {
        backgroundColor: colors.backGroundBlue,
        color: colors.white,
      },
    },
  },
} as const;
export default ToggleButtonOptions;
