import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { makeStyles } from "@mui/styles";
import { useAppSelector } from "hooks";
import colors from "res/colors";
import { ReactComponent as Invesco_MF_Logo } from "../../res/images/invescoMfLogo.svg";
import ProgressStepper from "components/ProgressStepper";
import {
  NonPaymentTransactionSteps,
  TransactionSteps,
  TransactionType,
} from "utils/constants";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";

const NavBar = () => {
  const { transactionType, transactionStage } = useAppSelector(
    (state) => state.appState
  );
  const location = useLocation();

  const classes = useStyles();
  return location?.pathname === "/privacy-policy" ? null : (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: colors.white,
        pl: 0,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        pt: "10px",
        pb: "20px",
      }}
    >
      <Toolbar className={classes.toolBarContainer}>
        <div className={classes.imageContainer}>
          <Invesco_MF_Logo />

          {transactionStage === 5 ? null : (
            <Box sx={{ mt: "20px" }}>
              <ProgressStepper
                current={transactionStage}
                options={
                  transactionType === TransactionType.sip ||
                  transactionType === TransactionType.lumpsum ||
                  transactionType === TransactionType.newSip ||
                  transactionType === TransactionType.newLumpsum
                    ? TransactionSteps
                    : NonPaymentTransactionSteps
                }
              />
            </Box>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};
const useStyles = makeStyles({
  appbar: {
    minHeight: "75px",
    justifyContent: "center",
    borderBottom: "1px solid",
    borderBottomColor: colors.lightGray,
    // marginBottom: '1rem',
  },
  toolBarContainer: {
    paddingLeft: "0px",
  },
  imageContainer: { flexGrow: 1 },
  imageStyle: {
    paddingLeft: "16px",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
});
export default NavBar;
