export enum KYCState {
  UnKnown = 1,
  VerifyingUrlPrams,
  InvalidUrlParams,
  ValidUrlParams,
}
export const switchModes = [
  { label: "All Amount" },
  { label: "Partial Amount" },
  { label: "All Units" },
  { label: "Partial Units" },
];

export const INVESCO_WEB_URL = "https://invescomutualfund.com/";
export const INVESCO_NPS_URL =
  "https://npci.qdegrees.com/site/client-url?j=OSlRI1gsHDKWiJjXnTahQ+Y+JQRjZS/QeBXFPQb0SIY=&vid=NPCI8";
export enum InvestorFlow {
  none = 0,
  transaction,
  ekyc,
}

export enum TransactionType {
  none = 0,
  lumpsum,
  sip,
  stp,
  swp,
  switch,
  redemption,
  newSip,
  newLumpsum,
  cancelSip,
  pauseSip,
  otm,
}
export const TransactionSteps = [
  { id: 0, title: "Verify Identity" },
  { id: 1, title: "Review" },
  { id: 2, title: "Payment Mode" },
];
export const NonPaymentTransactionSteps = [
  { id: 0, title: "Verify Identity" },
  { id: 1, title: "Review" },
];

export const getEditTransacation = (
  ihNumber: string,
  type: string,
  transactionDetails: { [key: string]: any },
  batchNumber: string
) => {
  const edit: { [key: string]: any } = { ...EDIT_TRANSACTION };
  edit.transactionType = type;
  edit.ihNo = batchNumber;
  edit.batchNo = batchNumber;
  return edit;
};
const EDIT_TRANSACTION = {
  transactionType: "",
  ihNo: "",
  batchNo: "",
  // editSchemeList: [
  //   {
  //     ihNo: "299296311",
  //     optionCode: "G",
  //     amount: "5000",
  //     sipStartDate: "",
  //     sipEndDate: "",
  //     sipFrequency: "",
  //     noOfInstallments: "",
  //     topUpAmount: "",
  //     topUpFrequency: "",
  //     topUpMaxYears: "",
  //     topUpMaxAmount: "",
  //   },
  // ],
  optionCode: "",
  toOptionCode: "",
  stpType: "",
  stpFrequency: "",
  startDate: "",
  endDate: "",
  amount: "",
  noOfInstallments: "",
  totalTransfer: "",
  swpType: "",
  swpFrequency: "",
  switchType: "",
  bankName: "",
  bankAccountNo: "",
  bankAccountType: "",
  bankIfsc: "",
  branchName: "",
  redemptionType: "",
  redemptionMode: "",
  applicableExitLoad: "",
  aorUFlag: "",
  units: "",
  tranMode: "",
  activity: "",
  pauseSIPStartDate: "",
  pauseSIPEndDate: "",
  noOfInstallmentsPaused: "",
  newUser: false,
  ap1Salutation: "",
  ap1LastName: "",
  ap1MobileNumber: "",
  ap1Dob: "",
  ap1NameOfGuardian: "",
  ap1Relationship: "",
  ap1Pan: "",
  ap1Nationality: "",
  ap1Status: "",
  ap1Occupation: "",
  ap1CountryOfBirth: "",
  ap1Address1: "",
  ap1Address2: "",
  ap1CountryOfResidence: "",
  ap1City: "",
  ap1State: "",
  ap1PinCode: "",
  ap1Email: "",
  ap1GrossAnnualIncome: "",
  ap1Networth: "",
  ap1ResidenceCategory: "",
  ap1PoliticallyExposedPerson: "",
  ap1RelatedToPEP: "",
  ap1TaxResidenceCountry: "",
  ap1TaxResidenceCountry2: "",
  ap1TaxResidenceCountry3: "",
  ap1TaxReferenceNumber: "",
  ap1TaxResidenceOutsideIndia: "",
  ap1TaxReferenceNumber2: "",
  ap1TaxReferenceNumber3: "",
  ap1CityOfBirth: "",
  ap2Salutation: "",
  ap2LastName: "",
  ap2MobileNumber: "",
  ap2Dob: "",
  ap2Pan: "",
  ap2Nationality: "",
  ap2Status: "",
  ap2Occupation: "",
  ap2CountryOfBirth: "",
  ap2CountryOfResidence: "",
  ap2Email: "",
  ap2GrossAnnualIncome: "",
  ap2Networth: "",
  ap2ResidenceCategory: "",
  ap2PoliticallyExposedPerson: "",
  ap2RelatedToPEP: "",
  ap2TaxResidenceOutsideIndia: "",
  ap2TaxResidenceCountry: "",
  ap2TaxResidenceCountry2: "",
  ap2TaxResidenceCountry3: "",
  ap2TaxReferenceNumber: "",
  ap2TaxReferenceNumber2: "",
  ap2TaxReferenceNumber3: "",
  ap2CityOfBirth: "",
  ap3Salutation: "",
  ap3LastName: "",
  ap3MobileNumber: "",
  ap3Dob: "",
  ap3Pan: "",
  ap3Nationality: "",
  ap3Status: "",
  ap3Occupation: "",
  ap3CountryOfBirth: "",
  ap3ResidenceCategory: "",
  ap3Email: "",
  ap3GrossAnnualIncome: "",
  ap3Networth: "",
  ap3CountryOfResidence: "",
  ap3PoliticallyExposedPerson: "",
  ap3RelatedToPEP: "",
  ap3TaxResidenceOutsideIndia: "",
  ap3TaxResidenceCountry: "",
  ap3TaxResidenceCountry2: "",
  ap3TaxResidenceCountry3: "",
  ap3TaxReferenceNumber: "",
  ap3TaxReferenceNumber2: "",
  ap3TaxReferenceNumber3: "",
  ap3CityOfBirth: "",
  modeOfHolding: "",
  nominee1Name: "",
  nominee1Relation: "",
  nominee1DOB: "",
  nominee1GuardianName: "",
  nominee1Address: "",
  nominee1Allocation: "",
  nominee1GuardianPan: "",
  nominee2Name: "",
  nominee2Relation: "",
  nominee2DOB: "",
  nominee2GuardianName: "",
  nominee2Address: "",
  nominee2Allocation: "",
  nominee2GuardianPan: "",
  nominee3Name: "",
  nominee3Relation: "",
  nominee3DOB: "",
  nominee3GuardianName: "",
  nominee3Address: "",
  nominee3Allocation: "",
  nominee3GuardianPan: "",
  payoutBankName: "",
  payoutBankAcNo: "",
  payoutAccountType: "",
  payoutIFSCCode: "",
  payoutMicrNo: "",
  payoutBranchName: "",
  payoutBankAddress: "",
  payoutCity: "",
  payoutPinCode: "",
  payMode: "",
  ap1Category: "",
  nriRepatriable: "",
  gender: "",
  guardianName: "",
  guardianPan: "",
  fatherName: "",
  idonotWishToNominee: "",
  gaurdianSalutation: "",
  gaurdianMobileNumber: "",
  gaurdianDOB: "",
  gaurdianRelationship: "",
  gaurdianPAN: "",
  gaurdianNationality: "",
  gaurdianStatus: "",
  gaurdianOccupation: "",
  gaurdianCountryofBirth: "",
  gaurdianAddress1: "",
  gaurdianAddress2: "",
  gaurdianAddress3: "",
  gaurdianCountryOfResidence: "",
  gaurdianCity: "",
  gaurdianState: "",
  gaurdianPinCode: "",
  gaurdianEmail: "",
  gaurdianGrossAnnualIncome: "",
  gaurdianNetworth: "",
  gaurdianPoliticallyExposedPerson: "",
  gaurdianRelatedToPEP: "",
  gaurdianTaxResidencyCategory: "",
  gaurdianTaxResidenceCountry: "",
  gaurdianTaxResidenceCountry2: "",
  gaurdianTaxResidenceCountry3: "",
  gaurdianTaxReferenceNumber: "",
  gaurdianTaxResidenceOutsideIndia: "",
  gaurdianCityOfBirth: "",
  isMinor: false,
  otmFrequency: "",
  otmPeriod: "",
  otmStartDate: "",
  otmAmountType: "",
  otmEndDate: "",
  otmAuthenticationMode: "",
  otmMicrCode: "",
  payoutRefNo: "",
};

export const ONLY_NUM_REG_EXP = /^\d*$/;
export const TWO_BYTES = 2097152;
