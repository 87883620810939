export const convertFrequency = (frequency: string) => {
  switch (frequency) {
    case "M":
      return "Monthly";
    case "W":
      return "Weekly";
    case "Q":
      return "Quarterly";
    case "D":
      return "Daily";
    case "F":
      return "Fortnightly";
  }
  return frequency;
};
