export const getDistributorDetails = (distributor: { [key: string]: any }) => {
  /*
 Distributor keys
  {
    EUIN: "";
    RIA: "";
    ARN: "";
  }*/
  return {
    arnCode: distributor.ARN,
    userId: distributor.ARN,
  };
};
