import { createSlice, PayloadAction } from "@reduxjs/toolkit";
type OtpState = {
  isGeneratingOtp: boolean;
  generateOtpError: string;
  didGenerateOtp: boolean;

  isVerifying: boolean;
  error: string;
  didVerifyOtp: boolean;

  userMakedInfo: any;

  isVerifyingApplicantOtp: boolean;
  applicantOtpError: string;
  didVerifyApplicantOtp: boolean;
};

const INITIAL_STATE: OtpState = {
  isGeneratingOtp: false,
  generateOtpError: "",
  didGenerateOtp: false,

  isVerifying: false,
  error: "",
  didVerifyOtp: false,
  userMakedInfo: {},

  isVerifyingApplicantOtp: false,
  applicantOtpError: "",
  didVerifyApplicantOtp: false,
};

const otp = createSlice({
  name: "otp",
  initialState: INITIAL_STATE,
  reducers: {
    generateOtp: (state, action: PayloadAction<any>) => {
      state.isGeneratingOtp = true;
      state.generateOtpError = "";
    },
    didGenerateOtp: (state, action: PayloadAction<any>) => {
      state.isGeneratingOtp = false;
      state.didGenerateOtp = true;
      state.userMakedInfo = action.payload;
    },
    didFailToGenerateOtp: (state, action: PayloadAction<any>) => {
      state.isGeneratingOtp = false;
      state.generateOtpError = action.payload;
    },
    setMaskedInfo: (state, action: PayloadAction<any>) => {
      state.userMakedInfo = action.payload;
    },
    verifyCartOtp: (state, _action: PayloadAction<any>) => {
      state.isVerifying = true;
    },
    verifyNewCartOtp: (state, _action: PayloadAction<any>) => {
      state.isVerifying = true;
    },
    verifyEKYCOtp: (state, _action: PayloadAction<any>) => {
      state.isVerifying = true;
    },
    verifyTransactionOtp: (state, _action: PayloadAction<any>) => {
      state.isVerifying = true;
    },
    veriftOtmOtp: (state, _action: PayloadAction<any>) => {
      state.isVerifying = true;
    },
    didVerifyOtp: (state) => {
      state.didVerifyOtp = true;
      state.isVerifying = false;
    },
    didFailToVerifyOtp: (state, action: PayloadAction<string>) => {
      state.isVerifying = false;
      state.error = "Invalid OTP. Please enter valid OTP.";
    },
    resendOtp: (_state, _action: PayloadAction<any>) => {},
    resetOtpStates: (state) => {
      state.isVerifying = false;
      state.error = "";
      state.didVerifyOtp = false;
      state.didGenerateOtp = false;
    },

    resetOtpError: (_state) => {
      _state.error = "";
    },

    verifyApplicantOtp: (_state, action: PayloadAction<any>) => {
      _state.isVerifyingApplicantOtp = true;
      _state.applicantOtpError = "";
      _state.didVerifyApplicantOtp = false;
    },
    didVerifyApplicantOtp: (_state) => {
      _state.isVerifyingApplicantOtp = false;
      _state.applicantOtpError = "";
      _state.didVerifyApplicantOtp = true;
    },
    didFailToVerifyApplicantOtp: (_state) => {
      _state.isVerifyingApplicantOtp = false;
      _state.applicantOtpError = "Invalid OTP. Please enter valid OTP.";
      _state.didVerifyApplicantOtp = false;
    },
    resetVerifyOtp: (_state) => {
      _state.applicantOtpError = "";
      _state.didVerifyApplicantOtp = false;
      _state.isVerifyingApplicantOtp = false;
    },
  },
});

export const {
  verifyNewCartOtp,
  generateOtp,
  didGenerateOtp,
  didFailToGenerateOtp,
  verifyCartOtp,
  verifyTransactionOtp,
  verifyEKYCOtp,
  veriftOtmOtp,
  setMaskedInfo,
  didVerifyOtp,
  didFailToVerifyOtp,
  resetOtpStates,
  resendOtp,
  resetOtpError,

  verifyApplicantOtp,
  didFailToVerifyApplicantOtp,
  didVerifyApplicantOtp,
  resetVerifyOtp,
} = otp.actions;
export default otp.reducer;
