import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import ActivityOverlay from "components/ActivityOverlay";
import OtpInput from "react-otp-input";
import OtpTimer from "components/OtpTimer";
import Text from "components/Text";
import colors from "res/colors";
import CloseIcon from "@mui/icons-material/Close";
export interface TwoFactorAuthenticationProps {
  otpDialogOpen: boolean;
  handleOtpDialogClose: () => void;
  isVerifyingApplicantOtp: boolean;
  maskedEmail: string;
  maskedMobile: string;
  otp: string;
  handleOTPChange: (event: string) => void;
  applicantOtpError: string;
  handleResendPressed: () => void;
}

const TwoFactorAuthentication: React.FC<TwoFactorAuthenticationProps> = ({
  otpDialogOpen = false,
  handleOtpDialogClose,
  isVerifyingApplicantOtp,
  maskedEmail,
  maskedMobile,
  otp,
  handleOTPChange,
  applicantOtpError,
  handleResendPressed,
}) => {
  const getSentence = () => {
    if (maskedEmail && maskedMobile) {
      return `mobile no. ${maskedMobile} and email ${maskedEmail}`;
    } else if (maskedEmail) {
      return `email ${maskedEmail}`;
    } else if (maskedMobile) {
      return `mobile no. ${maskedMobile}`;
    } else return "";
  };

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 310px)").matches
  );

  useEffect(() => {
    const handler = (e: any) => {
      setMatches(e.matches);
    };
    const mediaQuery = window.matchMedia("(min-width: 310px)");
    mediaQuery.addEventListener("change", handler);

    // Cleanup the event listener when the component is unmounted
    return () => {
      mediaQuery.removeEventListener("change", handler);
    };
  }, []);

  return (
    <Dialog
      open={otpDialogOpen}
      //  onClose={handleOtpDialogClose} //Block the user from clicking outside the box
    >
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Grid item xs={10}>
          <DialogTitle sx={{ marginLeft: "15px" }}>
            OTP Verification
          </DialogTitle>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={handleOtpDialogClose}>
            <CloseIcon sx={{ color: "red" }} />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        {isVerifyingApplicantOtp ? (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              top: 0,
            }}
          >
            <ActivityOverlay />
          </Box>
        ) : null}
        <Box
          sx={{
            mt: "30px",
            textAlign: "left",
            maxWidth: "640px",
            px: "16px",
            flexWrap: "wrap",
          }}
        >
          <Grid>
            <Text
              type="large"
              sx={{
                overflowWrap: "break-word",
                fontSize: "18px",
                letterSpacing: "0.16px",
                lineHeight: "22px",
                fontFamily: "Graphik",
              }}
            >
              {`We have sent the 5 digit OTP to your registered ${getSentence()}`}
            </Text>
          </Grid>
          <Grid>
            <Box sx={{ mt: "64px" }}>
              <OtpInput
                value={otp}
                numInputs={5}
                isInputNum={true}
                containerStyle={{
                  height: "36px",
                  marginTop: "35px",
                  marginBottom: "15px",
                  width: "100%",
                }}
                separator={<span style={{ paddingLeft: "20px" }}></span>}
                inputStyle={{
                  borderTopWidth: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 0,
                  fontSize: "20px",
                  width: matches ? "20px" : "15px",
                }}
                focusStyle={{ outline: "none" }}
                onChange={handleOTPChange}
                shouldAutoFocus
              />
            </Box>
          </Grid>
          {applicantOtpError ? (
            <Text type="dataSmallBold" color={colors.radicalRed}>
              {applicantOtpError}
            </Text>
          ) : null}
          <Grid sx={{ mb: "60px", mt: "20px" }}>
            <OtpTimer onResend={handleResendPressed} />
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TwoFactorAuthentication;
