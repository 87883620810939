import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type GlobalDataState = {
  schemes: Array<any>;
  schemeDropdownList: Array<any>;
  schemesCategeory: Array<{ [key: string]: string }>;
  allSchemes: { [key: string]: Array<any> };
  schemeOptions: { [key: string]: any };
  isLoadingGlobalInvestorList: boolean;
  globalInvestorList: { [key: string]: Array<any> };
  globalInvestorsListError: { [key: string]: string };
};

const INITIAL_STATE: GlobalDataState = {
  schemes: [],
  schemeDropdownList: [],
  schemesCategeory: [
    { label: "Equity", value: "EQUITY" },
    { label: "Debt", value: "FIXED INCOME" },
    { label: "Hybrid", value: "HYBRID" },
    { label: "FoF", value: "FUNDS OF FUND" },
    { label: "NFO", value: "NFO" },
  ],
  allSchemes: {
    EQUITY: [],
    HYBRID: [],
    "FIXED INCOME": [],
    "FUNDS OF FUND": [],
    "EXCHANGE TRADE FUND": [],
    NFO: [],
  },
  schemeOptions: {
    EQUITY: [],
    HYBRID: [],
    "FIXED INCOME": [],
    "FUNDS OF FUND": [],
    "EXCHANGE TRADE FUND": [],
    NFO: [],
  },
  globalInvestorList: {
    all: [],
    lumpsum: [],
    sip: [],
    dormantInvestor: [],
  },
  globalInvestorsListError: {
    all: "",
    lumpsum: "",
    sip: "",
    dormantInvestor: "",
  },
  isLoadingGlobalInvestorList: false,
};

const globalData = createSlice({
  name: "globalData",
  initialState: INITIAL_STATE,
  reducers: {
    loadEquitySchemes: (_state) => {},
    loadHybridSchemes: (_state) => {},
    loadFixedIncomeSchemes: (_state) => {},
    loadFunOfFundSchemes: (_state) => {},
    loadExchageTradeFundSchemes: (_state) => {},
    loadNFOSchemes: (_state) => {},
    didLoadSchemesForCategeory: (
      state,
      action: PayloadAction<{
        data: Array<any>;
        schemeOptions: { [key: string]: any };
        categeory: string;
      }>
    ) => {
      state.schemeOptions[action.payload.categeory] =
        action.payload.schemeOptions;
      state.allSchemes[action.payload.categeory] = action.payload.data.map(
        (item: any) => ({
          ...item,
          label: item.schemeDesc,
          value: JSON.stringify(item),
        })
      );
    },

    loadSchemes: (_state) => {},
    didLoadSchemes: (
      state,
      action: PayloadAction<{
        schemes: Array<any>;
        schemeDropdownList: Array<any>;
      }>
    ) => {
      state.schemes = action.payload.schemes;
      state.schemeDropdownList = action.payload.schemeDropdownList;
    },

    loadAllAndDormantInvestor: (state, _action: PayloadAction<any>) => {
      state.isLoadingGlobalInvestorList = true;
    },
    loadLumpsumInvestor: (state, _action: PayloadAction<any>) => {
      state.isLoadingGlobalInvestorList = true;
    },
    loadSipInvestor: (state, _action: PayloadAction<any>) => {
      state.isLoadingGlobalInvestorList = true;
    },
    didLoadInvestor: (
      _state,
      action: PayloadAction<{
        data: Array<any>;
        categeory: string;
      }>
    ) => {
      _state.globalInvestorList[action.payload.categeory] = action.payload.data;
      _state.isLoadingGlobalInvestorList = false;
    },
    didFailToLoadInvestor: (
      _state,
      action: PayloadAction<{
        error: string;
        categeory: string;
      }>
    ) => {
      _state.globalInvestorsListError[action.payload.categeory] =
        action.payload.error;
      _state.isLoadingGlobalInvestorList = false;
    },
  },
});

export const {
  loadSchemes,
  didLoadSchemes,
  loadEquitySchemes,
  didLoadSchemesForCategeory,
  loadHybridSchemes,
  loadFixedIncomeSchemes,
  loadFunOfFundSchemes,
  loadExchageTradeFundSchemes,
  loadNFOSchemes,
  loadAllAndDormantInvestor,
  loadLumpsumInvestor,
  loadSipInvestor,
  didLoadInvestor,
  didFailToLoadInvestor,
} = globalData.actions;
export default globalData.reducer;
