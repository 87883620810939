import { useEffect, useMemo } from "react";

import {
  confirmTransaction,
  loadFoliowiseSchemeData,
  rejectTransaction,
  resetConfirmTransactionStates,
  setEditTransactionInfo,
} from "store/transaction/slice";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import ActivityOverlay from "components/ActivityOverlay";
import RouteConfig from "routes/RouteConfig";
import TransactionDetails from "components/TransactionDetails/TransactionDetails";
import { TransactionSteps } from "utils/constants";

import { getDistributorDetails } from "utils/getDistributorDetails";
import { getSwitchRedemptionType } from "utils/getSwitchRedemptionType";

const SwitchTransactionDetails = () => {
  // setHeaderDeviceId(
  //   // Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
  //   1025504030526
  // );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    transactionDetails,
    isConfirmingTransaction,
    didConfirmTransaction,
    confirmTransactionError,
    transactionDistributor,
    isLoadingTransactionSchemesDetails,
    transactionSchemeDetails,
    isRejectingTransaction,
    didRejectTransaction,
    rejectTransactionError,
    didLoadFoliowiseScheme,
    isLoadingFoliowiseSchemeData,
  } = useAppSelector((state) => state.transaction);
  const { ihNo } = useAppSelector((state) => state.appState);
  //

  useEffect(() => {
    if (didConfirmTransaction) {
      dispatch(resetConfirmTransactionStates());
      navigate(RouteConfig.switchSuccess);
    }
  }, [didConfirmTransaction]);

  useEffect(() => {
    if (didRejectTransaction) {
      // dispatch(resetOtpStates());
      // dispatch(resetConfirmTransactionStates());
      navigate(RouteConfig.switchTransactionReject);
    }
  }, [didRejectTransaction]);

  useEffect(() => {
    if (didLoadFoliowiseScheme) {
      navigate(RouteConfig.switchEdit);
    }
  }, [didLoadFoliowiseScheme]);
  const transactions = useMemo(() => {
    if (
      !transactionDetails ||
      !transactionDetails.fetchTransactionDetails ||
      isLoadingTransactionSchemesDetails
    ) {
      return [];
    }

    const data = [];
    data.push({
      title: "Switch From",
      canEdit: false,
      contents: {
        Scheme: transactionSchemeDetails.length
          ? transactionSchemeDetails[0].fundDesc
          : "",
        Option: transactionSchemeDetails.length
          ? `${transactionSchemeDetails[0]?.optDesc} - ${transactionSchemeDetails[0]?.planDesc}`
          : "",
      },
    });

    data.push({
      title: "Switch To",
      canEdit: false,
      contents: {
        Scheme:
          transactionSchemeDetails.length > 1
            ? transactionSchemeDetails[1].fundDesc
            : "",
        Option:
          transactionSchemeDetails.length > 1
            ? `${transactionSchemeDetails[1].optDesc} - ${transactionSchemeDetails[1]?.planDesc}`
            : "",
      },
    });

    const details = {
      title: "Switch Details",
      canEdit: true,
      contents:
        transactionDetails.fetchTransactionDetails.aorUFlag !== "A"
          ? {
              "Switch Unit": transactionDetails.fetchTransactionDetails.units,
              "Switch Type": getSwitchRedemptionType(
                transactionDetails.fetchTransactionDetails.aorUFlag
              ),
            }
          : {
              "Switch Amount": parseInt(
                transactionDetails.fetchTransactionDetails.amount
              ).toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
                // minimumFractionDigits: 0,
                // maximumFractionDigits: 0,
              }),
              "Switch Type": getSwitchRedemptionType(
                transactionDetails.fetchTransactionDetails.aorUFlag
              ),
            },
    };

    data.push(details);

    return data;
  }, [
    transactionDetails,
    transactionSchemeDetails,
    isLoadingTransactionSchemesDetails,
  ]);

  const handleTransactionApprove = () => {
    dispatch(
      confirmTransaction({
        investorPan: transactionDetails.fetchTransactionDetails.investorPan,
        folioNo: transactionDetails.fetchTransactionDetails.folioNumber,
        batchNo: transactionDetails.fetchTransactionDetails.batchNo,
        ihNo: transactionDetails.fetchTransactionDetails.ihno,
        txnType: "SWITCH",
      })
    );
  };

  const handleTransactionReject = () => {
    const distributor = getDistributorDetails(transactionDistributor);
    dispatch(
      rejectTransaction({
        batchNo: transactionDetails.fetchTransactionDetails.batchNo,
        pan: transactionDetails.fetchTransactionDetails.investorPan,
        ...distributor,
        rejectReason: "Not Intrested",
      })
    );
  };

  const handleEditTransaction = (data: any, _type: string | undefined) => {
    dispatch(setEditTransactionInfo(data));
    dispatch(
      loadFoliowiseSchemeData({
        arnCode: transactionDetails.fetchTransactionDetails.brokerCode,
        userId: transactionDetails.fetchTransactionDetails.brokerCode,
        pan: transactionDetails.fetchTransactionDetails.investorPan,
        investorRequestFlag: true,
      })
    );
  };

  //
  return (
    <>
      {!isLoadingTransactionSchemesDetails ? (
        <TransactionDetails
          stepper={{ currentIndex: 0, steps: TransactionSteps }}
          error={confirmTransactionError || rejectTransactionError}
          transactions={transactions}
          onApproveTranaction={handleTransactionApprove}
          onEditTransaction={handleEditTransaction}
          onRejectTranaction={handleTransactionReject}
        />
      ) : null}
      {(isConfirmingTransaction ||
        isLoadingTransactionSchemesDetails ||
        isRejectingTransaction) && <ActivityOverlay />}
      {isLoadingFoliowiseSchemeData && <ActivityOverlay />}
    </>
  );
};
export default SwitchTransactionDetails;
