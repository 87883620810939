import { ChangeEvent, useEffect, useMemo, useState } from "react";

import {
  confirmCancelTransaction,
  confirmTransaction,
  rejectTransaction,
  resetConfirmTransactionStates,
} from "store/transaction/slice";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import ActivityOverlay from "components/ActivityOverlay";
import RouteConfig from "routes/RouteConfig";
import TransactionDetails from "components/TransactionDetails/TransactionDetails";

import { TransactionSteps } from "utils/constants";
import { getDistributorDetails } from "utils/getDistributorDetails";
import moment from "moment";
import Select from "components/Select";
import { SelectOption } from "components/FormSelect";
import Input from "components/Input";

const CANCEL_REASONS = [
  {
    title: "Non availability of funds",
    value: "Non availability of funds",
  },
  {
    title: "Scheme not performing",
    value: "Scheme not performing",
  },
  {
    title: "Service issue",
    value: "Service issue",
  },
  {
    title: "Load revised",
    value: "Load revised",
  },
  {
    title: "Wish to invest in other scheme",
    value: "Wish to invest in other scheme",
  },
  {
    title: "Change in Fund Manager",
    value: "Change in Fund Manager",
  },
  {
    title: "Goal achieved",
    value: "Goal achieved",
  },
  {
    title: "Others, Please specify",
    value: "Others",
  },
];

const CancelSIPTransactionDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    transactionDetails,
    transactionDistributor,
    isConfirmingTransaction,
    didConfirmTransaction,
    confirmTransactionError,
    transactionSchemeDetails,
    isLoadingTransactionSchemesDetails,
    isRejectingTransaction,
    didRejectTransaction,
    rejectTransactionError,
  } = useAppSelector((state) => state.transaction);
  // const alphanumericRegex = /^[a-zA-Z0-9]+$/;
  const alphanumericRegex = /^[A-Za-z0-9.,][A-Za-z0-9.,\s]*$/
  const { ihNo } = useAppSelector((state) => state.appState);
  const [selectedReason, setSelectedReason] = useState("");
  const [enteredReason, setEnteredReason] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (didConfirmTransaction) {
      dispatch(resetConfirmTransactionStates());
      if (
        transactionDetails?.fetchTransactionDetails?.transactionType === "SIPC"
      ) {
        navigate(RouteConfig.cancelSipStpSwpSuccess);
      } else if (
        transactionDetails?.fetchTransactionDetails?.transactionType === "SWPC"
      ) {
        navigate(RouteConfig.cancelSwpSuccess);
      } else if (
        transactionDetails?.fetchTransactionDetails?.transactionType === "STPC"
      ) {
        navigate(RouteConfig.cancelStpSuccess);
      }
    }
  }, [didConfirmTransaction]);

  useEffect(() => {
    if (didRejectTransaction) {
      navigate(RouteConfig.cancelSipStpSwpTransactionReject);
    }
  }, [didRejectTransaction]);

  const handleSelectedReason = (option: SelectOption) => {
    if (option?.value === "Others") {
      setIsError(true);
    } else {
      setIsError(false);
      setEnteredReason("");
    }
    setSelectedReason(option?.value);
  };
  const handleEnteredReason = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value && !alphanumericRegex.test(event?.target?.value)){
      return;
    }
    if (event?.target?.value) {
      setIsError(false);
    } else {
      setIsError(true);
    }
    setEnteredReason(event?.target?.value);
  };

  const transactions = useMemo(() => {
    if (
      !transactionDetails ||
      !transactionDetails.fetchTransactionDetails ||
      isLoadingTransactionSchemesDetails
    ) {
      return [];
    }

    const data: Array<any> = [];
    if (
      transactionDetails?.fetchTransactionDetails?.transactionType === "SIPC"
    ) {
      data.push({
        title: "SIP Cancellation Details",
        canEdit: false,
        type: "SIP_CANCEL",
        contents: {
          Scheme: transactionSchemeDetails.length
            ? transactionSchemeDetails[0].fundDesc
            : "",
          Option: transactionSchemeDetails.length
            ? `${transactionSchemeDetails[0]?.optDesc} - ${transactionSchemeDetails[0]?.planDesc}`
            : "",

          "Transaction Type": "Cancel SIP",
          Frequency: transactionDetails.fetchTransactionDetails.frequency
            ? transactionDetails.fetchTransactionDetails.frequency
            : "",

          "SIP cancel effective date": transactionDetails
            .fetchTransactionDetails.sipCancelEffectiveDate
            ? moment(
                transactionDetails.fetchTransactionDetails
                  .sipCancelEffectiveDate
              ).format("DD-MMM-YYYY")
            : "",

          "Reason for Cancellation": (
            <>
              <Select
                placeHolder="Choose"
                value={selectedReason}
                onOptionSelect={handleSelectedReason}
                options={CANCEL_REASONS}
                sx={{ width: "95%", height: "40px" }}
              />
              {selectedReason === "Others" && (
                <Input
                  value={enteredReason}
                  size={"small"}
                  placeholder={"Enter reason"}
                  onChange={handleEnteredReason}
                  multiline
                  sx={{ mt: "10px", width: "95%" }}
                  inputProps={{ maxLength: 93 }} //For reason field max 100 char. we need to pass "Others-entered reasons". so "Others-" 7 letter minus
                  focused
                  error={isError ? "Please enter valid reason" : ""}
                />
              )}
            </>
          ),
        },
      });
    } else if (
      transactionDetails?.fetchTransactionDetails?.transactionType === "SWPC"
    ) {
      data.push({
        title: "SWP Cancellation Details",
        canEdit: false,
        type: "SWP_CANCEL",
        contents: {
          Scheme: transactionSchemeDetails.length
            ? transactionSchemeDetails[0].fundDesc
            : "",
          Option: transactionSchemeDetails.length
            ? `${transactionSchemeDetails[0]?.optDesc} - ${transactionSchemeDetails[0]?.planDesc}`
            : "",

          "Transaction Type": "Cancel SWP",
          Frequency: transactionDetails.fetchTransactionDetails.frequency
            ? transactionDetails.fetchTransactionDetails.frequency
            : "",

          "SWP cancel effective date": transactionDetails
            .fetchTransactionDetails.sipCancelEffectiveDate
            ? moment(
                transactionDetails.fetchTransactionDetails
                  .sipCancelEffectiveDate
              ).format("DD-MMM-YYYY")
            : "",
        },
      });
    } else if (
      transactionDetails?.fetchTransactionDetails?.transactionType === "STPC"
    ) {
      data.push({
        title: "STP Cancellation Details",
        canEdit: false,
        type: "STP_CANCEL",
        contents: {
          Scheme: transactionSchemeDetails.length
            ? transactionSchemeDetails[0].fundDesc
            : "",
          Option: transactionSchemeDetails.length
            ? `${transactionSchemeDetails[0]?.optDesc} - ${transactionSchemeDetails[0]?.planDesc}`
            : "",

          "Transaction Type": "Cancel STP",
          Frequency: transactionDetails.fetchTransactionDetails.frequency
            ? transactionDetails.fetchTransactionDetails.frequency
            : "",

          "STP cancel effective date": transactionDetails
            .fetchTransactionDetails.sipCancelEffectiveDate
            ? moment(
                transactionDetails.fetchTransactionDetails
                  .sipCancelEffectiveDate
              ).format("DD-MMM-YYYY")
            : "",
        },
      });
    }

    return data;
  }, [
    transactionDetails,
    transactionSchemeDetails,
    isLoadingTransactionSchemesDetails,
    selectedReason,
    enteredReason,
  ]);

  const handleTransactionApprove = () => {
    let reason =
      selectedReason === "Others" ? `Others-${enteredReason}` : selectedReason;
    const params = {
      pan: transactionDetails.fetchTransactionDetails.investorPan,
      folio: transactionDetails.fetchTransactionDetails.folioNumber,
      cancelTxnReferenceNo: transactionDetails.fetchTransactionDetails.ihno,
      userId: transactionDetails.fetchTransactionDetails.brokerCode,
      arnCode: transactionDetails.fetchTransactionDetails.brokerCode,
      cancelReason: reason || "",
    };
    dispatch(confirmCancelTransaction(params));
  };

  const handleTransactionReject = () => {
    const distributor = getDistributorDetails(transactionDistributor);
    dispatch(
      rejectTransaction({
        batchNo: transactionDetails.fetchTransactionDetails.batchNo,
        pan: transactionDetails.fetchTransactionDetails.investorPan,
        ...distributor,
        rejectReason: "Not Intrested",
      })
    );
  };

  const handleEditTransaction = (data: any, _type: string | undefined) => {};

  return (
    <>
      {!isLoadingTransactionSchemesDetails ? (
        <TransactionDetails
          stepper={{ currentIndex: 0, steps: TransactionSteps }}
          error={confirmTransactionError || rejectTransactionError}
          transactions={transactions}
          onApproveTranaction={handleTransactionApprove}
          onEditTransaction={handleEditTransaction}
          onRejectTranaction={handleTransactionReject}
          showReasonError={transactionDetails?.fetchTransactionDetails?.transactionType === "SIPC"? (!Boolean(selectedReason) || isError): false}
        />
      ) : null}
      {(isConfirmingTransaction ||
        isLoadingTransactionSchemesDetails ||
        isRejectingTransaction) && <ActivityOverlay />}
    </>
  );
};
export default CancelSIPTransactionDetails;
