import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from "react";
import { useDropzone } from "react-dropzone";
import { ImageDropzonProps, ImageDropzonHandle } from "./types";
import UploadOrDrag from "components/UploadOrDrag";
import colors from "res/colors";
import Text from "components/Text";
import { Box } from "@mui/system";

const baseStyle = {
  display: "flex",
  // flexDirection: "column",
  // alignItems: "center",
  // padding: "20px",
  borderWidth: 2,
  // borderRadius: 2,
  borderRadius: "10px",
  // borderColor: "#eeeeee",
  borderStyle: "dashed",
  borderColor: colors.uploadBoxBorderColor,
  backgroundColor: colors.uploadBoxColor,
  color: "#bdbdbd",
  transition: "border .3s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const ImageDropZone: ForwardRefRenderFunction<
  ImageDropzonHandle,
  ImageDropzonProps
> = (
  {
    heading = "",
    onDropped,
    defaultImage = "",
    icon,
    type,
    error = "",
    required = false,
    resolution = "",
    showText = true,
  },
  ref
) => {
  const [files, setFiles] = useState<
    Array<{ url: string; name: string; size?: number }>
  >([]);

  useImperativeHandle(ref, () => ({
    getImage() {
      if (files.length < 1) {
        return null;
      }
      return files[0];
    },
    clearImage() {
      setFiles([]);
    },
  }));

  const onDrop = useCallback((acceptedFiles) => {
    const _imgData = acceptedFiles.map((file: any) => {
      return Object.assign(file, {
        url: URL.createObjectURL(file),
      });
    });
    setFiles(_imgData);
    if (onDropped != undefined && _imgData.length > 0) {
      onDropped(_imgData[0]);
    }
  }, []);

  let accept = "image/jpeg, image/png";
  if (type === "Video") accept = "video/*";
  else if (type === "PDF") accept = ".pdf";
  else if (type === "Excel")
    accept =
      "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: accept,
    maxFiles: 1,
    // maxSize:10000
  });

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  // clean up
  useEffect(
    () => () => {
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const renderThumbs = () => {
    if (files.length > 0)
      return files.map((file: any) => (
        <div key={file.name}>
          <Box sx={{ pb: "16px", mt: "10px" }}>
            <Text type="mediumBold">Preview</Text>
          </Box>
          <img
            src={file.url}
            style={{ width: "150px", height: "150px" }}
            alt={file.name}
          />
        </div>
      ));
    if (defaultImage)
      return (
        <div>
          <Box sx={{ pb: "16px", mt: "10px" }}></Box>
          <Text type="mediumBold">Preview</Text>
          <img
            src={defaultImage}
            style={{ width: "150px", height: "150px" }}
            alt={"default"}
          />
        </div>
      );
    return null;
  };
  //{/* <Box w="275px" h="192px" bgColor="#f1f1f1" /> */}
  return (
    <section>
      <Box
        sx={{
          pb: "16px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Text type="mediumBold">{heading}</Text>
        {required ? (
          <Text
            type="mediumBold"
            sx={{
              fontWeight: 600,
              fontSize: "16px",
              letterSpacing: "0em",
              lineHeight: "20px",
              fontFamily: "Graphik",
              color: colors.radicalRed,
            }}
          >
            *
          </Text>
        ) : null}
      </Box>
      <div {...getRootProps({ style })}>
        <UploadOrDrag
          icon={icon}
          type={type}
          resolution={resolution ? resolution : ""}
          showText={showText}
        />
        <input {...getInputProps()} />
        {/* <div>Drag and drop your images here.</div> */}
      </div>
      <aside>{renderThumbs()}</aside>
      <Text type="error">{error}</Text>
    </section>
  );
};
export default React.forwardRef(ImageDropZone);
