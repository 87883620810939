import {
  configureStore,
  combineReducers,
  AnyAction,
  Reducer,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";

import otpReducer from "./otp/slice";

import appStateReducer from "./appstate/slice";
import transactionReducer from "./transaction/slice";
import ekycRecuder from "pages/ekyc/store/slice";
import accountUnlockReducer from "pages/mfd-unlock-success/store/slice";
import globalDataReducer from "./global-data/slice";

import { otpSaga } from "./otp/saga";
import { transactionSaga } from "./transaction/saga";
import { accountUnlockSaga } from "pages/mfd-unlock-success/store/store";
import { globalDataSaga } from "./global-data/saga";

const sagaMiddleware = createSagaMiddleware();

const combinedReducer = combineReducers({
  otp: otpReducer,
  appState: appStateReducer,
  transaction: transactionReducer,
  ekyc: ekycRecuder,
  accountUnlock: accountUnlockReducer,
  globalData: globalDataReducer,
});

export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "user/logout") {
    // setHeaderRenewToken('')
    // setHeaderAccessToken('')
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(
      sagaMiddleware
    ),
});

function* rootSaga() {
  yield all([
    otpSaga(),
    transactionSaga(),
    accountUnlockSaga(),
    globalDataSaga(),
  ]);
}
sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
