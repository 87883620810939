import { useEffect, useMemo } from "react";

import TransactionDetails from "components/TransactionDetails/TransactionDetails";
import {
  confirmTransaction,
  rejectTransaction,
  resetConfirmTransactionStates,
  setEditTransactionInfo,
} from "store/transaction/slice";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import ActivityOverlay from "components/ActivityOverlay";
import RouteConfig from "routes/RouteConfig";
import { TransactionSteps } from "utils/constants";
import { convertSIPFrequency } from "utils/convertSIPFrequency";
import moment from "moment";
import { getDistributorDetails } from "utils/getDistributorDetails";
import { convertFrequency } from "utils/getFrequencyTypes";
import _ from "lodash";

const STPTransactionDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    transactionDetails,
    transactionDistributor,
    isConfirmingTransaction,
    didConfirmTransaction,
    confirmTransactionError,
    isLoadingTransactionSchemesDetails,
    transactionSchemeDetails,
    isRejectingTransaction,
    didRejectTransaction,
    rejectTransactionError,
  } = useAppSelector((state) => state.transaction);

  useEffect(() => {
    if (didConfirmTransaction) {
      dispatch(resetConfirmTransactionStates());
      navigate(RouteConfig.stpSuccess);
    }
  }, [didConfirmTransaction]);

  useEffect(() => {
    if (didRejectTransaction) {
      // dispatch(resetOtpStates());
      // dispatch(resetConfirmTransactionStates());
      navigate(RouteConfig.stpTransactionReject);
    }
  }, [didRejectTransaction]);
  const getStpAmount = () => {
    if (transactionDetails.fetchTransactionDetails.stpType === "C") {
      return {
        "No. of Installments":
          transactionDetails.fetchTransactionDetails.numberOfInstallments,
      };
    } else {
      return {
        "STP Installment Amount": parseInt(
          transactionDetails.fetchTransactionDetails.amount
        ).toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
          // minimumFractionDigits: 0,
          // maximumFractionDigits: 0,
        }),
        "No. of Installments":
          transactionDetails.fetchTransactionDetails.numberOfInstallments,
        "Total Transfer": (
          parseInt(transactionDetails.fetchTransactionDetails.amount) *
          parseInt(
            transactionDetails.fetchTransactionDetails.numberOfInstallments
          )
        ).toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
          // minimumFractionDigits: 0,
          // maximumFractionDigits: 0,
        }),
      };
    }
  };
  const transactions = useMemo(() => {
    if (
      !transactionDetails ||
      !transactionDetails.fetchTransactionDetails ||
      isLoadingTransactionSchemesDetails
    ) {
      return [];
    }
    const data = [];
    data.push({
      title: "Source Scheme",
      titleExtraMessage: "(Transfer From)",
      canEdit: false,
      contents: {
        Scheme: transactionSchemeDetails.length
          ? transactionSchemeDetails[0].fundDesc
          : "",
        Option: transactionSchemeDetails.length
          ? `${transactionSchemeDetails[0]?.optDesc} - ${transactionSchemeDetails[0]?.planDesc}`
          : "",
      },
    });

    data.push({
      title: "Target Scheme",
      titleExtraMessage: "(Transfer To)",
      canEdit: false,
      type: "targetScheme",
      contents: {
        Scheme:
          transactionSchemeDetails.length > 1
            ? transactionSchemeDetails[1].fundDesc
            : "",
        Option:
          transactionSchemeDetails.length > 1
            ? `${_.startCase(
                _.toLower(transactionSchemeDetails[1].optDesc)
              )} - ${transactionSchemeDetails[1]?.planDesc}`
            : "",
      },
    });

    data.push({
      title: "Systematic Transfer Plan Details",
      canEdit:
        transactionDetails.fetchTransactionDetails.stpType === "C"
          ? false
          : true,
      type: "stpDetails",
      index: 0,
      contents: {
        "STP Type": convertSIPFrequency(
          transactionDetails.fetchTransactionDetails.stpType
        ),
        "STP Frequency": convertFrequency(
          transactionDetails.fetchTransactionDetails.frequency
        ), //convertSIPFrequency(transactionDetails.fetchTransactionDetails.frequency)

        "STP Start Date": moment(
          transactionDetails.fetchTransactionDetails.startDate
        ).format("DD-MMM-YYYY"),
        "STP End Date": moment(
          transactionDetails.fetchTransactionDetails.endDate
        ).format("DD-MMM-YYYY"),
        ...getStpAmount(),
      },
    });

    return data;
  }, [
    transactionDetails,
    transactionSchemeDetails,
    isLoadingTransactionSchemesDetails,
  ]);

  const handleTransactionApprove = () => {
    dispatch(
      confirmTransaction({
        investorPan: transactionDetails.fetchTransactionDetails.investorPan,
        folioNo: transactionDetails.fetchTransactionDetails.folioNumber,
        batchNo: transactionDetails.fetchTransactionDetails.batchNo,
        ihNo: transactionDetails.fetchTransactionDetails.ihno,
        txnType: "STP",
      })
    );
  };
  const handleTransactionReject = () => {
    const distributor = getDistributorDetails(transactionDistributor);
    dispatch(
      rejectTransaction({
        batchNo: transactionDetails.fetchTransactionDetails.batchNo,
        pan: transactionDetails.fetchTransactionDetails.investorPan,
        ...distributor,
        rejectReason: "Not Intrested",
      })
    );
  };
  const handleEditTransaction = (data: any, _type: string | undefined) => {
    dispatch(setEditTransactionInfo(data));

    if (_type === "stpDetails") {
      navigate(RouteConfig.stpTargetSchemeEdit);
    } else return null;
  };

  return (
    <>
      {!isLoadingTransactionSchemesDetails ? (
        <TransactionDetails
          stepper={{ currentIndex: 0, steps: TransactionSteps }}
          error={confirmTransactionError || rejectTransactionError}
          transactions={transactions}
          onApproveTranaction={handleTransactionApprove}
          onEditTransaction={handleEditTransaction}
          onRejectTranaction={handleTransactionReject}
        />
      ) : null}
      {(isConfirmingTransaction ||
        isLoadingTransactionSchemesDetails ||
        isRejectingTransaction) && <ActivityOverlay />}
    </>
  );
};
export default STPTransactionDetails;
