import { Box, Grid } from "@mui/material";
import Text from "components/Text";
import { FC } from "react";
import colors from "res/colors";
import { InfoProps } from ".";

const Info: FC<InfoProps> = ({
  icon,
  message,
  iconPosition = "center",
  color = "blue",
  containerBackgroundColor = "blue",
  textStyle = null,
}) => {
  return (
    <Box>
      <Grid
        container
        direction="row"
        sx={[
          contentColorStyle[containerBackgroundColor],
          { py: "12px", borderRadius: "4px" },
        ]}
      >
        <Grid item xs={2}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: iconPosition,
              alignItems: "center",
              height: "100%",
              mt: "-5px",
            }}
          >
            {icon}
          </Box>
        </Grid>
        <Grid item xs={9.5}>
          {textStyle ? (
            <Text
              type="infoComponentSmall"
              color={contentTextStyle[color]}
              sx={textStyle}
            >
              {message}
            </Text>
          ) : (
            <Text type="infoComponentSmall" color={contentTextStyle[color]}>
              {message}
            </Text>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const contentColorStyle = Object.freeze({
  red: {
    backgroundColor: colors.infoBackgroundRed,
  },
  blue: {
    backgroundColor: colors.infoBackgroundBlue,
  },
  green: {
    backgroundColor: colors.infoBackgroundGreen,
  },
});

const contentTextStyle = Object.freeze({
  red: {
    color: colors.infoTextRed,
  },
  blue: {
    color: colors.infoTextBlue,
  },
  green: {
    color: colors.infoTextGreen,
  },
});

export default Info;
