/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import strings from "res/strings";
import Intro from "components/Intro";
import { useAppDispatch } from "hooks/useAppDispatch";
import { setInvestorFlow, setIHNo } from "store/appstate/slice";
import { InvestorFlow } from "utils/constants";
import RouteConfig from "routes/RouteConfig";
import { useNavigate, useParams } from "react-router-dom";

const Ekyc = () => {
  const navigate = useNavigate();
  const { ihNo } = useParams();
  const dispatch = useAppDispatch();
  const handleOtpSuccess = () => {
    navigate(RouteConfig.ekycOtp);
  };

  useEffect(() => {
    dispatch(setInvestorFlow(InvestorFlow.ekyc));

    dispatch(setIHNo(ihNo || ""));
  }, []);

  return (
    <Intro
      title="e-KYC Approval pending"
      message={strings.ekycIntroMessage}
      onSuccessfullyGeneratingOtp={handleOtpSuccess}
    />
  );
};
export default Ekyc;
