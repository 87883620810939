import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import OtpInput from "react-otp-input";
import colors from "res/colors";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import ActivityOverlay from "components/ActivityOverlay";
import {
  resendOtp,
  verifyCartOtp,
  verifyEKYCOtp,
  verifyTransactionOtp,
  verifyNewCartOtp,
  resetOtpStates,
  resetOtpError,
  veriftOtmOtp,
} from "store/otp/slice";
import isMobile from "utils/isMobile";
import ButtonIcon from "components/ButtonIcon";
import Text from "components/Text";
import OtpTimer from "components/OtpTimer";
import { InvestorFlow, TransactionType } from "utils/constants";
import { Navigate, useNavigate } from "react-router-dom";
import {
  loadEquitySchemes,
  loadFixedIncomeSchemes,
  loadFunOfFundSchemes,
  loadHybridSchemes,
  loadNFOSchemes,
} from "store/global-data/slice";
import { incrementTransactionStage } from "store/appstate/slice";

export interface OtpProps {
  errorMessage?: string;
  showAcitivityOverlay?: boolean;
  onVerifyOtp?: () => void;
}

/*
email: "XXXXXXXX@yahoo.com"
mobile: "XXXXXX111"
otp: "76839"
otpReferenceId: "9F15504
*/
const Otp: React.FC<OtpProps> = ({
  errorMessage = "",
  showAcitivityOverlay = false,
  onVerifyOtp,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isVerifying, error, didVerifyOtp, userMakedInfo, didGenerateOtp } =
    useAppSelector((state) => state.otp);

  const { ihNo, investorFlow, transactionType } = useAppSelector(
    (state) => state.appState
  );

  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  useEffect(() => {
    if (didVerifyOtp) {
      // move to next screen
      dispatch(loadEquitySchemes());
      dispatch(loadHybridSchemes());
      dispatch(loadFixedIncomeSchemes());
      dispatch(loadFunOfFundSchemes());
      dispatch(loadNFOSchemes());
      handleNextAfterOtpVerification();
      dispatch(incrementTransactionStage());
    }
  }, [didVerifyOtp]);

  // useEffect(() => {
  //   if (error) {
  //     setOtp("");
  //     dispatch(resetOtpError());
  //   }
  // }, [error]);

  useEffect(() => {
    if (otp.length === 5) {
      handleLogin();
    }
  }, [otp]);

  const handleNextAfterOtpVerification = () => {
    if (onVerifyOtp) {
      onVerifyOtp();
      return;
    }
    if (investorFlow === InvestorFlow.transaction) {
    }
  };

  const handleLogin = () => {
    if (!otp || otp.length < 5) {
      setOtpError("Please enter valid OTP.");
      return;
    }
    if (investorFlow === InvestorFlow.ekyc) {
      dispatch(
        verifyEKYCOtp({
          ihNo: ihNo,
          otpReferenceId: userMakedInfo.otpReferenceId,
          otp: otp,
        })
      );
    } else {
      if (
        transactionType === TransactionType.sip ||
        transactionType === TransactionType.lumpsum
      ) {
        dispatch(
          verifyCartOtp({
            batchNo: ihNo,
            otpReferenceId: userMakedInfo.otpReferenceId,
            otp: otp,
          })
        );
      } else if (
        transactionType === TransactionType.newSip ||
        transactionType === TransactionType.newLumpsum
      ) {
        dispatch(
          verifyNewCartOtp({
            batchNo: ihNo,
            otpReferenceId: userMakedInfo.otpReferenceId,
            otp: otp,
          })
        );
      } else if (transactionType === TransactionType.otm) {
        dispatch(
          veriftOtmOtp({
            payoutReference: ihNo,
            otpReferenceId: userMakedInfo.otpReferenceId,
            otp: otp,
          })
        );
      } else {
        dispatch(
          verifyTransactionOtp({
            batchNo: ihNo,
            otpReferenceId: userMakedInfo.otpReferenceId,
            otp: otp,
          })
        );
      }
    }
    //userMakedInfo.referenceId
  };

  const handleChange = (event: string) => {
    if (event && event.length === 5) {
      setOtpError("");
    }
    setOtp(event);
  };

  const onResend = () => {
    dispatch(
      resendOtp({
        ihNo: ihNo,
        otpReferenceId: userMakedInfo.otpReferenceId,
      })
    );
  };
  const showError = Boolean(otpError || error || errorMessage);
  if (!didGenerateOtp) {
    return <Navigate to="/" replace />;
  }
  const getSentence = (maskedEmail: string, maskedMobile: string) => {
    if (maskedEmail && maskedMobile) {
      return `mobile no. ${maskedMobile} and email ${maskedEmail}`;
    } else if (maskedEmail) {
      return `email ${maskedEmail}`;
    } else if (maskedMobile) {
      return `mobile no. ${maskedMobile}`;
    } else return "";
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          mt: isMobile() ? "30%" : "10%",
          textAlign: "left",
          maxWidth: "640px",
          px: "10%",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            mt: "20px",
          }}
          container
          direction="column"
          columnSpacing={{ xs: 1, sm: 3, md: 4, lg: 5 }}
        >
          <Grid>
            <Text type="large">
              {`We have sent the 5 digit OTP to your registered ${getSentence(
                userMakedInfo.email,
                userMakedInfo.mobile
              )}`}
              {/* <Text type="largeBold">{userMakedInfo.email}</Text>
              {"and mobile no. "}
              <Text type="largeBold">{userMakedInfo.mobile}</Text> */}
            </Text>
          </Grid>
          <Grid>
            <Box sx={{ mt: "64px" }}>
              <OtpInput
                value={otp}
                numInputs={5}
                isInputNum={true}
                // hasErrored={error}
                containerStyle={{
                  height: "36px",
                  marginTop: "35px",
                  marginBottom: "35px",
                  // backgroundColor: "red",
                  width: "300px",
                }}
                separator={<span style={{ paddingLeft: "20px" }}></span>}
                inputStyle={{
                  borderTopWidth: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 0,
                  fontSize: "20px",
                  // width: 65,
                  // height: 25,
                  // backgroundColor: "green",
                }}
                focusStyle={{ outline: "none" }}
                // separator={<Box sx={classes.otpSpacing}></Box>}
                onChange={handleChange}
                shouldAutoFocus
              />
              {/* <OtpInput
                value={otp}
                numInputs={5}
                isInputNum={true}
                containerStyle={styles.otpContainerStyle}
                inputStyle={styles.otpInputStyle}
                focusStyle={styles.otpFocusStyle}
                separator={<Box sx={styles.otpSpacing}></Box>}
                onChange={handleChange}
              /> */}
              {showError ? (
                <Text
                  type="mediumBold"
                  color="red"
                  sx={{ marginTop: "10px", marginBottom: "15px" }}
                >
                  {otpError || error || errorMessage}
                </Text>
              ) : null}
            </Box>
          </Grid>
          <Grid sx={{ mt: "24px", mb: "60px" }}>
            <OtpTimer onResend={onResend} />
          </Grid>
          <Grid
            sx={{
              width: "100%",
            }}
          >
            <ButtonIcon title={"Verify"} icon={true} onClick={handleLogin} />

            {/* <Text
              type="mediumBold"
              color="red"
              sx={{ marginTop: "10px", marginBottom: "15px" }}
            >
              {userMakedInfo.otp}
            </Text> */}

            {(isVerifying || showAcitivityOverlay) && <ActivityOverlay />}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default Otp;

const styles = Object.freeze({
  container: {
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "20%",
    justifyContent: "center",
  },
  otpContainerStyle: {
    height: "26px",
    marginTop: "20%",

    marginRight: "5%",
  },
  otpInputStyle: {
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    width: 15,
  },
  otpFocusStyle: { outline: "none" },
  otpSpacing: { paddingLeft: "40px" },
  verifyButtonStyle: {
    backgroundColor: colors.backGroundBlue,
    color: colors.white,
    width: isMobile() ? "100%" : "275px",
    py: "10px",
  },
  resendOtpStyle: { color: colors.backGroundBlue },
});
function dispatch(arg0: { payload: undefined; type: string }) {
  throw new Error("Function not implemented.");
}
