import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type EkycState = {
  ekycDetails: { [key: string]: any };
};

const INITIAL_STATE: EkycState = {
  ekycDetails: {},
};

const ekyc = createSlice({
  name: "ekyc",
  initialState: INITIAL_STATE,
  reducers: {
    setEkycDetails: (state, action: PayloadAction<{ [key: string]: any }>) => {
      state.ekycDetails = action.payload;
    },
  },
});

export const { setEkycDetails } = ekyc.actions;
export default ekyc.reducer;
