import { Box, Divider, Grid, IconButton, InputAdornment } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks";
import { useNavigate } from "react-router-dom";
import colors from "res/colors";
import strings from "res/strings";
import isMobile from "utils/isMobile";
import Text from "components/Text";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { ChangeEvent, useEffect, useState } from "react";
import Input from "components/Input";
import {
  editTransaction,
  resetEditTransactionError,
  resetFolioWiseSchemeDetails,
  setTransactionDetails,
} from "store/transaction/slice";
import ButtonIcon from "components/ButtonIcon";
import ActivityOverlay from "components/ActivityOverlay";
import ToggleButtonOptions from "components/ToggleButton/ToggleButtonOptions";
import { switchModes } from "utils/constants";
import { ToWords } from "to-words";

const RedemptionEdit = () => {
  const toWords = new ToWords({
    localeCode: "en-IN",
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ihNo } = useAppSelector((state) => state.appState);
  const {
    transactionDetails,
    editTransactionInfo,
    editTransactionError,
    isEditingTransaction,
    foliowiseSchemeData,
    transactionSchemeDetails,
  } = useAppSelector((state) => state.transaction);
  const [investmentAmount, setInvestmentAmount] = useState(
    transactionDetails.fetchTransactionDetails.amount
  );
  const [investmentUnit, setInvestmentUnit] = useState(
    transactionDetails.fetchTransactionDetails.units
  );
  const [redemptionTransactionType, setRedemptionTransactionType] = useState(
    transactionDetails.fetchTransactionDetails.aorUFlag
  );
  const [redemptionMode, setRedemptionMode] = useState("");
  const [totalValuation, setTotalValuation] = useState("");
  const [totalUnits, setTotalUnits] = useState("");
  const [maxAmountErrorFlag, setMaxAmountErrorFlag] = useState(false);
  const [maxUnitErrorFlag, setMaxUnitErrorFlag] = useState(false);
  const [minAmountErrorFlag, setMinAmountErrorFlag] = useState(false);
  const [minUnitErrorFlag, setMinUnitErrorFlag] = useState(false);
  const minSwitchAmount = transactionSchemeDetails[0].minAddPurchase;

  useEffect(() => {
    let tempSwitchtype = "";
    foliowiseSchemeData?.schemeDetails?.map((item: any) => {
      if (
        item.folioNo ===
          transactionDetails.fetchTransactionDetails.folioNumber &&
        item.schemecode ===
          transactionDetails.fetchTransactionDetails.schemeCode &&
        item.optionCode === transactionDetails.fetchTransactionDetails.option &&
        item.planCode === transactionDetails.fetchTransactionDetails.planCode
      ) {
        tempSwitchtype = transactionDetails.fetchTransactionDetails.aorUFlag;
        setTotalValuation(item.totalValuation);
        setTotalUnits(item.unitBalance);
      }
    });
    tempSwitchtype === "AU"
      ? setRedemptionMode("All Units")
      : tempSwitchtype === "A"
      ? setRedemptionMode("Partial Amount")
      : setRedemptionMode("Partial Units");
  }, [foliowiseSchemeData]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (redemptionMode === "Partial Amount") {
      if (
        parseFloat(event.target.value) < parseFloat(minSwitchAmount) ||
        isNaN(parseFloat(event.target.value))
      ) {
        setMinAmountErrorFlag(true);
      } else if (parseInt(event.target.value) > parseInt(totalValuation)) {
        setMaxAmountErrorFlag(true);
      } else {
        setMinAmountErrorFlag(false);
        setMaxAmountErrorFlag(false);
      }

      setInvestmentAmount(event.target.value);
      setInvestmentUnit("0");
    } else if (redemptionMode === "Partial Units") {
      if (parseFloat(event.target.value) < 0.001) {
        setMinUnitErrorFlag(true);
      } else if (parseInt(event.target.value) > parseInt(totalUnits)) {
        setMaxUnitErrorFlag(true);
      } else {
        setMinUnitErrorFlag(false);
        setMaxUnitErrorFlag(false);
      }
      setInvestmentUnit(event.target.value);
      setInvestmentAmount("0");
    }
  };
  const getMaxAmountErrorMessage = () => {
    if (maxAmountErrorFlag) {
      return `Maximum amount available is ${totalValuation}.`;
    }
  };
  const getMaxUnitErrorMessage = () => {
    if (maxUnitErrorFlag) {
      return `Maximum units available is ${totalUnits}.`;
    }
  };
  const getMinAmountErrorMessage = () => {
    if (minAmountErrorFlag) {
      return `Minimum amount is ₹${minSwitchAmount}.`;
    }
  };
  const getMinUnitErrorMessage = () => {
    if (minUnitErrorFlag) {
      return `Minimum units is 0.001.`;
    }
  };
  const handleScreenCancel = () => {
    dispatch(resetFolioWiseSchemeDetails());
    dispatch(resetEditTransactionError());

    navigate(-1);
  };

  const createNewTransactionDetails = () => {
    const data = { ...transactionDetails };
    data.fetchTransactionDetails = {
      ...transactionDetails.fetchTransactionDetails,
    };
    data.fetchTransactionDetails.units = investmentUnit;
    data.fetchTransactionDetails.amount = investmentAmount;
    data.fetchTransactionDetails.aorUFlag = redemptionTransactionType;
    dispatch(setTransactionDetails(data));

    // const tempSchemeDetails = [...transactionSchemeDetails];
    // tempSchemeDetails[editTransactionInfo.index].optDesc = selectedOptDesc;
    // dispatch(setTransactionSchemeDetails(tempSchemeDetails));
  };
  const handleAccept = () => {
    // const data = getEditTransacation(
    //   ihNo,
    //   "lumpsum",
    //   transactionDetails,
    //   transactionDetails.cartInitiateResponse.batchNo
    // );

    const editTransactionData = {
      transactionType: "redeem",
      ihNo: transactionDetails.fetchTransactionDetails.batchNo,
      batchNo: transactionDetails.fetchTransactionDetails.batchNo,
      transactionDateTime:
        transactionDetails.fetchTransactionDetails.transactionDateTime,
      editSchemeList: [{}],
      amount: investmentAmount,
      activity: "",
      isFreshPurhase: "N",
      units: investmentUnit,
      aorUFlag: redemptionTransactionType,
    };

    dispatch(editTransaction(editTransactionData));
    createNewTransactionDetails();
    dispatch(resetFolioWiseSchemeDetails());
    dispatch(resetEditTransactionError());

    navigate(-1);
  };

  const setTotalLimitValues = () => {
    setInvestmentAmount(totalValuation);
    setInvestmentUnit(totalUnits);
    setRedemptionTransactionType("AU");
  };
  const resetAmountAndUnits = () => {
    setInvestmentAmount("");
    setInvestmentUnit("");
  };
  const handleRedemptionMode = (selectedOption: string) => {
    setRedemptionMode(selectedOption);
    setMaxUnitErrorFlag(false);
    setMinUnitErrorFlag(false);
    setMaxAmountErrorFlag(false);
    setMinAmountErrorFlag(false);
    selectedOption === "All Amount" || selectedOption === "All Units"
      ? setTotalLimitValues()
      : selectedOption === "Partial Amount"
      ? resetAmountAndUnits()
      : selectedOption === "Partial Units"
      ? resetAmountAndUnits()
      : console.log(""); //do not remove as it is required to handle the case
    selectedOption === "Partial Amount"
      ? setRedemptionTransactionType("A")
      : selectedOption === "Partial Units"
      ? setRedemptionTransactionType("U")
      : setRedemptionTransactionType("AU");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          mt: isMobile() ? "30%" : "10%",
          mx: "5%",
          pb: "20px",
          width: isMobile() ? "90%" : "50%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton>
            <CancelOutlinedIcon
              sx={{ color: colors.gray }}
              onClick={handleScreenCancel}
            />
          </IconButton>
        </Box>
        <Box sx={{ px: "24px" }}>
          <Text type="subHeadingBold">{strings.editRedemption}</Text>
          <Box sx={{ my: "20px" }}>
            <Divider sx={{ backgroundColor: colors.backGroundBlue }} />
          </Box>

          <Grid container direction={"column"} rowSpacing={"24px"}>
            <Grid item>
              <Text type="dataLightMessage">{strings.FolioNo}</Text>
              <Text type="dataMediumBold">
                {transactionDetails.fetchTransactionDetails.folioNumber}
              </Text>
            </Grid>
            {/* <Grid item>
              <Text type="dataLightMessage">{strings.Scheme}</Text>
              <Text type="dataMediumBold">
                {editTransactionInfo.contents["Scheme Name"]}
              </Text>
            </Grid> */}
          </Grid>
          <Box sx={{ my: "20px" }}>
            <Divider />
          </Box>
          <ToggleButtonOptions
            options={switchModes}
            selectMultiple={false}
            setBorder={false}
            value={redemptionMode}
            onClick={handleRedemptionMode}
          />
          <Text type="smallBold">{strings.redemptionAmount}</Text>
          <Box sx={{ py: "12px" }}>
            <Input
              value={
                redemptionMode === "All Amount" ||
                redemptionMode === "Partial Amount"
                  ? investmentAmount
                  : investmentUnit
              }
              onChange={handleChange}
              error={
                getMaxAmountErrorMessage() ||
                getMinAmountErrorMessage() ||
                getMaxUnitErrorMessage() ||
                getMinUnitErrorMessage()
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {redemptionMode === "All Amount" ||
                    redemptionMode === "Partial Amount"
                      ? `Min amt. ${minSwitchAmount}`
                      : strings.minimumRedeemUnits}
                  </InputAdornment>
                ),
              }}
            />
            <Text type="medium">
              {redemptionMode === "All Amount" ||
              redemptionMode === "Partial Amount"
                ? parseInt(investmentAmount) > 0
                  ? toWords.convert(parseFloat(investmentAmount))
                  : null
                : null}
            </Text>
          </Box>
          {editTransactionError ? (
            <Text
              type="mediumBold"
              color="red"
              sx={{ marginTop: "10px", marginBottom: "15px" }}
            >
              {editTransactionError}
            </Text>
          ) : null}
          <ButtonIcon
            disabled={
              minAmountErrorFlag ||
              minUnitErrorFlag ||
              maxUnitErrorFlag ||
              maxAmountErrorFlag
            }
            title={"Accept"}
            icon={true}
            onClick={handleAccept}
          />
        </Box>
      </Box>
      {isEditingTransaction && <ActivityOverlay />}
    </Box>
  );
};

export default RedemptionEdit;
