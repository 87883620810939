import Otp from "components/Otp/Otp";
import { useNavigate } from "react-router-dom";
import RouteConfig from "routes/RouteConfig";
import { useAppSelector } from "hooks/useAppSelector";
import isMobile from "utils/isMobile";
const EkycOtp = () => {
  const { ekycDetails } = useAppSelector((state) => state.ekyc);
  const handleOnVerifyOtp = () => {
    window.location.replace(
      isMobile() ? ekycDetails.mobileAutoLoginUrl : ekycDetails.autoLoginUrL
    );
  };
  return <Otp onVerifyOtp={handleOnVerifyOtp} />;
};
export default EkycOtp;
