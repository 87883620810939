import { Box, IconButton } from "@mui/material";
import Text from "components/Text";
import { RejectProps } from "components/Reject/types";
import { FC } from "react";
import strings from "res/strings";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import colors from "res/colors";
import SuccesImage from "res/images/success.png";
const Success: FC<RejectProps> = ({ rejectMessage, cancel, download }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box>
          {download ? (
            <IconButton>
              <DownloadForOfflineIcon
                sx={{ color: colors.red, fontSize: "32px" }}
              />
            </IconButton>
          ) : null}
        </Box>
        {cancel ? (
          <IconButton>
            <CancelRoundedIcon
              sx={{
                color: colors.gray,

                fontSize: "32px",
              }}
            />
          </IconButton>
        ) : null}
      </Box>

      <Box>
        <img src={SuccesImage} alt="Success" height={"140px"} width={"190px"} />
      </Box>
      <Box sx={{ mt: "24px", mb: "12px" }}>
        <Text type="successLargeBold">{strings.reject}</Text>
      </Box>
      <Box>
        <Text type="smallTransparentCenter">{rejectMessage}</Text>
      </Box>
    </Box>
  );
};
export default Success;
