import React, { useEffect, useState } from "react";
import { ReactComponent as Invesco_MF_Logo } from "../../res/images/invescoMfLogo.svg";
import Text from "components/Text";
import { makeStyles } from "@mui/styles";
import InvescoLogo from "res/images/InvescoLogo.jpeg";
const PrivacyPolicy = () => {
  const classes = useStyles();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const listItemStyle: React.CSSProperties = {
    fontSize: "20px",
    textAlign: "justify",
    color: "black",
  };
  const h1Style: React.CSSProperties = {
    fontSize: "30px",
    textAlign: "center",
    color: "#000AD2",
  };
  const headingStyle: React.CSSProperties = {
    fontSize: "20px",
    textAlign: "justify",
    color: "black",
    fontWeight: "bold",
    marginTop: "30px",
    marginBottom: "-15px",
  };

  const paragraphStyle: React.CSSProperties = {
    fontSize: "20px",
    textAlign: "justify",
    color: "black",
  };

  const contactInfoStyle: React.CSSProperties = {
    fontSize: "20px",
    textAlign: "justify",
    color: "black",
    marginBottom: 0,
    marginTop: 0,
  };
  const contactContainer: React.CSSProperties = {
    marginTop: "30px",
  };

  return (
    <div style={{ padding: "20px" }}>
      <img
        src={InvescoLogo}
        alt="Invesco Logo"
        height={"10%"}
        width={windowWidth > 600 ? "250px" : windowWidth / 2}
      />
      <h1 style={h1Style}>PRIVACY POLICY</h1>
      <p style={paragraphStyle}>
        At Invesco Asset Management (India) Pvt. Ltd. (“IAMI”), we care about
        your privacy and value the trust you place in us when you share your
        personal information with us. We are committed to safeguarding your
        personal information. This policy is designed to provide you with a
        summary of the non-public personal information IAMI may collect and
        maintain about its users. We do not sell individual users' non-public
        personal information to anyone and only use or share it as described in
        this notice.
      </p>
      <h1 style={headingStyle}>Information that you provide voluntarily</h1>
      <p style={paragraphStyle}>
        When you contact provide your personal data to IAMI, we may collect your
        personal data. Mainly following information would be treated as personal
        information:
      </p>
      <ol type="A" style={{ marginLeft: "2%" }}>
        <li style={listItemStyle}>Password;</li>
        <li style={listItemStyle}>
          Financial information such as Bank account or credit card or debit
          card or other payment instrument details;
        </li>
        <li style={listItemStyle}>
          Physical, physiological and mental health condition;
        </li>
        <li style={listItemStyle}>Sexual orientation;</li>
        <li style={listItemStyle}>Medical records and history;</li>
        <li style={listItemStyle}>
          Any detail relating to the above clauses as provided to IAM for
          providing service; and
        </li>
        <li style={listItemStyle}>
          Any of the information received under above clauses by IAM for
          processing, stored or processed under lawful contract or otherwise.
        </li>
      </ol>
      <p style={paragraphStyle}>
        We will not use your personal data for any purpose other than to
        register your attendance at our events, to respond to your enquiry and,
        where appropriate and depending on the nature of your relationship with
        us, we may also use such personal data for client servicing purposes in
        connection with your existing and proposed investments in our investment
        funds managed or administered by the Invesco group (“Invesco”) which may
        include following up on your enquiries, market analysis and the
        formulation of business strategies and other similar administrative or
        business purposes. In doing so your personal data may be accessible to
        third parties (including other Invesco entities) who are engaged to
        support us in the above matters. You do not have to provide all the
        personal data we ask for, but it will help us to contact you quickly to
        help with your enquiry and to send you information that you may find
        useful. Further, you may at any time withdraw your consent to our use of
        your personal data for communications with you by selecting the
        unsubscribe option in the communication you receive or by contacting
        your regional sales representative.
      </p>
      <h1 style={headingStyle}>Exceptions</h1>
      <p style={paragraphStyle}>
        We may disclose your information available with us, if we are required
        by a Court Order, by Government Authorities, by Law Enforcement
        Authorities or by other legal processes or where it becomes necessary to
        do so to comply with laws/ rules/regulation or to protect the rights or
        property of IAMI and/or its group companies/affiliates.
      </p>
      <h1 style={headingStyle}>Confidentiality and Security</h1>
      <p style={paragraphStyle}>
        Our employees are required to follow procedures with respect to
        maintaining the confidentiality of our users' non-public personal
        information. A comprehensive documented information security programme
        and information security policies and procedures are in place.
      </p>
      <h1 style={headingStyle}>Changes to this Privacy Policy</h1>
      <p style={paragraphStyle}>
        IAMI reserves the right to change or update this Privacy Policy or any
        other of our Policies/Practices at any time. The same shall be notified
        to the users by posting such changes or updated Privacy Policy on the
        page. Any changes or updates will be effective immediately upon posting
        to this app. We are committed towards protecting and safeguarding your
        privacy on-line.
      </p>
      <h1 style={headingStyle}>Grievance Mechanism</h1>
      <p style={paragraphStyle}>
        IAMI will not host, display, upload, modify, publish, transmit, update
        or share any personal information of its users. Upon obtaining knowledge
        by itself or been brought to actual knowledge by an affected person in
        writing or through email signed with electronic signature about any such
        information IAMI shall act within thirty six hours and where applicable,
        work with user or owner of such information to disable such information
        that is in contravention. Further the intermediary shall preserve such
        information and associated records for at least ninety days for
        investigation purposes. In case, you suffer as a result of access or
        usage of device resource by any person in violation of rule can notify
        your complaints against such access or usage of device resource of IAMI.
        The Grievance Officer shall redress the complaints within one month from
        the date of receipt of complaint.
      </p>
      <p style={paragraphStyle}>
        IAM shall report cyber security incidents (if any) and also share cyber
        security incidents related information with the Indian Computer
        Emergency Response Team, as may be applicable from time to time.
      </p>
      <h1 style={headingStyle}>Grievance Officer</h1>
      <div style={contactContainer}>
        <p style={contactInfoStyle}>
          Mr. Surinder Singh Negi, Head - Operation and Customer Services,
        </p>
        <p style={contactInfoStyle}>
          Invesco Asset Management (India) Pvt. Ltd.
        </p>
        <p style={contactInfoStyle}>Unit No: 2101 A, 21st floor, A - Wing,</p>
        <p style={contactInfoStyle}>Marathon Futurex, N. M. Joshi Marg,</p>
        <p style={contactInfoStyle}>Lower Parel, Mumbai - 400 013</p>
        <p style={contactInfoStyle}>Tel. No.: +91-22-6731 0000</p>
        <p style={contactInfoStyle}>Fax No.: +91-22-2301 9422</p>
        <p style={contactInfoStyle}>E-mail: mfservices@invesco.com</p>
      </div>
      <h1 style={h1Style}>COOKIES POLICY</h1>
      <p style={paragraphStyle}>
        When you access our app Invesco Partner, we will place a 'cookie' on
        your device. Cookies are a standard mechanism used by many apps to store
        app related information on their users' device.
      </p>
      <p style={paragraphStyle}>
        Cookies only contain information relating to the app that created them
        and can only return the information to that app. In other words, our app
        cannot read the information contained in cookies created by anyone
        else's app on your device. Similarly, only our app can read the
        information in cookies that it creates. Cookies cannot be used to get or
        view data from your hard drive. Cookies cannot contain or deliver
        viruses.
      </p>
      <p style={paragraphStyle}>
        We or our third-party service providers use cookies to gather
        information about you and your browsing activities to:
      </p>
      <ul style={{ ...paragraphStyle, marginLeft: "2%" }}>
        <li>
          track and record the aggregate number of visitors to the app and the
          sections of the app visited by visitors using cookies;
        </li>
        <li>
          analyse our app, for example to collect information that will help us
          understand visitors' browsing habits on our app;
        </li>
        <li>
          compile statistical reports on app activity, e.g. number of visitors
          and the pages they visit;
        </li>
        <li>
          improve the functionality of our app by temporarily storing any
          information which you may enter in tools, such as calculators or
          demonstrations on our app; in some cases, remember information about
          you when you visit our app. We may need to do this to provide some of
          our services; and
        </li>
        <li>
          record your visit to our app, the pages you have visited and the links
          you have followed. We use this information to make our app more
          relevant to your interests and identify products or services that may
          be of interest to you. We may also share this information with third
          parties for these purposes.
        </li>
      </ul>
      <p style={paragraphStyle}>
        Use of Google Analytics and Google Ads (Remarketing)
      </p>
      <p style={paragraphStyle}>
        This app uses Google Analytics, a web analytics service provided by
        Google Inc. (Google). Google Analytics uses cookies, which are text
        files stored on your device or mobile phone to help analyse how the app
        is used by you. As a rule, the information generated by the cookie about
        your use of the app is transmitted to and stored by Google on a server
        in the United States. If IP anonymisation is activated on this app,
        however, your IP address will be truncated by Google in Member States of
        the European Union or in other states party to the Agreement on the
        European Economic Area prior to transmission. Only in exceptional cases
        will the complete IP address be transmitted to a Google server in the
        United States and truncated there. Google will use this information on
        behalf of the operator of this app for the purpose of evaluating your
        use of the app, compiling app activity reports and providing other
        services to us relating to the use of this app.
      </p>

      <p style={paragraphStyle}>
        The IP address transmitted by your browser within the scope of Google
        Analytics will not be associated with any other data held by Google.
      </p>
      <p style={paragraphStyle}>
        Cookies related to Google Analytics and Google Ads (see cookies: '_ga' &
        '_gid') are used to capture data around how our customers engage with
        our app, such as pages they visited, interactions they had on the pages,
        and information about what type of device they are using. We combine
        this data and analyse it so we can improve the performance of our
        services and refine the products we provide. We may also use this
        information and combine it with profile data to help us understand which
        of our products, services and offers may be relevant for you - with this
        information, we may tailor our marketing ads, and aspects of our apps to
        individual visitors based on your usage.
      </p>
      <h1 style={headingStyle}>Revisions to this Cookies Policy</h1>
      <p style={paragraphStyle}>
        By using our app and agreeing to these terms of use, you consent to our
        use of cookies in accordance with this Cookie Policy. We reserve the
        right to revise this Cookies Policy at any time by posting a revised
        version online and it shall be effective immediately.
      </p>
      <h1 style={headingStyle}>How can you contact us?</h1>
      <p style={paragraphStyle}>
        If you have any questions about this Cookies Policy, please contact us
        at:
      </p>
      <p style={contactInfoStyle}>Invesco Asset Management (India) Pvt. Ltd.</p>
      <p style={contactInfoStyle}>Unit No: 2101 A, 21st floor, A - Wing,</p>
      <p style={contactInfoStyle}>Marathon Futurex, N. M. Joshi Marg,</p>
      <p style={contactInfoStyle}>Lower Parel, Mumbai - 400 013</p>
      <p style={contactInfoStyle}>Tel. No.: +91-22-6731 0000</p>
      <p style={contactInfoStyle}>Fax No.: +91-22-2301 9422</p>
      <p style={contactInfoStyle}>E-mail: mfservices@invesco.com</p>
    </div>
  );
};

export default PrivacyPolicy;
const useStyles = makeStyles({});
