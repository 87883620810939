/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { useAppSelector } from "hooks/useAppSelector";
import TransactionDetails from "components/TransactionDetails/TransactionDetails";
import ActivityOverlay from "components/ActivityOverlay/ActivityOverlay";
import { TransactionSteps } from "utils/constants";
import { useAppDispatch } from "hooks/useAppDispatch";
import {
  rejectTransaction,
  loadPaymentOptions,
  setTransactionApproval,
  setEditTransactionInfo,
  resetDidLoadPaymentOptions,
} from "store/transaction/slice";
import RouteConfig from "routes/RouteConfig";
import { useNavigate } from "react-router-dom";
import { convertSIPFrequency } from "utils/convertSIPFrequency";
import { getDistributorDetails } from "utils/getDistributorDetails";
import moment from "moment";
import {
  decrementTransactionStage,
  incrementTransactionStage,
} from "store/appstate/slice";

const SipTransactionDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    transactionDetails,
    transactionDistributor,
    isLoadingPaymentOptions,
    didLoadPaymentOptions,
    paymentOptionsError,
    transactionSchemeDetails,
    isLoadingTransactionSchemesDetails,
    isRejectingTransaction,
    didRejectTransaction,
    rejectTransactionError,
    didApproveTransaction,
  } = useAppSelector((state) => state.transaction);

  useEffect(() => {
    if (didLoadPaymentOptions) {
      dispatch(setTransactionApproval(true));
      dispatch(incrementTransactionStage());
      navigate(RouteConfig.paymentOptions);
      dispatch(resetDidLoadPaymentOptions());
    }
  }, [didLoadPaymentOptions]);

  useEffect(() => {
    if (didRejectTransaction) {
      // dispatch(resetOtpStates());
      // dispatch(resetConfirmTransactionStates());
      navigate(RouteConfig.sipTransactionReject);
    }
  }, [didRejectTransaction]);

  useEffect(() => {
    if (didApproveTransaction) {
      dispatch(decrementTransactionStage());
    }
  }, []);
  const getSIPTopupData = (item: any) => {
    if (!item.topUpAmount || item.topUpAmount === "0") {
      return null;
    } else {
      return {
        "Top-Up Amount": parseInt(item.topUpAmount).toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
          // minimumFractionDigits: 0,
          // maximumFractionDigits: 0,
        }),
        "Top-Up Frequency":
          item.topUpFrequency === "H" ? "Half Yearly" : "Yearly",
        "Top-Up Start Date": item.topUpStartDate
          ? moment(item.topUpStartDate).format("DD-MMM-YYYY")
          : "",
        "Top-Up End Date": item.topUpEndDate
          ? moment(item.topUpEndDate).format("DD-MMM-YYYY")
          : "",
      };
    }
  };
  const transactions = useMemo(() => {
    if (
      !transactionDetails ||
      !transactionDetails.cartInitiateResponse ||
      !transactionDetails.cartInitiateResponse.schemeList ||
      isLoadingTransactionSchemesDetails
    ) {
      return [];
    }

    return transactionDetails.cartInitiateResponse.schemeList.map(
      (item: any, index: number) => {
        return {
          title: "Investment Details",
          index: index,
          canEdit: true,
          contents: {
            "Investment Type": "Systematic Investment Plan",
            "Scheme Name": transactionSchemeDetails[index].fundDesc,
            Option: `${transactionSchemeDetails[index]?.optDesc} - ${transactionSchemeDetails[index]?.planDesc}`,

            "SIP Amount": parseInt(item.amount).toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
              // minimumFractionDigits: 0,
              // maximumFractionDigits: 0,
            }),
            "SIP Frequency": convertSIPFrequency(item.sipFrequency),
            "SIP Start Date": moment(item.sipStartDate).format("DD-MMM-YYYY"),
            "SIP End Date": moment(item.sipEndDate).format("DD-MMM-YYYY"),
            "No. of Installment": item.noOfInstallments,
            ...getSIPTopupData(item),
          },
        };
      }
    );
  }, [
    transactionDetails,
    transactionSchemeDetails,
    isLoadingTransactionSchemesDetails,
  ]);
  //

  const handleTransactionReject = () => {
    const distributor = getDistributorDetails(transactionDistributor);
    dispatch(
      rejectTransaction({
        batchNo: transactionDetails.cartInitiateResponse.batchNo,
        pan: transactionDetails.cartInitiateResponse.pan,
        ...distributor,
        rejectReason: "Not Intrested",
      })
    );
  };

  const handleTransactionApprove = () => {
    dispatch(
      loadPaymentOptions({
        panNumber: transactionDetails.cartInitiateResponse.pan,
        folioNumber: transactionDetails.cartInitiateResponse.folioNumber,
        // arnCode: transactionDetails.cartInitiateResponse.arnCode,
        // panNumber: "ADXPS3064N",
        // folioNumber: "2108514332",
      })
    );
  };
  const handleEditTransaction = (data: any, _type: string | undefined) => {
    dispatch(setEditTransactionInfo(data));
    navigate(RouteConfig.sipTransactionEdit);
  };
  return (
    <>
      {!isLoadingTransactionSchemesDetails ? (
        <TransactionDetails
          stepper={{ currentIndex: 0, steps: TransactionSteps }}
          transactions={transactions}
          error={paymentOptionsError || rejectTransactionError}
          onApproveTranaction={handleTransactionApprove}
          onRejectTranaction={handleTransactionReject}
          onEditTransaction={handleEditTransaction}
        />
      ) : null}
      {(isLoadingPaymentOptions ||
        isLoadingTransactionSchemesDetails ||
        isRejectingTransaction) && <ActivityOverlay />}
    </>
  );
};
export default SipTransactionDetails;
