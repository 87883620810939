import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Checkbox,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Text from "components/Text";
import strings from "res/strings";
import colors from "res/colors";
import Input from "components/Input";
import { ChangeEvent, useState, useEffect, useRef } from "react";
import ButtonIcon from "components/ButtonIcon";
import isMobile from "utils/isMobile";
import {
  editTransaction,
  setNomineeDetails,
  setTransactionDetails,
  resetEditTransactionError,
} from "store/transaction/slice";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import {
  getEditTransacation,
  ONLY_NUM_REG_EXP,
  TransactionType,
} from "utils/constants";
import ActivityOverlay from "components/ActivityOverlay/ActivityOverlay";
import Select from "components/Select";
import { SelectOption } from "components/FormSelect";
import moment from "moment";
import RouteConfig from "routes/RouteConfig";
import DateInput from "components/DateInput";
import Info from "components/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const relationOption = [
  {
    title: "Father",
    value: "Father",
  },
  {
    title: "Mother",
    value: "Mother",
  },
  {
    title: "Court Appointed Guardian",
    value: "Court Appointed Guardian",
  },
];
export const typeOfGuardianOptions = [
  { title: "Natural Guardian", value: "Natural Guardian" },
  { title: "Court Appointed Guardian", value: "Court Appointed Guardian" },
];
const NomineeEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const { ihNo } = useAppSelector((state) => state.appState);
  let converter = require("number-to-words");
  const { ihNo, transactionType } = useAppSelector((state) => state.appState);
  const {
    transactionSchemeDetails,
    editTransactionInfo,
    transactionDetails,
    isEditingTransaction,
    didEditTransaction,
    editTransactionError,
    nomineeDetails,
  } = useAppSelector((state) => state.transaction);

  const [investmentOption, setInvestmentOption] = useState("");
  const [investmentAmount, setInvestmentAmount] = useState("");
  const [minAmount, setMinAmount] = useState("");
  const [amountErrorFlag, setAmountErrorFlag] = useState(false);

  const nameRegex = /^[a-zA-Z ]+$/;
  const panRegex = /^[a-zA-Z0-9]+$/;
  const [nomineeName, editNomineeName] = useState("");
  const [nameError, setNameError] = useState("");
  const [relationError, setRelationError] = useState("");
  const [allocationError, setAllocationError] = useState("");
  const [nomineeAllocation, editNomineeAllocation] = useState("");
  const [nomineeRelationShip, editNomineeRelationShip] = useState("");
  const [totalAllocation, setTotalAllocation] = useState(0);
  // const [age, setAge] = useState("");

  const [nomineeAddress, setNomineeAddress] = useState("");
  const [nomineeAddressChecked, setNomineeAddressChecked] = useState(false);
  const [nomineeDob, setNomineeDob] = useState<any>(null);
  const [nomineePan, setNomineePan] = useState("");
  const [relationshipWithInvestor, setRelationshipWithInvestor] = useState("");
  const [guardianName, setGuardianName] = useState("");
  const [guardianPan, setGuardianPan] = useState("");
  const [guardianEmail, setGuardianEmail] = useState("");
  const [relationshipWithGuardian, setRelationshipWithGuardian] = useState("");
  const [typeOfGuardian, setTypeOfGuardian] = useState("");

  const [nomineeIsMinor, setNomineeIsMinor] = useState(false);
  const [nomineeAddressError, setNomineeAddressError] = useState("");
  const [nomineePanError, setNomineePanError] = useState("");
  const [nomineeDobError, setNomineeDobError] = useState("");
  const [guardianNameError, setGuardianNameError] = useState("");
  const [guardianPanError, setGuardianPanError] = useState("");
  const [guardianEmailError, setGuardianEmailError] = useState("");
  const [relationshipWithGuardianError, setRelationshipWithGuardianError] =
    useState("");
  const [typeOfGuardianError, setTypeOfGuardianError] = useState("");
  const [tempNomineeDetailsStore, setTempNomineeDetailsStore] =
    useState<any>(null);

  const elementRef = useRef<any>(null);
  useEffect(() => {
    elementRef?.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    if (nomineeDetails) {
      let totalInvestmentStored = 0;
      nomineeDetails?.map((ele: any) => {
        totalInvestmentStored += parseFloat(ele?.nomineeAllocation);
      });
      setTotalAllocation(totalInvestmentStored);
    }
  }, [nomineeDetails]);

  useEffect(() => {
    if (Object.keys(editTransactionInfo).length) {
      editNomineeName(editTransactionInfo.contents["Nominee Name"]);
      editNomineeAllocation(
        editTransactionInfo.contents["Nominee Allocation"].slice(0, -1)
      );
      editNomineeRelationShip(
        editTransactionInfo.contents["Nominee Relationship"]
      );
      const applicantAddress = combinedAddress();
      if (
        applicantAddress ===
        editTransactionInfo?.additionalInfo["Nominee Address"]
      ) {
        setNomineeAddressChecked(true);
      }
      setNomineeAddress(editTransactionInfo?.additionalInfo["Nominee Address"]);
      const dobformat = moment(editTransactionInfo?.age).format("DD-MMM-YYYY");
      setNomineeDob(dobformat);
      setNomineePan(editTransactionInfo?.additionalInfo["Nominee Pan"] || "");
      const minor = checkIsMinor(editTransactionInfo?.age);
      setNomineeIsMinor(minor);
      if (minor) {
        setGuardianName(
          editTransactionInfo?.additionalInfo["Gaurdian Name"] || ""
        );
        setGuardianPan(
          editTransactionInfo?.additionalInfo["Gaurdian Pan"] || ""
        );
        setGuardianEmail(
          editTransactionInfo?.additionalInfo["Gaurdian Email"] || ""
        );
        setRelationshipWithGuardian(
          editTransactionInfo?.additionalInfo["Gaurdian Relation"] || ""
        );
        setTypeOfGuardian(
          editTransactionInfo?.additionalInfo["Type of Gaurdian"] || ""
        );
      }
      // setAge(editTransactionInfo?.age);
    }
  }, []);

  useEffect(() => {
    if (didEditTransaction) {
      dispatch(setNomineeDetails(tempNomineeDetailsStore));
      createNewTransactionDetails();
      navigate(-1);
      dispatch(resetEditTransactionError());
    }
  }, [didEditTransaction]);

  if (!Object.keys(editTransactionInfo).length) {
    navigate("/");
    return <div />;
  }
  const handleSelectedNomineeRelation = (option: SelectOption) => {
    editNomineeRelationShip(option.value);
  };

  const handleNomineeMajorRelation = (event: ChangeEvent<HTMLInputElement>) => {
    setRelationError("");
    if (event?.target?.value?.length <= 0) {
      setRelationError("Please enter nominee relation.");
    }
    editNomineeRelationShip(event?.target?.value);
  };
  const handleNomineeNameEdit = (event: ChangeEvent<HTMLInputElement>) => {
    setNameError("");
    if (event?.target?.value?.length <= 0) {
      setNameError("Please enter nominee name.");
    }
    if (nameRegex.test(event?.target?.value) || event?.target?.value === "") {
      if (
        event?.target?.value?.toLowerCase()?.trim() ===
        transactionDetails?.fetchFreshCartDataResponseList?.ap1LastName
          ?.toLowerCase()
          ?.trim()
      ) {
        setNameError("Nominee name can't be same as applicant name.");
      }
      editNomineeName(event.target.value);
    }
  };

  const handleNomineePanEdit = (event: ChangeEvent<HTMLInputElement>) => {
    setNomineePanError("");
    if (panRegex.test(event.target.value) || event?.target?.value === "") {
      setNomineePan(event.target.value?.toUpperCase());
    }
  };

  const handleNomineeDobEdit = (dob: any) => {
    const dobformat = moment(dob).format("DD-MMM-YYYY");
    const minor = checkIsMinor(dobformat);
    setNomineeIsMinor(minor);
    setNomineeDob(dobformat);
    setNomineeDobError("");
    if (!minor) {
      setGuardianName("");
      setGuardianPan("");
      setGuardianEmail("");
      setTypeOfGuardian("");
      setRelationshipWithGuardian("");

      setGuardianNameError("");
      setGuardianPanError("");
      setGuardianEmailError("");
      setRelationshipWithGuardianError("");
      setTypeOfGuardianError("");
    }
  };

  const combinedAddress = () => {
    let applicantAddress = [
      transactionDetails?.fetchFreshCartDataResponseList?.ap1Address1,
      transactionDetails?.fetchFreshCartDataResponseList?.ap1Address2,

      transactionDetails?.fetchFreshCartDataResponseList?.ap1City,

      transactionDetails?.fetchFreshCartDataResponseList?.ap1State,

      transactionDetails?.fetchFreshCartDataResponseList?.ap1CountryOfResidence,
      transactionDetails?.fetchFreshCartDataResponseList?.ap1PinCode,
    ]
      ?.filter((element) => Boolean(element))
      .join(", ");

    return applicantAddress;
  };

  const setSameAsApplicantAddress = () => {
    const applicantAddress = combinedAddress();
    setNomineeAddress(applicantAddress);
  };

  const handleNomineeAdressChecked = (event: ChangeEvent<HTMLInputElement>) => {
    setNomineeAddressChecked(event.target.checked);
    setNomineeAddressError("");
    if (event.target.checked) {
      setSameAsApplicantAddress();
    } else {
      setNomineeAddress("");
      setNomineeAddressError("Please enter nominee address.");
    }
  };

  const handleNomineeAddress = (event: ChangeEvent<HTMLInputElement>) => {
    setNomineeAddressError("");
    if (event?.target?.value?.length <= 0) {
      setNomineeAddressError("Please enter nominee address.");
    }
    setNomineeAddress(event.target.value);
  };

  const handleNomineeGaurdianName = (event: ChangeEvent<HTMLInputElement>) => {
    setGuardianNameError("");
    if (event?.target?.value?.length <= 0) {
      setGuardianNameError("Please enter guardian name.");
    }
    if (nameRegex.test(event?.target?.value) || event?.target?.value === "") {
      setGuardianName(event.target.value);
    }
  };

  const handleNomineeGaurdianPan = (event: ChangeEvent<HTMLInputElement>) => {
    setGuardianPanError("");
    if (event?.target?.value?.length <= 0) {
      setGuardianPanError("Please enter guardian pan.");
    }
    if (panRegex.test(event.target.value) || event?.target?.value === "") {
      setGuardianPan(event.target.value?.toUpperCase());
    }
  };
  const handleNomineeGaurdianEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setGuardianEmailError("");
    if (event?.target?.value?.length <= 0) {
      setGuardianEmailError("Please enter guardian email.");
    }
    setGuardianEmail(event.target.value);
  };

  const handleSelectedGaurdianRelation = (option: SelectOption) => {
    const value = option?.value;
    if (
      value === relationOption?.[0]?.value ||
      value === relationOption?.[1]?.value
    ) {
      setTypeOfGuardian(typeOfGuardianOptions?.[0]?.value);
    }
    if (value === relationOption?.[2]?.value) {
      setTypeOfGuardian(typeOfGuardianOptions?.[1]?.value);
    }
    setRelationshipWithGuardian(value);
    setRelationshipWithGuardianError("");
    setTypeOfGuardianError("");
  };

  const handleNomineeAllocationEdit = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setAllocationError("");
    if (event?.target?.value?.length <= 0) {
      setAllocationError("Please enter nominee allocation.");
    }
    if (
      ONLY_NUM_REG_EXP?.test(event?.target?.value) ||
      event?.target?.value === ""
    ) {
      if (parseFloat(event?.target?.value) > 100) {
        editNomineeAllocation(event.target.value);
        setAllocationError("Allocation can not be more than 100%");
      } else if (event.target.value) {
        editNomineeAllocation(event.target.value);
      } else {
        editNomineeAllocation("");
      }
    }
    editNomineeAllocation(event.target.value);
  };

  const createNewTransactionDetails = () => {
    const data = { ...transactionDetails };
    data.fetchFreshCartDataResponseList = {
      ...transactionDetails.fetchFreshCartDataResponseList,
    };

    const schemes =
      transactionDetails.fetchFreshCartDataResponseList.schemeList.map(
        (item: any, index: number) => {
          return {
            ...item,
          };
        }
      );
    if (editTransactionInfo.contentType === "Nominee1") {
      data.fetchFreshCartDataResponseList.nominee1Name = nomineeName;
      data.fetchFreshCartDataResponseList.nominee1Relation =
        nomineeRelationShip;
      data.fetchFreshCartDataResponseList.nominee1Allocation =
        nomineeAllocation;
      data.fetchFreshCartDataResponseList.nominee1Address = nomineeAddress;
      data.fetchFreshCartDataResponseList.nominee1DOB = nomineeDob;
      data.fetchFreshCartDataResponseList.nominee1Pan = nomineePan;
      data.fetchFreshCartDataResponseList.nominee1GuardianName = guardianName;
      data.fetchFreshCartDataResponseList.nominee1GuardianPan = guardianPan;
      data.fetchFreshCartDataResponseList.nominee1RelationshipWithGuardian =
        relationshipWithGuardian;
      data.fetchFreshCartDataResponseList.nominee1TypeoOfGuardian =
        typeOfGuardian;
    } else if (editTransactionInfo.contentType === "Nominee2") {
      data.fetchFreshCartDataResponseList.nominee2Name = nomineeName;
      data.fetchFreshCartDataResponseList.nominee2Relation =
        nomineeRelationShip;
      data.fetchFreshCartDataResponseList.nominee2Allocation =
        nomineeAllocation;
      data.fetchFreshCartDataResponseList.nominee2Address = nomineeAddress;
      data.fetchFreshCartDataResponseList.nominee2DOB = nomineeDob;
      data.fetchFreshCartDataResponseList.nominee2Pan = nomineePan;
      data.fetchFreshCartDataResponseList.nominee2GuardianName = guardianName;
      data.fetchFreshCartDataResponseList.nominee2GuardianPan = guardianPan;
      data.fetchFreshCartDataResponseList.nominee2RelationshipWithGuardian =
        relationshipWithGuardian;
      data.fetchFreshCartDataResponseList.nominee2TypeoOfGuardian =
        typeOfGuardian;
    } else if (editTransactionInfo.contentType === "Nominee3") {
      data.fetchFreshCartDataResponseList.nominee3Name = nomineeName;
      data.fetchFreshCartDataResponseList.nominee3Relation =
        nomineeRelationShip;
      data.fetchFreshCartDataResponseList.nominee3Allocation =
        nomineeAllocation;
      data.fetchFreshCartDataResponseList.nominee3Address = nomineeAddress;
      data.fetchFreshCartDataResponseList.nominee3DOB = nomineeDob;
      data.fetchFreshCartDataResponseList.nominee3Pan = nomineePan;
      data.fetchFreshCartDataResponseList.nominee3GuardianName = guardianName;
      data.fetchFreshCartDataResponseList.nominee3GuardianPan = guardianPan;
      data.fetchFreshCartDataResponseList.nominee3RelationshipWithGuardian =
        relationshipWithGuardian;
      data.fetchFreshCartDataResponseList.nominee3TypeoOfGuardian =
        typeOfGuardian;
    }
    // schemes[editTransactionInfo.index].amount = investmentAmount;
    // data.fetchFreshCartDataResponseList.schemeList = schemes;
    dispatch(setTransactionDetails(data));
  };

  const checkForMinorError = () => {
    let isError = false;
    if (!guardianName) {
      isError = true;
      setGuardianNameError("Please enter gaurdian name.");
    }
    // if (!guardianEmail) {
    //   isError = true;
    //   setGuardianEmailError("Please enter gaurdian email.");
    // }
    if (!relationshipWithGuardian) {
      isError = true;
      setRelationshipWithGuardianError(
        "Please enter relationship with gaurdian."
      );
    }
    if (!guardianPan) {
      isError = true;
      setGuardianPanError("Please enter gaurdian PAN.");
    }
    if (!typeOfGuardian) {
      isError = true;
      setTypeOfGuardianError("Please enter type of gaurdian.");
    }
    return isError;
  };

  const checkForNomineeError = () => {
    let isError = false;
    if (!nomineeDob) {
      isError = true;
      setNomineeDobError("Please enter nominee date of birth.");
    }
    if (nomineeDob === "Invalid date") {
      isError = true;
      setNomineeDobError("Please enter valid nominee date of birth.");
    }
    if (nomineePan && nomineePan?.length < 10) {
      isError = true;
      setNomineePanError("Please enter valid nominee PAN.");
    }
    return isError;
  };

  const handleAccept = () => {
    if (
      allocationError ||
      nameError ||
      relationError ||
      nomineeAddressError ||
      nomineePanError ||
      nomineeDobError ||
      guardianNameError ||
      guardianPanError ||
      relationshipWithGuardianError ||
      typeOfGuardianError
    ) {
      return;
    }
    if (checkForNomineeError()) {
      return;
    }
    if (nomineeIsMinor && checkForMinorError()) {
      return;
    }

    const editTransactionData = {
      transactionType:
        transactionType === TransactionType.newLumpsum ? "Lumpsum" : "SIP",
      batchNo: transactionDetails.fetchFreshCartDataResponseList.batchNo,
      transactionDateTime:
        transactionDetails.fetchFreshCartDataResponseList.transactionDateTime,
      activity: "",
      newUser: true,
    };

    let nomineeDetailsTemp: any = {};
    let nomineeTemp = [...nomineeDetails];
    if (editTransactionInfo.contentType === "Nominee1") {
      nomineeDetailsTemp["nominee1Name"] = nomineeName;
      nomineeDetailsTemp["nominee1Allocation"] = nomineeAllocation;
      nomineeDetailsTemp["nominee1Relation"] = nomineeRelationShip;
      nomineeDetailsTemp["nominee1Address"] = nomineeAddress;
      nomineeDetailsTemp["nominee1DOB"] = nomineeDob;
      nomineeDetailsTemp["nominee1Pan"] = nomineePan;
      nomineeDetailsTemp["nominee1GuardianName"] = guardianName;
      nomineeDetailsTemp["nominee1GuardianPan"] = guardianPan;
      nomineeDetailsTemp["nominee1RelationshipWithGuardian"] =
        relationshipWithGuardian;
      nomineeDetailsTemp["nominee1TypeoOfGuardian"] = typeOfGuardian;

      let temp = {
        ...nomineeDetails?.[0],
        nomineeName: nomineeName,
        nomineeRelation: nomineeRelationShip,
        nomineeAllocation: nomineeAllocation,
        nomineeAddress: nomineeAddress,
        nomineeDOB: nomineeDob,
        nomineeGuardianName: guardianName,
        nomineeGuardianPan: guardianPan,
        nomineePan: nomineePan,
        nomineeRelationshipWithGuardian: relationshipWithGuardian,
        nomineeTypeoOfGuardian: typeOfGuardian,
      };
      nomineeTemp[0] = temp;
    } else if (editTransactionInfo.contentType === "Nominee2") {
      nomineeDetailsTemp["nominee2Name"] = nomineeName;
      nomineeDetailsTemp["nominee2Relation"] = nomineeRelationShip;
      nomineeDetailsTemp["nominee2Allocation"] = nomineeAllocation;
      nomineeDetailsTemp["nominee2Address"] = nomineeAddress;
      nomineeDetailsTemp["nominee2DOB"] = nomineeDob;
      nomineeDetailsTemp["nominee2Pan"] = nomineePan;
      nomineeDetailsTemp["nominee2GuardianName"] = guardianName;
      nomineeDetailsTemp["nominee2GuardianPan"] = guardianPan;
      nomineeDetailsTemp["nominee2RelationshipWithGuardian"] =
        relationshipWithGuardian;
      nomineeDetailsTemp["nominee2TypeoOfGuardian"] = typeOfGuardian;

      let temp = {
        ...nomineeDetails?.[1],
        nomineeName: nomineeName,
        nomineeRelation: nomineeRelationShip,
        nomineeAllocation: nomineeAllocation,
        nomineeAddress: nomineeAddress,
        nomineeDOB: nomineeDob,
        nomineeGuardianName: guardianName,
        nomineeGuardianPan: guardianPan,
        nomineePan: nomineePan,
        nomineeRelationshipWithGuardian: relationshipWithGuardian,
        nomineeTypeoOfGuardian: typeOfGuardian,
      };
      nomineeTemp[1] = temp;
    } else if (editTransactionInfo.contentType === "Nominee3") {
      nomineeDetailsTemp["nominee3Name"] = nomineeName;
      nomineeDetailsTemp["nominee3Relation"] = nomineeRelationShip;
      nomineeDetailsTemp["nominee3Allocation"] = nomineeAllocation;
      nomineeDetailsTemp["nominee3Address"] = nomineeAddress;
      nomineeDetailsTemp["nominee3DOB"] = nomineeDob;
      nomineeDetailsTemp["nominee3Pan"] = nomineePan;

      nomineeDetailsTemp["nominee3GuardianName"] = guardianName;
      nomineeDetailsTemp["nominee3GuardianPan"] = guardianPan;
      nomineeDetailsTemp["nominee3RelationshipWithGuardian"] =
        relationshipWithGuardian;
      nomineeDetailsTemp["nominee3TypeoOfGuardian"] = typeOfGuardian;
      let temp = {
        ...nomineeDetails?.[2],
        nomineeName: nomineeName,
        nomineeRelation: nomineeRelationShip,
        nomineeAllocation: nomineeAllocation,
        nomineeAddress: nomineeAddress,
        nomineeDOB: nomineeDob,
        nomineeGuardianName: guardianName,
        nomineeGuardianPan: guardianPan,
        nomineePan: nomineePan,
        nomineeRelationshipWithGuardian: relationshipWithGuardian,
        nomineeTypeoOfGuardian: typeOfGuardian,
      };
      nomineeTemp[2] = temp;
    }

    setTempNomineeDetailsStore(nomineeTemp);

    const finalEditTransactionData = {
      ...transactionDetails?.fetchFreshCartDataResponseList,
      ...editTransactionData,
      ...nomineeDetailsTemp,
    };
    dispatch(editTransaction(finalEditTransactionData));
    // createNewTransactionDetails();
    // navigate(-1);
  };

  const handleScreenCancel = () => {
    if (editTransactionError) {
      dispatch(resetEditTransactionError());
    }
    transactionType === TransactionType.newLumpsum
      ? navigate(RouteConfig.newLumpsumTransactionDetails)
      : navigate(RouteConfig.newSipTransactionDetails);
  };

  const checkIsMinor = (age: any) => {
    return moment().diff(moment(age), "years") >= 18 ? false : true;
  };

  const renderNomineeMinorSection = () => {
    return (
      <>
        <Box sx={{ pt: "10px" }}>
          <Info
            message={strings.minorNomineeMessage}
            iconPosition={"center"}
            icon={<InfoOutlinedIcon sx={{ color: colors.infoTextBlue }} />}
            textStyle={{ fontSize: "15px" }}
          />
        </Box>
        <Grid item>
          <Text type="smallBold">{strings.minorNomineeGaurdian}</Text>
          <Box sx={{ my: "12px" }} />
          <Input
            value={guardianName}
            size={"small"}
            placeholder={"Name"}
            onChange={handleNomineeGaurdianName}
            error={guardianNameError}
          />
          <Box sx={{ my: "18px" }} />
          <Input
            value={guardianPan}
            size={"small"}
            placeholder={"PAN"}
            onChange={handleNomineeGaurdianPan}
            inputProps={{ maxLength: 10 }}
            error={guardianPanError}
          />
          {/* <Box sx={{ my: "18px" }} />
          <Input
            value={guardianEmail}
            size={"small"}
            placeholder={"Email"}
            onChange={handleNomineeGaurdianEmail}
            error={guardianEmailError}
          /> */}
          <Box sx={{ my: "18px" }} />
          <Select
            placeHolder={strings.gaurdianRelationship}
            value={relationshipWithGuardian}
            options={relationOption}
            onOptionSelect={handleSelectedGaurdianRelation}
            errorMessage={relationshipWithGuardianError}
            errorStyle={{
              fontSize: "14px",
              letterSpacing: "0em",
              lineHeight: "20px",
              // fontFamily: "Graphik",
              ml: "10px",
              my: "5px",
              color: "#d63227",
            }}
          />
          <Box sx={{ my: "18px" }} />
          {relationshipWithGuardian ? (
            <>
              <Text type="smallBold">{strings.typeOfGaurdian}</Text>
              <Box sx={{ my: "8px" }} />
              <Input
                value={typeOfGuardian}
                size={"small"}
                onChange={() => {}}
                error={typeOfGuardianError}
                inputProps={{ readOnly: true }}
              />
            </>
          ) : null}
          <Box sx={{ mb: "8px", mt: "24px" }}>
            <Divider />
          </Box>
        </Grid>
      </>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      ref={elementRef}
    >
      <Box
        sx={{
          mt: isMobile() ? "30%" : "10%",
          mx: "5%",
          pb: "20px",
          width: isMobile() ? "90%" : "50%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton onClick={handleScreenCancel}>
            <CancelOutlinedIcon sx={{ color: colors.gray }} />
          </IconButton>
        </Box>
        <Box sx={{ px: "24px" }}>
          <Text type="subHeadingBold">
            {strings.editNomineeDetails?.replace(
              "#",
              editTransactionInfo?.contentType?.slice(-1)
            )}
          </Text>
          <Box sx={{ my: "20px" }}>
            <Divider sx={{ backgroundColor: colors.backGroundBlue }} />
          </Box>

          <Grid container direction={"column"} rowSpacing={"24px"}>
            <Grid item>
              <Text type="smallBold">{strings.nomineeName}</Text>
              <Box sx={{ my: "12px" }} />
              <Input
                value={nomineeName}
                onChange={handleNomineeNameEdit}
                error={nameError}
                autoFocus={true}
              />
              <Box sx={{ my: "12px" }} />
            </Grid>
            <Grid item>
              <Text type="smallBold">{strings.nomineePan}</Text>
              <Box sx={{ my: "12px" }} />
              <Input
                value={nomineePan}
                size={"small"}
                placeholder={"PAN"}
                onChange={handleNomineePanEdit}
                inputProps={{ maxLength: 10 }}
                error={nomineePanError}
              />
              <Box sx={{ my: "8px" }} />
            </Grid>
            <Grid item>
              <Text type="smallBold">{strings.nomineeDob}</Text>
              <Box sx={{ my: "12px" }} />

              <DateInput
                label="Select DOB"
                onChange={handleNomineeDobEdit}
                error={Boolean(nomineeDobError)}
                helperText={nomineeDobError}
                dateValue={nomineeDob}
                inputFormat={"dd/MM/yyyy"}
              />
              {/* {nomineeDobError && <Text type="error">{nomineeDobError}</Text>} */}
              <Box sx={{ my: "8px" }} />
            </Grid>
            {nomineeIsMinor ? renderNomineeMinorSection() : null}

            <Grid>
              <Box sx={{ my: "16px" }} />
              <Text type="smallBold">{strings.allocation}</Text>
              <Box sx={{ my: "12px" }} />
              <Input
                value={nomineeAllocation}
                onChange={handleNomineeAllocationEdit}
                error={allocationError}
              />
              <Box sx={{ my: "18px" }} />
            </Grid>
            <Grid>
              <Box sx={{ my: "8px" }} />
              <Text type="smallBold">{strings.nomineeRelationShip}</Text>
              <Box sx={{ my: "12px" }} />
              <Input
                value={nomineeRelationShip}
                onChange={handleNomineeMajorRelation}
                error={relationError}
              />
            </Grid>
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mt: -1,
                }}
              >
                <Checkbox
                  sx={{
                    // mt: -1,
                    // mt: "24px",
                    // mb: "40px",
                    // color: colors.backGroundBlue,
                    "&.Mui-checked": {
                      color: colors.backGroundBlue,
                    },
                  }}
                  checked={nomineeAddressChecked}
                  onChange={handleNomineeAdressChecked}
                />
                <Text type="smallBold">{strings.nomineeSameAddress}</Text>
              </Box>
              <Box sx={{ my: "12px" }} />
              <>
                <Text type="smallBold">{strings.nomineeAddress}</Text>

                <Box sx={{ my: "12px" }} />
                <Input
                  value={nomineeAddress}
                  size={"small"}
                  placeholder={strings.nomineeAddress}
                  onChange={handleNomineeAddress}
                  error={nomineeAddressError}
                  inputProps={{ readOnly: nomineeAddressChecked }}
                />
                <Box sx={{ my: "8px" }} />
              </>
            </Grid>
          </Grid>
          <Grid>
            {nomineeDetails?.length <= 2 && totalAllocation < 100 ? (
              <>
                <ButtonIcon
                  // disabled={amountErrorFlag}
                  sx={{
                    backgroundColor: colors.white,
                    borderColor: colors.backGroundBlue,
                    color: colors.backGroundBlue,
                    width: "100%",
                    py: "12px",
                  }}
                  title={`+ Add ${converter.toWordsOrdinal(
                    nomineeDetails.length + 1
                  )} Nominee`}
                  icon={false}
                  onClick={() => {
                    navigate(RouteConfig.newTransactionNomineeAdd);
                  }}
                />
                <Box
                  sx={{
                    my: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Text
                    type="small"
                    sx={{
                      textAlign: "center",
                      fontSize: "15px",
                      letterSpacing: "0em",
                      lineHeight: "18px",
                      fontFamily: "Graphik",
                      opacity: 0.6,
                    }}
                  >
                    {strings.nomineeMessage}
                  </Text>
                </Box>
              </>
            ) : null}
          </Grid>
          <Box sx={{ pb: "12px" }} />
          {editTransactionError ? (
            <Text
              type="mediumBold"
              color="red"
              sx={{ marginTop: "10px", marginBottom: "15px" }}
            >
              {editTransactionError}
            </Text>
          ) : null}
          <ButtonIcon
            disabled={amountErrorFlag}
            title={"Accept"}
            icon={true}
            onClick={handleAccept}
          />
        </Box>
      </Box>
      {isEditingTransaction && <ActivityOverlay />}
    </Box>
  );
};

export default NomineeEdit;
