import { useEffect, useMemo } from "react";

import {
  confirmTransaction,
  rejectTransaction,
  resetConfirmTransactionStates,
  setEditTransactionInfo,
} from "store/transaction/slice";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import ActivityOverlay from "components/ActivityOverlay";
import RouteConfig from "routes/RouteConfig";
import TransactionDetails from "components/TransactionDetails/TransactionDetails";

import { INVESCO_WEB_URL, TransactionSteps } from "utils/constants";
import { convertSIPFrequency } from "utils/convertSIPFrequency";
import moment from "moment";
import { resetOtpStates } from "store/otp/slice";
import { getDistributorDetails } from "utils/getDistributorDetails";
import { convertFrequency } from "utils/getFrequencyTypes";

const SWPTransactionDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    transactionDetails,
    transactionDistributor,
    isConfirmingTransaction,
    didConfirmTransaction,
    confirmTransactionError,
    transactionSchemeDetails,
    isLoadingTransactionSchemesDetails,
    isRejectingTransaction,
    didRejectTransaction,
    rejectTransactionError,
  } = useAppSelector((state) => state.transaction);
  const { ihNo } = useAppSelector((state) => state.appState);

  useEffect(() => {
    if (didConfirmTransaction) {
      dispatch(resetConfirmTransactionStates());
      navigate(RouteConfig.swpSuccess);
    }
  }, [didConfirmTransaction]);

  useEffect(() => {
    if (didRejectTransaction) {
      // dispatch(resetOtpStates());
      // dispatch(resetConfirmTransactionStates());
      navigate(RouteConfig.swpTransactionReject);
    }
  }, [didRejectTransaction]);
  const getSwpAmount = () => {
    if (transactionDetails.fetchTransactionDetails.swpType !== "F") {
      return null;
    } else {
      return {
        "SWP Amount": parseInt(
          transactionDetails.fetchTransactionDetails.amount
        ).toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
          // minimumFractionDigits: 0,
          // maximumFractionDigits: 0,
        }),
      };
    }
  };
  const transactions = useMemo(() => {
    if (
      !transactionDetails ||
      !transactionDetails.fetchTransactionDetails ||
      isLoadingTransactionSchemesDetails
    ) {
      return [];
    }

    const data = [];

    data.push({
      title: "Withdraw From",
      canEdit: false,
      type: "SWP_FROM",
      contents: {
        Scheme: transactionSchemeDetails.length
          ? transactionSchemeDetails[0].fundDesc
          : "",
        Option: transactionSchemeDetails.length
          ? `${transactionSchemeDetails[0]?.optDesc} - ${transactionSchemeDetails[0]?.planDesc}`
          : "",
      },
    });
    const unitOrAmount = transactionDetails.fetchTransactionDetails.unit
      ? { "SWP Unit": transactionDetails.fetchTransactionDetails.unit }
      : {
          "SWP Amount": transactionDetails.fetchTransactionDetails.amount,
        };
    data.push({
      title: "SWP Details",
      index: 0,
      canEdit:
        transactionDetails.fetchTransactionDetails.swpType !== "F"
          ? false
          : true,
      type: "SWP_DETAILS",
      contents: {
        "SWP Type": convertSIPFrequency(
          transactionDetails.fetchTransactionDetails.swpType
        ),
        "SWP Frequency": convertFrequency(
          transactionDetails.fetchTransactionDetails.frequency
        ),
        "SWP Start Date": moment(
          transactionDetails.fetchTransactionDetails.startDate
        ).format("DD-MMM-YYYY"),
        "SWP End Date": moment(
          transactionDetails.fetchTransactionDetails.endDate
        ).format("DD-MMM-YYYY"),
        ...getSwpAmount(),
        "No. of SWP Installments":
          transactionDetails.fetchTransactionDetails.numberOfInstallments,
        "Bank Name": transactionDetails.fetchTransactionDetails.bankName,
        "Account number": transactionDetails.fetchTransactionDetails.bankAccNo,
      },
    });

    return data;
  }, [
    transactionDetails,
    transactionSchemeDetails,
    isLoadingTransactionSchemesDetails,
  ]);

  const handleTransactionApprove = () => {
    dispatch(
      confirmTransaction({
        investorPan: transactionDetails.fetchTransactionDetails.investorPan,
        folioNo: transactionDetails.fetchTransactionDetails.folioNumber,
        batchNo: transactionDetails.fetchTransactionDetails.batchNo,
        ihNo: transactionDetails.fetchTransactionDetails.ihno,
        txnType: "SWP",
      })
    );
  };

  const handleTransactionReject = () => {
    const distributor = getDistributorDetails(transactionDistributor);
    dispatch(
      rejectTransaction({
        batchNo: transactionDetails.fetchTransactionDetails.batchNo,
        pan: transactionDetails.fetchTransactionDetails.investorPan,
        ...distributor,
        rejectReason: "Not Intrested",
      })
    );
  };

  const handleEditTransaction = (data: any, _type: string | undefined) => {
    dispatch(setEditTransactionInfo(data));
    navigate(RouteConfig.swpedit);
  };
  return (
    <>
      {!isLoadingTransactionSchemesDetails ? (
        <TransactionDetails
          stepper={{ currentIndex: 0, steps: TransactionSteps }}
          error={confirmTransactionError || rejectTransactionError}
          transactions={transactions}
          onApproveTranaction={handleTransactionApprove}
          onEditTransaction={handleEditTransaction}
          onRejectTranaction={handleTransactionReject}
        />
      ) : null}
      {(isConfirmingTransaction ||
        isLoadingTransactionSchemesDetails ||
        isRejectingTransaction) && <ActivityOverlay />}
    </>
  );
};
export default SWPTransactionDetails;
