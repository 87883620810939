import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { TResponse } from "types";

import {
  verifyCartOtp,
  verifyTransactionOtp,
  verifyEKYCOtp,
  didVerifyOtp,
  resendOtp,
  didFailToVerifyOtp,
  generateOtp,
  didGenerateOtp,
  didFailToGenerateOtp,
  verifyNewCartOtp,
  veriftOtmOtp,
  didFailToVerifyApplicantOtp,
  didVerifyApplicantOtp,
  verifyApplicantOtp,
} from "./slice";
import {
  loadTransactionSchemeDetails,
  setTransactionInvestor,
  setTransactionDistributor,
  setTransactionInfo,
  didLoadPaymentOptions,
  setNomineeStatus,
  setNomineeDetails,
} from "../transaction/slice";
import { setEkycDetails } from "pages/ekyc/store/slice";
import { setTransactionDetails } from "../transaction/slice";
import Request from "networking/Request";
import moment from "moment";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const GENERATE_OTP_ENDPOINT = "v1/nontransaction/generateInvestorOTP";
const RESEND_OTP_ENDPOINT = "v1/nontransaction/resendInvestorOTP";

//has to check
const VERIFY_CART_OTP_ENDPOINT = "v1/transaction/fetchCartDetailsWithOtp";
const VERIFY_EKYC_OTP_ENDPOINT =
  "v1/nontransaction/fetchSignzyRecommendationWithOTP";
const VERIFY_TRANSACTION_OTP_ENDPOINT =
  "v1/transaction/fetchTransactionDetailsWithOtp";

const VERIFY_NEW_CART_OTP_ENDPOINT =
  "v1/transaction/getFreshCartDetailsWithOtp";

const VERIFY_FETCH_OTM_CART_ENDPOINT =
  "v1/transaction/otm/fetchOtmInititaeData";

const VERIFY_APPLICANT_CONTACT_OTP_ENDPOINT = "v1/nontransaction/validateOTP";
function* handleGenerateOtp(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.POST,
    GENERATE_OTP_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(didFailToGenerateOtp(response.error));
  } else {
    yield put(didGenerateOtp(response.data.data));
  }
}

function* handleResendOtp(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.POST,
    RESEND_OTP_ENDPOINT,
    params
  );
  if (response.error) {
    yield put(didFailToGenerateOtp(response.error));
  } else {
    yield put(didGenerateOtp(response.data.data));
  }
}

function* handleVerifyApplicantOtp(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.POST,
    VERIFY_APPLICANT_CONTACT_OTP_ENDPOINT,
    params
  );
  if (response.error) {
    yield put(didFailToVerifyApplicantOtp());
  } else {
    yield put(didVerifyApplicantOtp());
  }
}

function* handleVerifyOTMOtp(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    VERIFY_FETCH_OTM_CART_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(didFailToVerifyOtp(response.error));
  } else {
    if (response.data.data.fetchOtmResponse) {
      const investor = {
        "Investor Name": response.data.data.fetchOtmResponse.investorName,
        PAN: response.data.data.fetchOtmResponse.pan,
        Mobile: response.data.data.fetchOtmResponse.mobileNo,
        Email: response.data.data.fetchOtmResponse.emailId,
        "Folio No.": response.data.data.fetchOtmResponse.folioNo,
      };
      const currentTransactionDetails = {
        "Transaction Ref No.": response.data.data.fetchOtmResponse.batchNo,
        "Transaction Date": moment(
          response.data.data.fetchOtmResponse.transactionDate
        ).format("DD-MMM-YYYY"),
        "Transaction Status":
          response.data.data.fetchOtmResponse.transactionStatus,
      };
      const distributor: any = {
        "Initiated by": response.data.data.fetchOtmResponse.brokerName,
      };
      if (response.data.data.fetchOtmResponse.euinFlag === "Y") {
        distributor.ARN = response.data.data.fetchOtmResponse.brokerCode;
        distributor.EUIN = response.data.data.fetchOtmResponse.euin;
      } else if (response.data.data.fetchOtmResponse.riaFlag === "Y") {
        distributor.RIA = response.data.data.fetchOtmResponse.riaCode;
      } else {
        distributor.ARN = response.data.data.fetchOtmResponse.brokerCode;
        distributor.EUIN = response.data.data.fetchOtmResponse.euin;
      }
      const schemes = [
        {
          schemeCode: response.data.data.fetchOtmResponse.schemeCode,
          planCode: response.data.data.fetchOtmResponse.planCode,
          optionCode: response.data.data.fetchOtmResponse.option,
        },
      ];

      if (
        response.data.data.fetchOtmResponse.transactionType === "STP" ||
        response.data.data.fetchOtmResponse.transactionType === "SWITCH"
      ) {
        schemes.push({
          schemeCode: response.data.data.fetchOtmResponse.toScheme,
          planCode: response.data.data.fetchOtmResponse.toPlan,
          optionCode: response.data.data.fetchOtmResponse.toOption,
        });
      }
      yield put(setTransactionInvestor(investor));
      yield put(setTransactionDistributor(distributor));
      yield put(setTransactionInfo(currentTransactionDetails));
      yield put(loadTransactionSchemeDetails(schemes));
    }

    yield put(setTransactionDetails(response.data.data));
    yield put(didVerifyOtp());
  }
}

function* handleVerifyTransactionOtp(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    VERIFY_TRANSACTION_OTP_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(didFailToVerifyOtp(response.error));
  } else {
    if (response.data.data.fetchTransactionDetails) {
      const investor = {
        "Investor Name": response.data.data.fetchTransactionDetails.invName,
        "Folio No.": response.data.data.fetchTransactionDetails.folioNumber,
        PAN: response.data.data.fetchTransactionDetails.investorPan,
        Mobile: response.data.data.fetchTransactionDetails.invMobile,
        Email: response.data.data.fetchTransactionDetails.invEmail,
      };
      const currentTransactionDetails = {
        "Transaction Ref No.":
          response.data.data.fetchTransactionDetails.batchNo,
        "Transaction Date": moment(
          response.data.data.fetchTransactionDetails.transactionDate
        ).format("DD-MMM-YYYY"),
        "Transaction Status":
          response.data.data.fetchTransactionDetails.transactionStatus,
      };
      const distributor: any = {
        "Initiated by": response.data.data.fetchTransactionDetails.brokerName,
      };
      if (response.data.data.fetchTransactionDetails.euinFlag === "Y") {
        distributor.ARN = response.data.data.fetchTransactionDetails.brokerCode;
        distributor.EUIN = response.data.data.fetchTransactionDetails.euin;
      } else if (response.data.data.fetchTransactionDetails.riaFlag === "Y") {
        distributor.RIA = response.data.data.fetchTransactionDetails.riaCode;
      } else {
        distributor.ARN = response.data.data.fetchTransactionDetails.brokerCode;
        distributor.EUIN = response.data.data.fetchTransactionDetails.euin;
      }
      const schemes = [
        {
          schemeCode: response.data.data.fetchTransactionDetails.schemeCode,
          planCode: response.data.data.fetchTransactionDetails.planCode,
          optionCode: response.data.data.fetchTransactionDetails.option,
        },
      ];

      if (
        response.data.data.fetchTransactionDetails.transactionType === "STP" ||
        response.data.data.fetchTransactionDetails.transactionType === "SWITCH"
      ) {
        schemes.push({
          schemeCode: response.data.data.fetchTransactionDetails.toScheme,
          planCode: response.data.data.fetchTransactionDetails.toPlan,
          optionCode: response.data.data.fetchTransactionDetails.toOption,
        });
      }
      yield put(setTransactionInvestor(investor));
      yield put(setTransactionDistributor(distributor));
      yield put(setTransactionInfo(currentTransactionDetails));
      yield put(loadTransactionSchemeDetails(schemes));
    }

    yield put(setTransactionDetails(response.data.data));
    yield put(didVerifyOtp());
  }
}

function* handleVerifyNewCartOtp(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    VERIFY_NEW_CART_OTP_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(didFailToVerifyOtp(response.error));
  } else {
    if (
      response.data.data &&
      response.data.data.fetchFreshCartDataResponseList &&
      response.data.data.fetchFreshCartDataResponseList.schemeList
    ) {
      const schemes =
        response.data.data.fetchFreshCartDataResponseList.schemeList.map(
          (item: any) => {
            return {
              schemeCode: item.scheme,
              planCode: item.pln,
              optionCode: item.opt,
            };
          }
        );

      const currentTransactionDetails = {
        "Transaction Ref No.":
          response.data.data.fetchFreshCartDataResponseList.batchNo,
        "Transaction Date": moment(
          response.data.data.fetchFreshCartDataResponseList.transactionDateTime
        ).format("DD-MMM-YYYY"),
        "Transaction Status": response.data.data.fetchFreshCartDataResponseList
          .transactionStatus
          ? capitalizeFirstLetter(
              response.data.data.fetchFreshCartDataResponseList
                .transactionStatus
            )
          : "",
      };
      const distributor: any = {
        "Initiated by":
          response.data.data.fetchFreshCartDataResponseList.distributorName,
      };
      if (response.data.data.fetchFreshCartDataResponseList.euinFlag === "Y") {
        distributor.ARN =
          response.data.data.fetchFreshCartDataResponseList.distributorARN;
        distributor.EUIN =
          response.data.data.fetchFreshCartDataResponseList.euinNo;
      } else if (
        response.data.data.fetchFreshCartDataResponseList.riaFlag === "Y"
      ) {
        distributor.RIA =
          response.data.data.fetchFreshCartDataResponseList.riaCode;
      } else {
        distributor.ARN =
          response.data.data.fetchFreshCartDataResponseList.distributorARN;
        distributor.EUIN =
          response.data.data.fetchFreshCartDataResponseList.euinNo;
      }
      yield put(setTransactionDistributor(distributor));
      yield put(setTransactionInfo(currentTransactionDetails));
      yield put(loadTransactionSchemeDetails(schemes));

      // yield put(
      //   didLoadPaymentOptions([
      //     {
      //       bankName:
      //         response.data.data.fetchFreshCartDataResponseList.payoutBankName,
      //     },
      //   ])
      // );
    }
    yield put(setTransactionDetails(response.data.data));
    yield put(didVerifyOtp());
    const nomineeStatusFromApp = response?.data?.data?.fetchFreshCartDataResponseList?.idonotWishToNominee;
    yield put(setNomineeStatus(nomineeStatusFromApp));
    const transactionData =
      response?.data?.data?.fetchFreshCartDataResponseList;
    let nominee1Details = null;
    let nominee2Details = null;
    let nominee3Details = null;
    let nomineeData = [];
    if (transactionData?.nominee1Name) {
      nominee1Details = {
        nomineeAddress: transactionData?.nominee1Address,
        nomineeAllocation: transactionData?.nominee1Allocation,
        nomineeDOB: transactionData?.nominee1DOB,
        nomineeGuardianName: transactionData?.nominee1GuardianName,
        nomineeGuardianPan: transactionData?.nominee1GuardianPan,
        nomineeName: transactionData?.nominee1Name,
        nomineePan: transactionData?.nominee1Pan,
        nomineeProofOfRelationship:
          transactionData?.nominee1ProofOfRelationship,
        nomineeRelation: transactionData?.nominee1Relation,
        nomineeRelationshipWithGuardian:
          transactionData?.nominee1RelationshipWithGuardian,
        nomineeTypeoOfGuardian: transactionData?.nominee1TypeoOfGuardian,
      };
      nomineeData.push(nominee1Details);
    }
    if (transactionData?.nominee2Name) {
      nominee2Details = {
        nomineeAddress: transactionData?.nominee2Address,
        nomineeAllocation: transactionData?.nominee2Allocation,
        nomineeDOB: transactionData?.nominee2DOB,
        nomineeGuardianName: transactionData?.nominee2GuardianName,
        nomineeGuardianPan: transactionData?.nominee2GuardianPan,
        nomineeName: transactionData?.nominee2Name,
        nomineePan: transactionData?.nominee2Pan,
        nomineeProofOfRelationship:
          transactionData?.nominee2ProofOfRelationship,
        nomineeRelation: transactionData?.nominee2Relation,
        nomineeRelationshipWithGuardian:
          transactionData?.nominee2RelationshipWithGuardian,
        nomineeTypeoOfGuardian: transactionData?.nominee2TypeoOfGuardian,
      };
      nomineeData.push(nominee2Details);
    }
    if (transactionData?.nominee3Name) {
      nominee3Details = {
        nomineeAddress: transactionData?.nominee3Address,
        nomineeAllocation: transactionData?.nominee3Allocation,
        nomineeDOB: transactionData?.nominee3DOB,
        nomineeGuardianName: transactionData?.nominee3GuardianName,
        nomineeGuardianPan: transactionData?.nominee3GuardianPan,
        nomineeName: transactionData?.nominee3Name,
        nomineePan: transactionData?.nominee3Pan,
        nomineeProofOfRelationship:
          transactionData?.nominee3ProofOfRelationship,
        nomineeRelation: transactionData?.nominee3Relation,
        nomineeRelationshipWithGuardian:
          transactionData?.nominee3RelationshipWithGuardian,
        nomineeTypeoOfGuardian: transactionData?.nominee3TypeoOfGuardian,
      };
      nomineeData.push(nominee3Details);
    }
    yield put(setNomineeDetails(nomineeData));
  }
}

function* handleVerifyCartOtp(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    VERIFY_CART_OTP_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(didFailToVerifyOtp(response.error));
  } else {
    if (
      response.data.data &&
      response.data.data.cartInitiateResponse &&
      response.data.data.cartInitiateResponse.schemeList
    ) {
      const schemes = response.data.data.cartInitiateResponse.schemeList.map(
        (item: any) => {
          return {
            schemeCode: item.scheme,
            planCode: item.pln,
            optionCode: item.opt,
          };
        }
      );
      const investor = {
        "Investor Name": response.data.data.cartInitiateResponse.lname,
        "Folio No.": response.data.data.cartInitiateResponse.folioNumber,
        PAN: response.data.data.cartInitiateResponse.pan,
        Mobile: response.data.data.cartInitiateResponse.mobile,
        Email: response.data.data.cartInitiateResponse.mailID,
      };
      const currentTransactionDetails = {
        "Transaction Ref No.": response.data.data.cartInitiateResponse.batchNo,
        "Transaction Date": moment(
          response.data.data.cartInitiateResponse.transactionDateTime
        ).format("DD-MMM-YYYY"),
        "Transaction Status":
          response.data.data.cartInitiateResponse.transactionStatus,
      };
      const distributor: any = {
        "Initiated by": response.data.data.cartInitiateResponse.distributorName,
      };
      if (response.data.data.cartInitiateResponse.euinFlag === "Y") {
        distributor.ARN = response.data.data.cartInitiateResponse.arnCode;
        distributor.EUIN = response.data.data.cartInitiateResponse.euin;
      } else if (response.data.data.cartInitiateResponse.riaFlag === "Y") {
        distributor.RIA = response.data.data.cartInitiateResponse.riaCode;
      } else {
        distributor.ARN = response.data.data.cartInitiateResponse.arnCode;
        distributor.EUIN = response.data.data.cartInitiateResponse.euin;
      }
      yield put(setTransactionInvestor(investor));
      yield put(setTransactionDistributor(distributor));
      yield put(setTransactionInfo(currentTransactionDetails));
      yield put(loadTransactionSchemeDetails(schemes));
    }
    yield put(setTransactionDetails(response.data.data));
    yield put(didVerifyOtp());
  }
}

function* handleVerifyEKYCOtp(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.POST,
    VERIFY_EKYC_OTP_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(didFailToVerifyOtp(response.error));
  } else {
    yield put(setEkycDetails(response.data.data));
    yield put(didVerifyOtp());
  }
}

function* watcherVerifyEKYCOtp() {
  yield takeLatest(verifyEKYCOtp.type, handleVerifyEKYCOtp);
}
function* watcherVerifyCartOtp() {
  yield takeLatest(verifyCartOtp.type, handleVerifyCartOtp);
}
function* watcherResendOtp() {
  yield takeLatest(resendOtp.type, handleResendOtp);
}

function* watcherVerifyTransactionOtp() {
  yield takeLatest(verifyTransactionOtp.type, handleVerifyTransactionOtp);
}
function* watcherVerifyOTMOtp() {
  yield takeLatest(veriftOtmOtp.type, handleVerifyOTMOtp);
}

function* watcherVerifyNewCartOtp() {
  yield takeLatest(verifyNewCartOtp.type, handleVerifyNewCartOtp);
}

function* watcherGenerateOtp() {
  yield takeLatest(generateOtp.type, handleGenerateOtp);
}
function* watcherVerifyApplicantOtp() {
  yield takeLatest(verifyApplicantOtp.type, handleVerifyApplicantOtp);
}
export function* otpSaga() {
  yield all([
    watcherVerifyNewCartOtp(),
    watcherVerifyTransactionOtp(),
    watcherVerifyCartOtp(),
    watcherGenerateOtp(),
    watcherVerifyEKYCOtp(),
    watcherResendOtp(),
    watcherVerifyOTMOtp(),
    watcherVerifyApplicantOtp(),
  ]);
}
