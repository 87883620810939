import { useEffect, useMemo } from "react";

import {
  confirmPauseTransaction,
  confirmTransaction,
  rejectTransaction,
  resetConfirmTransactionStates,
} from "store/transaction/slice";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import ActivityOverlay from "components/ActivityOverlay";
import RouteConfig from "routes/RouteConfig";
import TransactionDetails from "components/TransactionDetails/TransactionDetails";

import { TransactionSteps } from "utils/constants";
import { getDistributorDetails } from "utils/getDistributorDetails";
import moment from "moment";

const PauseSIPTransactionDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    transactionDetails,
    transactionDistributor,
    isConfirmingTransaction,
    didConfirmTransaction,
    confirmTransactionError,
    transactionSchemeDetails,
    isLoadingTransactionSchemesDetails,
    isRejectingTransaction,
    didRejectTransaction,
    rejectTransactionError,
  } = useAppSelector((state) => state.transaction);
  const { ihNo } = useAppSelector((state) => state.appState);

  useEffect(() => {
    if (didConfirmTransaction) {
      dispatch(resetConfirmTransactionStates());
      navigate(RouteConfig.pauseSipStpSwpSuccess);
    }
  }, [didConfirmTransaction]);

  useEffect(() => {
    if (didRejectTransaction) {
      navigate(RouteConfig.pauseSipTransactionReject);
    }
  }, [didRejectTransaction]);

  const transactions = useMemo(() => {
    if (
      !transactionDetails ||
      !transactionDetails.fetchTransactionDetails ||
      isLoadingTransactionSchemesDetails
    ) {
      return [];
    }

    const data = [];

    data.push({
      title: "Scheme Details",
      canEdit: false,
      contents: {
        Scheme: transactionSchemeDetails.length
          ? transactionSchemeDetails[0].fundDesc
          : "",
        Option: transactionSchemeDetails.length
          ? `${transactionSchemeDetails[0]?.optDesc} - ${transactionSchemeDetails[0]?.planDesc}`
          : "",
        // "SIP Amount": transactionDetails.fetchTransactionDetails.amount
        //   ? transactionDetails.fetchTransactionDetails.amount
        //   : "",
        Frequency: transactionDetails.fetchTransactionDetails.frequency
          ? transactionDetails.fetchTransactionDetails.frequency
          : "",
        // "Start Date": transactionDetails.fetchTransactionDetails.startDate
        //   ? moment(transactionDetails.fetchTransactionDetails.startDate).format(
        //       "DD-MMM-YYYY"
        //     )
        //   : "",
        // "End Date": transactionDetails.fetchTransactionDetails.endDate
        //   ? moment(transactionDetails.fetchTransactionDetails.endDate).format(
        //       "DD-MMM-YYYY"
        //     )
        //   : "",
      },
    });

    data.push({
      title: "SIP Pause Details",
      canEdit: false,
      type: "SIP_PAUSE",
      contents: {
        "Pause Start Date": transactionDetails.fetchTransactionDetails
          .sipPauseStartDate
          ? moment(
              transactionDetails.fetchTransactionDetails.sipPauseStartDate
            ).format("DD-MMM-YYYY")
          : "",
        "Pause End Date": transactionDetails.fetchTransactionDetails
          .sipPauseEndDate
          ? moment(
              transactionDetails.fetchTransactionDetails.sipPauseEndDate
            ).format("DD-MMM-YYYY")
          : "",
        "No. of installments to be paused": transactionDetails
          .fetchTransactionDetails.noOfInstallmentsPaused
          ? transactionDetails.fetchTransactionDetails.noOfInstallmentsPaused
          : "",
      },
    });

    return data;
  }, [
    transactionDetails,
    transactionSchemeDetails,
    isLoadingTransactionSchemesDetails,
  ]);

  const handleTransactionApprove = () => {
    dispatch(
      confirmPauseTransaction({
        pan: transactionDetails.fetchTransactionDetails.investorPan,
        folio: transactionDetails.fetchTransactionDetails.folioNumber,
        pauseRefNo: transactionDetails.fetchTransactionDetails.ihno,
        userId: transactionDetails.fetchTransactionDetails.brokerCode,
        arnCode: transactionDetails.fetchTransactionDetails.brokerCode,
        transactionDateTime:
          transactionDetails.fetchTransactionDetails.transactionDate,

        // ihNo: transactionDetails.fetchTransactionDetails.ihno,
        // txnType: "PAUS",
      })
    );
  };

  const handleTransactionReject = () => {
    const distributor = getDistributorDetails(transactionDistributor);
    dispatch(
      rejectTransaction({
        batchNo: transactionDetails.fetchTransactionDetails.batchNo,
        pan: transactionDetails.fetchTransactionDetails.investorPan,
        ...distributor,
        rejectReason: "Not Intrested",
      })
    );
  };

  const handleEditTransaction = (data: any, _type: string | undefined) => {};
  return (
    <>
      {!isLoadingTransactionSchemesDetails ? (
        <TransactionDetails
          stepper={{ currentIndex: 0, steps: TransactionSteps }}
          error={confirmTransactionError || rejectTransactionError}
          transactions={transactions}
          onApproveTranaction={handleTransactionApprove}
          onEditTransaction={handleEditTransaction}
          onRejectTranaction={handleTransactionReject}
        />
      ) : null}
      {(isConfirmingTransaction ||
        isLoadingTransactionSchemesDetails ||
        isRejectingTransaction) && <ActivityOverlay />}
    </>
  );
};
export default PauseSIPTransactionDetails;
