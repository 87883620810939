export default Object.freeze({
  navigatioHeaderTint: "#f7f7f7",
  primary: "blue",
  secondary: "#FFD082",
  background: "#fff",
  textColor: "#6A6B6B",
  title: "#fff",
  gray: "gray",
  lightBlue: "#88E1F2",
  placeholder: "#b6b6b6",
  lightGreen: "#408341",
  containerBorder: "#d3d3d3",
  lightBG: "#ededed",
  blackTransperant: "rgba(0, 0, 0, 0.25)",
  activityOverlayBackground: "rgba(230, 230, 230, 0.25)",
  whiteOverlay: "rgba(255,255,255, 0.25)",
  black: "black",
  orange: "#e65100",
  blue: "#4A2EDE",
  darkBlue: "#120759",
  offWhiteBackground: "#F5F7F9",
  lightGray: "#d3d3d3",
  red: "#C0392B",
  lightText: "#6C6C6C",
  // lightBG: '#eeeeee',
  disbledText: "#00000080",
  radicalRed: "#f33e57",
  kellyGreen: "#169D12",
  white: "#ffffff",
  txtColor1: "#716A97", //Light Blue,
  lavenderBlue: "#C4BBFB",
  mediumSlateBlue: "#8472F6",
  green: "#219B21",
  contactItemBorderColor: "#d3d3d3",
  chatLeftBubbleBackground: "#ffe3c2",
  chatRightBubbleBackground: "#efefef",
  lightBlue2: "#e8f0fb",
  separator: "#fbfbfb",
  violet: "#9b2ac4",
  hint: "#800c0155",
  textLight: "#0c0155",
  transparent: "#80000000",
  enterOtpHint: "#80120759",
  verticalLine: "#fbfbfb",
  developmentGraphText: "#cc000000",
  developmentGraphButton: "#dbdbdb",
  blueOpacity: "#4a2ede33",
  lightGray1: "#ececec",
  lightGray2: "#e6e4e480",
  underlayColor: "#dddddd",
  devAgeIconTint: "#888888",
  radicalRedT: "#f33e5744",
  violetT: "#9b2ac444",
  disbled: "#6C6C6C33",
  pink: "#FEC3C7",
  primaryGreen: "#60b246",
  backGroundBlue: "#000AD2",
  uploadBoxBorderColor: "#A6D3D6",
  uploadBoxColor: "#edfeff",
  infoBackgroundRed: "#FEDEDC",
  infoBackgroundBlue: "#DAF0FE",
  infoBackgroundGreen: "#E4F6F3",
  infoTextRed: "#BA0A0A",
  infoTextBlue: "#2180BD",
  infoTextGreen: "#249C71",
  backgroundWhite: "#E5E5E5",
  stepperRed: "#FF4632",
});
