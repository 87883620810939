import {
  Box,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  styled,
} from "@mui/material";
import { FC } from "react";
import colors from "res/colors";
import { ProgressStepperProps } from ".";

const StepperConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.infoTextGreen,
      borderStyle: "solid",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.infoTextGreen,
      borderStyle: "solid",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderStyle: "dashed",
    borderColor: colors.gray,
    borderWidth: 1,
    width: "100%",
  },
}));

const ProgressStepper: FC<ProgressStepperProps> = ({ options, current }) => {
  // const [current, setCurrent] = useState(0);

  // const handleNext = () => {
  //   setCurrent(Math.min(current + 1, options.length));
  // };
  return (
    <Box>
      <Stepper activeStep={current} connector={<StepperConnector />}>
        {options.map((option, index) => {
          return (
            <Step key={option.id}>
              {current === option.id ? (
                <StepLabel
                  sx={{
                    "& .MuiStepLabel-iconContainer": {
                      "& .Mui-active": {
                        color: colors.stepperRed,
                        borderRadius: "5px",
                      },
                      "& .Mui-completed": {
                        color: colors.infoTextGreen,
                      },
                      "& .MuiStepIcon-text": {
                        color: colors.red,
                      },
                    },
                  }}
                >
                  {option.title}
                </StepLabel>
              ) : (
                <StepLabel
                  sx={{
                    "& .MuiStepLabel-iconContainer": {
                      "& .Mui-active": {
                        color: colors.red,
                        borderRadius: "5px",
                      },
                      "& .Mui-completed": {
                        color: colors.infoTextGreen,
                      },
                      "& .MuiStepIcon-text": {
                        color: colors.red,
                      },
                    },
                  }}
                ></StepLabel>
              )}
            </Step>
          );
        })}
      </Stepper>
      {/* <Button onClick={handleNext}>Next</Button> */}
    </Box>
  );
};

export default ProgressStepper;
