import { Box, Divider, Grid, IconButton, InputAdornment } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Text from "components/Text";
import strings from "res/strings";
import colors from "res/colors";
import Input from "components/Input";
import { ChangeEvent, useState, useEffect } from "react";
import ButtonIcon from "components/ButtonIcon";
import ToggleButtonOptions from "components/ToggleButton/ToggleButtonOptions";
import isMobile from "utils/isMobile";
import {
  editTransaction,
  setTransactionDetails,
} from "store/transaction/slice";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { getEditTransacation } from "utils/constants";
import ActivityOverlay from "components/ActivityOverlay/ActivityOverlay";

const amountData = [{ label: "1000" }, { label: "2000" }, { label: "3000" }];

const investmentType = [
  { label: "Growth" },
  { label: "IDCW Payout" },
  { label: "IDCW Reinvest" },
];

const CancelSIPEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ihNo } = useAppSelector((state) => state.appState);
  const {
    editTransactionInfo,
    transactionDetails,
    isEditingTransaction,
    didEditTransaction,
    editTransactionError,
  } = useAppSelector((state) => state.transaction);
  const [investmentOption, setInvestmentOption] = useState("");
  const [investmentAmount, setInvestmentAmount] = useState("");
  useEffect(() => {
    if (Object.keys(editTransactionInfo).length) {
      setInvestmentAmount(
        transactionDetails.cartInitiateResponse.schemeList[
          editTransactionInfo.index
        ].amount
      );
      setInvestmentOption(editTransactionInfo.contents.Option);
    }
  }, []);

  useEffect(() => {
    if (didEditTransaction) {
    }
  }, [didEditTransaction]);

  if (!Object.keys(editTransactionInfo).length) {
    navigate("/");
    return <div />;
  }

  var converter = require("number-to-words");

  const handleInvestmentAmount = (amount: string) => {
    setInvestmentAmount(amount);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInvestmentAmount(event.target.value);
  };
  const handleInvestmentOption = (InvestmentOptionSelected: string) => {
    setInvestmentOption(InvestmentOptionSelected);
  };

  const createNewTransactionDetails = () => {
    const data = { ...transactionDetails };
    data.cartInitiateResponse = { ...transactionDetails.cartInitiateResponse };

    const schemes = transactionDetails.cartInitiateResponse.schemeList.map(
      (item: any, index: number) => {
        return {
          ...item,
        };
      }
    );
    schemes[editTransactionInfo.index].amount = investmentAmount;
    data.cartInitiateResponse.schemeList = schemes;
    dispatch(setTransactionDetails(data));
  };

  const handleAccept = () => {
    const data = getEditTransacation(
      ihNo,
      "ADD",
      transactionDetails,
      transactionDetails.cartInitiateResponse.batchNo
    );

    const schemes = transactionDetails.cartInitiateResponse.schemeList.map(
      (item: { [key: string]: any }) => {
        return {
          ihNo: item.ihNo,
          optionCode: item.opt,
          amount: item.amount,
          sipStartDate: item.sipStartDate,
          sipEndDate: item.sipEndDate,
          sipFrequency: item.sipFrequency,
          noOfInstallments: item.noOfInstallments,
          topUpAmount: item.topUpAmount,
          topUpFrequency: item.topUpFrequency,
          topUpMaxYears: item.topUpMaxYears,
          topUpMaxAmount: item.topUpMaxAmount,
        };
      }
    );
    schemes[editTransactionInfo.index].amount = investmentAmount;
    data.editSchemeList = schemes;

    dispatch(editTransaction(data));
    createNewTransactionDetails();
  };

  const handleScreenCancel = () => {};

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          mt: isMobile() ? "30%" : "10%",
          mx: "5%",
          pb: "20px",
          width: isMobile() ? "90%" : "50%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton>
            <CancelOutlinedIcon
              sx={{ color: colors.gray }}
              onClick={handleScreenCancel}
            />
          </IconButton>
        </Box>
        <Box sx={{ px: "24px" }}>
          <Text type="subHeadingBold">{strings.editInvestmentDetails}</Text>
          <Box sx={{ my: "20px" }}>
            <Divider sx={{ backgroundColor: colors.backGroundBlue }} />
          </Box>

          <Grid container direction={"column"} rowSpacing={"24px"}>
            <Grid item>
              <Text type="dataLightMessage">{strings.FolioNo}</Text>
              <Text type="dataMediumBold">
                {transactionDetails.cartInitiateResponse.folioNumber}
              </Text>
            </Grid>
            <Grid item>
              <Text type="dataLightMessage">{strings.Scheme}</Text>
              <Text type="dataMediumBold">
                {editTransactionInfo.contents["Scheme Name"]}
              </Text>
            </Grid>
          </Grid>

          <Box sx={{ my: "20px" }}>
            <Divider />
          </Box>
          <ToggleButtonOptions
            options={investmentType}
            selectMultiple={false}
            setBorder={false}
            title={"Investment Option"}
            value={investmentOption}
            onClick={handleInvestmentOption}
          />
          {/* <ToggleOption
            options={investmentType}
            onClick={handleInvestmentOption}
          /> */}
          <Box sx={{ mt: "24px" }}>
            <Text type="lightMessage">{strings.IDCW}</Text>
          </Box>
          <Box sx={{ mb: "24px", mt: "12px" }}>
            <Divider />
          </Box>
          <Text type="smallBold">{strings.investmentAmount}</Text>
          <Box sx={{ py: "12px" }}>
            <Input
              value={investmentAmount}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {strings.minimumAmount}
                  </InputAdornment>
                ),
              }}
            />
            <Text type="medium">
              {parseInt(investmentAmount) > 0
                ? converter.toWords(investmentAmount)
                : null}
            </Text>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ mb: "20px" }}>
              <ToggleButtonOptions
                title="Investment Option"
                options={amountData}
                selectMultiple={false}
                setBorder={false}
                value={investmentAmount}
                onClick={handleInvestmentAmount}
              />
            </Box>
          </Box>

          {editTransactionError ? (
            <Text
              type="mediumBold"
              color="red"
              sx={{ marginTop: "10px", marginBottom: "15px" }}
            >
              {editTransactionError}
            </Text>
          ) : null}
          <ButtonIcon title={"Accept"} icon={true} onClick={handleAccept} />
        </Box>
      </Box>
      {isEditingTransaction && <ActivityOverlay />}
    </Box>
  );
};

export default CancelSIPEdit;
