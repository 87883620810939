import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type UnlockAccountState = {
  isUnlockingAccount: boolean;
  didUnlockAccount: boolean;
  unlockError: string;
};

const INITIAL_STATE: UnlockAccountState = {
  isUnlockingAccount: false,
  didUnlockAccount: false,
  unlockError: "",
};

const accountUnlock = createSlice({
  name: "accountUnlock",
  initialState: INITIAL_STATE,
  reducers: {
    unlockAccount: (state, action: PayloadAction<any>) => {
      state.isUnlockingAccount = true;
      state.didUnlockAccount = false;
      state.unlockError = "";
    },
    didUnlockAccount: (state) => {
      state.isUnlockingAccount = false;
      state.didUnlockAccount = true;
    },
    didFailToUnlockAccount: (state, action: PayloadAction<string>) => {
      state.isUnlockingAccount = false;
      state.unlockError = action.payload;
    },
  },
});

export const { unlockAccount, didUnlockAccount, didFailToUnlockAccount } =
  accountUnlock.actions;
export default accountUnlock.reducer;
