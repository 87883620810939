export default Object.freeze({
  appName: "Invesco Admin",
  welcomeScreenMessage:
    "You have been added as a new investor on the INVESCO Mutual Fund Portal. As your PAN is not KYC verified, your distributor has sent the link to complete the KYC verification.",
  ekycIntroMessage: "Your e-KYC completion approval is pending.",

  verifyYourIdentity: "VERIFY YOUR IDENTITY",
  initiatedBy: "Initated by ",
  arn: "ARN: ",
  kycVerificationPending: "YOUR KYC VERIFICATION IS PENDING",
  verifyYourTransactionMessage:
    "We need to verify your identity. OTP will be sent to your registered Email ID and mobile number",
  otpMessage: "OTP will be sent on your registered Mobile Number and Email ID.",
  generateOtp: "Generate OTP",
  invalid_kyc: "Invalid KYC",
  resentOtp: "Resend OTP in ",
  didntGetOtp: "Don't recieve the OTP? ",
  kycSuccessMessage: "Your KYC registration was successful.",
  termsAndConditionMessage1: "I hereby accept ",
  termsAndConditionMessage2:
    "and confirm to comply with all the applicable laws.",
  termsAndCondition: "terms & conditions ",
  lumpsumApprovalPending: "Your Lumpsum Transaction Approval is Pending ",
  cancelSipPending: "Your Transaction Approval is Pending ",
  pauseSipPending: "Your Pause SIP Transaction Approval is Pending ",
  sipApprovalPending: "Your SIP Transaction Approval is Pending ",

  otmApprovalPending: "Your OTM approval is Pending ",
  stpApprovalPending: "Your STP Transaction Approval is Pending ",
  swpApprovalPending: "Your SWP approval is Pending ",
  switchApprovalPending: "Your Switch Transaction approval is Pending ",
  redemptionApprovalPending: "Your Redemption Transaction approval is Pending ",
  sipCancellationPending: "Your Cancel Transaction Approval is Pending ",
  transactionPausePending: "Your Pause Transaction Approval is Pending ",
  transactionTopupPending: "Your Top-up Transaction Approval is Pending ",
  transactionRedemptionPending:
    "Your Redemption Transaction Approval is Pending",
  approvalMessage:
    "We need to verify your identity before you can approve the transaction",
  lumpsumApprovalExpiryMessage: "",
  sipApprovalExpiryMessage:
    "The transaction will expiren in case you do not respond by 24 Aug 2021 at 23:59 hours IST.",
  stpApprovalExpiryMessage: "",
  editInvestmentDetails: "Edit Investment Details",
  editBankDetails: "Edit Bank Details",
  editMandateDetails: "Edit Mandate Details",
  FolioNo: "Folio No.",
  Scheme: "Scheme",
  IDCW: "IDCW: Income distribution cum capital withdrawal(s)",
  investmentAmount: "Investment Amount",
  transactionApproval: "Transaction approval",
  otmApproval: "OTM approval",
  stpApproval: "STP approval",
  swpApproval: "SWP approval",
  switchApproval: "Switch approval",
  sipCancellation: "SIP Cancellation",
  investorApproval:
    "You have been added as a new investor on the Invesco India Mutual Fund portal.",
  minimumAmount: "Min. amt ₹500",
  minimumRedeemUnits: "Min. unit 0.001",
  minimumUnits: "Min. unit 0.01",
  successful: "Successful!",
  reject: "Rejected!",
  sipActivationPending: "SIP activation pending",
  stepsRegisterUrnlabel1:
    "Login to your registered bank’s website - ICICI Bank",
  stepsRegisterUrnlabel2:
    "Select the ‘Bill Pay’ or ‘Biller Registration‘ section",
  stepsRegisterUrnlabel3:
    "Add / Select ‘Mutual Funds’ and add Invesco Mutual Fund’ as a biller",
  stepsRegisterUrnlabel4:
    "Enter your URN as INVESCODIR - A44550 and select the ‘Auto Debit’ option",
  nameAsPerPan: "Name (as per pan)",
  yourPAN: "Your PAN",
  dob: "DOB",
  contactDetails: "Contact Details",
  primaryDetails: "Primary applicant details",
  asPerKycRecords: "As per KYC records",
  addCoApplicant: "+ Add co-applicant",
  maximumCoApplicantMessage:
    "Maximum 2 co-applicant can be added. Mode of holding will be anyone or survivor.",
  addNomineeQuestion: "Would investor like to make a nomination?",
  paymentMessage:
    "Please provide investor’s bank details through which he will make payment for schemes. Also note that  investor will recieve all redemption and dividend proceeds into this bank account.",
  accountNumber: "Account Number",
  ifsc: "IFSC",
  investorSuccessFullMessage:
    "The transaction link is successfully sent to Anand Nandkumar Vartak for approval via SMS +91 9330457890 and Email anandn1234@gmail.com.",
  mandateAmount: "  Mandate Amount",
  period: "Period",
  rejectMessage: "Are you sure you want to reject OTM request?",
  otmSuccessMessage: "Your OTM registration was successful.",
  stpSuccessMessage: "Your STP request is received for registration.",
  swpSuccessMessage: "Your SWP request is received for registration.",
  switchSuccessMessage: "Your switch request has been successfully registered.",
  transactioPauseSuccessMessage:
    "Your SIP Pause request for Invesco India Financial Services Fund was successful.",
  transactionTopupSuccessMessage:
    "Your SIP Top-up request for Invesco India Financial Services Fund was successful.",
  transactionRedemptionSuccessMessage:
    "Your Redemption towards Invesco India Largecap was successful.",
  investorApprovalRejectMessage:
    "Your Investor Approval registration was rejected.",
  otmRejectMessage: "Your eMandate registration was rejected.",
  swpRejectMessage: "Your SWP registration was rejected.",
  switchRejectMessage: "Your switch request was Rejected.",
  sipCancellationRejectMessage: "Your SIP Cancellation request was Rejected ",
  transactioPauseRejectMessage:
    "Your SIP Pause request for Invesco India Financial Services Fund was rejected",
  transactioTopupRejectMessage:
    "Your SIP Top-up request for Invesco India Financial Services Fund was rejected",
  transactioRedemptionRejectMessage:
    "Your Redemption towards Invesco India Largecap was rejected",
  sipApprovalConfirmationSecondMessage:
    " Your distributor will receive a notification for this transaction.",
  approvalConfirmationMessage:
    "It usually takes 2-3 days to confirm allocation of units. Confirmation is subject to realization of payment.",
  uanNumberGenerated: "Unique Registration Number (URN)  Generated",
  editStpTargetSchemeOption: "Edit Target Scheme Details",
  editRedemption: "Edit Redemption Amount",
  redemptionAmount: "Redemption Amount",
  editSwitch: "Edit Switch Amount",
  switchAmount: "Switch Amount",
  editSwp: "Edit SWP Amount",
  swpAmount: "SWP Amount",
  emailId: "Email Id",
  editApplicantDetails: "Edit Application Details",
  mobileNumber: "Mobile Number",
  nomineeName: "Nominee Name",

  allocation: "Allocation",
  nomineeRelationShip: "Nominee Relationship",
  mobileBelongs: "Mobile number belongs to",
  emailBelongs: "Email ID belongs to",
  addNomineeDetails: "Add Nominee # Details",
  editNomineeDetails: "Edit Nominee # Details",
  nomineePan: "PAN (Optional)",
  nomineeDob: "Date of birth",
  nomineeAllocation: "Allocation (in %)",
  nomineeRelation: "Relationship with the investor",
  nomineeSameAddress: "Address same as applicant",
  nomineeAddress: "Nominee address",
  nomineeMessage: "Maximum 3 nomination can be done with a total of 100%",
  minorNomineeMessage:
    "Nominee is minor so you need to add nominee’s guardian details",
  minorNomineeGaurdian: `Minor Nominee's Gaurdian`,
  gaurdianRelationship: "Relationship with Gaurdian",
  typeOfGaurdian: "Type of Gaurdian",
  minor_bank_details:
    "All redemption payouts will be credited to the bank account where the minor is the primary account holder.",
});
