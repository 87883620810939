import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InvestorFlow, TransactionType } from "utils/constants";
type AppStateState = {
  investorFlow: InvestorFlow;
  transactionType: TransactionType;
  transactionStage: number;
  successPageScheme: string;
  ihNo: string;
};

const INITIAL_STATE: AppStateState = {
  investorFlow: InvestorFlow.none,
  transactionType: TransactionType.none,
  transactionStage: 0,
  successPageScheme: "",
  ihNo: "",
};

const appState = createSlice({
  name: "appState",
  initialState: INITIAL_STATE,
  reducers: {
    setIHNo: (state, action: PayloadAction<string>) => {
      state.ihNo = action.payload;
    },
    setInvestorFlow: (state, action: PayloadAction<InvestorFlow>) => {
      state.investorFlow = action.payload;
    },
    setTransactionType: (state, action: PayloadAction<TransactionType>) => {
      state.transactionType = action.payload;
    },
    incrementTransactionStage: (state) => {
      state.transactionStage = state.transactionStage + 1;
    },
    decrementTransactionStage: (state) => {
      state.transactionStage = state.transactionStage - 1;
    },
    setTransactionStage: (state) => {
      state.transactionStage = 5;
    },
    setSuccessPageScheme: (state, action: PayloadAction<string>) => {
      state.successPageScheme = action.payload;
    },
  },
});

export const {
  setInvestorFlow,
  setTransactionType,
  setIHNo,
  incrementTransactionStage,
  decrementTransactionStage,
  setTransactionStage,
  setSuccessPageScheme,
} = appState.actions;
export default appState.reducer;
