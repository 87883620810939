import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TransactionState = {
  transactionDetails: { [key: string]: any };
  transactionInvestor: { [key: string]: any };
  transactionDistributor: { [key: string]: any };
  transactionInfo: { [key: string]: any };

  didApproveTransaction: boolean;

  isLoadingPaymentOptions: boolean;
  didLoadPaymentOptions: boolean;
  paymentOptions: any[];
  otmRegisteredBankData: any[];
  paymentOptionsError: string;

  isGettingBilldeskUrl: boolean;
  billdeskUrlError: string;
  billdeskUrl: string;

  isConfirmingTransaction: boolean;
  didConfirmTransaction: boolean;
  confirmTransactionError: string;

  isLoadingTransactionSchemesDetails: boolean;
  didLoadTransactionSchemeDetails: boolean;
  transactionSchemeDetails: { [key: string]: any }[];

  isRejectingTransaction: boolean;
  didRejectTransaction: boolean;
  rejectTransactionError: string;

  isEditingTransaction: boolean;
  didEditTransaction: boolean;
  editTransactionError: string;
  editTransactionInfo: { [key: string]: any };
  editedTransaction: { [key: string]: any };

  foliowiseSchemeData: { [key: string]: any };
  isLoadingFoliowiseSchemeData: boolean;
  didLoadFoliowiseScheme: boolean;

  fetchExitLoadData: { [key: string]: any };
  isLoadingFetchExitLoadData: boolean;
  didLoadFetchExitLoadData: boolean;
  didFailedToFetchExitLoad: boolean;

  otmBankData: { [key: string]: any };
  isLoadingOtmBankData: boolean;
  didLoadOtmBankData: boolean;
  didFailedToLoadOtmBankData: boolean;
  otpVerifiedMobile: any;
  otpVerifiedEmail: any;
  otpVerifiedMobileList: any;
  otpVerifiedEmailList: any;
  countVerified: number;

  otmConfirmCartData: any;
  isLoadingOtmConfirm: boolean;
  didLoadOtmConfirm: boolean;
  otmConfirmError: string;

  isLoadingValidateBank: boolean;
  didValidateBankDetails: boolean;
  validateBankDetailsError: string;

  isLoadingUploadChequeLeaf: boolean;
  didUploadChequeLeaf: boolean;
  uploadChequeLeafError: string;

  nomineeStatus: string;
  nomineeDetails: Array<{ [key: string]: any }>;
  nomineeProceedPressed: boolean;

  showUploadCheckLeaf: boolean;
  validateBankDetailsMsgShown: boolean;
};

const INITIAL_STATE: TransactionState = {
  transactionDetails: {},
  transactionInvestor: {},
  transactionDistributor: {},
  transactionInfo: {},
  didApproveTransaction: false,

  isLoadingPaymentOptions: false,
  didLoadPaymentOptions: false,
  paymentOptions: [],
  paymentOptionsError: "",
  otmRegisteredBankData: [],

  isGettingBilldeskUrl: false,
  billdeskUrlError: "",
  billdeskUrl: "",

  isConfirmingTransaction: false,
  didConfirmTransaction: false,
  confirmTransactionError: "",

  isRejectingTransaction: false,
  didRejectTransaction: false,
  rejectTransactionError: "",

  isLoadingTransactionSchemesDetails: false,
  didLoadTransactionSchemeDetails: false,
  transactionSchemeDetails: [],

  isEditingTransaction: false,
  didEditTransaction: false,
  editTransactionError: "",
  editTransactionInfo: {},
  editedTransaction: {},

  foliowiseSchemeData: {},
  isLoadingFoliowiseSchemeData: false,
  didLoadFoliowiseScheme: false,

  fetchExitLoadData: {},
  isLoadingFetchExitLoadData: false,
  didLoadFetchExitLoadData: false,
  didFailedToFetchExitLoad: false,

  otmBankData: {},
  isLoadingOtmBankData: false,
  didLoadOtmBankData: false,
  didFailedToLoadOtmBankData: false,

  otmConfirmCartData: {},
  isLoadingOtmConfirm: false,
  didLoadOtmConfirm: false,
  otmConfirmError: "",

  isLoadingValidateBank: false,
  didValidateBankDetails: false,
  validateBankDetailsError: "",

  isLoadingUploadChequeLeaf: false,
  didUploadChequeLeaf: false,
  uploadChequeLeafError: "",

  otpVerifiedMobile: {
    Applicant_1: false,
    Applicant_2: false,
    Applicant_3: false,
  },
  otpVerifiedEmail: {
    Applicant_1: false,
    Applicant_2: false,
    Applicant_3: false,
  },
  otpVerifiedMobileList: {
    Applicant_1: "",
    Applicant_2: "",
    Applicant_3: "",
  },
  otpVerifiedEmailList: {
    Applicant_1: "",
    Applicant_2: "",
    Applicant_3: "",
  },
  countVerified: 0,
  nomineeStatus: "",
  nomineeDetails: [],
  nomineeProceedPressed: false,

  showUploadCheckLeaf: false,
  validateBankDetailsMsgShown: false,
};

const transaction = createSlice({
  name: "transaction",
  initialState: INITIAL_STATE,
  reducers: {
    setTransactionDetails: (state, action: PayloadAction<any>) => {
      state.transactionDetails = action.payload;
    },
    setTransactionInvestor: (state, action: PayloadAction<any>) => {
      state.transactionInvestor = action.payload;
    },
    setTransactionDistributor: (state, action: PayloadAction<any>) => {
      state.transactionDistributor = action.payload;
    },
    setTransactionInfo: (state, action: PayloadAction<any>) => {
      state.transactionInfo = action.payload;
    },
    setTransactionApproval: (state, action: PayloadAction<boolean>) => {
      state.didApproveTransaction = action.payload;
    },
    loadPaymentOptions: (state, _action: PayloadAction<any>) => {
      state.isLoadingPaymentOptions = true;
      state.didLoadPaymentOptions = false;
    },
    didLoadPaymentOptions: (state, action: PayloadAction<any>) => {
      state.isLoadingPaymentOptions = false;
      state.didLoadPaymentOptions = true;
      state.paymentOptions = action?.payload?.registeredBankResponseList;
      state.otmRegisteredBankData = action?.payload?.otmBankResponseList;
    },
    didFailToLoadPaymentOptions: (state, action: PayloadAction<any>) => {
      state.isLoadingPaymentOptions = false;
      state.paymentOptionsError = action.payload;
    },

    loadNewTransactionPaymentOptions: (state, _action: PayloadAction<any>) => {
      state.isLoadingPaymentOptions = true;
      state.didLoadPaymentOptions = false;
    },
    didLoadNewTransactionPaymentOptions: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoadingPaymentOptions = false;
      state.didLoadPaymentOptions = true;
      state.paymentOptions = action.payload;
    },
    didFailToLoadNewTransactionPaymentOptions: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoadingPaymentOptions = false;
      state.paymentOptionsError = action.payload;
    },

    confirmTransaction: (state, _action: PayloadAction<any>) => {
      state.isConfirmingTransaction = true;
      state.didConfirmTransaction = false;
    },
    confirmPauseTransaction: (state, _action: PayloadAction<any>) => {
      state.isConfirmingTransaction = true;
      state.didConfirmTransaction = false;
    },
    confirmCancelTransaction: (state, _action: PayloadAction<any>) => {
      state.isConfirmingTransaction = true;
      state.didConfirmTransaction = false;
    },
    didConfirmTransaction: (state, _action: PayloadAction<any>) => {
      state.isConfirmingTransaction = false;
      state.didConfirmTransaction = true;
    },
    didFailToConfirmConfirmTransaction: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isConfirmingTransaction = false;
      state.confirmTransactionError = action.payload;
    },

    resetConfirmTransactionStates: (state) => {
      state.isConfirmingTransaction = false;
      state.didConfirmTransaction = false;
      state.confirmTransactionError = "";

      state.isRejectingTransaction = false;
      state.didRejectTransaction = false;
      state.rejectTransactionError = "";
    },
    resetPaymentOptions: (state) => {
      state.isLoadingPaymentOptions = false;
      state.didLoadPaymentOptions = false;
      state.paymentOptionsError = "";
      state.paymentOptions = [];
    },
    resetDidLoadPaymentOptions: (state) => {
      state.didLoadPaymentOptions = false;
    },

    getBilldeskUrl: (state, _action: PayloadAction<any>) => {
      state.isGettingBilldeskUrl = true;
    },
    getOtmBilldeskUrl: (state, _action: PayloadAction<any>) => {
      state.isGettingBilldeskUrl = true;
    },
    didGetBilldeskUrl: (state, action: PayloadAction<any>) => {
      state.isGettingBilldeskUrl = false;
      state.billdeskUrl = action.payload;
    },
    didFailToGetBilldeskUrl: (state, action: PayloadAction<any>) => {
      state.isGettingBilldeskUrl = false;
      state.billdeskUrlError = action.payload;
    },
    resetBilldeskDetails: (state) => {
      state.isGettingBilldeskUrl = false;
      state.paymentOptionsError = "";
      state.billdeskUrl = "";
    },
    resetBilldeskError: (state) => {
      state.billdeskUrlError = "";
    },
    loadTransactionSchemeDetails: (state, action: PayloadAction<any>) => {
      state.isLoadingTransactionSchemesDetails = true;
    },
    didLoadTransactionSchemeDetails: (state, action: PayloadAction<any>) => {
      state.isLoadingTransactionSchemesDetails = false;
      state.didLoadTransactionSchemeDetails = true;

      state.transactionSchemeDetails = action.payload;
    },
    setTransactionSchemeDetails: (state, action: PayloadAction<any>) => {
      state.transactionSchemeDetails = action.payload;
    },
    rejectTransaction: (state, _action: PayloadAction<any>) => {
      state.isRejectingTransaction = true;
      state.didRejectTransaction = false;
    },

    didRejectTransaction: (state, _action: PayloadAction<any>) => {
      state.isRejectingTransaction = false;
      state.didRejectTransaction = true;
      state.transactionDetails = {};
      state.transactionDistributor = {};
      state.editTransactionInfo = {};
      state.transactionInvestor = {};
    },
    didFailToRejectTransaction: (state, action: PayloadAction<string>) => {
      state.isRejectingTransaction = false;
      state.rejectTransactionError = action.payload;
    },

    editTransaction: (state, _action: PayloadAction<any>) => {
      state.isEditingTransaction = true;
      state.didEditTransaction = false;
      state.editTransactionError = "";
    },
    didEditTransaction: (state, action: PayloadAction<any>) => {
      state.isEditingTransaction = false;
      state.didEditTransaction = true;
    },
    didFailToEditTransaction: (state, action: PayloadAction<any>) => {
      state.isEditingTransaction = false;
      state.editTransactionError = action.payload;
    },
    resetEditTransactionError: (state) => {
      state.editTransactionError = "";
      state.didEditTransaction = false;
    },
    setEditTransactionInfo: (state, action: PayloadAction<any>) => {
      state.editTransactionInfo = action.payload;
    },
    setEditedTransaction: (state, action: PayloadAction<any>) => {
      state.editedTransaction = action.payload;
    },

    loadFoliowiseSchemeData: (state, action: PayloadAction<any>) => {
      state.isLoadingFoliowiseSchemeData = true;
      state.didLoadFoliowiseScheme = false;
    },
    didLoadFoliowiseSchemeData: (state, action: PayloadAction<any>) => {
      state.foliowiseSchemeData = action.payload;
      state.isLoadingFoliowiseSchemeData = false;
      state.didLoadFoliowiseScheme = true;
    },
    didFailToLoadFoliowiseSchemeData: (state, action: PayloadAction<any>) => {
      state.foliowiseSchemeData = action.payload;
      state.isLoadingFoliowiseSchemeData = false;
      state.didLoadFoliowiseScheme = false;
    },
    resetFolioWiseSchemeDetails: (state) => {
      state.foliowiseSchemeData = {};
      state.isLoadingFoliowiseSchemeData = false;
      state.didLoadFoliowiseScheme = false;
    },
    loadFetchExitLoadData: (state, action: PayloadAction<any>) => {
      state.isLoadingFetchExitLoadData = true;

      state.didLoadFetchExitLoadData = false;
      state.didFailedToFetchExitLoad = false;
    },
    didLoadFetchExitLoadData: (state, _action: PayloadAction<any>) => {
      state.fetchExitLoadData = _action.payload;
      state.isLoadingFetchExitLoadData = false;
      state.didLoadFetchExitLoadData = true;
      state.didFailedToFetchExitLoad = false;
    },
    didFailedToLoadFetchExitLoad: (state) => {
      state.didFailedToFetchExitLoad = true;
      state.isLoadingFetchExitLoadData = false;
    },

    loadOtmBankData: (state, action: PayloadAction<any>) => {
      state.isLoadingOtmBankData = true;
      state.didLoadOtmBankData = false;
      state.didFailedToLoadOtmBankData = false;
    },
    didLoadOtmBankData: (state, _action: PayloadAction<any>) => {
      state.otmBankData = _action.payload;
      state.isLoadingOtmBankData = false;
      state.didLoadOtmBankData = true;
    },
    didFailedLoadOtmBankData: (state) => {
      state.isLoadingOtmBankData = false;
      state.didFailedToLoadOtmBankData = true;
    },

    loadOtmConfirmation: (state, action: PayloadAction<any>) => {
      state.isLoadingOtmConfirm = true;
      state.didLoadOtmConfirm = false;
      state.otmConfirmCartData = {};
      state.otmConfirmError = "";
    },

    didLoadOtmConfirmation: (state, _action: PayloadAction<any>) => {
      state.isLoadingOtmConfirm = false;
      state.otmConfirmCartData = _action?.payload;
      state.didLoadOtmConfirm = true;
      state.otmConfirmError = "";
    },

    didFailToLoadOtmConfirmation: (state, _action: PayloadAction<any>) => {
      state.isLoadingOtmConfirm = false;
      state.otmConfirmError = _action?.payload;
      state.didLoadOtmConfirm = false;
    },
    resetOtmConfirmation: (state) => {
      state.isLoadingOtmConfirm = false;
      state.didLoadOtmConfirm = false;
      state.otmConfirmCartData = {};
      state.otmConfirmError = "";
    },
    validateBankDetails: (state, action: PayloadAction<any>) => {
      state.isLoadingValidateBank = true;
      state.didValidateBankDetails = false;
      state.validateBankDetailsError = "";
    },

    didValidateBankDetails: (state, action: PayloadAction<any>) => {
      state.isLoadingValidateBank = false;
      state.didValidateBankDetails = true;
    },

    didFailToValidateBankDetails: (state, action: PayloadAction<any>) => {
      state.isLoadingValidateBank = false;
      state.validateBankDetailsError = action?.payload;
    },
    uploadChequeLeaf: (state, action: PayloadAction<any>) => {
      state.isLoadingUploadChequeLeaf = true;
      state.didUploadChequeLeaf = false;
      state.uploadChequeLeafError = "";
    },
    didUploadChequeLeaf: (state, action: PayloadAction<any>) => {
      state.isLoadingUploadChequeLeaf = false;
      state.didUploadChequeLeaf = true;
    },
    didFailToUploadChequeLeaf: (state, action: PayloadAction<any>) => {
      state.isLoadingUploadChequeLeaf = false;
      state.uploadChequeLeafError = action?.payload;
    },
    resetValidateBankDetails: (state) => {
      state.isLoadingValidateBank = false;
      state.didValidateBankDetails = false;
      state.validateBankDetailsError = "";
    },
    resetUploadChequeLeaf: (state) => {
      state.isLoadingUploadChequeLeaf = false;
      state.uploadChequeLeafError = "";
    },
    resetUploadChequeLeafMessage: (state) => {
      state.showUploadCheckLeaf = false;
    },
    resetValidationError: (state) => {
      state.validateBankDetailsError = "";
    },
    setOtpVerifiedMobile: (state, action: PayloadAction<any>) => {
      state.otpVerifiedMobile[action.payload] = true;
    },
    setOtpVerifiedEmail: (state, action: PayloadAction<any>) => {
      state.otpVerifiedEmail[action.payload] = true;
    },
    setOtpVerifiedMobileList: (state, action: PayloadAction<any>) => {
      state.otpVerifiedMobileList[action.payload?.key] = action.payload?.value;
    },
    setOtpVerifiedEmailList: (state, action: PayloadAction<any>) => {
      state.otpVerifiedEmailList[action.payload?.key] = action.payload?.value;
    },
    setCountVerified: (state, action: PayloadAction<any>) => {
      state.countVerified = action.payload;
    },
    setNomineeStatus: (state, action: PayloadAction<any>) => {
      state.nomineeStatus = action.payload;
    },
    setNomineeDetails: (state, action: PayloadAction<any>) => {
      state.nomineeDetails = action.payload;
    },
    setNomineeProceedPressed: (state) => {
      state.nomineeProceedPressed = true;
    },
    resetNomineeProceedPressed: (state) => {
      state.nomineeProceedPressed = false;
    },
    setShowUploadCheckLeaf: (state) => {
      state.showUploadCheckLeaf = true;
    },
    setValidateBankDetailsMsgShown: (state) => {
      state.validateBankDetailsMsgShown = true;
    },
  },
});

export const {
  resetBilldeskError,
  setValidateBankDetailsMsgShown,
  resetUploadChequeLeafMessage,
  setShowUploadCheckLeaf,
  resetValidationError,
  resetNomineeProceedPressed,
  setNomineeProceedPressed,
  setNomineeStatus,
  setNomineeDetails,
  setTransactionInvestor,
  setTransactionDistributor,
  setTransactionInfo,
  setEditedTransaction,
  setEditTransactionInfo,
  editTransaction,
  didEditTransaction,
  didFailToEditTransaction,
  resetEditTransactionError,
  rejectTransaction,
  didRejectTransaction,
  didFailToRejectTransaction,
  loadTransactionSchemeDetails,
  didLoadTransactionSchemeDetails,
  setTransactionDetails,
  setTransactionApproval,
  loadPaymentOptions,
  didLoadPaymentOptions,
  didFailToLoadPaymentOptions,
  resetPaymentOptions,
  resetDidLoadPaymentOptions,

  getOtmBilldeskUrl,
  getBilldeskUrl,
  didGetBilldeskUrl,
  didFailToGetBilldeskUrl,
  resetBilldeskDetails,
  confirmTransaction,
  confirmPauseTransaction,
  confirmCancelTransaction,
  didConfirmTransaction,
  didFailToConfirmConfirmTransaction,

  loadNewTransactionPaymentOptions,
  didFailToLoadNewTransactionPaymentOptions,
  didLoadNewTransactionPaymentOptions,

  resetConfirmTransactionStates,

  setTransactionSchemeDetails,
  loadFoliowiseSchemeData,
  didFailToLoadFoliowiseSchemeData,
  didLoadFoliowiseSchemeData,
  resetFolioWiseSchemeDetails,

  loadFetchExitLoadData,
  didFailedToLoadFetchExitLoad,
  didLoadFetchExitLoadData,

  loadOtmBankData,
  didFailedLoadOtmBankData,
  didLoadOtmBankData,

  setOtpVerifiedMobile,
  setOtpVerifiedEmail,

  setOtpVerifiedMobileList,
  setOtpVerifiedEmailList,
  setCountVerified,

  loadOtmConfirmation,
  didLoadOtmConfirmation,
  didFailToLoadOtmConfirmation,
  resetOtmConfirmation,

  validateBankDetails,
  didValidateBankDetails,
  didFailToValidateBankDetails,
  resetValidateBankDetails,

  uploadChequeLeaf,
  didUploadChequeLeaf,
  didFailToUploadChequeLeaf,
  resetUploadChequeLeaf,
} = transaction.actions;
export default transaction.reducer;
