/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { useAppSelector } from "hooks/useAppSelector";
import TransactionDetails from "components/TransactionDetails/TransactionDetails";
import ActivityOverlay from "components/ActivityOverlay/ActivityOverlay";
import { TransactionSteps } from "utils/constants";
import { useAppDispatch } from "hooks/useAppDispatch";
import {
  rejectTransaction,
  setEditTransactionInfo,
  getOtmBilldeskUrl,
  loadOtmBankData,
} from "store/transaction/slice";
import RouteConfig from "routes/RouteConfig";
import { useNavigate } from "react-router-dom";
import { getDistributorDetails } from "utils/getDistributorDetails";
import moment from "moment";

const OTMTransactionDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    transactionDetails,
    transactionDistributor,
    isLoadingPaymentOptions,
    didLoadPaymentOptions,
    paymentOptionsError,
    transactionSchemeDetails,
    isLoadingTransactionSchemesDetails,
    isRejectingTransaction,
    didRejectTransaction,
    rejectTransactionError,
    billdeskUrl,
    otmBankData,
    isLoadingOtmBankData,
    didLoadOtmBankData,
    didFailedToLoadOtmBankData,
  } = useAppSelector((state) => state.transaction);

  // useEffect(() => {
  //   if (didLoadOtmBankData) {
  //     //
  //     //

  //   }
  // }, [didLoadOtmBankData]);

  useEffect(() => {
    if (didRejectTransaction) {
      // dispatch(resetOtpStates());
      // dispatch(resetConfirmTransactionStates());
      navigate(RouteConfig.otmTransactionReject);
    }
  }, [didRejectTransaction]);

  useEffect(() => {
    if (billdeskUrl) {
      //
      // window.location.replace(billdeskUrl);
      //This is POST url call implementation to hide the confidential data in billdesk url wich shows in browser address bar.
      let billdeskUrlArray = billdeskUrl?.split("?");
      const finalURLVAluesArray = billdeskUrlArray[1]
        ?.split("&")
        ?.map((ele: any) => ele.split("=")[1]);

      // Create form element
      const form = document.createElement("form");
      form.id = "myForm";
      form.method = "post";
      form.action = billdeskUrlArray[0];
      const action = document.createElement("input");
      action.type = "hidden";
      action.name = "action";
      action.value = finalURLVAluesArray[0];

      const msg = document.createElement("input");
      msg.type = "hidden";
      msg.name = "msg";
      msg.value = finalURLVAluesArray[1];

      // Create submit button
      const submitBtn = document.createElement("button");
      submitBtn.type = "submit";
      submitBtn.textContent = "Submit";

      // Append elements to the form
      form.appendChild(action);
      form.appendChild(msg);
      form.appendChild(submitBtn);
      document.body.appendChild(form);
      form.submit();
    }
  }, [billdeskUrl]);

  const getOtmEndDate = () => {
    if (transactionDetails.fetchOtmResponse.otmEndDate) {
      return {
        "End Date": moment(
          transactionDetails.fetchOtmResponse.otmEndDate,
          "MMM DD YYYY hh:mmA"
        ).format("DD-MMM-YYYY"),
      };
    }
    return {};
  };
  const transactions = useMemo(() => {
    if (
      !transactionDetails ||
      !transactionDetails.fetchOtmResponse ||
      // !transactionDetails.cartInitiateResponse.schemeList ||
      isLoadingTransactionSchemesDetails
    ) {
      return [];
    }

    const data = [];
    data.push({
      title: "Bank Details",
      canEdit: false,
      type: "OTM_BANK",
      contents: {
        "Bank name": transactionDetails.fetchOtmResponse.bankName,
        "Account No.": transactionDetails.fetchOtmResponse.acNo,
        "Account type": transactionDetails.fetchOtmResponse.acType,
        "IFSC code": transactionDetails.fetchOtmResponse.ifscCode,
      },
    });

    data.push({
      title: "Mandate Details",
      canEdit: false,
      type: "MANDATE",
      contents: {
        Amount: parseInt(
          transactionDetails.fetchOtmResponse.amount
        ).toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
          // minimumFractionDigits: 0,
          // maximumFractionDigits: 0,
        }),
        "Amount Type": transactionDetails.fetchOtmResponse.otmAmountType,
        Frequency: transactionDetails.fetchOtmResponse.otmFrequency,
        Period: transactionDetails.fetchOtmResponse.otmPeriod,
        "Purpose of Mandate":
          transactionDetails.fetchOtmResponse.purposeOfMandate,
        "Authentication mode":
          transactionDetails.fetchOtmResponse.otmAuthenticationMode,
        "Start Date": moment(
          transactionDetails.fetchOtmResponse.otmStartDate,
          "MMM DD YYYY hh:mmA"
        ).format("DD-MMM-YYYY"),
        ...getOtmEndDate(),
      },
    });

    data.push({
      title: "Coporate Information",
      canEdit: false,
      type: "CORPORATE",
      contents: {
        Name: "Invesco Mutual Fund",
        "Utility No.": "NACH000000003747",
      },
    });

    return data;
  }, [
    transactionDetails,
    transactionSchemeDetails,
    isLoadingTransactionSchemesDetails,
  ]);
  //

  const handleTransactionReject = () => {
    const distributor = getDistributorDetails(transactionDistributor);
    dispatch(
      rejectTransaction({
        batchNo: transactionDetails.fetchOtmResponse.batchNo,
        pan: transactionDetails.fetchOtmResponse.pan,
        ...distributor,
        rejectReason: "Not Intrested",
      })
    );
  };

  const handleTransactionApprove = () => {
    dispatch(
      getOtmBilldeskUrl({
        uniqueRegNumberOTM: transactionDetails.fetchOtmResponse.payoutRefNo,
        accountNumber: transactionDetails.fetchOtmResponse.acNo,
        bankIfscCode: transactionDetails.fetchOtmResponse.ifscCode,
        folioNo: transactionDetails.fetchOtmResponse.folioNo,
        investorEmailId: transactionDetails.fetchOtmResponse.emailId,
        userName: transactionDetails.fetchOtmResponse.investorName,
        investorMobileNumber: transactionDetails.fetchOtmResponse.mobileNo,
        modeOfAuthentication:
          transactionDetails.fetchOtmResponse.otmAuthenticationMode.toUpperCase(),
        accountType:
          transactionDetails.fetchOtmResponse.acType === "SAVINGS"
            ? "SB"
            : transactionDetails.fetchOtmResponse.acType === "CURRENT"
            ? "CB"
            : transactionDetails.fetchOtmResponse.acType,
        maximumOTMAmount: transactionDetails.fetchOtmResponse.amount,
        otmAmountType: transactionDetails.fetchOtmResponse.otmAmountType.slice(
          0,
          3
        ),
        otmStartDate: moment(
          transactionDetails.fetchOtmResponse.otmStartDate,
          "MMM DD YYYY hh:mmA"
        ).format("YYYYMMDD"),
        otmEndDate: moment(
          transactionDetails.fetchOtmResponse.otmEndDate,
          "MMM DD YYYY hh:mmA"
        ).format("YYYYMMDD"),
        otmFrequency:
          transactionDetails.fetchOtmResponse.otmFrequency ===
          "As and when presented"
            ? "ADHO"
            : transactionDetails.fetchOtmResponse.otmFrequency,
        pan: transactionDetails.fetchOtmResponse.pan,
        bankCode: transactionDetails.fetchOtmResponse.ifscCode.slice(0, 4),
      })
    );
    // dispatch(
    //   loadOtmBankData({
    //     ifscCode: transactionDetails.fetchOtmResponse.ifscCode,
    //   })
    // );
  };
  const handleEditTransaction = (data: any, _type: string | undefined) => {
    dispatch(setEditTransactionInfo(data));
    navigate(RouteConfig.sipTransactionEdit);
  };
  return (
    <>
      {!isLoadingTransactionSchemesDetails ? (
        <TransactionDetails
          stepper={{ currentIndex: 0, steps: TransactionSteps }}
          transactions={transactions}
          error={paymentOptionsError || rejectTransactionError}
          onApproveTranaction={handleTransactionApprove}
          onRejectTranaction={handleTransactionReject}
          onEditTransaction={handleEditTransaction}
        />
      ) : null}
      {(isLoadingPaymentOptions ||
        isLoadingTransactionSchemesDetails ||
        isRejectingTransaction) && <ActivityOverlay />}
    </>
  );
};
export default OTMTransactionDetails;
